import { apiTimeStringToDate, isApiTimeString } from '../types/datetime'

// Extracts the duration values from a millisecond value.
export const msToDuration = (ms) => {
    let seconds = Math.floor(ms / 1000) % 60
    let minutes = Math.floor(ms / (1000 * 60)) % 60
    let hours = Math.floor(ms / (1000 * 60 * 60)) % 24
    let days = Math.floor(ms / (1000 * 60 * 60 * 24))

    return { seconds, minutes, hours, days }
}

// Converts a millisecond value to a human readable string
export const millisecondsToString = (ms) => {
    const duration = msToDuration(ms)
    return `${duration.days}d ${duration.hours}h ${duration.minutes}m ${duration.seconds}s`
}

export const DEFAULT_TIME_STR = ' - '

// Converts a maybe time to string
export const timeToString = (ms) =>
    ms ? millisecondsToString(ensureDateIsNumber(ms)) : DEFAULT_TIME_STR

export const ensureDateIsNumber = (date) =>
    typeof date === 'number' ? date : date.getTime()

export const auctionTime = (maybeDate, relative = Date.now()) => {
    const date = maybeDate && new Date(parseInt(maybeDate) * 1000)
    if (!date) return DEFAULT_TIME_STR
    const ms = ensureDateIsNumber(date)

    return ms - relative > 0 ? timeToString(ms - relative) : DEFAULT_TIME_STR
}

export const timestampToString = (timestamp) => {
    return new Date(parseInt(timestamp) * 1000).toLocaleString()
}

// Checks if a value is a valid date
export const isValidDate = (date) => date instanceof Date && !isNaN(+date)

// reg expression to check if a string is a valid date and capture all elements
export const stringToDate = (dateStr) => {
    if (isApiTimeString(dateStr)) return apiTimeStringToDate(dateStr)
    return null
}

export const prepend = (input, replace) =>
    (replace + input).slice(-replace.length)

export function format(dateInput, defaultValue = DEFAULT_TIME_STR) {
    const date =
        typeof dateInput === 'string'
            ? stringToDate(dateInput)
            : typeof dateInput === 'number'
            ? new Date(dateInput)
            : dateInput
    if (!date) return defaultValue
    return `${prepend(date.getFullYear(), '0000')}-${prepend(
        date.getMonth() + 1,
        '00',
    )}-${prepend(date.getDate(), '00')} ${prepend(
        date.getHours(),
        '00',
    )}:${prepend(date.getMinutes(), '00')}:${prepend(date.getSeconds(), '00')}`
}
