import { getGlobalObject } from './global'

/**
 * Tells whether current environment supports ErrorEvent objects
 * {@link supportsErrorEvent}.
 */
export const supportsErrorEvent = () => {
    try {
        new ErrorEvent('')
        return true
    } catch (_error) {
        return false
    }
}

/**
 * Tells whether current environment supports DOMError objects
 * {@link supportsDOMError}.
 */
export const supportsDOMError = () => {
    try {
        new DOMError('')
        return true
    } catch (_error) {
        return false
    }
}

/**
 * Tells whether current environment supports DOMException objects
 * {@link supportsDOMException}.
 */
export const supportsDOMException = () => {
    try {
        new DOMException('')
        return true
    } catch (_error) {
        return false
    }
}

/**
 * Tells whether current environment supports Fetch API
 * {@link supportsFetch}.
 *
 * @returns Answer to the given question.
 */
export const supportsFetch = () => {
    if (!('fetch' in getGlobalObject())) {
        return false
    }

    try {
        new Headers()
        new Request('')
        new Response()
        return true
    } catch (_error) {
        return false
    }
}

/**
 * Tells whether current environment supports ReportingObserver API
 * {@link supportsReportingObserver}.
 */
export const supportsReportingObserver = () =>
    'ReportingObserver' in getGlobalObject()

/**
 * Tells whether current environment supports Referrer Policy API
 * {@link supportsReferrerPolicy}.
 */

/**
 * Tells whether current environment supports History API
 * {@link supportsHistory}.
 */
export const supportsHistory = () => {
    const global = getGlobalObject()
    const hasHistoryApi =
        'history' in global &&
        !!global.history.pushState &&
        !!global.history.replaceState

    return hasHistoryApi
}
