import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { dropStartEndDateEditor } from '../editor/DropCreatorUtils'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function UpdateStartEndDatePopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const dropId = props['dropId']

    const [startDateTime, setStartDateTime] = useState(
        new Date(props['dropStartDateTime']),
    )
    const [endDateTime, setEndDateTime] = useState(
        new Date(props['dropEndDateTime']),
    )

    const [dropStartDateEnabled, setDropStartDateEnabled] = useState(
        props['dropStartDateTime'] !== undefined,
    )
    const [dropEndDateEnabled, setDropEndDateEnabled] = useState(
        props['dropEndDateTime'] !== undefined,
    )
    const [use24hrFormat, setUse24hrFormat] = useState(false)

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const data = {
            authorized_account: userName,
            drop_id: dropId,
            start_time: dropStartDateEnabled
                ? Math.floor(startDateTime.getTime() / 1000)
                : 0,
            end_time: dropEndDateEnabled
                ? Math.floor(endDateTime.getTime() / 1000)
                : 0,
        }

        const action = {
            account: 'nfthivedrops',
            name: 'setdroptimes',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: data,
        }

        actions.push(action)
        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result.transactionId
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Change Drop Start and Endtime" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to update the Start- and End Date/Time of Drop{' '}
                {dropId} ({collectionName})?
            </div>
            <div className="PopupBody">
                <div>
                    {dropStartEndDateEditor(
                        startDateTime,
                        setStartDateTime,
                        dropStartDateEnabled,
                        setDropStartDateEnabled,
                        endDateTime,
                        setEndDateTime,
                        dropEndDateEnabled,
                        setDropEndDateEnabled,
                        use24hrFormat,
                        setUse24hrFormat,
                    )}
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={create}>
                        Update Drop
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default UpdateStartEndDatePopup
