import { isError, isErrorEvent, isEvent, isPlainObject } from './is'
import { eventFromError } from './parsers'

export const _eventFromIncompleteOnError = (msg, url, line, column) => {
    const ERROR_TYPES_RE =
        /^(?:[Uu]ncaught (?:exception: )?)?(?:((?:Eval|Internal|Range|Reference|Syntax|Type|URI|)Error): )?(.*)$/i

    // If 'message' is ErrorEvent, get real message from inside
    let message = isErrorEvent(msg) ? msg.message : msg
    let name = 'Error'

    const groups = message.match(ERROR_TYPES_RE)
    if (groups) {
        name = groups[1]
        message = groups[2]
    }

    const event = {
        exception: {
            values: [
                {
                    type: name,
                    value: message,
                },
            ],
        },
    }

    return _enhanceEventWithInitialFrame(event, url, line, column)
}

export const _enhanceEventWithInitialFrame = (event, url, line, column) => {
    // event.exception
    const exception = (event.exception = event.exception || {})
    // event.exception.values
    const values = (exception.values = exception.values || [])
    // event.exception.values[0]
    const firstValue = (values[0] = values[0] || {})
    // event.exception.values[0].stacktrace
    const stacktraces = (firstValue.stacktrace = firstValue.stacktrace || {})
    // event.exception.values[0].stacktrace.frames
    const frames = (stacktraces.frames = stacktraces.frames || [])

    const colno = isNaN(parseInt(column, 10)) ? undefined : column
    const lineno = isNaN(parseInt(line, 10)) ? undefined : line
    const filename =
        typeof url === 'string' && url.length > 0 ? url : getLocationHref()

    // event.exception.values[0].stacktrace.frames
    if (frames.length === 0)
        frames.push({
            colno,
            filename,
            function: '?',
            in_app: true,
            lineno,
        })

    return event
}

export const eventFromUnknownInput = (
    exception,
    syntheticException,
    options = {},
) => {
    if (isErrorEvent(exception) && exception.error)
        return eventFromError(exception.error)

    if (isError(exception)) return eventFromError(exception)

    if (isPlainObject(exception) || isEvent(exception)) {
        // If it's a plain object or an instance of `Event` (the built-in JS kind, not this SDK's `Event` type), serialize
        // it manually. This will allow us to group events based on top-level keys which is much better than creating a new
        // group on any key/value change.
        const event = eventFromPlainObject(
            exception,
            syntheticException,
            options.isRejection,
        )
        // addExceptionMechanism(event, {
        //     synthetic: true,
        // })
        return event
    }

    const event = eventFromString(exception, syntheticException, options)
    // addExceptionTypeValue(event, `${exception}`, undefined)
    // addExceptionMechanism(event, {
    //     synthetic: true,
    // })

    return event
}

export const _eventFromRejectionWithPrimitive = (reason) => ({
    exception: {
        values: [
            {
                type: 'UnhandledRejection',
                // String() is needed because the Primitive type includes symbols (which can't be automatically stringified)
                value: `Non-Error promise rejection captured with value: ${String(
                    reason,
                )}`,
            },
        ],
    },
})

export const _eventFromResponse = async (response, options = {}) => ({
    exception: {
        values: [
            {
                type: 'FailedRequest',
                value: response.bodyUsed
                    ? 'Body already used'
                    : await response.text(),
            },
        ],
    },
})
