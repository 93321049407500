import { Button, FormControlLabel, Switch } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function CreatePackReleasePopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']

    const [usePremint, setUsePremint] = useState(true)

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const createReleaseActions = [getBoostAction(activeUser)]

        const createReleaseData = {
            authorized_account: userName,
            collection_name: collectionName,
            premint: usePremint,
        }

        const createReleaseAction = {
            account: 'nfthivepacks',
            name: 'createreleas',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: createReleaseData,
        }

        createReleaseActions.push(createReleaseAction)
        let createReleaseTransferError = null
        let createReleaseTransactionId = null

        try {
            const createReleaseResult = await activeUser.signTransaction(
                {
                    actions: createReleaseActions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            createReleaseTransactionId = createReleaseResult.transactionId
        } catch (e) {
            createReleaseTransferError = e.message
            setError(e.message)
        }

        if (!createReleaseTransferError) {
            closeCallBack()
            setIsLoading(false)
            callBack(
                !createReleaseTransferError,
                createReleaseTransferError
                    ? [createReleaseTransferError]
                    : null,
                createReleaseTransactionId,
            )
        }

        if (createReleaseTransferError) {
            setIsLoading(false)
            callBack(
                false,
                [createReleaseTransferError],
                createReleaseTransactionId,
            )
        }
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Create Release" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to create a new release for the {collectionName}{' '}
                collection?
            </div>
            <div className="PopupBody">
                <div className="PopupDetails">
                    <div className="flex justify-center my-10">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={usePremint}
                                    onChange={() => {
                                        setUsePremint(!usePremint)
                                    }}
                                    // TODO - enable once mint on demand is implemented
                                    disabled
                                />
                            }
                            label="Use Preminting"
                            labelPlacement="start"
                        />
                    </div>
                    {/* TODO - delete block below once mint on demand is implemented */}
                    <div className="flex justify-center my-10">
                        <div className="text-red-600 font-bold">
                            Only preminting is supported at this time.
                        </div>
                    </div>
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={create}>
                        Create Release
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default CreatePackReleasePopup
