import cn from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import ReactTooltip from 'react-tooltip'
import AssetPreview from '../assetpreview/AssetPreview'
import TemplatePreview from '../assetpreview/TemplatePreview'
import NewBuyOfferAsset from '../buyoffers/NewBuyOfferAsset'
import { get } from '../helpers/Api'
import { formatWAX } from '../helpers/FormatLinks'
import { getWaxBuyOfferBalance } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator'
import { Context } from '../waxplorer'

function AssetList(props) {
    const assets = props['assets']
    const onSale = props['onSale']
    const name = props['name']
    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const type = props['type']
    const search = props['search']
    const clickAsset = props['clickAsset']
    const selectedAsset = props['selectedAsset']
    const onAdd = props['onAdd']
    const id = props['id']
    const selectedAssets = props['selectedAssets']
    const bundleView = props['bundleView']
    const loadMoreButton = props['loadMoreButton']
    const isLoading = props['isLoading']
    const limit = props['limit']
    const page = props['page']
    const searchAuthor = props['searchAuthor']
    const selectable = props['selectable']

    const [state, dispatch] = useContext(Context)

    const [userBalance, setUserBalance] = useState(0)
    const [isLoadingBuyOfferBalance, setIsLoadingBuyOfferBalance] =
        useState(true)

    const { data } = useQuery(`get-rplanet-authors`, () =>
        get(`get-rplanet-authors`),
    )

    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const parseBuyOfferBalance = (res) => {
        setUserBalance(res)
        setIsLoadingBuyOfferBalance(false)
    }

    const showBuyofferManagementPopup = async () => {
        dispatch({
            type: 'SET_ACTION',
            payload: 'manage_buyoffer_contract_balance',
        })
    }

    useEffect(() => {
        ReactTooltip.rebuild()
        setIsLoadingBuyOfferBalance(true)
        if (
            (search === 'buyoffers' ||
                search === 'my_buyoffers' ||
                page === 'newbuyoffer') &&
            userName
        ) {
            getWaxBuyOfferBalance(userName).then(parseBuyOfferBalance)
        }
    }, [search, userName])

    return (
        <div className={'w-full'}>
            {(search === 'buyoffers' ||
                search === 'my_buyoffers' ||
                page === 'newbuyoffer') &&
                userName && (
                    <>
                        {!isLoadingBuyOfferBalance ? (
                            userBalance > 0 && (
                                <div className={cn('flex justify-center pb-3')}>
                                    <div className={cn('text-white px-1')}>
                                        You current buyoffer contract balance is{' '}
                                        {formatWAX(userBalance)} -
                                    </div>

                                    <div
                                        className={cn(
                                            'font-bold cursor-pointer px-1',
                                        )}
                                        onClick={showBuyofferManagementPopup}
                                    >
                                        Manage your balance here
                                    </div>
                                </div>
                            )
                        ) : (
                            <LoadingIndicator />
                        )}
                    </>
                )}
            <div
                className={
                    !(search === 'tradable')
                        ? cn(
                              'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 5xl:grid-cols-5',
                              'text-center mx-4',
                          )
                        : cn('grid grid-cols-1 md:grid-cols-2 gap-x-5')
                }
                id={id ? id : ''}
            >
                {search === 'buyoffers' && !isLoading && (
                    <NewBuyOfferAsset
                        linkTarget={
                            searchAuthor
                                ? `/newoffer?collection=${searchAuthor}`
                                : '/newoffer'
                        }
                    />
                )}
                {search === 'templates'
                    ? assets.map((template, index) => (
                          <TemplatePreview
                              index={index}
                              key={name + '_' + index}
                              template={template}
                              ual={ual}
                          />
                      ))
                    : assets
                          .filter(
                              (asset) =>
                                  !selectedAssets ||
                                  !selectedAssets.includes(
                                      asset.assetId.toString(),
                                  ),
                          )
                          .map((asset, index) =>
                              onSale ? (
                                  <AssetPreview
                                      bundleView={bundleView}
                                      rplanet={data}
                                      index={index}
                                      onAdd={onAdd}
                                      type={type}
                                      key={`${
                                          asset.assetId || asset.aAssetId
                                      }_${index}`}
                                      asset={asset}
                                      ual={ual}
                                      page={page}
                                      showOwner={props['showOwners']}
                                      selectable={selectable}
                                  />
                              ) : (
                                  <div onClick={() => clickAsset(index)}>
                                      <AssetPreview
                                          bundleView={bundleView}
                                          index={index}
                                          onAdd={onAdd}
                                          type={type}
                                          key={`${
                                              asset.assetId || asset.aAssetId
                                          }_${index}`}
                                          asset={asset}
                                          ual={ual}
                                          selectedAsset={selectedAsset}
                                          page={page}
                                          showOwner={props['showOwners']}
                                          selectable={selectable}
                                      />
                                  </div>
                              ),
                          )}
            </div>
            <div className={'w-full flex justify-center'}>
                {isLoading ? <LoadingIndicator /> : null}
                {!isLoading &&
                assets &&
                assets.length > 0 &&
                assets.length % limit === 0 &&
                loadMoreButton
                    ? loadMoreButton
                    : ''}
            </div>
            <ReactTooltip />
        </div>
    )
}

export default AssetList
