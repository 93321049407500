import { InputAdornment, TextField } from '@mui/material'
import cn from 'classnames'
import config from '../../config.json'
export const integerRegEx = new RegExp('^-?\\d+$')
export const unsignedIntegerRegEx = new RegExp('^\\d+$')
export const floatRegEx = new RegExp('^-?\\d+(\\.\\d+)?$')

export const editableField = (
    onChange,
    field,
    placeholder,
    onChainValue,
    editValue,
    helperText,
    required,
    error,
    multiline,
    endAdornment,
    disabled,
) => {
    return (
        <div className="mb-10 mx-10">
            <TextField
                fullWidth={true}
                variant="outlined"
                label={placeholder}
                value={editValue ? editValue : onChainValue ? onChainValue : ''}
                helperText={helperText}
                onChange={(e) => onChange(e, field)}
                required={required}
                error={error}
                multiline={multiline}
                InputProps={
                    endAdornment && {
                        endAdornment: (
                            <InputAdornment position="end">
                                {endAdornment}
                            </InputAdornment>
                        ),
                    }
                }
                disabled={disabled}
            />
        </div>
    )
}

export const EditorBreadCrumbs = ({
    collectionImage,
    collectionName,
    schemaName,
    assetName,
}) => {
    let crumbs = `Editing: `

    if (collectionName && collectionName !== 'new') {
        crumbs = crumbs + collectionName
    } else if (collectionName && collectionName === 'new') {
        crumbs = crumbs + `New Collection`
    }

    if (schemaName && schemaName !== 'new') {
        crumbs = crumbs + ` > ${schemaName}`
    } else if (schemaName && schemaName === 'new') {
        crumbs = crumbs + ` > New Schema`
    }

    if (assetName && assetName !== 'new') {
        crumbs = crumbs + ` > ${assetName}`
    } else if (assetName && assetName === 'new') {
        crumbs = crumbs + ` > New NFT`
    }

    return (
        <div className={cn('flex pl-4 h-10 text-white')}>
            <img
                className="max-h-full mr-5"
                src={
                    collectionImage
                        ? collectionImage.startsWith(config.ipfs)
                            ? collectionImage
                            : `${config.ipfs}${collectionImage}`
                        : '/image-outline.svg'
                }
                alt="none"
            />
            <div className="my-auto">{crumbs}</div>
        </div>
    )
}

export const linkClass = 'text-primary hover:text-neutral underline'
