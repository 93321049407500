import cn from 'classnames'
import React from 'react'
import { SideNavigationHeaderWrap } from './SideNavigationHeaderWrap'

export const SideNavigationHeaderWrapper = ({
    className,
    active,
    ...props
}) => (
    <div
        className={cn(
            'relative cursor-pointer',
            'border-b border-neutral border-opacity-20',
            'transition-colors bg-opacity-5',
            { 'bg-primary hover:bg-blued': active },
            { 'hover:bg-blued': !active },
            className,
        )}
        {...props}
    />
)

export const SideNavigationHeader = ({
    onClick,
    active,
    title,
    open,
    icon,
    href,
    hasSubmenu = true,
}) => {
    return (
        <SideNavigationHeaderWrap onClick={onClick} href={href}>
            <div
                className={cn(
                    'flex mr-3 h-8 w-6 md:h-4 md:w-4 lg:h-5 lg:w-5 xl:h-6 xl:w-6 my-auto',
                    {
                        'text-secondary': active,
                    },
                )}
            >
                <img src={icon} alt={title} className="block my-auto" />
            </div>
            <div
                className={cn(
                    'relative text-left text-base md:text-xs lg:text-sm xl:text-base',
                    'text-neutral mr-auto leading-8 md:leading-5 xl:leading-6',
                )}
            >
                {title}
            </div>
            {hasSubmenu && (
                <div className="h-5 w-5 ml-auto">
                    <img
                        src={open ? '/minus2.svg' : '/plus2.svg'}
                        className="block text-primary"
                    />
                </div>
            )}
        </SideNavigationHeaderWrap>
    )
}

export default SideNavigationHeader
