import cn from 'classnames'
import React, { useState } from 'react'
import Dropdown from 'react-dropdown'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import Popup from './Popup'

function CollectionCategoryPopup(props) {
    const closeCallBack = props.closeCallBack
    const collectionName = props.collectionName
    const ual = props.ual
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null
    const { t } = useTranslation('common')
    const callBack = props.callBack

    const suggest = props.suggest

    const [categoryId, setCategoryId] = useState(null)

    const cancel = () => {
        closeCallBack()
        callBack({ categorySet: false, error: null })
    }

    const selectCategory = (category) => {
        setCategoryId(category.value)
    }

    let dropDownOptions = []
    dropDownOptions.push({ value: 1, label: t('collection_category.1') })
    dropDownOptions.push({ value: 2, label: t('collection_category.2') })
    dropDownOptions.push({ value: 3, label: t('collection_category.3') })
    dropDownOptions.push({ value: 4, label: t('collection_category.4') })
    dropDownOptions.push({ value: 5, label: t('collection_category.5') })
    dropDownOptions.push({ value: 6, label: t('collection_category.6') })
    dropDownOptions.push({ value: 7, label: t('collection_category.7') })

    let selectedCategory = { value: '-', label: 'Categories' }

    if (categoryId)
        selectedCategory = dropDownOptions.filter(
            (cat) => cat.value === categoryId,
        )[0]

    const save = async () => {
        const actions = [getBoostAction(activeUser)]

        closeCallBack()

        if (suggest) {
            actions.push({
                account: 'clltncattool',
                name: 'suggestcat',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: {
                    collection_name: collectionName,
                    category_id: categoryId,
                },
            })
        } else {
            actions.push({
                account: 'clltncattool',
                name: 'setcatcol',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: {
                    collection_name: collectionName,
                    category_id: categoryId,
                },
            })
        }

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ categorySet: selectedCategory, error: null })
        } catch (e) {
            callBack({ categorySet: false, error: e.message })
            console.log(e)
        }
    }

    return (
        <Popup
            cancel={cancel}
            title={
                suggest
                    ? `Suggest a category for the ${collectionName} collection`
                    : `Select your desired category for the ${collectionName} collection`
            }
            className="h-auto"
        >
            <div className={cn('block h-full')}>
                <div className={' w-1/2 mx-auto'}>
                    <Dropdown
                        value={selectedCategory}
                        options={dropDownOptions}
                        id="CategoryDropdown"
                        onChange={selectCategory}
                    />
                </div>
                <div className={'flex justify-center mt-40'}>
                    <MainButton
                        className={cn(
                            'text-white rounded border border-white outline-none text-sm align-middle font-normal',
                            'bg-transparent cursor-pointer hover:font-bold mr-10',
                        )}
                        onClick={cancel}
                    >
                        {t('asset.cancel')}
                    </MainButton>
                    <MainButton
                        onClick={save}
                        disabled={!categoryId ? 'disabled' : ''}
                        className={'ml-10'}
                    >
                        Save
                    </MainButton>
                </div>
            </div>
        </Popup>
    )
}

export default CollectionCategoryPopup
