import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import ReactDropdown from 'react-dropdown'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import { get } from '../helpers/Api'
import { formatUSD, formatWAX } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function SellOfferPopup(props) {
    const asset = props['asset']
    const { t } = useTranslation('common')

    const {
        author,
        name,
        image,
        templateId,
        lowest,
        usd_lowest,
        average,
        usd_average,
        offer,
        usd_offer,
    } = asset

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']

    const callBack = props['callBack']

    const userName = activeUser ? activeUser['accountName'] : null
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [collectionFee, setCollectionFee] = useState(null)
    const [assetToSell, setAssetToSell] = useState(null)

    const [myAssets, setMyAssets] = useState(asset.myAssets)

    const getCollectionFee = async (author) => {
        get('collection-fee/' + author).then((result) =>
            setCollectionFee(result['fee']),
        )
    }

    const parseAssets = (res) => {
        if (res) setMyAssets(res)
    }

    const getMyAssets = async () => {
        get(
            `buy-offer-assets?seller=${userName}&offer_id=${asset.orderId}`,
        ).then(parseAssets)
    }

    useEffect(() => {
        getCollectionFee(author)
        getMyAssets()
    }, [])

    const create = async () => {
        if (!assetToSell) return
        const quantity = parseFloat(offer)
        const { orderId } = asset

        closeCallBack()
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        actions.push({
            account: 'atomicassets',
            name: 'transfer',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: {
                from: userName,
                to: 'waxbuyoffers',
                asset_ids: [assetToSell],
                memo: 'offer:' + orderId + ';nft.hive',
            },
        })

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ sold: true, market: 'waxbuyoffers', offer: quantity })
        } catch (e) {
            callBack(false, e, assetToSell)
            console.log(e)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    let cut = asset && offer ? offer - 0.04 * offer : 0
    if (collectionFee && asset && offer) cut = cut - collectionFee * offer

    const cancel = () => {
        callBack({ sold: false, market: null, offer: 0 })
        closeCallBack()
    }

    const assetOptions = []

    myAssets &&
        myAssets
            .sort((a, b) => (a.mint && b.mint ? b.mint - a.mint : 0))
            .map((asset) => {
                assetOptions.push({
                    value: asset['assetId'],
                    label: `${asset['assetId']}: Mint #${asset['mint']}`,
                })
            })

    const selectAssetToSell = (e) => {
        setAssetToSell(e.value)
    }

    return (
        <Popup title={name} image={image} cancel={cancel}>
            <div className={'grid grid-cols-2 w-1/2 mx-auto'}>
                <div className={'text-left'}>Collection:</div>
                <div className={'text-right'}>{author}</div>
                <div className={'text-left'}>Template ID:</div>
                <div className={'text-right'}>{templateId}</div>
                <div className={'text-left'}>Median:</div>
                <div className={'text-right'}>
                    {average
                        ? `${formatWAX(average)} / ${
                              usd_average ? formatUSD(usd_average) : ''
                          }`
                        : ''}
                </div>
                <div className={'text-left'}>Floor:</div>
                <div className={'text-right'}>
                    {lowest
                        ? `${formatWAX(lowest)} / ${
                              usd_lowest ? formatUSD(usd_lowest) : ''
                          }`
                        : ''}
                </div>
            </div>
            {(lowest && offer < lowest / 3) ||
            (lowest && offer < lowest / 3) ? (
                <div
                    className={
                        'w-2/3 flex flex-wrap border border-primary bg-primaryt2 rounded mx-auto p-2 my-2'
                    }
                >
                    <img
                        className="w-full md:w-5 h-5 mx-2"
                        src="/Warning_icn.svg"
                        alt="!"
                    />
                    Warning! This Offer is significantly lower than the Median /
                    Floor Price.
                </div>
            ) : (
                ''
            )}
            <div className={cn('text-center text-base m-4')}>
                {`Accept this offer for ${formatWAX(
                    offer,
                )}? Select a matching Asset to Sell`}
            </div>
            <div className={'relative w-full flex justify-center text-center'}>
                <ReactDropdown
                    controlClassName={cn(
                        'text-xs bg-dark relative w-72 h-8 mx-auto',
                    )}
                    menuClassName={cn('mx-auto text-xs bg-dark absolute w-72')}
                    options={assetOptions}
                    onChange={selectAssetToSell}
                />
            </div>
            {assetToSell && (
                <div className={cn('text-center text-base m-4')}>
                    Sell Asset <b className={'text-primary'}>{assetToSell}</b>{' '}
                    for {formatWAX(offer)}/{formatUSD(usd_offer)}?<br />
                </div>
            )}
            {error ? <ErrorMessage error={error} /> : ''}
            <div className="Buttons">
                <MainButton
                    disabled={!assetToSell ? 'disabled' : ''}
                    className="PopupSellButton Small"
                    onClick={create}
                >
                    Accept
                </MainButton>
            </div>
            {collectionFee || collectionFee === 0 ? (
                <div className="SellCalc Small">
                    <div>{t('popups.market_fee')}: 2%</div>
                    <div>{t('popups.defi_fee')}: 2%</div>
                    <div>{`${t('popups.collection_fee')}: ${
                        collectionFee * 100
                    }%`}</div>
                    <div>
                        {t('popups.your_cut')}: {cut} WAX
                    </div>
                </div>
            ) : (
                <LoadingIndicator />
            )}

            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default SellOfferPopup
