import cn from 'classnames'
import React, { useContext, useEffect } from 'react'
import { Context } from '../waxplorer'
import AuctionPopup from './AuctionPopup'
import AuthorizeNfthivedropsPopup from './AuthorizeNfthivedropsPopup'
import BidPopup from './BidPopup'
import BulkBurnPopup from './BulkBurnPopup'
import BulkBuyPopup from './BulkBuyPopup'
import BulkCancelPopup from './BulkCancelPopup'
import BulkDistributePopup from './BulkDistributePopup'
import BulkEditPopup from './BulkEditPopup'
import BulkMultiSellPopup from './BulkMultiSellPopup'
import BulkSellPopup from './BulkSellPopup'
import BulkStakePopup from './BulkStakePopup'
import BulkTransferPopup from './BulkTransferPopup'
import BulkUnstakePopup from './BulkUnstakePopup'
import BundlePopup from './BundlePopup'
import BurnPopup from './BurnPopup'
import BuyDropPopup from './BuyDropPopup'
import BuyDropRamPopup from './BuyDropRamPopup'
import BuyMissingPopup from './BuyMissingPopup'
import BuyOfferBalanceManagementPopup from './BuyOfferBalanceManagementPopup'
import BuyOfferPopup from './BuyOfferPopup'
import BuyPopup from './BuyPopup'
import BuyWaxPopup from './BuyWaxPopup'
import CollectionCategoryPopup from './CollectionCategoryPopup'
import CollectionPopup from './CollectionPopup'
import CreateDropPopup from './CreateDropPopup'
import CreatePackPopup from './CreatePackPopup'
import CreatePackReleasePopup from './CreatePackReleasePopup'
import CreatePoolPopup from './CreatePoolPopup'
import DeleteDropPopup from './DeleteDropPopup'
import DeletePackPopup from './DeletePackPopup'
import DeletePackReleasePopup from './DeletePackReleasePopup'
import DeletePoolPopup from './DeletePoolPopup'
import DropAuthProofPopup from './DropAuthProofPopup'
import DropWhitelistPopup from './DropWhitelistPopup'
import EditCollectionPopup from './EditCollectionPopup'
import EditPackDescriptionAnimationPopup from './EditPackDescriptionAnimationPopup'
import EditPackTotalsPopup from './EditPackTotalsPopup'
import EditPopup from './EditPopup'
import EditSchemaPopup from './EditSchemaPopup'
import EditTemplatePopup from './EditTemplatePopup'
import HandleTradePopup from './HandleTradePopup'
import MintNftPopup from './MintNftPopup'
import SellOfferPopup from './SellOfferPopup'
import SellPopup from './SellPopup'
import SetItemPopup from './SetItemPopup'
import SharePopup from './SharePopup'
import StakeCpuNetPopup from './StakeCpuNetPopup'
import TagsPopup from './TagsPopup'
import TopUpPopup from './TopUpPopup'
import TradePopup from './TradePopup'
import TransferPopup from './TransferPopup'
import TransferWaxPopup from './TransferWaxPopup'
import TubePopup from './TubePopup'
import UnlockReleasePopup from './UnlockReleasePopup'
import UnpackPopup from './UnpackPopup'
import UnstakeCpuNetPopup from './UnstakeCpuNetPopup'
import UpdateDropAssetsPopup from './UpdateDropAssetsPopup'
import UpdateDropNameDescrPopup from './UpdateDropNameDescrPopup'
import UpdateDropPricePopup from './UpdateDropPricePopup'
import UpdatePoolPopup from './UpdatePoolPopup'
import UpdateStartEndDatePopup from './UpdateStartEndDatePopup'
import UserPicturePopup from './UserPicturePopup'
import WithdrawBuyOffersPopup from './WithdrawBuyOffersPopup'

function OutsideAlerter({ callBack, action, dispatch, children }) {
    const handleClickOutside = (event) => {
        if (
            action &&
            event &&
            event.target &&
            event.target.id === 'OutsideAlerter'
        ) {
            dispatch({ type: 'SET_ACTION', payload: '' })
            callBack()
            event.preventDefault()
            event.stopPropagation()
        }
    }

    return action ? (
        <div
            id={'OutsideAlerter'}
            className={cn(
                'absolute w-screen h-screen bg-transparent',
                'z-100 backdrop-filter backdrop-blur-sm flex',
            )}
            onClick={handleClickOutside}
        >
            {children}
        </div>
    ) : (
        ''
    )
}

function PopupWrapper(props) {
    const ual = props['ual'] ? props['ual'] : { activeUser: null }

    const [state, dispatch] = useContext(Context)

    const asset = state.asset
    const amount = state.claimAmount

    const drop = state.drop
    const link = state.link
    const sellPrice = state.sellPrice
    const action = state.action
    const callBack = state.callBack
    const assets = state.assets
    const soldAssets = state.soldAssets
    const receiver = state.receiver ? state.receiver.trim().toLowerCase() : ''
    const wax_balance = state.wax_balance
    const offerId = state.offerId
    const tradeOption = state.tradeOption
    const tubeId = state.tubeId
    const lowestMints = state.lowestMints

    const editDescription = state.editDescription
    const editCollectionName = state.editCollectionName
    const editSchemaName = state.editSchemaName
    const editDisplayName = state.editDisplayName
    const editUrl = state.editUrl
    const editMarketFee = state.editMarketFee
    const editImage = state.editImage
    const newCollection = state.newCollection
    const editSchemaAttributes = state.editSchemaAttributes
    const editTemplateAttributes = state.editTemplateAttributes
    const templateId = state.templateId
    const cpu = state.cpu
    const net = state.net
    const memo = state.memo

    const collectionName = state.collectionName
    const tags = state.tags

    const dropTemplateIds = state.dropTemplateIds
    const dropName = state.dropName
    const dropId = state.dropId
    const dropDescription = state.dropDescription
    const dropStartDateTime = state.dropStartDateTime
    const dropEndDateTime = state.dropEndDateTime
    const dropPrice = state.dropPrice
    const waxRam = state.waxRam
    const dropCurrency = state.dropCurrency
    const dropPayoutRecipient = state.dropPayoutRecipient
    const dropMaxClaimableDrops = state.dropMaxClaimableDrops
    const dropAccountLimit = state.dropAccountLimit
    const dropAccountLimitCooldown = state.dropAccountLimitCooldown
    const dropUseAuthProof = state.dropUseAuthProof
    const dropAuthProofRulesJoin = state.dropAuthProofRulesJoin
    const dropUserWhitelist = state.dropUserWhitelist
    const dropAuthProofRules = state.dropAuthProofRules
    const dropUsePremint = state.dropUsePremint
    const dropPremintedAssetIds = state.dropPremintedAssetIds
    const dropAssetsToTransfer = state.dropAssetsToTransfer
    const dropCreatePool = state.dropCreatePool
    const dropRamBalance = state.dropRamBalance

    const packId = state.packId
    const poolId = state.poolId
    const assetsToAddToPool = state.assetsToAddToPool
    const assetsToRemoveFromPool = state.assetsToRemoveFromPool
    const packTemplateId = state.packTemplateId
    const packReleaseId = state.packReleaseId
    const packName = state.packName
    const packDescription = state.packDescription
    const packUnpackAnimation = state.packUnpackAnimation
    const packUnlockTime = state.packUnlockTime
    const packTotalPacks = state.packTotalPacks
    const packSlots = state.packSlots

    const sellElement = (
        <SellPopup
            asset={asset}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const auctionElement = (
        <AuctionPopup
            asset={asset}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const buyOfferElement = (
        <BuyOfferPopup
            asset={asset}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(offered, e = null, asset_id = null) => {
                callBack(offered, e)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const topupElement = (
        <TopUpPopup
            asset={asset}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(offered, e = null, asset_id = null) => {
                callBack(offered, e)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const sellOfferElement = (
        <SellOfferPopup
            asset={asset}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const bulkSellElement = (
        <BulkSellPopup
            assets={assets}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(sold) => {
                callBack(sold)
            }}
            offer={sellPrice}
        />
    )

    const bulkMultiSellElement = (
        <BulkMultiSellPopup
            assets={assets}
            soldAssets={soldAssets}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(sold) => {
                callBack(sold)
            }}
            offer={sellPrice}
        />
    )

    const bulkDistributeElement = (
        <BulkDistributePopup
            assets={assets}
            ual={ual}
            memo={memo}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(distributed) => {
                callBack(distributed)
            }}
        />
    )

    const bulkBuyElement = (
        <BulkBuyPopup
            assets={assets}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(sold) => {
                callBack(sold)
            }}
            offer={sellPrice}
        />
    )

    const bulkEditElement = (
        <BulkEditPopup
            assets={assets}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(sold) => {
                callBack(sold)
            }}
            offer={sellPrice}
        />
    )

    const bulkTransferElement = (
        <BulkTransferPopup
            assets={assets}
            receiver={receiver}
            memo={memo}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_RECEIVER', payload: '' })
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                dispatch({ type: 'SET_RECEIVER', payload: '' })
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const editElement = (
        <EditPopup
            asset={asset}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const bulkCancelElement = (
        <BulkCancelPopup
            assets={assets}
            receiver={receiver}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const bulkBurnElement = (
        <BulkBurnPopup
            assets={assets}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const bulkStakeElement = (
        <BulkStakePopup
            assets={assets}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const bulkUnstakeElement = (
        <BulkUnstakePopup
            assets={assets}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const bundleElement = (
        <BundlePopup
            assets={assets}
            receiver={receiver}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasSold, e = null, asset_id = null) => {
                callBack(wasSold)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
            offer={sellPrice}
        />
    )

    const bidElement = (
        <BidPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(bidInfo) => {
                callBack(bidInfo)
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            offer={sellPrice}
        />
    )

    const burnElement = (
        <BurnPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(burned) => {
                callBack(burned)
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const buyElement = (
        <BuyPopup
            asset={asset}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasBought, e = null, asset_id = null) => {
                callBack(wasBought)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const buyMissingElement = (
        <BuyMissingPopup
            asset={asset}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasBought, e = null, asset_id = null) => {
                callBack(wasBought)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const buyDropElement = (
        <BuyDropPopup
            drop={drop}
            amount={amount}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_DROP', payload: '' })
            }}
            callBack={(wasBought, e = null, asset_id = null) => {
                callBack(wasBought)
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const createDropElement = (
        <CreateDropPopup
            ual={ual}
            collectionName={collectionName}
            dropTemplateIds={dropTemplateIds}
            dropName={dropName}
            dropDescription={dropDescription}
            dropStartDateTime={dropStartDateTime}
            dropEndDateTime={dropEndDateTime}
            dropPrice={dropPrice}
            waxRam={waxRam}
            dropCurrency={dropCurrency}
            dropPayoutRecipient={dropPayoutRecipient}
            dropMaxClaimableDrops={dropMaxClaimableDrops}
            dropAccountLimit={dropAccountLimit}
            dropAccountLimitCooldown={dropAccountLimitCooldown}
            dropUseAuthProof={dropUseAuthProof}
            dropUsePremint={dropUsePremint}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_DROP_TEMPLATE_IDS', payload: '' })
                dispatch({ type: 'SET_DROP_NAME', payload: '' })
                dispatch({ type: 'SET_DROP_DESCRIPTION', payload: '' })
                dispatch({ type: 'SET_DROP_START_DATETIME', payload: '' })
                dispatch({ type: 'SET_DROP_END_DATETIME', payload: '' })
                dispatch({ type: 'SET_DROP_PRICE', payload: '' })
                dispatch({ type: 'SET_DROP_CURRENCY', payload: '' })
                dispatch({ type: 'SET_DROP_PAYOUT_RECIPIENT', payload: '' })
                dispatch({ type: 'SET_DROP_MAX_CLAIMABLE_DROPS', payload: '' })
                dispatch({ type: 'SET_DROP_ACCOUNT_LIMIT', payload: '' })
                dispatch({
                    type: 'SET_DROP_ACCOUNT_LIMIT_COOLDOWN',
                    payload: '',
                })
                dispatch({ type: 'SET_DROP_USE_AUTH_PROOF', payload: '' })
                dispatch({ type: 'SET_DROP_USE_PREMINT', payload: '' })
            }}
            callBack={(wasCreated, e = null, transactionId = null) => {
                callBack(wasCreated, e, transactionId)
            }}
        />
    )

    const buyDropRamElement = (
        <BuyDropRamPopup
            ual={ual}
            collectionName={collectionName}
            dropRamBalance={dropRamBalance}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_DROP_RAM_BALANCE', payload: '' })
            }}
            callBack={(success, e = null, transactionId = null) => {
                callBack(success, e, transactionId)
            }}
        />
    )

    const updateDropAssetsElement = (
        <UpdateDropAssetsPopup
            ual={ual}
            dropId={dropId}
            collectionName={collectionName}
            dropTemplateIds={dropTemplateIds}
            dropPremintedAssetIds={dropPremintedAssetIds}
            dropAssetsToTransfer={dropAssetsToTransfer}
            createPool={dropCreatePool}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_DROP_PREMINTED_ASSET_IDS', payload: '' })
                dispatch({ type: 'SET_DROP_ASSETS_TO_TRANSFER', payload: '' })
            }}
            callBack={(wasCreated, e = null, transactionId = null) => {
                callBack(wasCreated, e, transactionId)
            }}
        />
    )

    const createPackReleaseElement = (
        <CreatePackReleasePopup
            ual={ual}
            collectionName={collectionName}
            callBack={(wasCreated, e = null, transactionId = null) => {
                callBack(wasCreated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
            }}
        />
    )

    const createPoolElement = (
        <CreatePoolPopup
            ual={ual}
            collectionName={collectionName}
            packReleaseId={packReleaseId}
            callBack={(wasCreated, e = null, transactionId = null) => {
                callBack(wasCreated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_RELEASE_ID', payload: '' })
            }}
        />
    )

    const updatePoolElement = (
        <UpdatePoolPopup
            ual={ual}
            collectionName={collectionName}
            poolId={poolId}
            assetsToAddToPool={assetsToAddToPool}
            assetsToRemoveFromPool={assetsToRemoveFromPool}
            callBack={(wasCreated, e = null, transactionId = null) => {
                callBack(wasCreated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_POOL_ID', payload: '' })
                dispatch({ type: 'SET_ASSETS_TO_ADD_TO_POOL', payload: '' })
                dispatch({
                    type: 'SET_ASSETS_TO_REMOVE_FROM_POOL',
                    payload: '',
                })
            }}
        />
    )

    const authorizeNfthivedropsElement = (
        <AuthorizeNfthivedropsPopup
            ual={ual}
            collectionName={collectionName}
            callBack={(wasAuthorized, e = null, transactionId = null) => {
                callBack(wasAuthorized, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
            }}
        />
    )

    const dropWhitelistElement = (
        <DropWhitelistPopup
            ual={ual}
            dropId={dropId}
            dropUserWhitelist={dropUserWhitelist}
            callBack={(wasWhitelisted, e = null, transactionId = null) => {
                callBack(wasWhitelisted, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_DROP_ID', payload: '' })
                dispatch({ type: 'SET_DROP_USER_WHITELIST', payload: '' })
            }}
        />
    )

    const dropAuthProofElement = (
        <DropAuthProofPopup
            ual={ual}
            dropId={dropId}
            dropAuthProofRules={dropAuthProofRules}
            dropAuthProofRulesJoin={dropAuthProofRulesJoin}
            callBack={(wasAuthProofed, e = null, transactionId = null) => {
                callBack(wasAuthProofed, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_DROP_ID', payload: '' })
                dispatch({ type: 'SET_DROP_AUTH_PROOF_RULES', payload: '' })
                dispatch({
                    type: 'SET_DROP_AUTH_PROOF_RULES_JOIN',
                    payload: '',
                })
            }}
        />
    )

    const updateDropNameDescrElement = (
        <UpdateDropNameDescrPopup
            ual={ual}
            collectionName={collectionName}
            dropId={dropId}
            dropName={dropName}
            dropDescription={dropDescription}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_DROP_ID', payload: '' })
                dispatch({ type: 'SET_DROP_NAME', payload: '' })
                dispatch({ type: 'SET_DROP_DESCRIPTION', payload: '' })
            }}
        />
    )

    const updateDropPriceElement = (
        <UpdateDropPricePopup
            ual={ual}
            collectionName={collectionName}
            dropId={dropId}
            dropCurrency={dropCurrency}
            dropPrice={dropPrice}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_DROP_ID', payload: '' })
                dispatch({ type: 'SET_DROP_PRICE', payload: '' })
                // we cant just send over the currency, as it consists of a symbol & label, which we don't have in the overview
            }}
        />
    )

    const updateDropStartEndDateElement = (
        <UpdateStartEndDatePopup
            ual={ual}
            collectionName={collectionName}
            dropId={dropId}
            dropStartDateTime={dropStartDateTime}
            dropEndDateTime={dropEndDateTime}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_DROP_START_DATETIME', payload: '' })
                dispatch({ type: 'SET_DROP_END_DATETIME', payload: '' })
            }}
        />
    )

    const deleteDropElement = (
        <DeleteDropPopup
            ual={ual}
            collectionName={collectionName}
            dropId={dropId}
            callBack={(wasDeleted, e = null, transactionId = null) => {
                callBack(wasDeleted, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_DROP_ID', payload: '' })
            }}
        />
    )

    const deletePackReleaseElement = (
        <DeletePackReleasePopup
            ual={ual}
            collectionName={collectionName}
            releaseId={packReleaseId}
            callBack={(wasDeleted, e = null, transactionId = null) => {
                callBack(wasDeleted, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_RELEASE_ID', payload: '' })
            }}
        />
    )

    const unlockReleaseElement = (
        <UnlockReleasePopup
            ual={ual}
            collectionName={collectionName}
            releaseId={packReleaseId}
            callBack={(wasDeleted, e = null, transactionId = null) => {
                callBack(wasDeleted, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_RELEASE_ID', payload: '' })
            }}
        />
    )

    const deletePackElement = (
        <DeletePackPopup
            ual={ual}
            collectionName={collectionName}
            packId={packId}
            releaseId={packReleaseId}
            callBack={(wasDeleted, e = null, callBackData = null) => {
                callBack(wasDeleted, e, callBackData)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_ID', payload: '' })
            }}
        />
    )

    const deletePoolElement = (
        <DeletePoolPopup
            ual={ual}
            collectionName={collectionName}
            poolId={poolId}
            releaseId={packReleaseId}
            callBack={(wasDeleted, e = null, callBackData = null) => {
                callBack(wasDeleted, e, callBackData)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_POOL_ID', payload: '' })
            }}
        />
    )

    const createPackElement = (
        <CreatePackPopup
            ual={ual}
            collectionName={collectionName}
            packReleaseId={packReleaseId}
            packTemplateId={packTemplateId}
            packName={packName}
            packDescription={packDescription}
            packUnpackAnimation={packUnpackAnimation}
            packTotalPacks={packTotalPacks}
            packSlots={packSlots}
            packUnlockTime={packUnlockTime}
            callBack={(wasCreated, e = null, callBackData = null) => {
                callBack(wasCreated, e, callBackData)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_DESCRIPTION', payload: '' })
                dispatch({ type: 'SET_PACK_UNPACK_ANIMATION', payload: '' })
                dispatch({ type: 'SET_PACK_TOTAL_PACKS', payload: '' })
                dispatch({ type: 'SET_PACK_SLOTS', payload: '' })
                dispatch({ type: 'SET_PACK_UNLOCK_TIME', payload: '' })
                dispatch({ type: 'SET_PACK_RELEASE_ID', payload: '' })
                dispatch({ type: 'SET_PACK_TEMPLATE_ID', payload: '' })
            }}
        />
    )

    const editPackDescriptionAnimationElement = (
        <EditPackDescriptionAnimationPopup
            ual={ual}
            collectionName={collectionName}
            packId={packId}
            packName={packName}
            packDescription={packDescription}
            packUnpackAnimation={packUnpackAnimation}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({
                    type: 'SET_ACTION',
                    payload: '',
                })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_ID', payload: '' })
                dispatch({ type: 'SET_PACK_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_DESCRIPTION', payload: '' })
                dispatch({
                    type: 'SET_PACK_UNPACK_ANIMATION',
                    payload: '',
                })
            }}
        />
    )

    const editPackTotalsElement = (
        <EditPackTotalsPopup
            ual={ual}
            collectionName={collectionName}
            packId={packId}
            packTotalPacks={packTotalPacks}
            callBack={(wasUpdated, e = null, transactionId = null) => {
                callBack(wasUpdated, e, transactionId)
            }}
            closeCallBack={() => {
                dispatch({
                    type: 'SET_ACTION',
                    payload: '',
                })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_ID', payload: '' })
                dispatch({ type: 'SET_PACK_NAME', payload: '' })
                dispatch({ type: 'SET_PACK_TOTAL_PACKS', payload: '' })
            }}
        />
    )

    const setItemElement = (
        <SetItemPopup
            asset={asset}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const editCollectionElement = (
        <EditCollectionPopup
            drop={drop}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_EDIT_DESCRIPTION', payload: '' })
                dispatch({ type: 'SET_EDIT_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_EDIT_URL', payload: '' })
                dispatch({ type: 'SET_EDIT_MARKET_FEE', payload: '' })
                dispatch({ type: 'SET_EDIT_IMAGE', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_EDIT_NEW', payload: '' })
            }}
            chainUniqueName={collectionName}
            editDescription={editDescription}
            editUniqueName={editCollectionName}
            editUrl={editUrl}
            editMarketFee={editMarketFee}
            editImage={editImage}
            editDisplayName={editDisplayName}
            newCollection={newCollection}
            callBack={(
                wasEdited,
                wasNewCollection,
                e = null,
                transactionId = null,
            ) => {
                callBack(wasEdited, wasNewCollection, e, transactionId)
            }}
        />
    )

    const editSchemaElement = (
        <EditSchemaPopup
            drop={drop}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_EDIT_SCHEMA_NAME', payload: '' })
                dispatch({ type: 'SET_EDIT_NEW', payload: '' })
                dispatch({ type: 'SET_EDIT_SCHEMA_ATTRIBUTES', payload: [] })
            }}
            // Repurposing SET_EDIT_NEW / newCollection here, maybe change later
            collectionUniqueName={collectionName}
            editSchemaName={editSchemaName}
            newSchema={newCollection}
            editSchemaAttributes={editSchemaAttributes}
            callBack={(
                wasEdited,
                wasNewSchema,
                e = null,
                transactionId = null,
            ) => {
                callBack(wasEdited, wasNewSchema, e, transactionId)
            }}
        />
    )

    const editTemplateElement = (
        <EditTemplatePopup
            drop={drop}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_EDIT_SCHEMA_NAME', payload: '' })
                dispatch({ type: 'SET_EDIT_TEMPLATE_ATTRIBUTES', payload: [] })
            }}
            collectionUniqueName={collectionName}
            editSchemaName={editSchemaName}
            editTemplateAttributes={editTemplateAttributes}
            callBack={(wasEdited, e = null, transactionId = null) => {
                callBack(wasEdited, e, transactionId)
            }}
        />
    )

    const mintNftElement = (
        <MintNftPopup
            drop={drop}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_EDIT_SCHEMA_NAME', payload: '' })
                dispatch({ type: 'SET_EDIT_TEMPLATE_ATTRIBUTES', payload: [] })
                dispatch({ type: 'SET_TEMPLATE_ID', payload: '' })
            }}
            collectionUniqueName={collectionName}
            editSchemaName={editSchemaName}
            editTemplateAttributes={editTemplateAttributes}
            templateId={templateId}
            callBack={(
                wasEdited,
                errors = null,
                transactionIds = null,
                nftRecipient = null,
                mintedNftAmount,
            ) => {
                callBack(
                    wasEdited,
                    errors,
                    transactionIds,
                    nftRecipient,
                    mintedNftAmount,
                )
            }}
        />
    )

    const editCollectionCategoryElement = (
        <CollectionCategoryPopup
            ual={ual}
            collectionName={collectionName}
            callBack={callBack}
            suggest={action === 'suggestCollectionCategory'}
            closeCallBack={() => {
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const editTagsElement = (
        <TagsPopup
            ual={ual}
            collectionName={collectionName}
            callBack={callBack}
            tags={tags}
            suggest={action === 'suggestTag'}
            closeCallBack={() => {
                dispatch({ type: 'SET_COLLECTION_NAME', payload: '' })
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const transferElement = (
        <TransferPopup
            asset={asset}
            receiver={receiver}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_RECEIVER', payload: '' })
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasTransferred, receiver, e = null, asset_id = null) => {
                callBack(wasTransferred, receiver)
                dispatch({ type: 'SET_RECEIVER', payload: '' })
                if (e) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            error: e,
                            asset_id: asset_id,
                        },
                    })
                }
            }}
        />
    )

    const collectionElement = (
        <CollectionPopup
            asset={asset}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(wasBought) => {
                callBack(wasBought)
            }}
        />
    )

    const shareElement = (
        <SharePopup
            asset={asset}
            link={link}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const tradeElement = (
        <TradePopup
            assets={assets}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={(tradeInfo) => {
                callBack(tradeInfo)
            }}
        />
    )

    const transferWaxElement = (
        <TransferWaxPopup
            wax_balance={wax_balance}
            ual={ual}
            callBack={(transferInfo) => {
                callBack(transferInfo)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const handleTradeElement = (
        <HandleTradePopup
            offerId={offerId}
            tradeOption={tradeOption}
            ual={ual}
            callBack={(transferInfo) => {
                callBack(transferInfo)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const tubeElement = (
        <TubePopup
            assets={assets}
            tubeId={tubeId}
            lowestMints={lowestMints}
            ual={ual}
            callBack={(transferInfo) => {
                callBack(transferInfo)
            }}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const unpackElement = (
        <UnpackPopup
            asset={asset}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    const claimElement = (
        <UnpackPopup
            asset={asset}
            ual={ual}
            claimOnly={true}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    const unstakeCpuNetElement = (
        <UnstakeCpuNetPopup
            cpu={cpu}
            net={net}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    const stakeCpuNetElement = (
        <StakeCpuNetPopup
            balance={wax_balance}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    const withdrawBuyOffersElement = (
        <WithdrawBuyOffersPopup
            balance={wax_balance}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    const buyOfferBalanceManagementElement = (
        <BuyOfferBalanceManagementPopup
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const buyWaxElement = (
        <BuyWaxPopup
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
        />
    )

    const addPictureElement = (
        <UserPicturePopup
            asset={asset}
            ual={ual}
            closeCallBack={() => {
                dispatch({ type: 'SET_ACTION', payload: '' })
            }}
            callBack={callBack}
        />
    )

    useEffect(() => {}, [action])

    return (
        <div className={cn('absolute')}>
            <OutsideAlerter
                callBack={() => {
                    callBack(false)
                    dispatch({ type: 'SET_ACTION', payload: '' })
                }}
                action={action}
                dispatch={dispatch}
            >
                {action === 'addpicture' ? addPictureElement : null}
                {action === 'auction' ? auctionElement : null}
                {action === 'bid' ? bidElement : null}
                {action === 'bulk_burn' ? bulkBurnElement : null}
                {action === 'bulk_buy' ? bulkBuyElement : null}
                {action === 'bulk_cancel' ? bulkCancelElement : null}
                {action === 'bulk_distribute' ? bulkDistributeElement : null}
                {action === 'bulk_edit' ? bulkEditElement : null}
                {action === 'bulk_sell' ? bulkSellElement : null}
                {action === 'bulk_multi_sell' ? bulkMultiSellElement : null}
                {action === 'bulk_stake' ? bulkStakeElement : null}
                {action === 'bulk_transfer' ? bulkTransferElement : null}
                {action === 'bulk_unstake' ? bulkUnstakeElement : null}
                {action === 'bundle' ? bundleElement : null}
                {action === 'burn_asset' ? burnElement : null}
                {action === 'buy_missing' ? buyMissingElement : null}
                {action === 'buy_wax' ? buyWaxElement : null}
                {action === 'buy' ? buyElement : null}
                {action === 'claim_pack' ? claimElement : null}
                {action === 'claimdrop' ? buyDropElement : null}
                {action === 'createoffer' ? buyOfferElement : null}
                {action === 'editCollection' ? editCollectionElement : null}
                {action === 'editCollectionCategory' ||
                action === 'suggestCollectionCategory'
                    ? editCollectionCategoryElement
                    : null}
                {action === 'editSchema' ? editSchemaElement : null}
                {action === 'editTemplate' ? editTemplateElement : null}
                {action === 'createDrop' ? createDropElement : null}
                {action === 'buyDropRam' ? buyDropRamElement : null}
                {action === 'updateDropAssets' ? updateDropAssetsElement : null}
                {action === 'authorizeNfthivedrops'
                    ? authorizeNfthivedropsElement
                    : null}
                {action === 'createPackRelease'
                    ? createPackReleaseElement
                    : null}
                {action === 'createPool' ? createPoolElement : null}
                {action === 'updatePoolAssets' ? updatePoolElement : null}
                {action === 'createPack' ? createPackElement : null}
                {action === 'updatePackDescriptionAnimation'
                    ? editPackDescriptionAnimationElement
                    : null}
                {action === 'updatePackTotals' ? editPackTotalsElement : null}
                {action === 'addToDropWhiteList' ? dropWhitelistElement : null}
                {action === 'setDropAuthRules' ? dropAuthProofElement : null}
                {action === 'updateDropNameDescr'
                    ? updateDropNameDescrElement
                    : null}
                {action === 'updateDropPrice' ? updateDropPriceElement : null}
                {action === 'updateDropStartEndDate'
                    ? updateDropStartEndDateElement
                    : null}
                {action === 'deleteDrop' ? deleteDropElement : null}
                {action === 'deletePackRelease'
                    ? deletePackReleaseElement
                    : null}
                {action === 'unlockRelease' ? unlockReleaseElement : null}
                {action === 'deletePack' ? deletePackElement : null}
                {action === 'deletePool' ? deletePoolElement : null}
                {action === 'mintNft' ? mintNftElement : null}
                {action === 'suggestTag' || action === 'setTag'
                    ? editTagsElement
                    : null}
                {action === 'editoffer' ? editElement : null}
                {action === 'handle_trade' ? handleTradeElement : null}
                {action === 'manage_buyoffer_contract_balance'
                    ? buyOfferBalanceManagementElement
                    : null}
                {action === 'sell' ? sellElement : null}
                {action === 'selloffer' ? sellOfferElement : null}
                {action === 'setitem' ? setItemElement : null}
                {action === 'share' ? shareElement : null}
                {action === 'stake_cpu_net' ? stakeCpuNetElement : null}
                {action === 'topup' ? topupElement : null}
                {action === 'trade' ? tradeElement : null}
                {action === 'transfer_wax' ? transferWaxElement : null}
                {action === 'transfer' ? transferElement : null}
                {action === 'tube' ? tubeElement : null}
                {action === 'unpack' ? unpackElement : null}
                {action === 'unstake_cpu_net' ? unstakeCpuNetElement : null}
                {action === 'view' ? collectionElement : null}
                {action === 'withdraw_waxbuyoffers'
                    ? withdrawBuyOffersElement
                    : null}
                {action === 'buy_drop' ? buyDropElement : null}
            </OutsideAlerter>
        </div>
    )
}

export default PopupWrapper
