import {
    eventFromUnknownInput,
    _enhanceEventWithInitialFrame,
    _eventFromIncompleteOnError,
    _eventFromRejectionWithPrimitive,
    _eventFromResponse,
} from './event'
import { getGlobalTransport } from './global'
import { shouldIgnoreOnError } from './helpers'
import { addInstrumentationHandler } from './instrument'
import { isAbortError, isFailedRequest, isOwnError, isPrimitive } from './is'

const attachStacktrace = true

export const installGlobalOnErrorHandler = () => {
    const transport = getGlobalTransport()
    addInstrumentationHandler('error', (data) => {
        const { msg, url, line, column, error } = data
        if (shouldIgnoreOnError() || isOwnError(error)) {
            return
        }

        const event =
            error === undefined && typeof msg === 'string'
                ? _eventFromIncompleteOnError(msg, url, line, column)
                : _enhanceEventWithInitialFrame(
                      eventFromUnknownInput(error || msg, undefined, {
                          attachStacktrace,
                          isRejection: false,
                      }),
                      url,
                      line,
                      column,
                  )

        event.level = 'error'

        transport(error, event, 'onerror')
    })
}

export const installGlobalOnUnhandledRejectionHandler = () => {
    const transport = getGlobalTransport()
    addInstrumentationHandler('unhandledrejection', (e) => {
        let error = e
        try {
            // PromiseRejectionEvents store the object of the rejection under 'reason'
            // see https://developer.mozilla.org/en-US/docs/Web/API/PromiseRejectionEvent
            if ('reason' in e) {
                error = e.reason
            }
            // something, somewhere, (likely a browser extension) effectively casts PromiseRejectionEvents
            // to CustomEvents, moving the `promise` and `reason` attributes of the PRE into
            // the CustomEvent's `detail` attribute, since they're not part of CustomEvent's spec
            // see https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent and
            // https://github.com/getsentry/sentry-javascript/issues/2380
            else if ('detail' in e && 'reason' in e.detail) {
                error = e.detail.reason
            }
        } catch (_oO) {
            // no-empty
        }

        if (shouldIgnoreOnError() || isOwnError(error)) return

        const event = isPrimitive(error)
            ? _eventFromRejectionWithPrimitive(error)
            : eventFromUnknownInput(error, undefined, {
                  attachStacktrace,
                  isRejection: true,
              })

        event.level = 'error'

        transport(error, event, 'onunhandledrejection')
        return
    })
}

export const installGlobalFetchHandler = (filter) => {
    const transport = getGlobalTransport()
    addInstrumentationHandler('fetch', async (data) => {
        const { args, error, fetchData, response } = data
        const { url, method } = fetchData

        if (!filter(url, data)) return
        if (isAbortError(error)) return
        if (shouldIgnoreOnError() || isOwnError(error)) return
        if (!isFailedRequest(response) || error) return

        const event = isFailedRequest(response)
            ? await _eventFromResponse(response)
            : isPrimitive(error)
            ? _eventFromRejectionWithPrimitive(error)
            : eventFromUnknownInput(error, undefined, {
                  attachStacktrace,
                  isRejection: true,
              })

        event.url = url
        event.method = method
        event.args = args
        event.level = 'error'

        transport(error, event, 'failedfetch')
    })
}
