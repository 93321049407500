import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import cn from 'classnames'
import React from 'react'

// extract the dimension from the card to give control to the external component
export const cardHeight = {
    normal: 'h-112 md:h-80 lg:h-88 xl:h-112 4xl:h-140',
    high: 'h-120 md:h-88 lg:h-96 xl:h-120 4xl:h-144',
}

export const BaseCard = ({ children, height = 'normal' }) => (
    <div
        className={cn(
            'w-full max-w-80 md:max-w-64 lg:max-w-72 xl:max-w-92 4xl:max-w-108',
            'relative min-w-min rounded-xl overflow-hidden flex flex-col',
            'center shadow hover:shadow-bluet bg-paper hover:bg-paperl transition-colors',
            'rounded-2xl mx-auto my-4 md:my-2 lg:my-4 backdrop-filter backdrop-blur-sm',
            cardHeight[height],
        )}
    >
        {children}
    </div>
)

const CardSideFront = 'front'
const CardSideBack = 'back'

export const FlippableBaseWrapper = ({
    children,
    className,
    height = 'normal',
}) => (
    <div
        className={cn(
            'w-full max-w-80 md:max-w-64 lg:max-w-72 xl:max-w-92 4xl:max-w-108',
            'relative mx-auto my-4 md:my-2 lg:my-4',
            cardHeight[height],
            className,
        )}
    >
        {children}
    </div>
)

const enterCardAnimation = keyframes`
    from {
        opacity: 0;
        transform: rotateZ(-20deg);
    }
    40% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    to {
        opacity: 1;
    } 
`

const EnterAnimationWrapper = styled.div`
    opacity: 0;
    animation: ${enterCardAnimation} var(--enter-card-animation-duration, 0.2s)
        ease-in-out calc(var(--index, 0) * 0.1s) 1 forwards;
`

const FlippableCardWrapper = styled(FlippableBaseWrapper)`
    will-change: transform;
    transition: transform var(--flip-transition-duration, 0.3s);
    transform-style: preserve-3d;
    transform: ${({ side }) =>
        side === CardSideFront ? 'rotateY(0deg)' : 'rotateY(180deg)'};
`

export const FlippableBaseCard = ({ children, className }) => (
    <div
        className={cn(
            'absolute top-0 left-0 w-full h-full min-w-min rounded-xl overflow-hidden flex flex-col',
            'center shadow hover:shadow-bluet bg-paper hover:bg-paperl transition-colors',
            'rounded-2xl backdrop-filter backdrop-blur-sm',
            className,
        )}
        children={children}
    />
)

const FrontCard = styled(FlippableBaseCard)``

export const StandardCard = ({ cardHeader, children, height = 'normal' }) => (
    <div
        className={cn(
            'relative w-full max-w-80 md:max-w-64 lg:max-w-72 xl:max-w-92 4xl:max-w-108',
            'min-w-min rounded-xl overflow-hidden flex flex-col',
            'center shadow hover:shadow-bluet bg-paper hover:bg-paperl transition-colors',
            'rounded-2xl mx-auto my-4 md:my-2 lg:my-4 backdrop-filter backdrop-blur-sm',
            cardHeight[height],
        )}
    >
        {cardHeader}
        {children}
    </div>
)

export const Card = ({ ...props }) => {
    if (props['cardHeader'])
        return (
            <div className={'relative px-4'}>
                <EnterAnimationWrapper>
                    <StandardCard {...props} />
                </EnterAnimationWrapper>
            </div>
        )
    return <BaseCard {...props} />
}
