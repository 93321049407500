// global reference to slice
const UNKNOWN_FUNCTION = '?'

const createFrame = (filename, func, lineno, colno) => {
    const frame = {
        filename,
        function: func,
        // All browser frames are considered in_app
        in_app: true,
    }

    if (lineno !== undefined) frame.lineno = lineno
    if (colno !== undefined) frame.colno = colno
    return frame
}

// Chromium based browsers: Chrome, Brave, new Opera, new Edge
const chromeRegex =
    /^\s*at (?:(.*?) ?\((?:address at )?)?((?:file|https?|blob|chrome-extension|address|native|eval|webpack|<anonymous>|[-a-z]+:|.*bundle|\/).*?)(?::(\d+))?(?::(\d+))?\)?\s*$/i
const chromeEvalRegex = /\((\S*)(?::(\d+))(?::(\d+))\)/
export const chrome = (line) => {
    const parts = chromeRegex.exec(line)

    if (parts) {
        const isEval = parts[2] && parts[2].indexOf('eval') === 0 // start of line

        if (isEval) {
            const subMatch = chromeEvalRegex.exec(parts[2])

            if (subMatch) {
                // throw out eval line/column and use top-most line/column number
                parts[2] = subMatch[1] // url
                parts[3] = subMatch[2] // line
                parts[4] = subMatch[3] // column
            }
        }

        const [func, filename] = extractSafariExtensionDetails(
            parts[1] || UNKNOWN_FUNCTION,
            parts[2],
        )

        return createFrame(
            filename,
            func,
            parts[3] ? +parts[3] : undefined,
            parts[4] ? +parts[4] : undefined,
        )
    }

    return
}

// gecko regex: `(?:bundle|\d+\.js)`: `bundle` is for react native, `\d+\.js` also but specifically for ram bundles because it
// generates filenames without a prefix like `file://` the filenames in the stacktrace are just 42.js
// We need this specific case for now because we want no other regex to match.
const geckoREgex =
    /^\s*(.*?)(?:\((.*?)\))?(?:^|@)?((?:file|https?|blob|chrome|webpack|resource|moz-extension|capacitor).*?:\/.*?|\[native code\]|[^@]*(?:bundle|\d+\.js)|\/[\w\-. /=]+)(?::(\d+))?(?::(\d+))?\s*$/i
const geckoEvalRegex = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i
export const gecko = (line) => {
    const parts = geckoREgex.exec(line)

    if (parts) {
        const isEval = parts[3] && parts[3].indexOf(' > eval') > -1
        if (isEval) {
            const subMatch = geckoEvalRegex.exec(parts[3])

            if (subMatch) {
                // throw out eval line/column and use top-most line number
                parts[1] = parts[1] || `eval`
                parts[3] = subMatch[1]
                parts[4] = subMatch[2]
                parts[5] = '' // no column when eval
            }
        }

        let filename = parts[3]
        let func = parts[1] || UNKNOWN_FUNCTION
        ;[func, filename] = extractSafariExtensionDetails(func, filename)

        return createFrame(
            filename,
            func,
            parts[4] ? +parts[4] : undefined,
            parts[5] ? +parts[5] : undefined,
        )
    }

    return
}

const winjsRegex =
    /^\s*at (?:((?:\[object object\])?.+) )?\(?((?:file|ms-appx|https?|webpack|blob):.*?):(\d+)(?::(\d+))?\)?\s*$/i
export const winjs = (line) => {
    const parts = winjsRegex.exec(line)

    return parts
        ? createFrame(
              parts[2],
              parts[1] || UNKNOWN_FUNCTION,
              +parts[3],
              parts[4] ? +parts[4] : undefined,
          )
        : undefined
}

const opera10Regex =
    / line (\d+).*script (?:in )?(\S+)(?:: in function (\S+))?$/i
export const opera10 = (line) => {
    const parts = opera10Regex.exec(line)
    return parts
        ? createFrame(parts[2], parts[3] || UNKNOWN_FUNCTION, +parts[1])
        : undefined
}

const opera11Regex =
    / line (\d+), column (\d+)\s*(?:in (?:<anonymous function: ([^>]+)>|([^)]+))\(.*\))? in (.*):\s*$/i

export const opera11 = (line) => {
    const parts = opera11Regex.exec(line)
    return parts
        ? createFrame(
              parts[5],
              parts[3] || parts[4] || UNKNOWN_FUNCTION,
              +parts[1],
              +parts[2],
          )
        : undefined
}

/**
 * Safari web extensions, starting version unknown, can produce "frames-only" stacktraces.
 * What it means, is that instead of format like:
 *
 * Error: wat
 *   at function@url:row:col
 *   at function@url:row:col
 *   at function@url:row:col
 *
 * it produces something like:
 *
 *   function@url:row:col
 *   function@url:row:col
 *   function@url:row:col
 *
 * Because of that, it won't be captured by `chrome` RegExp and will fall into `Gecko` branch.
 * This function is extracted so that we can use it in both places without duplicating the logic.
 * Unfortunately "just" changing RegExp is too complicated now and making it pass all tests
 * and fix this case seems like an impossible, or at least way too time-consuming task.
 */
const extractSafariExtensionDetails = (func, filename) => {
    const isSafariExtension = func.indexOf('safari-extension') !== -1
    const isSafariWebExtension = func.indexOf('safari-web-extension') !== -1

    return isSafariExtension || isSafariWebExtension
        ? [
              func.indexOf('@') !== -1 ? func.split('@')[0] : UNKNOWN_FUNCTION,
              isSafariExtension
                  ? `safari-extension:${filename}`
                  : `safari-web-extension:${filename}`,
          ]
        : [func, filename]
}
