import cn from 'classnames'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUAL } from '../../hooks/ual'
import BurnIndicator from '../burnindicator/BurnIndicator'
import CheckIndicator from '../checkindicator/CheckIndicator'
import ErrorNote from '../common/util/ErrorNote'
import Link from '../common/util/input/Link'
import MainButton from '../common/util/input/MainButton'
import get from '../helpers/Api'
import { formatNumber, formatUSDM } from '../helpers/FormatLinks'
import { getBoostAction, performLogin } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator'
import { Context } from '../waxplorer'

const threshold = 20

export const MarketButtons = ({
    asset,
    bidPlaced,
    bought,
    burned,
    canceled,
    claimed,
    transferred,
    claimer,
    className = 'mt-auto h-8 w-full',
    created,
    displayPrice = true,
    edited,
    error,
    frontVisible = true,
    handleBidPlaced,
    handleBought,
    handleCancel,
    handleClaim,
    handleCreateOffer,
    handleEditOffer,
    handleSell,
    handleBurn,
    handleSellOffer,
    handleTopup,
    handleUnpack,
    handleUnstake,
    isClaimed,
    isLoading,
    newOwner,
    onAdd,
    page,
    setError,
    setIsLoading,
    setSold,
    sold,
    staked,
    swapped,
    toppedUp,
    unpacked,
    unstaked,
    showOwner,
    view,
}) => {
    const ual = useUAL()
    const { activeUser } = ual
    const userName = activeUser ? activeUser['accountName'] : null
    const { t } = useTranslation('common')
    const [currentAction, setCurrentAction] = useState(1)
    const [state, dispatch] = useContext(Context)

    const {
        sender,
        balance,
        assets,
        bundle,
        offer,
        usd_offer,
        isAuction,
        orderId,
        price,
        usd_price,
        numBids,
        active,
        bidder,
        isBurned,
        market,
        buyer,
        seller,
        blacklisted,
        unpackUrl,
    } = asset

    let { owner, assetId, aAssetId, standard, contract } = asset

    const assetIds = []

    if (bundle) {
        assets.forEach((asset) => assetIds.push(asset.assetId))
    } else {
        assetIds.push(assetId)
    }

    const popError =
        state &&
        state.error &&
        (state.error['asset_id'] === orderId ||
            state.error['asset_id'] === assetId)
            ? state.error['error']
            : null

    const getOrderId = async (assetId, market, bundle) => {
        const orderId = await get(
            'get-order-id/' +
                (market ? market + '/' : '') +
                assetId +
                '?bundle=' +
                bundle,
        )
        return orderId ? orderId['orderId'] : null
    }

    const getPackOrderId = async (symbol, market, seller, offer) => {
        const orderId = await get(
            'get-pack-order-id/' +
                market +
                '/' +
                seller +
                '/' +
                symbol +
                '?offer=' +
                offer,
        )
        return orderId ? orderId['orderId'] : null
    }

    const cancel = async () => {
        let { assetId, author, isAuction, assets, orderId, symbol, owner } =
            asset

        let { market } = asset

        if (!market) {
            market = owner
        }

        setError(null)
        setIsLoading(true)

        let newOrderId = null

        if (assetId || (assetIds.length > 0 && assetIds[0]))
            newOrderId = await getOrderId(
                assetId ? assetId : assetIds[0],
                market ? market : owner,
                assets && assets.length > 1,
            )

        if (symbol)
            newOrderId = await getPackOrderId(
                symbol,
                market ? market : owner,
                userName,
                offer,
            )

        if (newOrderId) orderId = newOrderId

        try {
            let result
            switch (market) {
                case 'atomicmarket':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: isAuction
                                        ? 'cancelauct'
                                        : 'cancelsale',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: isAuction
                                        ? {
                                              auction_id: orderId,
                                          }
                                        : {
                                              sale_id: orderId,
                                          },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'wax.stash':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: owner,
                                    name: 'cancellisting',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        collection_name: author,
                                        sale_id: orderId,
                                        from: userName,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'waxbuyoffers':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'eraseoffer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        offer_id: orderId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'waxarena3dk1':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'waxarena3dk1',
                                    name: 'claim',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        asset_id: assetId,
                                        winner: userName,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'nft.hive':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'nft.hive',
                                    name: 'cancelsale',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        sale_id: orderId,
                                    },
                                },
                                {
                                    account: 'nft.hive',
                                    name: 'withdraw',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        user: userName,
                                        quantity:
                                            (author === 'a.rplanet'
                                                ? (1.0).toFixed(4)
                                                : 1) +
                                            ' ' +
                                            symbol,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'alcornftswap':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'cancelsell',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        maker: userName,
                                        order_id: orderId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'waxinventory':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'cancel',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        owner: userName,
                                        assetids: [assetId],
                                    },
                                },
                                {
                                    account: 'simpleassets',
                                    name: 'claim',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        claimer: userName,
                                        assetids: [assetId],
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'simplemarket':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'cancel',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        owner: userName,
                                        assetids: [assetId],
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'waxplorercom':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: market,
                                    name: 'cancelsale',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        sale_id: orderId,
                                    },
                                },
                                {
                                    account: 'simpleassets',
                                    name: 'claim',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        claimer: userName,
                                        assetids: assetIds,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleCancel(true)
                    break
                case 'market.place':
                    if (!orderId) {
                        throw Error('Invalid Listing Id')
                    }
                    if (assetIds.length === 1) {
                        result = await activeUser.signTransaction(
                            {
                                actions: [
                                    getBoostAction(activeUser),
                                    {
                                        account: 'market.place',
                                        name: 'unlist',
                                        authorization: [
                                            {
                                                actor: userName,
                                                permission:
                                                    activeUser[
                                                        'requestPermission'
                                                    ],
                                            },
                                        ],
                                        data: {
                                            account: userName,
                                            listingid: orderId,
                                        },
                                    },
                                    {
                                        account: 'market.place',
                                        name: 'withdraw',
                                        authorization: [
                                            {
                                                actor: userName,
                                                permission:
                                                    activeUser[
                                                        'requestPermission'
                                                    ],
                                            },
                                        ],
                                        data: {
                                            user: userName,
                                            assets: assetIds.map((id) => {
                                                return {
                                                    contract: 'simpleassets',
                                                    id: id,
                                                    quantity: '0.00000000 WAX',
                                                }
                                            }),
                                        },
                                    },
                                    {
                                        account: 'simpleassets',
                                        name: 'claim',
                                        authorization: [
                                            {
                                                actor: userName,
                                                permission:
                                                    activeUser[
                                                        'requestPermission'
                                                    ],
                                            },
                                        ],
                                        data: {
                                            claimer: userName,
                                            assetids: assetIds,
                                        },
                                    },
                                ],
                            },
                            {
                                expireSeconds: 300,
                                blocksBehind: 0,
                            },
                        )
                        handleCancel(true)
                    } else {
                        if (currentAction === 1) {
                            result = await activeUser.signTransaction(
                                {
                                    actions: [
                                        getBoostAction(activeUser),
                                        {
                                            account: 'market.place',
                                            name: 'unlist',
                                            authorization: [
                                                {
                                                    actor: userName,
                                                    permission:
                                                        activeUser[
                                                            'requestPermission'
                                                        ],
                                                },
                                            ],
                                            data: {
                                                account: userName,
                                                listingid: orderId,
                                                referral: 'waxplorerref',
                                            },
                                        },
                                    ],
                                },
                                {
                                    expireSeconds: 300,
                                    blocksBehind: 0,
                                },
                            )
                            setCurrentAction(2)
                        }

                        if (
                            assetIds.length > threshold &&
                            currentAction === 3
                        ) {
                            const transferIds = [...assetIds]
                            transferIds.splice(threshold)
                            result = await activeUser.signTransaction(
                                {
                                    actions: [
                                        getBoostAction(activeUser),
                                        {
                                            account: 'market.place',
                                            name: 'withdraw',
                                            authorization: [
                                                {
                                                    actor: userName,
                                                    permission:
                                                        activeUser[
                                                            'requestPermission'
                                                        ],
                                                },
                                            ],
                                            data: {
                                                user: userName,
                                                assets: transferIds.map(
                                                    (id) => {
                                                        return {
                                                            contract:
                                                                'simpleassets',
                                                            id: id,
                                                            quantity:
                                                                '0.00000000 WAX',
                                                        }
                                                    },
                                                ),
                                            },
                                        },
                                        {
                                            account: 'simpleassets',
                                            name: 'claim',
                                            authorization: [
                                                {
                                                    actor: userName,
                                                    permission:
                                                        activeUser[
                                                            'requestPermission'
                                                        ],
                                                },
                                            ],
                                            data: {
                                                claimer: userName,
                                                assetids: transferIds,
                                            },
                                        },
                                    ],
                                },
                                {
                                    expireSeconds: 300,
                                    blocksBehind: 0,
                                },
                            )
                            handleCancel(true)
                        }

                        if (currentAction === 2) {
                            const transferIds = [...assetIds]
                            transferIds.splice(0, threshold)
                            result = await activeUser.signTransaction(
                                {
                                    actions: [
                                        getBoostAction(activeUser),
                                        {
                                            account: 'market.place',
                                            name: 'withdraw',
                                            authorization: [
                                                {
                                                    actor: userName,
                                                    permission:
                                                        activeUser[
                                                            'requestPermission'
                                                        ],
                                                },
                                            ],
                                            data: {
                                                user: userName,
                                                assets: transferIds.map(
                                                    (id) => {
                                                        return {
                                                            contract:
                                                                'simpleassets',
                                                            id: id,
                                                            quantity:
                                                                '0.00000000 WAX',
                                                        }
                                                    },
                                                ),
                                            },
                                        },
                                        {
                                            account: 'simpleassets',
                                            name: 'claim',
                                            authorization: [
                                                {
                                                    actor: userName,
                                                    permission:
                                                        activeUser[
                                                            'requestPermission'
                                                        ],
                                                },
                                            ],
                                            data: {
                                                claimer: userName,
                                                assetids: transferIds,
                                            },
                                        },
                                    ],
                                },
                                {
                                    expireSeconds: 300,
                                    blocksBehind: 0,
                                },
                            )
                            if (assetIds.length > threshold) {
                                setCurrentAction(3)
                            } else {
                                handleCancel(true)
                            }
                        }
                    }
                    break
            }
        } catch (e) {
            console.log(e)
            setSold(false)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const claim = async () => {
        let { assetId, market, orderId } = asset

        setError(null)
        setIsLoading(true)

        try {
            switch (owner) {
                case 'atomicmarket':
                    await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'atomicmarket',
                                    name: 'auctclaimbuy',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        auction_id: orderId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleClaim(true)
                    break
                case 'waxarena3dk1':
                    await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'waxarena3dk1',
                                    name: 'claim',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        asset_id: assetId,
                                        winner: userName,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    handleClaim(true)
                    break
            }
        } catch (e) {
            console.log(e)
            setSold(false)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const unstake = async () => {
        let { assetId, standard, owner, action } = asset

        const stakingContract = newOwner ? newOwner : owner
        if (!stakingContracts.includes(stakingContract)) return

        setError(null)
        setIsLoading(true)

        try {
            await activeUser.signTransaction(
                {
                    actions: [
                        getBoostAction(activeUser),
                        stakingContract === 's.rplanet'
                            ? {
                                  account: stakingContract,
                                  name: 'unstake',
                                  authorization: [
                                      {
                                          actor: userName,
                                          permission:
                                              activeUser['requestPermission'],
                                      },
                                  ],
                                  data: {
                                      asset_ids: [assetId],
                                      contract: standard,
                                      to: userName,
                                  },
                              }
                            : {
                                  account: stakingContract,
                                  name: 'unstake',
                                  authorization: [
                                      {
                                          actor: userName,
                                          permission:
                                              activeUser['requestPermission'],
                                      },
                                  ],
                                  data: {
                                      asset_ids: [assetId],
                                      authorized_user: userName,
                                      memo: action,
                                  },
                              },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            handleUnstake(true)
        } catch (e) {
            console.log(e)
            setSold(false)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const buy = () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (bought) => handleBought(bought),
        })
        dispatch({ type: 'SET_ACTION', payload: 'buy' })
    }

    const sell = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (sellInfo) => handleSell(sellInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'sell' })
    }

    const burn = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (burnInfo) => handleBurn(burnInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'burn_asset' })
    }

    const topup = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (topupInfo) => handleTopup(topupInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'topup' })
    }

    const selloffer = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (sellInfo) => handleSellOffer(sellInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'selloffer' })
    }

    const unpack = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (unpacked) => handleUnpack(unpacked),
        })
        dispatch({ type: 'SET_ACTION', payload: 'unpack' })
    }

    const claimPack = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (unpacked) => handleUnpack(unpacked),
        })
        dispatch({ type: 'SET_ACTION', payload: 'claim_pack' })
    }

    const bid = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({ type: 'SET_SELLPRICE', payload: offer })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (bid) => handleBidPlaced(bid),
        })
        dispatch({ type: 'SET_ACTION', payload: 'bid' })
    }

    const edit = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (offerInfo) => handleEditOffer(offerInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'editoffer' })
    }

    const disMissError = () => {
        if (popError) dispatch({ type: 'SET_ERROR', payload: null })
        const errorAssets = state.errorAssets
        if (errorAssets) {
            if (assetId) delete errorAssets[assetId.toString()]
            if (aAssetId) delete errorAssets[aAssetId.toString()]
            dispatch({ type: 'SET_ERROR_ASSETS', payload: errorAssets })
        }
        setError(null)
    }

    const priceTagClassName = cn(
        'relative flex max-h-8 py-0 my-auto px-0 2xl:px-1 w-full text-base',
        'md:text-xs xl:text-base leading-5 flex-wrap',
        { 'justify-center': view !== 'full' },
        { 'justify-center md:justify-start': view === 'full' },
    )
    const buyContainerClassName = cn(
        'relative grid grid-cols-2 font-bold text-white block h-full w-full',
        { 'text-center': view !== 'full' },
        { 'text-left': view === 'full' },
    )
    const singleContainerClassName = cn(
        'mt-auto relative grid font-bold text-white block h-full w-full text-center grid-cols-1',
    )
    const sellContainerClassName = cn(
        'mt-auto relative grid font-bold text-white block h-full w-full',
        { 'grid-cols-2': price && !sold },
        { 'grid-cols-1': !price || sold },
        { 'text-center': view !== 'full' },
        { 'text-left': view === 'full' },
    )
    const buttonClassName = cn(
        'text-primary rounded border border-primary outline-none text-sm align-middle font-normal',
        'bg-transparent cursor-pointer hover:font-bold m-auto',
    )
    const cancelButtonClassName = cn(
        'text-white rounded border border-white outline-none text-sm align-middle font-normal',
        'bg-transparent cursor-pointer hover:font-bold',
    )
    const topupButtonClassName = cn(
        'text-white rounded border border-white outline-none text-sm align-middle font-normal',
        'bg-transparent cursor-pointer hover:font-bold p-1',
    )
    const usdClassName = cn('text-xs ml-1 my-auto md:text-2xs xl:text-xs')

    const defaultPriceTag =
        price && !sold ? (
            <div className={priceTagClassName}>
                {formatNumber(price)} WAX
                {usd_price ? (
                    <div className={usdClassName}>{` / ${formatUSDM(
                        usd_price,
                    )}`}</div>
                ) : (
                    ''
                )}
            </div>
        ) : (
            <div className={priceTagClassName} />
        )

    const inventoryPriceTag = price && !sold && (
        <div className={priceTagClassName + ' relative -top-1'}>
            <div className={'text-1xs leading-3 font-normal w-full'}>
                Bought for:
            </div>
            <div className={'text-sm'}>{formatNumber(price)} WAX</div>
            {usd_price ? (
                <div className={usdClassName}>{` / ${formatUSDM(
                    usd_price,
                )}`}</div>
            ) : (
                ''
            )}
        </div>
    )

    const sellField = (
        <div className={sellContainerClassName}>
            {inventoryPriceTag}
            {[
                'atomicassets',
                'simpleassets',
                'packs.topps',
                'packs.ws',
                'pack.worlds',
            ].includes(standard) ? (
                <MainButton className={buttonClassName} onClick={sell}>
                    {t('asset.sell')}
                </MainButton>
            ) : (
                ''
            )}
        </div>
    )

    const sellUnpackLinkField = (
        <div className={buyContainerClassName}>
            <MainButton className={buttonClassName} onClick={unpack}>
                {t('asset.unpack')}
            </MainButton>
            {[
                'atomicassets',
                'simpleassets',
                'packs.topps',
                'packs.ws',
                'pack.worlds',
            ].includes(standard) ? (
                <MainButton className={buttonClassName} onClick={sell}>
                    {t('asset.sell')}
                </MainButton>
            ) : (
                ''
            )}
        </div>
    )

    const unpackLinkField = (
        <div className={sellContainerClassName}>
            <Link href={unpackUrl}>
                <img src={''} />
            </Link>
            {[
                'atomicassets',
                'simpleassets',
                'packs.topps',
                'packs.ws',
                'pack.worlds',
            ].includes(standard) ? (
                <MainButton className={buttonClassName} onClick={sell}>
                    {t('asset.sell')}
                </MainButton>
            ) : (
                ''
            )}
        </div>
    )

    const burnField = (
        <div className={singleContainerClassName}>
            <div className={priceTagClassName}>
                {['atomicassets', 'simpleassets'].includes(standard) ? (
                    <MainButton
                        className={cancelButtonClassName}
                        onClick={burn}
                    >
                        {t('asset.burn')}
                    </MainButton>
                ) : (
                    ''
                )}
            </div>
        </div>
    )

    const offerField = (
        <>
            {displayPrice ? (
                <div className={buyContainerClassName}>
                    <div className={priceTagClassName}>
                        {formatNumber(offer)} WAX
                        {usd_offer ? (
                            <div className={usdClassName}>{` / $${formatNumber(
                                usd_offer,
                            )}`}</div>
                        ) : (
                            ''
                        )}
                    </div>

                    <MainButton className={buttonClassName} onClick={selloffer}>
                        Accept Offer
                    </MainButton>
                </div>
            ) : (
                <MainButton className={buttonClassName} onClick={selloffer}>
                    Accept Offer
                </MainButton>
            )}
        </>
    )

    const unpackField = (
        <div className={sellContainerClassName}>
            {['atomicassets'].includes(standard) &&
            ['atomicpacksx', 'neftyblocksp', 'nfthivepacks'].includes(
                contract,
            ) ? (
                <MainButton className={buttonClassName} onClick={unpack}>
                    {t('asset.unpack')}
                </MainButton>
            ) : (
                ''
            )}
        </div>
    )

    const claimField = (
        <div className={sellContainerClassName}>
            {['atomicassets'].includes(standard) &&
            ['atomicpacksx', 'neftyblocksp', 'nfthivepacks'].includes(
                contract,
            ) ? (
                <MainButton className={buttonClassName} onClick={claimPack}>
                    {t('asset.claim')}
                </MainButton>
            ) : (
                ''
            )}
        </div>
    )

    const bidField = (
        <div className={buyContainerClassName}>
            <div className={cn(priceTagClassName, 'relative -top-1')}>
                <div>
                    {isAuction
                        ? `${
                              numBids && numBids > 0
                                  ? t('search.top_bid')
                                  : t('search.start_bid')
                          }: `
                        : ''}
                </div>
                <div>{formatNumber(offer)} WAX</div>
                {usd_offer ? (
                    <div className={usdClassName}>{` / $${formatNumber(
                        usd_offer,
                    )}`}</div>
                ) : (
                    ''
                )}
            </div>
            <MainButton className={buttonClassName} onClick={bid}>
                {t('asset.bid')}
            </MainButton>
        </div>
    )

    const cancelField = (
        <div className={buyContainerClassName}>
            <div className={priceTagClassName}>
                {isAuction
                    ? `${
                          numBids && numBids > 0
                              ? t('search.top_bid')
                              : t('search.start_bid')
                      }: `
                    : ''}
                {formatNumber(offer)} WAX
                {usd_offer ? (
                    <div className={usdClassName}>{` / $${formatNumber(
                        usd_offer,
                    )}`}</div>
                ) : (
                    ''
                )}
            </div>
            <MainButton className={cancelButtonClassName} onClick={cancel}>
                {t('asset.cancel')}
            </MainButton>
        </div>
    )

    const editField = (
        <div className={buyContainerClassName}>
            <div className={priceTagClassName}>
                {formatNumber(offer)} WAX
                {usd_offer ? (
                    <div className={usdClassName}>{` / $${formatNumber(
                        usd_offer,
                    )}`}</div>
                ) : (
                    ''
                )}
            </div>
            {edited ? (
                <CheckIndicator />
            ) : (
                <MainButton className={buttonClassName} onClick={edit}>
                    {t('asset.edit')}
                </MainButton>
            )}
        </div>
    )

    const unstakeField = (
        <MainButton className={cancelButtonClassName} onClick={unstake}>
            {t('asset.unstake')}
        </MainButton>
    )

    const createoffer = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (offerInfo) => handleCreateOffer(offerInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'createoffer' })
    }

    const buyofferField = (
        <div className={sellContainerClassName}>
            <MainButton className={buttonClassName} onClick={createoffer}>
                Buy Offer
            </MainButton>
        </div>
    )

    const ownerField = (
        <div className={sellContainerClassName}>
            <Link href={'/user/' + owner}>{owner}</Link>
        </div>
    )

    const topupField = (
        <div className={buyContainerClassName}>
            <div
                className={cn(
                    'bg-paper backdrop-filter backdrop-blur-3xl',
                    'w-full h-full max-w-60 max-h-24',
                    'flex justify-center text-primary',
                    'text-xs mx-auto p-1 leading-5',
                    'border border-solid rounded-md border-negative',
                    'z-30',
                )}
            >
                <div className="m-auto">
                    <img className="w-5 h-5" src="/Warning_icn.svg" alt="!" />
                </div>
                <div className="pl-4 m-auto text-center">
                    Overdrawn Balance: {`${formatNumber(offer - balance)} WAX`}
                </div>
            </div>
            <MainButton className={topupButtonClassName} onClick={topup}>
                Top Up / Cancel
            </MainButton>
        </div>
    )

    const numActions =
        assetIds.length > threshold ? 3 : assetIds.length > 1 ? 2 : 1
    const cancelCollectablesField = (
        <div className={buyContainerClassName}>
            <div className={priceTagClassName}>
                {isAuction
                    ? `${
                          numBids && numBids > 0
                              ? t('search.top_bid')
                              : t('search.start_bid')
                      }: `
                    : ''}
                {formatNumber(offer)} WAX
                {usd_offer ? (
                    <div className={usdClassName}>{` / $${formatNumber(
                        usd_offer,
                    )}`}</div>
                ) : (
                    ''
                )}
            </div>
            <MainButton
                className={cancelButtonClassName}
                onClick={cancel}
                disabled={isLoading ? 'disabled' : ''}
            >
                {`${
                    currentAction === 1 ? t('asset.cancel') : t('asset.claim')
                }${numActions > 1 ? ` ${currentAction}/${numActions}` : ''}`}
            </MainButton>
        </div>
    )

    const buyField = (
        <div className={buyContainerClassName}>
            <div className={priceTagClassName}>
                {isAuction
                    ? `${
                          numBids && numBids > 0
                              ? t('search.top_bid')
                              : t('search.start_bid')
                      }: `
                    : ''}
                {formatNumber(offer)} WAX
                {usd_offer ? (
                    <div className={usdClassName}>{` / $${formatNumber(
                        usd_offer,
                    )}`}</div>
                ) : (
                    ''
                )}
            </div>
            <MainButton className={buttonClassName} onClick={buy}>
                {t('asset.buy')}
            </MainButton>
        </div>
    )

    const loginField = (
        <div className={buyContainerClassName}>
            <div className={priceTagClassName}>
                {isAuction
                    ? `${
                          numBids && numBids > 0
                              ? t('search.top_bid')
                              : t('search.start_bid')
                      }: `
                    : ''}
                {formatNumber(offer)} WAX
                {usd_offer ? (
                    <div className={usdClassName}>{` / $${formatNumber(
                        usd_offer,
                    )}`}</div>
                ) : (
                    ''
                )}
            </div>
            <MainButton
                className={buttonClassName}
                onClick={() => performLogin(ual)}
            >
                {t('asset.buy')} ({t('navigation.login')})
            </MainButton>
        </div>
    )

    const addField = (
        <div className={singleContainerClassName}>
            <MainButton
                className={buttonClassName}
                onClick={() => onAdd(asset)}
            >
                {t('trade.add')}
            </MainButton>
        </div>
    )

    const claimAuctionField = (
        <div className={buyContainerClassName}>
            <div className={priceTagClassName}>
                {isAuction
                    ? `${
                          numBids && numBids > 0
                              ? t('search.top_bid')
                              : t('search.start_bid')
                      }: `
                    : ''}
                {formatNumber(price)} WAX
                {usd_price ? (
                    <div className={usdClassName}>{` / $${formatNumber(
                        usd_price,
                    )}`}</div>
                ) : (
                    ''
                )}
            </div>
            <MainButton className={buttonClassName} onClick={claim}>
                {t('asset.claim')}
            </MainButton>
        </div>
    )

    const claimedField = (
        <div className={buyContainerClassName}>
            {t('asset.claimed_by', { claimer: claimer })}
        </div>
    )

    const stakingContracts = ['s.rplanet', 'kryptonstake']

    const claimableAuction =
        !isClaimed && isAuction && !active && userName === bidder
    const auctionClaimed = isClaimed && isAuction && !active

    const buyable =
        offer &&
        !isAuction &&
        (!userName || userName !== seller) &&
        !created &&
        !bought &&
        seller &&
        [
            'nft.hive',
            'wax.stash',
            'waxplorercom',
            'simplemarket',
            'waxinventory',
            'alcornftswap',
            'market.place',
            'gpk.market',
            'atomicmarket',
            'waxarena3dk1',
        ].includes(market)
    const offerable =
        offer &&
        !isAuction &&
        (!userName || userName !== buyer) &&
        !sold &&
        buyer &&
        'waxbuyoffers' === market
    const unstakable =
        !unstaked &&
        userName &&
        !burned &&
        userName === sender &&
        (stakingContracts.includes(owner) ||
            stakingContracts.includes(newOwner))
    const buyofferable = !created && userName && page && page === 'newbuyoffer'
    const burnable =
        !burned && userName && userName === owner && handleBurn && blacklisted
    const sellable =
        [
            'atomicassets',
            'simpleassets',
            'packs.topps',
            'packs.ws',
            'pack.worlds',
        ].includes(standard) &&
        !swapped &&
        userName &&
        (userName === owner || (newOwner && newOwner === userName)) &&
        !offer &&
        (!sold || bought) &&
        !isClaimed &&
        !staked &&
        !edited &&
        !canceled &&
        !burned &&
        !unstakable &&
        !unpacked &&
        !created &&
        (!page || page === 'inventory')

    const unpacksellable =
        unpackUrl &&
        contract &&
        !claimed &&
        !sold &&
        !unpacked &&
        (userName === owner || (newOwner && newOwner === userName)) &&
        assetId &&
        !burned

    const topuppable =
        userName === buyer &&
        market === 'waxbuyoffers' &&
        !toppedUp &&
        !canceled &&
        balance < offer

    const topupcanceled =
        userName === buyer &&
        market === 'waxbuyoffers' &&
        !toppedUp &&
        canceled &&
        balance < offer

    const checked =
        !swapped &&
        userName &&
        (userName === owner ||
            (newOwner && newOwner === userName) ||
            transferred) &&
        !offer &&
        !sold &&
        !unstakable &&
        (canceled ||
            isClaimed ||
            swapped ||
            unpacked ||
            auctionClaimed ||
            bidPlaced ||
            staked ||
            topupcanceled ||
            created ||
            transferred ||
            burned)

    const biddable =
        offer &&
        !created &&
        active &&
        isAuction &&
        userName &&
        userName !== seller &&
        !bidPlaced &&
        [
            'atomicmarket',
            'waxplorercom',
            'wax.stash',
            'simplemarket',
            'waxinventory',
            'alcornftswap',
            'market.place',
            'gpk.market',
            'waxarena3dk1',
        ].includes(newOwner ? newOwner : market ? market : owner)

    const cancable =
        userName &&
        !topuppable &&
        (((userName === seller || sold) && market !== 'waxbuyoffers') ||
            (userName === buyer && market === 'waxbuyoffers' && !sold)) &&
        (offer || (isAuction && numBids === 0)) &&
        !canceled &&
        [
            'nft.hive',
            'waxplorercom',
            'wax.stash',
            'simplemarket',
            'waxinventory',
            'market.myth',
            'alcornftswap',
            'waxarena3dk1',
            'waxbuyoffers',
        ].includes(newOwner ? newOwner : market ? market : owner)

    const editable =
        userName &&
        (userName === seller || sold) &&
        !topuppable &&
        offer &&
        !isAuction &&
        !canceled &&
        ['atomicmarket'].includes(newOwner ? newOwner : market ? market : owner)
    const collticancable =
        userName &&
        (userName === seller || sold) &&
        !canceled &&
        (owner === 'market.place' || (newOwner && newOwner === 'market.place'))
    const unpackable =
        ['atomicassets'].includes(standard) &&
        ['neftyblocksp', 'atomicpacksx', 'nfthivepacks'].includes(contract) &&
        !swapped &&
        userName &&
        (userName === owner || (newOwner && newOwner === userName)) &&
        !offer &&
        (!sold || bought || canceled) &&
        !isClaimed &&
        !staked &&
        !unstakable &&
        page &&
        page === 'packs' &&
        !unpacked

    const claimable =
        ['atomicassets'].includes(standard) &&
        ['neftyblocksp', 'atomicpacksx', 'nfthivepacks'].includes(contract) &&
        !swapped &&
        userName &&
        (userName === owner || (newOwner && newOwner === userName)) &&
        !offer &&
        (!sold || bought || canceled) &&
        !isClaimed &&
        !staked &&
        !unstakable &&
        page &&
        page === 'claims' &&
        !unpacked

    if (isBurned)
        return (
            <div
                className={cn(
                    'relative w-full h-20 mb-auto flex flex-wrap justify-between',
                    { hidden: !frontVisible },
                )}
            />
        )
    return (
        <div
            className={cn(
                'relative',
                {
                    hidden: !frontVisible,
                },
                className,
            )}
        >
            {!isLoading && !blacklisted && !onAdd && checked ? (
                <CheckIndicator />
            ) : (
                ''
            )}
            {!isLoading && !blacklisted && onAdd ? addField : ''}
            {isLoading ? (
                <LoadingIndicator className={buttonClassName} margin={'m-0'} />
            ) : (
                ''
            )}
            {!isLoading && !blacklisted && !onAdd && claimableAuction
                ? claimAuctionField
                : ''}
            {!isLoading && !blacklisted && !onAdd && claimed && claimer
                ? claimedField
                : ''}
            {!isLoading && !blacklisted && !onAdd && !sold && offerable
                ? offerField
                : ''}
            {!isLoading &&
            !onAdd &&
            (((sold || canceled) && 'waxbuyoffers' === market) || created) ? (
                <CheckIndicator />
            ) : (
                ''
            )}
            {!isLoading && burnable ? burnField : ''}
            {!isLoading && !onAdd && burned ? <BurnIndicator /> : ''}
            {!isLoading && !blacklisted && !onAdd && buyable
                ? userName
                    ? buyField
                    : loginField
                : ''}
            {!isLoading && !blacklisted && !onAdd && unstakable
                ? unstakeField
                : ''}
            {!isLoading && !blacklisted && !onAdd && buyofferable
                ? buyofferField
                : ''}
            {!isLoading && !blacklisted && !onAdd && sellable && !unpacksellable
                ? sellField
                : ''}
            {!isLoading && !blacklisted && !onAdd && sellable && unpacksellable
                ? sellUnpackLinkField
                : ''}
            {!isLoading && !blacklisted && !onAdd && unpackable
                ? unpackField
                : ''}
            {!isLoading && !blacklisted && !onAdd && claimable
                ? claimField
                : ''}
            {!isLoading && !blacklisted && !onAdd && biddable ? bidField : ''}
            {!isLoading && !blacklisted && !onAdd && cancable
                ? cancelField
                : ''}
            {!isLoading && !blacklisted && !onAdd && editable ? editField : ''}
            {!isLoading && !blacklisted && !onAdd && topuppable
                ? topupField
                : ''}
            {!isLoading && !blacklisted && !onAdd && !sellable && showOwner
                ? ownerField
                : ''}
            {!isLoading && !blacklisted && !onAdd && collticancable
                ? cancelCollectablesField
                : ''}
            {!isLoading && !onAdd && (error || popError) ? (
                <ErrorNote
                    onClick={disMissError}
                    error={error ? error : popError.message}
                    className={'-top-8 rounded-2xl'}
                />
            ) : (
                ''
            )}
        </div>
    )
}

export default MarketButtons
