import { Checkbox, Modal } from '@mui/material'
import cn from 'classnames'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { useUAL } from '../../hooks/ual'
import { AetherIcon } from '../aethericon/AetherIcon'
import AssetPreviewDisplay from '../assetpreviewdisplay/AssetPreviewDisplay'
import BackedInfo from '../backedinfo/BackedInfo'
import { Card } from '../card/Card'
import Link from '../common/util/input/Link'
import get from '../helpers/Api'
import MarketButtons from '../marketbuttons/MarketButtons'
import { MarketInfo } from '../marketinfo/MarketInfo'
import { MintInfo } from '../mintinfo/MintInfo'
import { Context } from '../waxplorer'
import { AssetPreviewInfo } from './AssetPreviewInfo'
import { AuctionTimeleft } from './AuctionTimeleft'
import CollectionHeader from './CollectionHeader'
import MoreOptions from './MoreOptions'

// @ts-ignore
export const AssetPreview = ({
    asset,
    selectedAsset,
    onAdd,
    rplanet,
    bundleView,
    page,
    showOwner,
    selectable,
    previewDuration = 2,
}) => {
    const ual = useUAL()

    const { t } = useTranslation('common')
    const [priceInfo, setPriceInfo] = useState(null)
    const [currentAsset, setCurrentAsset] = useState(0)
    const [newOwner, setNewOwner] = useState(null)

    const [favored, setFavored] = useState(asset['isFavorited'])
    const [frontVisible, setFrontVisible] = useState(true)
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null
    const [showMenu, setShowMenu] = useState(false)
    const [error, setError] = useState(null)
    const [swapped, setSwapped] = useState(false)
    const [unstaked, setUnstaked] = useState(false)
    const [staked, setStaked] = useState(false)
    const [bidPlaced, setBidPlaced] = useState(false)
    const [bought, setBought] = useState(false)
    const [burned, setBurned] = useState(false)
    const [unpacked, setUnpacked] = useState(false)
    const [canceled, setCanceled] = useState(false)
    const [edited, setEdited] = useState(false)
    const [toppedUp, setToppedUp] = useState(false)
    const [isClaimed, setIsClaimed] = useState(false)
    const [sold, setSold] = useState(false)
    const [created, setCreated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [transferred, setTransferred] = useState(false)

    const [state, dispatch] = useContext(Context)

    const selectedAssets = state.selectedAssets

    useEffect(() => {
        if (Object.keys(state.errorAssets).length > 0) {
            for (let localId of Object.keys(state.errorAssets)) {
                if (
                    assetId.toString() === localId.toString() ||
                    aAssetId.toString() === localId.toString()
                ) {
                    setError(state.errorAssets[localId])
                }
            }
        }
        if (Object.keys(state.transferredAssets).length > 0) {
            for (let localId of Object.keys(state.transferredAssets)) {
                if (
                    assetId.toString() === localId.toString() ||
                    aAssetId.toString() === localId.toString()
                ) {
                    setNewOwner(state.transferredAssets[localId]['newOwner'])
                    asset['owner'] =
                        state.transferredAssets[localId]['newOwner']
                    asset['sender'] = userName
                    setTransferred(true)
                }
            }
        }
    }, [
        selectedAssets.length,
        state.errorAssets && Object.keys(state.errorAssets).length,
        state.transferredAssets && Object.keys(state.transferredAssets).length,
    ])

    const { assets, bundle, orderName, offer, isAuction, saleId, owner } = asset

    let {
        assetId,
        templateId,
        aAssetId,
        author,
        name,
        image,
        previmg,
        backimg,
        mint,
        backedTokens,
        standard,
        claimer,
        claimed,
        orderId,
        verified,
        blacklisted,
        symbol,
        market,
        mdata,
        authorImg,
        aether_value,
        thumbnail,
        authorThumbnail,
        craftingCost,
    } = asset

    if (!authorImg && verified) {
        authorImg =
            config.ipfs + 'QmVVE5aLtq4EtMe4Khev5CSic3AKqX2RuNTAB79thvpqQW'
    } else if (!verified && authorImg) {
        authorImg = null
    }

    if (standard && standard === 'atomicassets')
        assetId = aAssetId ? aAssetId : assetId

    const assetIds = []

    const handleBought = (buyInfo) => {
        if (buyInfo) {
            const { bought, error } = buyInfo

            if (bought) {
                setNewOwner(userName)
                asset['owner'] = newOwner
                asset['offer'] = null
            }

            setError(error)
            dispatch({ type: 'SET_BALANCE_CHANGED', payload: true })
            setBought(bought)
        }
        setIsLoading(false)
    }

    const handleSell = (sellInfo) => {
        if (sellInfo) {
            const wasSold = sellInfo['sold']
            const market = sellInfo['market']
            const offer = sellInfo['offer']

            if (wasSold) {
                setNewOwner(market)
                asset['owner'] = market
                asset['market'] = market
                asset['offer'] = offer
                asset['seller'] = userName
                setCanceled(false)
            }

            if (sellInfo['error']) {
                setError(sellInfo['error'])
            }

            setSold(wasSold)
        }
        setIsLoading(false)
    }

    const handleTopup = (topupInfo) => {
        if (topupInfo) {
            const wasCanceled = topupInfo['canceled']
            const wasToppedUp = topupInfo['toppedUp']

            if (topupInfo['error']) {
                setError(topupInfo['error'])
                setIsLoading(false)
                return
            }

            setToppedUp(wasToppedUp)
            setCanceled(wasCanceled)
        }
        setIsLoading(false)
    }

    const handleCreateOffer = (offerInfo) => {
        if (offerInfo) {
            const wasCreated = offerInfo['created']

            if (offerInfo['error']) {
                setError(offerInfo['error'])
                setIsLoading(false)
                return
            }

            setCreated(wasCreated)
        }
        setIsLoading(false)
    }

    const handleBurn = (burnedInfo) => {
        if (burnedInfo) {
            const wasBurned = burnedInfo['burned']

            if (burnedInfo['error']) {
                setError(burnedInfo['error'])
                setIsLoading(false)
                return
            }

            setBurned(wasBurned)
        }
        setIsLoading(false)
    }

    const handleEditOffer = (offerInfo) => {
        if (offerInfo) {
            const edited = offerInfo['edited']
            const wasCanceled = offerInfo['canceled']

            if (offerInfo['error']) {
                setError(offerInfo['error'])
                setIsLoading(false)
                return
            }

            if (edited) {
                setEdited(true)
                asset['offer'] = edited
            }

            if (wasCanceled) {
                setNewOwner(userName)
                asset['owner'] = userName
                asset['offer'] = null
                setCanceled(wasCanceled)
            }
        }
        setIsLoading(false)
    }

    const handleUnpack = (unpackInfo) => {
        if (unpackInfo) {
            if (unpackInfo['error']) {
                setError(unpackInfo['error'])
                setIsLoading(false)
                return
            }

            const wasUnpacked = unpackInfo['unpacked']

            if (wasUnpacked) {
                setNewOwner(null)
            }

            setUnpacked(wasUnpacked)
        }
        setIsLoading(false)
    }

    const handleCancel = (cancel) => {
        try {
            if (cancel) {
                setNewOwner(userName)
                asset['owner'] = userName
                asset['offer'] = null
                setCanceled(cancel)
            }

            setIsLoading(false)
        } catch (e) {
            console.log(e.message)
            setCanceled(false)
            setIsLoading(false)
            setError(e.message)
        }
    }

    const handleBidPlaced = (bidInfo) => {
        if (bidInfo['error']) setError(bidInfo['error'])
        setBidPlaced(bidInfo['bidPlaced'])
        setIsLoading(false)
    }

    const handleClaim = (claim) => {
        try {
            if (claim) {
                setNewOwner(userName)
                asset['owner'] = userName
                asset['offer'] = null
                setIsClaimed(true)
            }

            setIsLoading(false)
        } catch (e) {
            console.log(e.message)
            setCanceled(false)
            setIsLoading(false)
            setError(e.message)
        }
    }

    const handleUnstake = (unstake) => {
        try {
            if (unstake) {
                setNewOwner(userName)
                asset['owner'] = userName
                asset['offer'] = null
                setUnstaked(true)
                setStaked(false)
                setBought(false)
                setSold(false)
            }

            setIsLoading(false)
        } catch (e) {
            console.log(e.message)
            setCanceled(false)
            setIsLoading(false)
            setError(e.message)
        }
    }

    const handleStake = (stake) => {
        try {
            if (stake) {
                setNewOwner('s.rplanet')
                asset['owner'] = userName
                asset['offer'] = null
                setStaked(true)
                setUnstaked(false)
                setBought(false)
            }

            setIsLoading(false)
        } catch (e) {
            console.log(e.message)
            setCanceled(false)
            setIsLoading(false)
            setError(e.message)
        }
    }

    const handleSellOffer = (sellInfo) => {
        if (sellInfo) {
            if (sellInfo['error']) {
                setError(sellInfo['error'])
                setIsLoading(false)
                return
            }

            const wasSold = sellInfo['sold']

            setSold(wasSold)
        }
        setIsLoading(false)
    }

    const handleTransfer = (sellInfo) => {
        if (sellInfo) {
            const wasTransferred = sellInfo['transferred']
            const owner = sellInfo['receiver']

            if (wasTransferred) {
                setNewOwner(owner)
                asset['owner'] = owner
                asset['sender'] = userName
            }

            setTransferred(wasTransferred)
        }
        setIsLoading(false)
    }

    const handleSwap = (swap) => {
        try {
            setSwapped(swap)

            setIsLoading(false)
            setCanceled(false)
        } catch (e) {
            console.log(e.message)
            setSwapped(false)
            setIsLoading(false)
            setError(e.message)
        }
    }

    if (assets && !bundleView) {
        const a = assets[currentAsset]
        assetId = a.assetId
        author = a.author
        name =
            assets.length > 1
                ? orderName
                    ? `${currentAsset + 1}/${assets.length} ${orderName.substr(
                          0,
                          60,
                      )}`
                    : `${assets[currentAsset].name} (Bundle ${
                          currentAsset + 1
                      }/${assets.length})`
                : a.name
        image = a.image
        thumbnail = a.thumbnail
        backimg = a.backimg
        mint = a.mint
        mdata = a.mdata
        assets.forEach((asset) => assetIds.push(asset.assetId))
    } else {
        assetIds.push(assetId)
    }

    const prevAsset = async () => {
        if (currentAsset > 0) setCurrentAsset(currentAsset - 1)
        else setCurrentAsset(assets.length - 1)
    }

    const nextAsset = async () => {
        if (currentAsset === assets.length - 1) setCurrentAsset(0)
        else setCurrentAsset(currentAsset + 1)
    }

    const favor = () => {
        setFavored(true)
        var encodedString = window.btoa(activeUser['accountName'])

        get(
            assetId
                ? `favor/${assetId}?key=${encodedString}`
                : `favor-template/${templateId}?key=${encodedString}`,
        )
    }

    const unfavor = () => {
        setFavored(false)
        var encodedString = window.btoa(activeUser['accountName'])

        get(
            assetId
                ? `unfavor/${assetId}?key=${encodedString}`
                : `unfavor-template/${templateId}?key=${encodedString}`,
        )
    }

    const assetData = mdata ? JSON.parse(mdata) : null

    let video = ''

    if (assetData && Object.keys(assetData).includes('video')) {
        video = assetData['video'].includes('http')
            ? assetData['video']
            : `${config.ipfs}${assetData['video']}`
    } else if (image && image.includes('video:')) {
        video = image.replace('video:', '')
        video = video.includes('http') ? video : `${config.ipfs}${video}`
    }

    if (thumbnail && video) video = thumbnail

    const toggleShowMenu = () => {
        setShowMenu(!showMenu)
    }

    const loadBack = () => {
        setFrontVisible(false)
    }

    const filterFunc = (a) => {
        return a.assetId !== asset.assetId
    }

    const assetIncluded = () => {
        return state.selectedAssets
            .map((asset) => asset.assetId)
            .includes(asset.assetId)
    }

    const handleCheck = (e) => {
        if (e.target.checked) {
            selectedAssets.push(asset)
            dispatch({ type: 'SET_SELECTED_ASSETS', payload: selectedAssets })
        } else {
            const newSelectedAssets = selectedAssets.filter(filterFunc)
            dispatch({
                type: 'SET_SELECTED_ASSETS',
                payload: newSelectedAssets,
            })
        }
    }

    const checked = assetIncluded()

    const handleClose = () => {
        setFrontVisible(true)
    }

    const cardHeader = (
        <div className={'absolute w-full h-8 z-10'}>
            <div className={'absolute flex m-auto h-10 w-8'}>
                {selectable && (
                    <Checkbox checked={checked} onChange={handleCheck} />
                )}
            </div>
            <div />
            <div />
            <div
                className={cn(
                    'absolute right-10 w-8 h-8 -top-0.5',
                    {
                        'z-10': showMenu,
                    },
                    { 'z-30': !showMenu },
                )}
            >
                <img
                    onClick={favored ? unfavor : favor}
                    className={cn('h-6 w-6 mx-auto')}
                    src={favored ? '/bookmark.svg' : '/bookmark-outline.svg'}
                    alt={t('search.favorites')}
                />
            </div>
            {page !== 'newbuyoffer' && page !== 'editor' && (
                <MoreOptions
                    setShowMenu={setShowMenu}
                    newOwner={newOwner}
                    rplanet={rplanet}
                    showMenu={showMenu}
                    asset={asset}
                    handleSell={handleSell}
                    handleTransfer={handleTransfer}
                    handleSwap={handleSwap}
                    handleStake={handleStake}
                    handleCreateOffer={handleCreateOffer}
                    handleBurn={handleBurn}
                    created={created}
                    burned={burned}
                    staked={staked}
                    setError={setError}
                    sold={sold}
                    setSold={setSold}
                    setIsLoading={setIsLoading}
                    transferred={transferred}
                    toggleShowMenu={toggleShowMenu}
                />
            )}
        </div>
    )

    const router = useRouter()
    const location = router.asPath.substring(
        0,
        router.asPath.indexOf('?') > 0
            ? router.asPath.indexOf('?')
            : router.asPath.length,
    )

    const origin = location.includes('/collections/')
        ? location
        : offer || market === 'waxbuyoffers' || saleId
        ? '/market'
        : ''

    const href = symbol
        ? '/pack/' +
          symbol +
          (orderId ? `?listing_id=${orderId ? orderId : ''}` : '') +
          (assetId ? `${orderId ? '&' : '?'}asset_id=${assetId}` : '') +
          (market ? `${orderId || assetId ? '&' : '?'}market=${market}` : '')
        : market === 'waxbuyoffers'
        ? origin + `/offer/${orderId}`
        : saleId
        ? origin + `/sale/${saleId}`
        : offer
        ? isAuction
            ? origin + '/auction/' + orderId
            : origin +
              '/listing/' +
              assetId +
              (orderId ? `?listing_id=${orderId}` : '')
        : origin + '/asset/' + assetId

    const cardFront = (
        <>
            <div className={'bg-page relative'}>
                <div
                    className={cn(
                        'flex relative m-auto',
                        'w-56 md:w-40 xl:w-56 2xl:w-72 4xl:w-72',
                        'h-56 md:h-40 xl:h-56 2xl:h-60 4xl:h-72',
                    )}
                >
                    {bundle && !bundleView && assets.length > 1 ? (
                        <button
                            className={cn(
                                'absolute m-auto h-full border-0 text-primary cursor-pointer',
                                'outline-none text-2xl left-0 opacity-80 hover:opacity-100',
                            )}
                            onClick={prevAsset}
                        >
                            ‹
                        </button>
                    ) : null}
                    <Link
                        inactive={page === 'newbuyoffer' || page === 'editor'}
                        href={href}
                    >
                        <div className={cn('m-auto cursor-pointer')}>
                            <AssetPreviewDisplay
                                image={image}
                                prevImg={thumbnail ? thumbnail : previmg}
                                video={video}
                                backImg={backimg}
                                previewDuration={previewDuration}
                            />
                        </div>
                    </Link>
                    {bundle && !bundleView && assets.length > 1 && (
                        <button
                            className={cn(
                                'absolute m-auto h-full border-0 text-primary cursor-pointer',
                                'outline-none text-2xl right-0 opacity-80 hover:opacity-100',
                            )}
                            onClick={nextAsset}
                        >
                            ›
                        </button>
                    )}
                </div>
                {!isLoading && aether_value > 0.0001 ? (
                    <AetherIcon
                        aetherValue={aether_value}
                        enabled={
                            activeUser &&
                            activeUser['accountName'] ===
                                (newOwner ? newOwner : owner)
                        }
                        asset={asset}
                        handleStake={handleStake}
                        staked={Number(staked)}
                    />
                ) : null}
                {mint && <MintInfo top={'bottom-0 left-1'} mint={mint} />}
                {backedTokens && <BackedInfo backedTokens={backedTokens} />}
            </div>
            <CollectionHeader
                author={author}
                authorImg={authorImg}
                authorThumbnail={authorThumbnail}
                verified={verified}
                blacklisted={blacklisted}
            />
            <div
                className={cn(
                    'relative mx-auto w-auto flex justify-evenly',
                    'text-white cursor-pointer font-bold 4xl:-top-2',
                    {
                        'text-sm md:text-2xs lg:text-2xs xl:text-xs leading-3':
                            name && name.length >= 50,
                    },
                    {
                        'text-sm md:text-2xs lg:text-1xs xl:text-1xs':
                            name && name.length >= 30 && name.length < 50,
                    },
                    {
                        'text-base md:text-1xs lg:text-xs xl:text-base':
                            name && name.length < 30,
                    },
                    {
                        'h-8 md:h-4 lg:h-6 xl:h-10': !craftingCost,
                    },
                    {
                        'h-7 md:h-4 lg:h-5 xl:h-8': craftingCost,
                    },
                )}
            >
                <Link
                    inactive={page === 'newbuyoffer' || page === 'editor'}
                    href={href}
                >
                    {name ? name : assetId}
                </Link>
                <div onClick={loadBack} className={cn('flex relative z-10')}>
                    <img
                        className={cn(
                            'h-5 m-2 pl-2 top-1 mx-auto opacity-80 hover:opacity-100',
                        )}
                        src={'/info_icn.svg'}
                        alt={t('search.info')}
                    />
                </div>
            </div>
            <MarketInfo
                asset={asset}
                priceInfo={priceInfo}
                currentAsset={currentAsset}
                assets={assets}
                bundle={bundle}
            />
            {!selectedAsset && selectedAsset !== 0 && (
                <MarketButtons
                    className={'mt-auto mt-2 mb-4 h-8 w-full'}
                    onAdd={onAdd}
                    asset={asset}
                    newOwner={newOwner}
                    claimer={claimer}
                    claimed={claimed}
                    handleSell={handleSell}
                    handleBurn={handleBurn}
                    handleUnpack={handleUnpack}
                    handleBought={handleBought}
                    handleClaim={handleClaim}
                    handleSellOffer={handleSellOffer}
                    handleUnstake={handleUnstake}
                    handleCancel={handleCancel}
                    handleBidPlaced={handleBidPlaced}
                    handleTopup={handleTopup}
                    handleCreateOffer={handleCreateOffer}
                    handleEditOffer={handleEditOffer}
                    created={created}
                    swapped={swapped}
                    burned={burned}
                    transferred={transferred}
                    unstaked={unstaked}
                    unpacked={unpacked}
                    staked={staked}
                    toppedUp={toppedUp}
                    page={page}
                    bidPlaced={bidPlaced}
                    bought={bought}
                    canceled={canceled}
                    edited={edited}
                    isClaimed={isClaimed}
                    error={error}
                    setError={setError}
                    sold={sold}
                    setSold={setSold}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    showOwner={showOwner}
                />
            )}

            {isAuction && <AuctionTimeleft asset={asset} />}
        </>
    )

    return (
        <>
            <Card
                height={isAuction ? 'high' : 'normal'}
                cardHeader={cardHeader}
                onFlip={loadBack}
            >
                {cardFront}
            </Card>
            <Modal
                open={!frontVisible}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <AssetPreviewInfo
                    cancel={handleClose}
                    asset={asset}
                    newOwner={newOwner}
                    priceInfo={priceInfo}
                    setPriceInfo={setPriceInfo}
                    currentAsset={currentAsset}
                    bundleView={bundleView}
                    show={!frontVisible}
                />
            </Modal>
        </>
    )
}

export default AssetPreview
