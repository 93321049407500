import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import {
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
} from '@mui/material'
import cn from 'classnames'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from '../../utils/date'
import Link from '../common/util/input/Link'
import {
    formatNumber,
    formatPercentage,
    formatPercentageFull,
    openSearchPopup,
} from '../helpers/FormatLinks'
import { Context } from '../waxplorer'

const descriptionTermClass = cn('text-left text-neutral text-xs pl-7 font-bold')
const descriptionDetailClass = cn(
    'ml-auto text-neutral text-xs leading-4 overflow-x-auto mr-7',
)

const DescriptionEntry = ({ detail, term, onClick }) => (
    <div className="flex" onClick={onClick}>
        <div className={descriptionTermClass}>{term}</div>
        <div className={descriptionDetailClass}>{detail}</div>
    </div>
)

export const MarketInfoDetails = ({
    asset,
    priceInfo,
    assets,
    bundle,
    currentAsset,
    className,
}) => {
    const {
        average,
        usd_average,
        last_sold,
        usd_last_sold,
        lowest,
        usd_lowest,
        revenue,
        usd_revenue,
        num_sales,
        craftingCost,
        buyer,
        seller,
        market,
        price,
        usd_price,
        date,
        sender,
        receiver,
        transactionId,
    } = asset

    const { t } = useTranslation('common')

    const dateStr = format(date, null)

    const avgWAX =
        priceInfo && priceInfo['average'] ? priceInfo['average'] : average
    const avgUSD =
        priceInfo && priceInfo['average_usd']
            ? priceInfo['average_usd']
            : usd_average
    const lastSold =
        priceInfo && priceInfo['last_sold'] ? priceInfo['last_sold'] : last_sold
    const lastSoldUSD =
        priceInfo && priceInfo['last_sold_usd']
            ? priceInfo['last_sold_usd']
            : usd_last_sold
    const lowestWAX = priceInfo ? priceInfo['lowest'] : lowest
    const lowestUSD = priceInfo ? priceInfo['lowest_usd'] : usd_lowest
    const revenueWAX = priceInfo ? priceInfo['revenue'] : revenue
    const revenueUSD = priceInfo ? priceInfo['revenue_usd'] : usd_revenue
    const numSales = priceInfo ? priceInfo['num_sales'] : num_sales

    let diff = 0
    if (asset && asset['usd_offer'] && avgUSD)
        diff = asset['usd_offer'] / avgUSD - 1

    const initialOpenItems = () => {
        const openItems = new Map()
        openItems.set(t('search.market'), true)
        return openItems
    }

    const [toggleItem, setToggleItem] = useState(initialOpenItems())

    const toggleItemByName = (name) => {
        const newMap = new Map(toggleItem)
        newMap.set(name, !toggleItem.get(name))
        setToggleItem(newMap)
    }

    const collapsibleListItem = (name, children) => {
        return (
            <>
                <div
                    onClick={() => {
                        toggleItemByName(name)
                    }}
                    className="flex my-2 cursor-pointer"
                >
                    <div className="mr-2 my-auto">
                        {toggleItem.get(name) ? (
                            <ArrowDropUpIcon />
                        ) : (
                            <ArrowDropDownIcon />
                        )}
                    </div>
                    <div className="my-auto text-primary text-lg">{name}</div>
                </div>
                <Collapse in={toggleItem.get(name)}>
                    <div className="mb-5">{children}</div>
                </Collapse>
            </>
        )
    }

    const [, dispatch] = useContext(Context)
    return buyer && seller && price ? (
        <div
            className={
                className
                    ? className
                    : 'relative w-full mt-6 max-h-100 overflow-y-auto InsideScroll'
            }
        >
            {collapsibleListItem(
                t('asset.details'),
                <Table>
                    <TableBody>
                        {buyer && (
                            <TableRow>
                                <TableCell>Buyer</TableCell>
                                <TableCell>{buyer}</TableCell>
                            </TableRow>
                        )}
                        {seller && (
                            <TableRow>
                                <TableCell>Seller</TableCell>
                                <TableCell>{seller}</TableCell>
                            </TableRow>
                        )}
                        {market && (
                            <TableRow>
                                <TableCell>Market</TableCell>
                                <TableCell>{market}</TableCell>
                            </TableRow>
                        )}
                        {price && (
                            <TableRow>
                                <TableCell>Price</TableCell>
                                <TableCell>{`${formatNumber(
                                    price,
                                )} WAX / $${formatNumber(
                                    usd_price,
                                )}`}</TableCell>
                            </TableRow>
                        )}
                        {dateStr && (
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>{dateStr}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>,
            )}
        </div>
    ) : receiver && sender ? (
        <div
            className={
                className
                    ? className
                    : 'relative w-full mt-6 max-h-100 overflow-y-auto InsideScroll'
            }
        >
            {collapsibleListItem(
                t('asset.details'),
                <Table>
                    <TableBody>
                        {dateStr && (
                            <TableRow>
                                <TableCell>Sender</TableCell>
                                <TableCell>{sender}</TableCell>
                            </TableRow>
                        )}
                        {receiver && (
                            <TableRow>
                                <TableCell>Receiver</TableCell>
                                <TableCell>{receiver}</TableCell>
                            </TableRow>
                        )}
                        {transactionId && (
                            <TableRow>
                                <TableCell>Tx</TableCell>
                                <TableCell>
                                    <Link
                                        href={
                                            'https://wax.bloks.io/transaction/' +
                                            transactionId
                                        }
                                        external={true}
                                    >
                                        {transactionId.substr(0, 20) + '...'}
                                    </Link>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>,
            )}
        </div>
    ) : (
        <div
            className={
                className
                    ? className
                    : 'relative w-full mt-6 max-h-100 overflow-y-auto InsideScroll'
            }
        >
            {collapsibleListItem(
                t('search.market'),
                <Table size="small">
                    <TableBody>
                        {avgWAX && (
                            <TableRow>
                                <TableCell>Median</TableCell>
                                <TableCell>
                                    {
                                        <div className={'flex'}>
                                            {`${formatNumber(
                                                avgWAX,
                                            )} WAX / $${formatNumber(avgUSD)}`}
                                            {diff ? (
                                                <Tooltip
                                                    title={
                                                        'waxbuyoffers' !==
                                                        market
                                                            ? diff > 0
                                                                ? `Price is ${formatPercentageFull(
                                                                      diff,
                                                                  )} above Median`
                                                                : `Price is ${formatPercentageFull(
                                                                      diff,
                                                                  )} below Median`
                                                            : diff > 0
                                                            ? `Offer is ${formatPercentageFull(
                                                                  diff,
                                                              )} above Median`
                                                            : `Offer is ${formatPercentageFull(
                                                                  diff,
                                                              )} below Median`
                                                    }
                                                >
                                                    <div
                                                        className={cn(
                                                            {
                                                                'text-negative':
                                                                    'waxbuyoffers' !==
                                                                    market
                                                                        ? diff >
                                                                          0
                                                                        : diff <
                                                                          0,
                                                            },
                                                            {
                                                                'text-positive':
                                                                    'waxbuyoffers' !==
                                                                    market
                                                                        ? diff <
                                                                          0
                                                                        : diff >
                                                                          0,
                                                            },
                                                        )}
                                                    >
                                                        &nbsp;
                                                        {diff
                                                            ? ` (${
                                                                  diff > 0
                                                                      ? '+'
                                                                      : ''
                                                              }${formatPercentage(
                                                                  diff,
                                                              )})`
                                                            : ''}
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    }
                                </TableCell>
                            </TableRow>
                        )}
                        {lastSold && (
                            <TableRow>
                                <TableCell>Last Sold</TableCell>
                                <TableCell>
                                    <div
                                        className="cursor-pointer"
                                        onClick={() =>
                                            openSearchPopup(
                                                'trades',
                                                bundle
                                                    ? assets[currentAsset]
                                                    : asset,
                                                dispatch,
                                            )
                                        }
                                    >
                                        {`${formatNumber(
                                            lastSold,
                                        )} WAX / $${formatNumber(lastSoldUSD)}`}
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                        {!lowestWAX && revenue && (
                            <TableRow>
                                <TableCell>Volume</TableCell>
                                <TableCell>{`${formatNumber(
                                    revenueWAX,
                                )} WAX / $${formatNumber(
                                    revenueUSD,
                                )}`}</TableCell>
                            </TableRow>
                        )}
                        {numSales && (
                            <TableRow>
                                <TableCell>Sales</TableCell>
                                <TableCell>{numSales}</TableCell>
                            </TableRow>
                        )}
                        {lowestWAX && (
                            <TableRow>
                                <TableCell>Floor</TableCell>
                                <TableCell>
                                    <div
                                        className="cursor-pointer"
                                        onClick={() =>
                                            openSearchPopup(
                                                'sales',
                                                bundle
                                                    ? assets[currentAsset]
                                                    : asset,
                                                dispatch,
                                            )
                                        }
                                    >{`${formatNumber(
                                        lowestWAX,
                                    )} WAX / $${formatNumber(lowestUSD)}`}</div>
                                </TableCell>
                            </TableRow>
                        )}
                        {craftingCost && (
                            <TableRow>
                                <TableCell>Crafting Cost</TableCell>
                                <TableCell>{`~${formatNumber(
                                    craftingCost,
                                )} WAX`}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>,
            )}
        </div>
    )
}
