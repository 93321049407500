import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { unsignedIntegerRegEx } from '../editor/EditorComponents'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function EditTemplatePopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionUniqueName = props['collectionUniqueName']
    const editSchemaName = props['editSchemaName']
    const editTemplateAttributes = props['editTemplateAttributes']

    const defaultMaxSupply = 0
    const [editMaxSupply, setEditMaxSupply] = useState(defaultMaxSupply)

    const onChange = (event) => {
        setEditMaxSupply(event.target.value)
    }

    const [error, setError] = useState(null)

    const POPUP_STRING_CUTOFF = 20

    const create = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const transferable = editTemplateAttributes.transferable
        const burnable = editTemplateAttributes.burnable
        const immutableData = editTemplateAttributes.immutableData

        const data = {
            authorized_creator: userName,
            collection_name: collectionUniqueName,
            schema_name: editSchemaName,
            transferable: transferable,
            burnable: burnable,
            max_supply: editMaxSupply,
            immutable_data: immutableData,
        }

        const action = {
            account: 'atomicassets',
            name: 'createtempl',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: data,
        }

        actions.push(action)

        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result.transactionId
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Create Template" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to create a template with these attributes?
            </div>
            <div className="PopupBody">
                <div className="PopupDetails">
                    <div className="my-10">
                        <TextField
                            value={editMaxSupply}
                            size="small"
                            fullWidth={true}
                            label="Max Supply (0 for unlimited)"
                            onChange={onChange}
                            error={!unsignedIntegerRegEx.test(editMaxSupply)}
                        />
                    </div>
                    <div className="grid grid-cols-2 text-xl my-10">
                        <div className="font-bold mb-2 w-full">Name</div>
                        <div className="font-bold mb-2 w-full">Value</div>
                        {editTemplateAttributes.immutableData.map(
                            (attribute) => (
                                <>
                                    <div className="w-full">
                                        {attribute.key}
                                    </div>
                                    <div className="w-full">
                                        {[
                                            'img',
                                            'image',
                                            'backimg',
                                            'backimage',
                                            'video',
                                        ].includes(attribute.key) ? (
                                            <a
                                                href={`${
                                                    config.ipfs
                                                }${attribute.value[1].replace(
                                                    config.ipfs,
                                                    '',
                                                )}`}
                                                target="_blank"
                                            >
                                                {attribute.value[1].length >
                                                POPUP_STRING_CUTOFF
                                                    ? attribute.value[1].substring(
                                                          0,
                                                          POPUP_STRING_CUTOFF,
                                                      ) + '...'
                                                    : attribute.value[1]}
                                            </a>
                                        ) : attribute.value[0] === 'string' ? (
                                            attribute.value[1].length >
                                            POPUP_STRING_CUTOFF ? (
                                                attribute.value[1].substring(
                                                    0,
                                                    POPUP_STRING_CUTOFF,
                                                ) + '...'
                                            ) : (
                                                attribute.value[1]
                                            )
                                        ) : (
                                            attribute.value[1]
                                        )}
                                    </div>
                                </>
                            ),
                        )}
                    </div>
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button
                        variant="outlined"
                        onClick={create}
                        disabled={!unsignedIntegerRegEx.test(editMaxSupply)}
                    >
                        Create Template
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default EditTemplatePopup
