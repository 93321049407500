import { getGlobalObject } from './global'
import { isDebugBuild, isInstanceOf } from './is'
import { fill } from './object'
import { supportsFetch } from './supports'

const global = getGlobalObject()
// Record of handlers for various events
const handlers = {}

const triggerHandlers = (type, data) => {
    if (!type || !handlers[type]) return

    for (const handler of handlers[type] || []) {
        // wrap with try/catch to ensure this will not throw!
        try {
            handler(data)
        } catch (e) {
            if (isDebugBuild())
                console.error(`Error while triggering handler\nError: ${e}`)
        }
    }
}

const instrumented = {}
/**
 * This function will register the instrument once. This ensures that the handlers will not be stacked
 * and each instrument can be used any number of times.
 */
const instrument = (type) => {
    if (instrumented[type]) return

    instrumented[type] = true

    if (type === 'unhandledrejection') return instrumentUnhandledRejection()
    if (type === 'error') return instrumentError()
    if (type === 'console') return instrumentConsole()
    if (type === 'fetch') return instrumentFetch()
    if (type === 'history') return instrumentHistory()
}

/** given a type, this will register a handler to be called when that instrument triggers */
export const addInstrumentationHandler = (type, handler) => {
    console.warn('adding to', type)
    if (!handlers[type]) handlers[type] = []
    handlers[type].push(handler)
    instrument(type)
}

/** Unhandled Rejections */
let _oldOnUnhandledRejectionHandler // old handler | undefined
const instrumentUnhandledRejection = () => {
    _oldOnUnhandledRejectionHandler = global.onunhandledrejection

    global.onunhandledrejection = (e) => {
        triggerHandlers('unhandledrejection', e)
        return _oldOnUnhandledRejectionHandler
            ? _oldOnUnhandledRejectionHandler.apply(this, arguments)
            : true
    }
}

/** global Error */
let _oldOnErrorHandler // old handler | undefined
const instrumentError = () => {
    _oldOnErrorHandler = global.onerror

    global.onerror = (msg, url, line, column, error = {}) => {
        if (error.__LOGGING_MARKER__) return
        error.__LOGGING_MARKER__ = true

        triggerHandlers('error', {
            column,
            error,
            line,
            msg,
            url,
        })

        return _oldOnErrorHandler
            ? _oldOnErrorHandler.apply(this, arguments)
            : false
    }
}

/** Console */
const instrumentConsole = () => {
    if (!('console' in global)) {
        return
    }

    ;['debug', 'info', 'warn', 'error', 'log', 'assert'].forEach((level) => {
        if (!(level in global.console)) return

        fill(global.console, level, (originalConsoleMethod) => (...args) => {
            triggerHandlers('console', { args, level })

            // this fails for some browsers. :(
            if (originalConsoleMethod) {
                originalConsoleMethod.apply(global.console, args)
            }
        })
    })
}

/** Fetch */
const getFetchMethod = (fetchArgs) => {
    if (
        'Request' in global &&
        isInstanceOf(fetchArgs[0], Request) &&
        fetchArgs[0].method
    )
        return String(fetchArgs[0].method).toUpperCase()

    if (fetchArgs[1] && fetchArgs[1].method)
        return String(fetchArgs[1].method).toUpperCase()

    return 'GET'
}
const getFetchUrl = (fetchArgs) => {
    if (typeof fetchArgs[0] === 'string') return fetchArgs[0]

    if ('Request' in global && isInstanceOf(fetchArgs[0], Request))
        return fetchArgs[0].url

    return String(fetchArgs[0])
}

const instrumentFetch = () => {
    if (!supportsFetch()) return

    fill(
        global,
        'fetch',
        (originalFetch) =>
            // required to return a non-arrow function
            function (...args) {
                const handlerData = {
                    args,
                    fetchData: {
                        method: getFetchMethod(args),
                        url: getFetchUrl(args),
                    },
                    startTimestamp: Date.now(),
                }

                triggerHandlers('fetch', {
                    ...handlerData,
                })

                return originalFetch.apply(global, args).then(
                    (response) => {
                        triggerHandlers('fetch', {
                            ...handlerData,
                            endTimestamp: Date.now(),
                            response: response.clone(),
                        })
                        return response
                    },
                    (error) => {
                        triggerHandlers('fetch', {
                            ...handlerData,
                            endTimestamp: Date.now(),
                            error,
                        })
                        // NOTE: This is expected behavior and NOT indicative of a bug with logging.
                        // It just re-threw the error after logging.
                        throw error
                    },
                )
            },
    )
}

/** History */
const instrumentHistory = () => {
    if (!supportsHistory()) {
        return
    }

    const oldOnPopState = global.onpopstate
    global.onpopstate = function (...args) {
        const to = global.location.href
        // keep track of the current URL state, as we always receive only the updated state
        const from = lastHref
        lastHref = to
        triggerHandlers('history', {
            from,
            to,
        })
        if (oldOnPopState)
            try {
                return oldOnPopState.apply(self, args)
            } catch (_oO) {}
    }

    const historyReplacementFunction = (originalHistoryFunction) => {
        // required to be a non-arrow function
        return function (...args) {
            const url = args.length > 2 ? args[2] : undefined
            if (url) {
                // coerce to string (this is what pushState does)
                const from = lastHref
                const to = String(url)
                // keep track of the current URL state, as we always receive only the updated state
                lastHref = to
                triggerHandlers('history', {
                    from,
                    to,
                })
            }
            return originalHistoryFunction.apply(this, args)
        }
    }

    fill(global.history, 'pushState', historyReplacementFunction)
    fill(global.history, 'replaceState', historyReplacementFunction)
}
