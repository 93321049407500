import { createTheme } from '@mui/material'

export const mainTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#F6C30D',
        },
        secondary: {
            main: '#F6C30D',
        },
        default: {
            main: '#F6C30D',
        },
        info: {
            main: '#F6C30D',
        },
        neutral: {
            main: '#FEFEFE',
        },
        text: {
            disabled: '#333333',
        },
        action: {
            disabled: '#333333',
        },
    },
})
