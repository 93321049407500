import React from 'react'
import LoadingIndicator from './LoadingIndicator'

export const PopupLoadingIndicator = ({ text }) => {
    return (
        <div className="absolute flex top-0 left-0 w-full h-full backdrop-filter backdrop-blur-3xl">
            <div className={'w-1/2 m-auto'}>
                <LoadingIndicator text={text} />
            </div>
        </div>
    )
}
