import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function EditSchemaPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const editSchemaAttributes = props['editSchemaAttributes']
    const collectionUniqueName = props['collectionUniqueName']
    const editSchemaName = props['editSchemaName']
    const newSchema = props['newSchema']

    const minimumAttributes = [
        { name: 'name', type: 'string' },
        { name: 'img', type: 'image' },
    ]

    const [error, setError] = useState(null)

    const edit = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        if (newSchema) {
            const data = {
                authorized_creator: userName,
                collection_name: collectionUniqueName,
                schema_name: editSchemaName,
            }

            if (editSchemaAttributes && editSchemaAttributes.length > 0) {
                const completeAttributes =
                    minimumAttributes.concat(editSchemaAttributes)
                const attributes = { schema_format: completeAttributes }
                data = { ...data, ...attributes }
            } else {
                const attributes = { schema_format: minimumAttributes }
                data = { ...data, ...attributes }
            }

            const action = {
                account: 'atomicassets',
                name: 'createschema',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: data,
            }
            actions.push(action)
        } else {
            if (editSchemaAttributes && editSchemaAttributes.length > 0) {
                const data = {
                    authorized_editor: userName,
                    collection_name: collectionUniqueName,
                    schema_name: editSchemaName,
                    schema_format_extension: editSchemaAttributes,
                }
                const action = {
                    account: 'atomicassets',
                    name: 'extendschema',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser['requestPermission'],
                        },
                    ],
                    data: data,
                }
                actions.push(action)
            }
        }

        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result.transactionId
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            newSchema,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, newSchema, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Edit Schema" cancel={cancel}>
            <div className="mx-auto text-xl font-bold mb-4">
                {newSchema
                    ? `Do you want to create a schema with these attributes?`
                    : `Do you want to add these attributes to this schema?`}
            </div>
            <div className="PopupBody">
                <div className="PopupDetails">
                    <div className="flex justify-center text-xl font-bold mb-2">
                        Schema Name: {editSchemaName}
                    </div>
                    <div className="grid grid-cols-2 text-xl my-10">
                        <div className="m-auto font-bold mb-2">
                            Attribute Name
                        </div>
                        <div className="m-auto font-bold mb-2">Type</div>
                        {newSchema &&
                            minimumAttributes.map((attribute) => (
                                <>
                                    <div className="m-auto">
                                        {attribute.name}
                                    </div>
                                    <div className="m-auto">
                                        {attribute.type}
                                    </div>
                                </>
                            ))}
                        {editSchemaAttributes.map((attribute) => (
                            <>
                                <div className="m-auto">{attribute.name}</div>
                                <div className="m-auto">{attribute.type}</div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="Buttons">
                <MainButton className="PopupCancelButton" onClick={cancel}>
                    {t('asset.cancel')}
                </MainButton>
                <MainButton className="PopupEditButton" onClick={edit}>
                    {t('collection.save_collection')}
                </MainButton>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default EditSchemaPopup
