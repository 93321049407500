const fallbackGlobalObject = {}

// checks global, globalThis, window and this to return a valid global object, fallback provided
export const getGlobalObject = () =>
    typeof global !== 'undefined'
        ? global
        : typeof globalThis !== 'undefined'
        ? globalThis
        : typeof window !== 'undefined'
        ? window
        : typeof this !== 'undefined'
        ? this
        : fallbackGlobalObject

// List of transport handlers
const tranports = []
export const getGlobalTransport = () => (error, event, type) => {
    for (let transport of tranports) {
        try {
            transport(error, event, type)
        } catch (e) {
            // no-empty
        }
    }
}

export const addTransport = (transport) => tranports.push(transport)
