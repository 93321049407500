import cn from 'classnames'
import NextLink from 'next/link'
import React from 'react'

export const Link = ({
    children,
    inactive,
    className,
    href,
    onClick,
    external,
    forcePointer,
}) => {
    const linkClassNames = cn(
        'block',
        'focus-visible:ring-1 focus-visible:ring-inset',
        'focus-visible:ring-primary m-auto',
        { 'cursor-default': !forcePointer && inactive },
        { 'cursor-pointer': forcePointer || !inactive },
        className,
    )
    if (inactive || !href)
        return <div className={linkClassNames}>{children}</div>

    if (external)
        return (
            <a
                href={href.toString()}
                target="_blank"
                rel="noopener noreferrer"
                className={linkClassNames}
            >
                {children}
            </a>
        )
    return (
        <NextLink href={href} passHref>
            <div className={linkClassNames} onClick={onClick}>
                {children}
            </div>
        </NextLink>
    )
}

Link.displayName = 'Hive-Link'

export default Link
