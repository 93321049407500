import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function TransferPopup(props) {
    const asset = props['asset']
    const { t } = useTranslation('common')

    const {
        assetId,
        aAssetId,
        schema,
        number,
        rarity,
        variant,
        author,
        name,
        image,
        standard,
    } = asset

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']

    const callBack = props['callBack']
    const [receiver, setReceiver] = useState(
        props['receiver'].trim().toLowerCase(),
    )
    const [memo, setMemo] = useState('')

    const userName = activeUser ? activeUser['accountName'] : null
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']

    const transfer = async () => {
        if (!receiver) return
        closeCallBack()
        setIsLoading(true)

        try {
            let result
            switch (standard) {
                case 'simpleassets':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'simpleassets',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        to: receiver,
                                        assetids: [assetId],
                                        memo: memo,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'atomicassets':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'atomicassets',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        memo: memo,
                                        asset_ids: [aAssetId],
                                        to: receiver,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'packs.ws':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'packs.ws',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        memo: memo,
                                        quantity: `1 ${name}`,
                                        to: receiver,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'packs.topps':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'packs.topps',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        memo: memo,
                                        quantity: `1 ${name}`,
                                        to: receiver,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
                case 'pack.worlds':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'pack.worlds',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        memo: memo,
                                        quantity: `1 ${name}`,
                                        to: receiver,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
            }
            callBack({ transferred: true, receiver: receiver })
        } catch (e) {
            callBack(false, e, assetId)
            console.log(e)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const cancel = () => {
        callBack({ transferred: false, receiver: null, offer: 0 })
        closeCallBack()
    }

    const changeReceiver = (e) => {
        const val = e.target.value
        setReceiver(val.trim().toLowerCase())
    }

    const changeMemo = (e) => {
        const val = e.target.value
        setMemo(val)
    }

    return (
        <Popup title={name} cancel={cancel} image={image}>
            <div className="mx-auto text-xl font-bold mb-4">
                {t('popups.are_you_sure_you_want_to_transfer_asset_to', {
                    name: name,
                    account: receiver,
                })}
            </div>
            <div className="PopupDetails">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <b>{t('asset.schema')}:</b>
                            </td>
                            <td>{schema}</td>
                        </tr>
                        {number && variant ? (
                            <tr>
                                <td>
                                    <b>{t('asset.card')}:</b>
                                </td>
                                <td>
                                    {number}
                                    {variant}
                                </td>
                            </tr>
                        ) : (
                            ''
                        )}
                        {rarity ? (
                            <tr>
                                <td>
                                    <b>{t('asset.rarity')}:</b>
                                </td>
                                <td>{rarity}</td>
                            </tr>
                        ) : (
                            ''
                        )}
                        <tr>
                            <td>
                                <b>{t('asset.author')}:</b>
                            </td>
                            <td>{author}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {error ? <ErrorMessage error={error} /> : ''}
            <div className="Buttons">
                <div className="EditContainer">
                    <input
                        className={'SellInput Memo'}
                        type="text"
                        onChange={changeReceiver}
                        value={receiver ? receiver : ''}
                        placeholder={t('asset.receiver')}
                    />
                </div>
                <div className="EditContainer">
                    <input
                        className={'SellInput Memo'}
                        type="text"
                        onChange={changeMemo}
                        value={memo ? memo : ''}
                        placeholder={t('asset.memo')}
                    />
                </div>
                <div className={'h-auto'}>
                    <MainButton className="PopupCancelButton" onClick={cancel}>
                        {t('asset.cancel')}
                    </MainButton>
                    <MainButton
                        className="PopupSellButton"
                        disabled={!receiver}
                        onClick={transfer}
                    >
                        {t('asset.transfer')}
                    </MainButton>
                </div>
            </div>

            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default TransferPopup
