import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import { get } from '../helpers/Api'
import { getBoostAction } from '../helpers/WaxApi'
import SelectionField from '../searchitems/searchfilters/SelectionField'
import Popup from './Popup'

function TagsPopup(props) {
    const closeCallBack = props.closeCallBack
    const collectionName = props.collectionName
    const ual = props.ual
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null
    const { t } = useTranslation('common')
    const callBack = props.callBack
    const appliedTags = props.tags

    const [selectedTags, setSelectedTags] = useState([])

    const [tags, setTags] = useState([])

    const suggest = props.suggest

    const cancel = () => {
        closeCallBack()
        callBack({ addedTags: false, error: null })
    }

    const selectTag = (tag) => {
        const newTags = []
        if (selectedTags.includes(tag.value)) {
            for (let selectedTag of selectedTags) {
                if (selectedTag !== tag.value) {
                    newTags.push(selectedTag)
                }
            }
        } else {
            for (let selectedTag of selectedTags) {
                newTags.push(selectedTag)
            }
            newTags.push(tag.value)
        }
        setSelectedTags(newTags)
    }

    const parseTags = (res) => {
        setTags(res)
    }

    const getMissingTags = async (collectionName) => {
        get('missing-tags/' + collectionName).then(parseTags)
    }

    useEffect(() => {
        getMissingTags(collectionName)
    }, [collectionName])

    let dropDownOptions = []
    tags.sort((a, b) => a.tagId - b.tagId).map((tag) => {
        dropDownOptions.push({
            value: tag.tagId,
            label:
                t(`collection_tags.${tag.tagId}`) !==
                `collection_tags.${tag.tagId}`
                    ? t(`collection_tags.${tag.tagId}`)
                    : tag.tagName[0].toUpperCase() + tag.tagName.substring(1),
        })
    })

    const save = async () => {
        const actions = [getBoostAction(activeUser)]
        closeCallBack()

        if (suggest) {
            for (let tagId of selectedTags) {
                actions.push({
                    account: 'clltncattool',
                    name: 'suggesttag',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser['requestPermission'],
                        },
                    ],
                    data: {
                        collection_name: collectionName,
                        tag_id: tagId,
                    },
                })
            }
        } else {
            for (let tagId of selectedTags) {
                actions.push({
                    account: 'clltncattool',
                    name: 'settagcol',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser['requestPermission'],
                        },
                    ],
                    data: {
                        collection_name: collectionName,
                        tag_id: tagId,
                    },
                })
            }
        }

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            const addedTags = []

            for (let selectedTag of selectedTags) {
                for (let option of dropDownOptions) {
                    if (option.value === selectedTag) {
                        addedTags.push({
                            tagId: option.value,
                            tagName: option.label,
                        })
                    }
                }
            }

            callBack({
                addedTags: addedTags,
                error: null,
            })
        } catch (e) {
            callBack({ addedTags: false, error: e.message })
            console.log(e)
        }
    }

    return (
        <Popup
            cancel={cancel}
            title={
                suggest
                    ? `Suggest a Tag for the ${collectionName} collection`
                    : `Select your desired category for the ${collectionName} collection`
            }
            className="h-auto"
        >
            <div className={cn('block h-full')}>
                <div className={'w-full md:w-2/3 mx-auto'}>
                    <SelectionField
                        multiSelect
                        center={true}
                        options={dropDownOptions}
                        onChange={selectTag}
                        values={selectedTags}
                        lockedValues={
                            appliedTags && appliedTags.map((tag) => tag.tagId)
                        }
                        placeholder={t('search.schema')}
                        id="DropdownField3"
                    />
                </div>
                <div className={'flex justify-center mt-40'}>
                    <MainButton
                        className={cn(
                            'text-white rounded border border-white outline-none text-sm align-middle font-normal',
                            'bg-transparent cursor-pointer hover:font-bold mr-10',
                        )}
                        onClick={cancel}
                    >
                        {t('asset.cancel')}
                    </MainButton>
                    <MainButton
                        onClick={save}
                        disabled={selectedTags.length === 0 ? 'disabled' : ''}
                        className={'ml-10'}
                    >
                        Save
                    </MainButton>
                </div>
            </div>
        </Popup>
    )
}

export default TagsPopup
