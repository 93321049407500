import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function DropWhitelistPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const dropId = props['dropId']
    const dropUserWhitelist = props['dropUserWhitelist']
        ? props['dropUserWhitelist']
        : []

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const actions = []

        const data = {
            authorized_account: userName,
            drop_id: parseInt(dropId),
            accounts_to_add: dropUserWhitelist,
        }

        const action = {
            account: 'nfthivedrops',
            name: 'addtowl',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: data,
        }

        actions.push(action)

        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result.transactionId
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Add accounts to Drop Whitelist" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to add the following accounts to the whitelist?
            </div>
            <div className="PopupBody">
                <div className="PopupDetails">
                    <div className="flex justify-center mb-5">
                        Drop ID: {dropId}
                    </div>
                    <div className="flex flex-wrap justify-center mb-5">
                        {dropUserWhitelist.map((account) => (
                            <div className="mr-2">
                                <a
                                    className="text-primary cursor-pointer"
                                    target="_blank"
                                    href={`/user/${account}`}
                                >
                                    {account}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={create}>
                        Add to Whitelist
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default DropWhitelistPopup
