import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function AuthorizeNfthivedropsPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']

    const [error, setError] = useState(null)

    const edit = async () => {
        setIsLoading(true)

        const actions = []

        actions.push({
            account: 'atomicassets',
            name: 'addcolauth',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: {
                collection_name: collectionName,
                account_to_add: 'nfthivedrops',
            },
        })

        let transferError = null
        let transactionId = null
        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result.transactionId
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Add authorization for nfthivedrops" cancel={cancel}>
            <div className="mx-auto text-xl font-bold mb-4">
                Do You want to grant nfthivedrops permission to create drops
                from the collection {collectionName}?
            </div>
            <div className="PopupBody">
                <div className="PopupDetails"></div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="Buttons">
                <MainButton className="PopupCancelButton" onClick={cancel}>
                    Cancel
                </MainButton>
                <MainButton className="PopupEditButton" onClick={edit}>
                    Grant Permission
                </MainButton>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default AuthorizeNfthivedropsPopup
