import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    dropDetailsEditor,
    editUnpackAnimation,
} from '../editor/DropCreatorUtils'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function EditPackDescriptionAnimationPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const packId = props['packId']

    const [editorOpen, setEditorOpen] = useState(false)
    const [useUnpackAnimation, setUseUnpackAnimation] = useState(
        props['packUnpackAnimation'] && props['packUnpackAnimation'].length > 0,
    )

    const [packName, setPackName] = useState(props['packName'])
    const [packDescription, setPackDescription] = useState(
        props['packDescription'],
    )
    const [unpackAnimation, setUnpackAnimation] = useState(
        props['packUnpackAnimation'],
    )

    const [error, setError] = useState(null)

    const update = async () => {
        setIsLoading(true)

        const updatePackDisplayDataActions = [getBoostAction(activeUser)]

        const updatePackDisplayDataData = {
            authorized_account: userName,
            collection_name: collectionName,
            pack_id: packId,
            display_data: JSON.stringify({
                name: packName,
                description: packDescription,
                animation: {
                    drawing: {
                        type: 'video',
                        data: {
                            video: useUnpackAnimation ? unpackAnimation : '',
                        },
                        bg_color: '#000000',
                    },
                },
            }),
        }

        const updatePackDisplayDataAction = {
            account: 'nfthivepacks',
            name: 'setdisplay',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: updatePackDisplayDataData,
        }

        updatePackDisplayDataActions.push(updatePackDisplayDataAction)

        let updatePackDisplayDataError = null
        let updatePackDisplayDataTransactionId = null

        try {
            const updatePackDisplayDataResult =
                await activeUser.signTransaction(
                    {
                        actions: updatePackDisplayDataActions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )

            updatePackDisplayDataTransactionId =
                updatePackDisplayDataResult.transactionId
        } catch (e) {
            updatePackDisplayDataError = e.message
            setError(e.message)
        }

        if (!updatePackDisplayDataError) {
            closeCallBack()
            setIsLoading(false)
            callBack(
                !updatePackDisplayDataError,
                updatePackDisplayDataError
                    ? [updatePackDisplayDataError]
                    : null,
                updatePackDisplayDataTransactionId,
            )
        }

        if (updatePackDisplayDataError) {
            setIsLoading(false)
            callBack(
                false,
                [updatePackDisplayDataError],
                updatePackDisplayDataTransactionId,
            )
        }
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup
            title="Update Pack Name / Description / Unpack Animation"
            cancel={cancel}
        >
            <div className="text-xl font-bold mb-4">
                Do you want to update the data of pack #{packId} of the{' '}
                {collectionName} collection?
            </div>
            <div className="PopupBody">
                <div>
                    {dropDetailsEditor(
                        packName,
                        setPackName,
                        editorOpen,
                        setEditorOpen,
                        packDescription,
                        setPackDescription,
                        true,
                    )}
                    {editUnpackAnimation(
                        useUnpackAnimation,
                        setUseUnpackAnimation,
                        unpackAnimation,
                        setUnpackAnimation,
                    )}
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={update}>
                        Update Pack
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default EditPackDescriptionAnimationPopup
