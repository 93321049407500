let ignoreOnError = 0

export const shouldIgnoreOnError = () => ignoreOnError > 0

export const ignoreNextOnError = () => {
    // onerror should trigger before setTimeout
    ignoreOnError += 1
    setTimeout(() => {
        ignoreOnError -= 1
    })
}
