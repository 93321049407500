import cn from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import config from '../../config.json'
import LazyLoad from '../helpers/LazyLoad'

export const isVideoString = (video) => !!video && video.includes('video:')

export const videoFrom = (maybeVideo) =>
    isVideoString(maybeVideo) ? maybeVideo.replace('video:', '') : null

export const toIPFSVideo = (video) =>
    video.includes('http') ? video : config.ipfs + video

export const VideoElement = ({
    video: videoStr,
    backimg,
    className,
    image,
    timeout = 2000,
    autoPlay = true,
    ...props
}) => {
    const video = toIPFSVideo(videoStr)
    const videoRef = useRef(null)
    const [state, setState] = useState({
        playing: false,
        initial: true,
    })

    useEffect(() => {
        if (state.initial && state.playing && timeout > 0 && video) {
            const timeoutId = setTimeout(pauseVideo, timeout)
            return () => clearTimeout(timeoutId)
        }
    }, [state.playing, state.initial, timeout, video])

    const playVideo = useCallback(() => {
        const element = videoRef.current
        setState({ playing: true, initial: false })
        if (!element) return
        element.play()
    }, [])

    const pauseVideo = useCallback(() => {
        const element = videoRef.current
        if (!element) return
        element.pause()
    }, [])

    const startPlaying = () => {
        setState({ playing: true, initial: state.initial })
    }

    return (
        <div className={cn('flex h-full content-center')}>
            <LazyLoad>
                <video
                    loop
                    autoPlay
                    muted
                    playsInline
                    {...props}
                    onMouseLeave={pauseVideo}
                    onMouseEnter={playVideo}
                    onPlay={startPlaying}
                    ref={videoRef}
                    className={cn('m-auto h-auto', className)}
                    poster={
                        image && !image.includes('video:') ? image : backimg
                    }
                    src={video}
                />
            </LazyLoad>
        </div>
    )
}

export default VideoElement
