import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import { MintInfo } from '../mintinfo/MintInfo'
import CollectionHeaderPopups from '../popups/CollectionHeaderPopups'
import VideoElement from '../videoelement/VideoElement'

function ResultCard({ index, asset, resultType }) {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), index * 1000)
    }, [])

    let { name, rarity, mint } = asset

    let video =
        asset['video'] && !asset['video'].includes('http')
            ? 'https://ipfs.hivebp.io/video?hash=' +
              asset['video'].replace('video:', '')
            : asset['video']
    let image =
        asset['image'] && !asset['image'].includes('http')
            ? 'https://ipfs.hivebp.io/thumbnail?hash=' + asset['image']
            : asset['image']

    if (!video && image && image.includes('video:')) {
        image = null
        video =
            'https://ipfs.hivebp.io/video?hash=' +
            asset['image'].replace('video:', '')
    }

    if (!image && !video && asset['idata']) {
        const data = JSON.parse(asset['idata'])
        video =
            data['video'] && !data['video'].includes('http')
                ? 'https://ipfs.hivebp.io/video?hash=' +
                  data['video'].replace('video:', '')
                : data['video']
        image =
            data['img'] && !data['img'].includes('http')
                ? 'https://ipfs.hivebp.io/thumbnail?hash=' + data['img']
                : data['img']
        rarity = data['rarity'] ? data['rarity'] : rarity
    }

    if (!name && asset['idata']) {
        const data = JSON.parse(asset['idata'])
        name = data['name'] ? data['name'] : null
    }

    return (
        <div
            className={cn(
                'relative w-full mx-auto rounded-md overflow-hidden',
                'flex flex-col text-base break-words backdrop-filter backdrop-blur-sm border border-paper',
                'shadow-xl bg-paper select-none',
            )}
            id={'ResultCard_' + index}
        >
            <div className={cn('flex justify-between my-4 mx-auto px-2')}>
                <div
                    className={cn(
                        'relative flex items-center leading-4',
                        'text-white leading-relaxed text-sm top-4',
                    )}
                >
                    <CollectionHeaderPopups asset={asset} />
                </div>
            </div>
            <div className="flex h-full m-auto">
                <div className="relative flex justify-center">
                    {loading ? (
                        <LoadingIndicator />
                    ) : video ? (
                        <VideoElement
                            className={''}
                            image={image}
                            backimg={image}
                            video={video}
                            autoPlay={true}
                            timeout={5}
                        />
                    ) : image ? (
                        <img
                            className="m-auto max-h-144"
                            src={image}
                            alt="none"
                        />
                    ) : (
                        ''
                    )}

                    {mint && <MintInfo top={'bottom-0 left-1'} mint={mint} />}
                </div>
            </div>
            <div className="relative">
                <p
                    className={cn(
                        'w-full pt-4 px-2 mb-5',
                        'text-center text-base font-light text-neutral',
                        'overflow-visible',
                    )}
                >
                    {name}
                    <br />
                    {rarity ? `(${rarity})` : ''}
                </p>
            </div>
        </div>
    )
}

export default ResultCard
