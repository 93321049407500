import React, { useMemo, useRef, useState } from 'react'
import { useIntersection } from '../hooks/intersection'

export const withLazy =
    (Component, Fallback = () => null) =>
    (props) => {
        const [visible, setVisibility] = useState(false)
        const element = useRef(null)
        useIntersection(element, () => setVisibility(true))

        return useMemo(
            () =>
                visible ? (
                    <Component {...props} />
                ) : (
                    <div ref={element}>
                        <Fallback />
                    </div>
                ),
            [visible, props],
        )
    }
