import React from 'react'
import Link from '../common/util/input/Link'

export const SideNavigationHeaderWrap = ({ onClick, children, href }) => (
    <Link inactive={!href} href={href} forcePointer>
        <div
            className="relative flex align-center w-full py-1 lg:py-2 xl:py-3 px-3 leading-relaxed"
            onClick={onClick}
        >
            {children}
        </div>
    </Link>
)

export default SideNavigationHeaderWrap
