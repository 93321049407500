import { ClickAwayListener } from '@mui/material'
import cn from 'classnames'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useUAL } from '../../hooks/ual'
import Link from '../common/util/input/Link'
import {
    stakeAtomicAssets,
    stakeSimpleAssets,
    swapTransaction,
} from '../helpers/WaxApi'
import ShareButton from '../sharebutton/ShareButton'
import { Context } from '../waxplorer'

const moreOptionsBtnClassName = cn(
    'flex justify-start w-24 transition delay-200 width',
    'h-4 rounded text-white cursor-pointer outline-none',
    'text-xs font-bold my-2',
)

const MoreOptionsButton = ({
    onClick,
    children,
    imgSrc,
    imgAlt = typeof children === 'string' ? children : '',
}) => (
    <div className={moreOptionsBtnClassName} onClick={onClick}>
        <div className="w-4 h-4 mx-2">
            <img className="w-4 h-4" src={imgSrc} alt={imgAlt} />
        </div>
        <div>{children}</div>
    </div>
)

const MoreOptionsAnchor = ({ href, children, imgSrc, imgAlt }) => (
    <Link href={href} rel="noreferrer" external={href.includes('http')}>
        <MoreOptionsButton
            imgSrc={imgSrc}
            imgAlt={imgAlt}
            children={children}
        />
    </Link>
)

export const MoreOptions = ({
    asset,
    burned,
    created,
    handleBurn,
    handleCreateOffer,
    handleSell,
    handleStake,
    handleSwap,
    handleTransfer,
    newOwner,
    rplanet,
    setError,
    setIsLoading,
    setShowMenu,
    setSold,
    showMenu,
    sold,
    staked,
    transferred,
    toggleShowMenu,
}) => {
    const { t } = useTranslation('common')
    const { activeUser } = useUAL()

    const userName = activeUser ? activeUser['accountName'] : null

    const [, dispatch] = useContext(Context)

    if (newOwner) asset['owner'] = newOwner

    const {
        sender,
        standard,
        orderId,
        assetId,
        offer,
        author,
        unpackUrl,
        schema,
        templateId,
        burnable,
        transferable,
    } = asset

    const auction = async () => {
        setIsLoading(true)
        setShowMenu(false)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (sellInfo) => handleSell(sellInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'auction' })
    }

    const createoffer = async () => {
        setIsLoading(true)
        setShowMenu(false)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (offerInfo) => handleCreateOffer(offerInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'createoffer' })
    }

    const burnAsset = async () => {
        setIsLoading(true)
        setShowMenu(false)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (burnInfo) => handleBurn(burnInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'burn_asset' })
    }

    const transfer = async () => {
        setIsLoading(true)
        setShowMenu(false)
        dispatch({ type: 'SET_ASSET', payload: asset })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (sellInfo) => handleTransfer(sellInfo),
        })
        dispatch({ type: 'SET_ACTION', payload: 'transfer' })
    }

    const stake = async () => {
        let { assetId, aAssetId, standard } = asset
        setShowMenu(false)

        setError(null)
        setIsLoading(true)

        let result
        try {
            switch (standard) {
                case 'simpleassets':
                    result = stakeSimpleAssets(activeUser, [assetId])
                    break

                case 'atomicassets':
                    result = stakeAtomicAssets(activeUser, [aAssetId])
                    break
            }
            handleStake(true)
        } catch (e) {
            if (!(e instanceof Error)) return console.error('Invalid error', e)
            console.log(e)
            setSold(false)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const doSwap = async () => {
        const { assetId, aAssetId, standard } = asset
        setShowMenu(false)
        setError(null)
        setIsLoading(true)

        const data = {
            from: userName,
            to: 'atomicbridge',
            memo: 'swap',
        }

        if (standard === 'atomicassets') data['asset_ids'] = [aAssetId]
        else data['assetids'] = [assetId]

        try {
            await swapTransaction(activeUser, standard, data)
            handleSwap(true)
        } catch (e) {
            if (!(e instanceof Error)) return console.error('Invalid error', e)
            console.log(e)
            handleSwap(false)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const tradable =
        asset.owner !== userName &&
        standard === 'atomicassets' &&
        !staked &&
        !transferred &&
        !sold &&
        !burned &&
        sender !== userName &&
        assetId

    const stakable =
        !staked &&
        !burned &&
        ((rplanet &&
            rplanet.includes(author) &&
            schema !== 'packs' &&
            schema !== 'blocks') ||
            (author === 'ilovekolobok' && schema === 'prize') ||
            (author === 'gpk.topps' && standard === 'simpleassets') ||
            (author === 'a.rplanet' && schema === 'packs')) &&
        ['atomicassets', 'simpleassets'].includes(standard) &&
        asset.owner === userName

    const auctionable =
        standard === 'atomicassets' &&
        asset.owner === userName &&
        !sold &&
        assetId &&
        !burned

    const unpackable =
        unpackUrl && asset.owner === userName && assetId && !burned

    const buyable =
        handleCreateOffer &&
        !created &&
        userName &&
        templateId &&
        templateId > 0 &&
        !burned

    const isburnable =
        burnable &&
        !created &&
        !transferred &&
        userName === asset.owner &&
        !staked

    const swappable =
        author === 'gpk.topps' &&
        ['series1', 'series2', 'exotic'].includes(schema) &&
        ['simpleassets', 'atomicassetes'].includes(standard) &&
        asset.owner === userName &&
        !burned

    const isTransferable =
        !sold &&
        !transferred &&
        asset.owner === userName &&
        assetId &&
        !burned &&
        (transferable || transferable === undefined)

    return (
        <ClickAwayListener onClickAway={() => setShowMenu(false)}>
            <div
                className={cn(
                    'h-auto absolute right-0 p-2 z-20 w-40 right-0 top-0 flex-wrap',
                )}
            >
                <div
                    className={cn(
                        'h-auto absolute right-0 p-2 z-20 w-40 right-0 top-0 flex-wrap',
                        'bg-gradient-to-bl from-blue to-brown rounded-2xl',
                        { 'opacity-100 z-10 transition delay-200': showMenu },
                        {
                            'opacity-0 z-10 hidden transition delay-200':
                                !showMenu,
                        },
                    )}
                >
                    {tradable ? (
                        <MoreOptionsAnchor
                            href={`/trade?user=${userName}&partner=${sender}&asset_id=${assetId}&tab=new-trade`}
                            imgSrc="/trade.svg"
                            imgAlt="trade"
                        >
                            {t('trade.send_offer')}
                        </MoreOptionsAnchor>
                    ) : null}
                    <ShareButton
                        type={'asset'}
                        link={
                            'https://nfthive.io' +
                            (assetId
                                ? (offer ? '/listing/' : '/asset/') +
                                  assetId +
                                  (orderId ? `?sale_id=${orderId}` : '')
                                : '/template/' + templateId)
                        }
                    />
                    {isTransferable ? (
                        <MoreOptionsButton
                            onClick={transfer}
                            imgSrc="/diagonal-arrow-right-up-outline.svg"
                            imgAlt={t('asset.transfer')}
                        >
                            {t('asset.transfer')}
                        </MoreOptionsButton>
                    ) : null}
                    {stakable ? (
                        <MoreOptionsButton
                            onClick={stake}
                            imgSrc="/aether_icon.png"
                            imgAlt={t('asset.stake_to_rplanetio')}
                        >
                            {t('asset.stake')}
                        </MoreOptionsButton>
                    ) : null}
                    {swappable ? (
                        <MoreOptionsButton
                            onClick={doSwap}
                            imgSrc={
                                standard === 'atomicassets'
                                    ? '/simpleassets.svg'
                                    : '/atomic.png'
                            }
                            imgAlt={t('profile.swap')}
                        >
                            {t('profile.swap')}
                        </MoreOptionsButton>
                    ) : null}
                    {auctionable ? (
                        <MoreOptionsButton
                            onClick={auction}
                            imgSrc="/pricetags-outline.svg"
                            imgAlt={t('popups.auction')}
                        >
                            {t('popups.auction')}
                        </MoreOptionsButton>
                    ) : null}
                    {unpackable ? (
                        <MoreOptionsAnchor
                            href={unpackUrl}
                            imgSrc={'/cube-outline.svg'}
                            imgAlt={t('asset.unpack')}
                            onClick={() => setShowMenu(false)}
                        >
                            {t('asset.unpack')}
                        </MoreOptionsAnchor>
                    ) : null}
                    {buyable ? (
                        <MoreOptionsButton
                            imgSrc="/pricetags-outline.svg"
                            onClick={createoffer}
                            imgAlt={t('Create Buy Offer')}
                        >
                            Buy Offer
                        </MoreOptionsButton>
                    ) : null}
                    {isburnable ? (
                        <MoreOptionsButton
                            imgSrc="/fire.png"
                            onClick={burnAsset}
                            imgAlt={t('asset.burn')}
                        >
                            {t('asset.burn')}
                        </MoreOptionsButton>
                    ) : null}
                </div>
                <div
                    onClick={toggleShowMenu}
                    className={cn(
                        'absolute text-sm w-8 h-8 right-0 top-0 text-white m-auto z-30',
                    )}
                >
                    <img
                        className={cn(
                            'absolute w-6 h-6 right-4 top-3',
                            {
                                'transition transform duration-250 rotate-90':
                                    showMenu,
                            },
                            {
                                'transition transform duration-250 rotate-0':
                                    !showMenu,
                            },
                        )}
                        src="/more.svg"
                        alt=""
                    />
                </div>
            </div>
        </ClickAwayListener>
    )
}

export default MoreOptions
