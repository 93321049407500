import { Tooltip } from '@mui/material'
import cn from 'classnames'
import React from 'react'

export const MintInfo = ({ mint, small = false, top = 'top-0' }) => {
    return (
        <div
            className={cn(
                //'absolute flex top-0 m-auto h-8 rounded-3xl bg-dark b-1',
                'absolute flex h-8 z-10',
                { 'left-0': small },
                top,
            )}
        >
            <Tooltip title={'Mint: #' + mint}>
                <div
                    className={cn(
                        'h-auto my-auto p-mint text-white bg-mint rounded-full',
                        'text-xs md:text-2xs lg:text-1xs xl:text-xs 4xl:text-base min-w-8 mx-3',
                    )}
                >
                    #{mint}
                </div>
            </Tooltip>
        </div>
    )
}
export default MintInfo
