import { Button, Collapse, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { floatRegEx } from '../editor/EditorComponents'
import { post } from '../helpers/Api'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function BuyDropRamPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const ramBalance = props['dropRamBalance']
    const [waxRam, setWaxRam] = useState(0)
    const [ramRate, setRamRate] = useState({ base: 1, quote: 1 })
    const [ramBytes, setRamBytes] = useState(0)

    const [error, setError] = useState(null)

    const getRamRate = async () => {
        const body = {
            code: 'eosio',
            index_position: 1,
            json: 'true',
            key_type: 'i64',
            limit: 1,
            lower_bound: '',
            upper_bound: '',
            reverse: 'true',
            scope: 'eosio',
            show_payer: 'false',
            table: 'rammarket',
            table_key: '',
        }

        const url =
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.testapi + '/v1/chain/get_table_rows'
                : config.api + '/v1/chain/get_table_rows'
        const res = await post(url, body)

        if (res && res.status === 200 && res.data && res.data.rows) {
            const market = res.data.rows[0]
            const base = market['base']
            const quote = market['quote']

            setRamRate({
                base: parseFloat(base['balance'].replace(' RAM', '')),
                quote: parseFloat(quote['balance'].replace(' WAX', '')),
            })

            setRamBytes(
                ramBalance +
                    (waxRam > 0
                        ? parseInt(
                              (waxRam * (ramRate.base / ramRate.quote)) / 1.01,
                          )
                        : 0),
            )
        }
    }

    const handleWaxRamUpdate = (waxRamInput) => {
        setWaxRam(waxRamInput)
        setRamBytes(
            ramBalance +
                (waxRamInput > 0
                    ? parseInt(
                          (waxRamInput * (ramRate.base / ramRate.quote)) / 1.01,
                      )
                    : 0),
        )
    }

    const [isLoadingRamRate, setIsLoadingRamRate] = useState(true)
    useEffect(() => {
        getRamRate().then(setIsLoadingRamRate(false))
    }, [])

    const buyRam = async () => {
        setIsLoading(true)

        const buyRamActions = [getBoostAction(activeUser)]

        if (waxRam && parseFloat(waxRam) > 0) {
            buyRamActions.push({
                account: 'eosio.token',
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: {
                    from: userName,
                    to: 'nfthivedrops',
                    quantity: `${parseFloat(waxRam).toFixed(8)} WAX`,
                    memo: 'deposit_collection_ram:' + collectionName,
                },
            })
        }

        let buyRamError = null
        let buyRamTransactionId = null

        try {
            const buyRamResult = await activeUser.signTransaction(
                {
                    actions: buyRamActions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            buyRamTransactionId = buyRamResult.transactionId
        } catch (e) {
            buyRamError = e.message
            setError(e.message)
        }

        if (!buyRamError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !buyRamError,
            buyRamError ? [buyRamError] : null,
            buyRamTransactionId,
        )

        return
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Buy RAM for Mint on Demand Drops" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                {`How much RAM do you want to buy for the collection ${collectionName}?`}
            </div>
            <div className="PopupBody">
                <div className="PopupDetails">
                    {isLoadingRamRate ? (
                        <LoadingIndicator />
                    ) : (
                        <div>
                            <div>Current RAM Balance: {ramBalance}</div>
                            <div className="my-5">
                                <TextField
                                    label="RAM to Buy in WAX"
                                    value={waxRam}
                                    required={true}
                                    error={
                                        !floatRegEx.test(waxRam) || waxRam <= 0
                                    }
                                    onChange={(e) => {
                                        handleWaxRamUpdate(e.target.value)
                                    }}
                                    helperText={
                                        !floatRegEx.test(waxRam) || waxRam <= 0
                                            ? 'Amount must be >0'
                                            : ''
                                    }
                                />
                            </div>
                            <Collapse
                                in={floatRegEx.test(waxRam) && waxRam > 0}
                            >
                                <div>
                                    {`${waxRam} WAX will get you ≈ ${ramBytes} Bytes, which
                                allows for approx. ${parseInt(
                                    Math.floor(ramBytes / 151),
                                )}
                                  claims / mints`}
                                </div>
                            </Collapse>
                        </div>
                    )}
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button
                        variant="outlined"
                        onClick={buyRam}
                        disabled={!(waxRam && parseFloat(waxRam) > 0)}
                    >
                        Buy RAM
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default BuyDropRamPopup
