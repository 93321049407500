export const apiTimeStringRegExp =
    /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})(?: GMT([\+-]?\d{1,2}(?::\d{1,2})?))?$/

export const isApiTimeString = (value) =>
    typeof value === 'string' && apiTimeStringRegExp.test(value)

export const apiTimeStringToDate = (value) => {
    const [, year, month, day, hour, minute, second, timezone = '0'] =
        value.match(apiTimeStringRegExp)
    const [hourOffset, minuteOffset = '0'] = timezone.split(':')
    const date = new Date()
    date.setUTCFullYear(parseInt(year, 10))
    date.setUTCMonth(parseInt(month, 10) - 1)
    date.setUTCDate(parseInt(day, 10))
    date.setUTCHours(parseInt(hour, 10) - parseInt(hourOffset, 10))
    date.setUTCMinutes(parseInt(minute, 10) - parseInt(minuteOffset, 10))
    date.setUTCSeconds(parseInt(second, 10))
    date.setUTCMilliseconds(0)
    return date
}
