import cn from 'classnames'
import React from 'react'
import Link from '../common/util/input/Link'

export const CollectionHeaderPopups = ({ asset }) => {
    if (!asset) return <div />

    let { authorImg, authorThumbnail, verified, author } = asset

    if (!authorThumbnail)
        authorThumbnail = authorImg
            ? authorImg
            : 'https://ipfs.hivebp.io/media/author/' + author

    const displayName = asset.author

    return (
        <Link href={`/collection/${displayName}`}>
            <div
                className={cn(
                    'h-10 md:h-8 xl:h-10 4xl:h-12 -top-5 md:-top-4 2xl:-top-5 4xl:-top-4',
                    'relative flex justify-center text-white cursor-pointer ',
                )}
            >
                <div
                    className={cn(
                        'flex w-auto border rounded-full bg-page',
                        { 'border-primaryt': verified },
                        { 'border-neutralt': !verified },
                    )}
                >
                    {authorThumbnail && (
                        <div
                            className={cn(
                                'flex w-9 mt-a-pix ml-a-pix',
                                'md:w-7 xl:w-9 4xl:w-11 h-9 md:h-7 xl:h-9 4xl:h-11',
                                'rounded-full overflow-hidden',
                            )}
                        >
                            <img
                                className={cn('m-auto')}
                                src={authorThumbnail}
                                alt=""
                            />
                        </div>
                    )}
                    <div
                        className={cn(
                            'text-center mx-2 my-auto bottom-2 h-full',
                        )}
                    >
                        <p
                            className={cn(
                                'mb-auto h-full leading-9 text-base md:leading-7 md:text-xs lg:text-base',
                                'xl:text-base xl:leading-9 4xl:leading-12',
                            )}
                        >
                            {displayName && displayName.length > 20
                                ? displayName.substring(0, 20) + '...'
                                : displayName}
                        </p>
                    </div>
                    {verified && (
                        <div
                            className={cn(
                                'w-7 md:w-5 xl:w-7 h-6 md:h-5 xl:h-6 4xl:h-7 4xl:w-8 my-auto',
                            )}
                        >
                            <img src={'/verified.svg'} alt="" />
                        </div>
                    )}
                </div>
            </div>
        </Link>
    )
}

export default CollectionHeaderPopups
