import cn from 'classnames'
import React from 'react'

export const SearchBar = ({
    onSubmit,
    placeHolder,
    onClickSearch,
    disabled,
    onChange,
    value,
}) => {
    return (
        <form
            className={cn(
                'mx-auto w-fit-content flex h-10 border my-auto',
                'bg-bluet rounded-3xl border-2 border-primaryt',
            )}
            onSubmit={onSubmit}
            target={'_blank'}
        >
            <input
                className={cn(
                    'w-24 md:focus:w-36 md:active:w-36 h-9 bg-transparent font-bold md:font-semibold',
                    'text-primary outline-none my-auto mx-3 transition-width',
                    {
                        'text-xs lg:text-sm xl:text-base':
                            !placeHolder || placeHolder.length < 20,
                    },
                    {
                        'text-2xs lg:text-1xs xl:text-xs':
                            placeHolder && placeHolder.length >= 20,
                    },
                )}
                name="term"
                placeholder={placeHolder}
                type="text"
                value={value}
                onChange={onChange}
            />
            <button
                onClick={onClickSearch}
                disabled={disabled}
                className={cn('w-6 h-7 my-auto mr-1 xl:mr-3')}
            >
                <img src="/search.svg" alt="Search" />
            </button>
        </form>
    )
}
