import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function UpdatePoolPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const poolId = props['poolId']
    const assetsToAddToPool = props['assetsToAddToPool']
    const assetsToRemoveFromPool = props['assetsToRemoveFromPool']

    const [error, setError] = useState(null)

    const update = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        if (assetsToAddToPool.length > 0) {
            const addAssetsData = {
                authorized_account: userName,
                collection_name: collectionName,
                pool_id: poolId,
                asset_ids: assetsToAddToPool,
            }

            const addAssetsAction = {
                account: 'nfthivepacks',
                name: 'addassets',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: addAssetsData,
            }

            actions.push(addAssetsAction)
        }

        if (assetsToRemoveFromPool.length > 0) {
            const removeAssetsData = {
                authorized_account: userName,
                collection_name: collectionName,
                pool_id: poolId,
                asset_ids: assetsToRemoveFromPool,
            }

            const removeAssetsAction = {
                account: 'nfthivepacks',
                name: 'remassets',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: removeAssetsData,
            }

            actions.push(removeAssetsAction)
        }

        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result.transactionId
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Create Pool" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to update Pool #{poolId} ({collectionName})?
            </div>
            <div className="PopupBody">
                {assetsToAddToPool.length > 0 && (
                    <>
                        <div className="text-lg">
                            {`${assetsToAddToPool.length} Assets to be added to the pool:`}
                        </div>
                        <div className="flex flex-wrap mt-2 mb-8">
                            {assetsToAddToPool.map((assetId) => (
                                <a href={`/asset/${assetId}`} target="_blank">
                                    <div className="text-sm m-1 text-primary cursor-pointer">
                                        {assetId}
                                    </div>
                                </a>
                            ))}
                        </div>
                    </>
                )}
                {assetsToRemoveFromPool.length > 0 && (
                    <>
                        <div className="text-lg">
                            {`${assetsToRemoveFromPool.length} Assets to be removed to the pool:`}
                        </div>
                        <div className="flex flex-wrap mt-2 mb-8">
                            {assetsToRemoveFromPool.map((assetId) => (
                                <a href={`/asset/${assetId}`} target="_blank">
                                    <div className="text-sm m-1 text-primary cursor-pointer">
                                        {assetId}
                                    </div>
                                </a>
                            ))}
                        </div>
                    </>
                )}
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={update}>
                        Update Pool
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default UpdatePoolPopup
