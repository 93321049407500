import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    useCreateBoostAction,
    useCreateBurnAction,
    useTransaction,
} from '../../hooks/waxActions'
import MainButton from '../common/util/input/MainButton'
import Popup from './Popup'

const BurnPopup = ({ asset, callBack, closeCallBack }) => {
    const { t } = useTranslation('common')

    const { name, image } = asset

    // this is needed to ensure the ual is available within the action creators
    // the boost action would not need this, but the burn action does.
    // The litte problem fixed here is when we want to burn multiple assets, then we'd have
    // to call useBurnAction(asset[i]) for each asset. This is not possible with
    // hooks. That is why we generate creator functions, than we'd be able to call
    // `const burnActions = assets.map(createBurnAction)` and we have a valid list of
    // actions to burn all assets.
    const createBoostAction = useCreateBoostAction()
    const createBurnAction = useCreateBurnAction()

    // setup a consistent list of actions to be performed
    const actions = useMemo(
        () => [createBoostAction(), createBurnAction(asset)],
        [asset],
    )

    // create a transaction callback that signs the actions
    const startBurn = useTransaction(actions, async (error, result) => {
        // now we convert the result into the callBack format
        // the result is in the form of a promise, when we want to wait we
        // could just `await result`.
        closeCallBack()

        callBack({
            burned: error === undefined,
            asset,
            tx: await result,
            error,
        })
    })

    const cancel = () => {
        callBack({
            burned: false,
            asset,
        })
        onClose()
    }

    return (
        <Popup
            title={t('popups.burn.title', { assetName: name })}
            image={image}
            cancel={cancel}
        >
            <div className="mx-auto text-xl font-bold mb-4">
                {t('popups.burn.question', { assetName: name })}
            </div>
            <div className="flex mx-auto text-xl font-bold mb-4 justify-center">
                <img
                    className={'h-5 mx-2'}
                    src={'/Warning_icn.svg'}
                    alt={'Warning'}
                />
                <div className={'text-sm text-primary'}>
                    {t('popups.burn.explanation', { assetName: name })}
                </div>
            </div>
            <div className="Buttons">
                <MainButton className="PopupCancelButton" onClick={cancel}>
                    {t('asset.cancel')}
                </MainButton>
                <MainButton className="PopupSellButton" onClick={startBurn}>
                    {t('asset.burn')}
                </MainButton>
            </div>
        </Popup>
    )
}

export default BurnPopup
