import { ClickAwayListener, Popper } from '@mui/material'
import cn from 'classnames'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { useStorage } from '../../hooks/storage'
import { useUAL } from '../../hooks/ual'
import Link from '../common/util/input/Link'
import { get, post } from '../helpers/Api'
import { formatNumber } from '../helpers/FormatLinks'
import Transitions from '../ui/extended/Transitions'
import { Picture } from '../user/UserPicture'
import { Context } from '../waxplorer'

export const UserChip = ({ userName }) => {
    const [imgCache, setImgCache] = useStorage(`image-${userName}`)
    const [balance, setBalance] = useState(null)
    const [showMenu, setShowMenu] = useState(false)
    const [userPicture, setUserPicture] = useState(imgCache)
    const ual = useUAL()

    const logout = ual?.logout

    const { t } = useTranslation('common')

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }

    const parseBalance = (res) => {
        if (
            res &&
            res.data &&
            res.data.rows &&
            res.data.rows.length > 0 &&
            res.data.rows[0].balance
        ) {
            setBalance(parseFloat(res.data.rows[0].balance.replace(' WAX', '')))
        }

        dispatch({ type: 'SET_BALANCE_CHANGED', payload: false })
    }

    const [state, dispatch] = useContext(Context)

    const balanceChanged = state.balanceChanged
    const userPictureChanged = state.userPictureChanged

    const getBalance = async () => {
        const body = {
            code: 'eosio.token',
            index_position: 'primary',
            json: 'true',
            key_type: 'i64',
            limit: 1,
            reverse: 'false',
            scope: userName,
            show_payer: 'false',
            table: 'accounts',
            table_key: '',
        }

        const url =
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.testapi + '/v1/chain/get_table_rows'
                : config.api + '/v1/chain/get_table_rows'
        post(url, body).then((res) => parseBalance(res))
    }

    const parseUserPicture = (res) => {
        if (res && res.image) {
            setUserPicture(res.image)
            setImgCache(res.image)
        }
        dispatch({ type: 'SET_USER_PICTURE_CHANGED', payload: false })
    }

    const getUserPicture = async (userPictureChanged) => {
        if (imgCache && !userPictureChanged) {
            setUserPicture(imgCache)
        } else if (userPictureChanged) {
            setTimeout(() => {
                get('user-picture/' + userName).then(parseUserPicture)
            }, 3000)
        } else {
            get('user-picture/' + userName).then(parseUserPicture)
        }
    }

    useEffect(() => {
        if (userName) {
            if (!balance || balanceChanged) getBalance()
            if (!userPicture || userPictureChanged)
                getUserPicture(userPictureChanged)
        }
    }, [balanceChanged, userPictureChanged, userName])

    const anchorRef = useRef(null)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setShowMenu(false)
    }

    return (
        <>
            <div
                className={cn(
                    'flex max-w-36 min-w-32 text-neutral my-auto rounded-full bg-paperl hover:bg-primaryt2',
                )}
                onClick={toggleMenu}
                ref={anchorRef}
            >
                <Picture
                    className="max-h-10 max-w-10 rounded-full p-1.5"
                    image={userPicture}
                />
                <div className={'m-auto'}>
                    <div className={cn('text-xs')}>{userName}</div>
                    <div className={cn('text-1xs opacity-60')}>
                        {formatNumber(balance || balance === 0 ? balance : 0.0)}{' '}
                        WAX
                    </div>
                </div>
            </div>

            <Popper
                placement="bottom"
                open={showMenu}
                role={undefined}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [15, 0],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={showMenu} {...TransitionProps}>
                            <div
                                className={cn(
                                    'absolute -right-12 top-0 right-0 overflow-y-auto z-index-100 bg-dark shadow p-2',
                                )}
                            >
                                <div className={'rounded p-2'}>
                                    <Link href={'/user/' + userName}>
                                        <div className={'flex cursor-pointer'}>
                                            <div
                                                className={cn(
                                                    'flex mr-3 h-5 w-5 my-auto text-secondary',
                                                )}
                                            >
                                                <img
                                                    src={'/person-outline.svg'}
                                                    alt={'inventory'}
                                                    className="block my-auto"
                                                />
                                            </div>
                                            <div
                                                className={cn(
                                                    'relative text-left text-base md:text-sm',
                                                    'text-neutral mr-auto leading-8 md:leading-6',
                                                )}
                                            >
                                                {t('navigation.account')}
                                            </div>
                                        </div>
                                    </Link>
                                    <Link
                                        href={
                                            '/user/' +
                                            userName +
                                            '?tab=inventory'
                                        }
                                    >
                                        <div className={'flex cursor-pointer'}>
                                            <div
                                                className={cn(
                                                    'flex mr-3 h-5 w-5 my-auto text-secondary',
                                                )}
                                            >
                                                <img
                                                    src={'/inventory.svg'}
                                                    alt={'inventory'}
                                                    className="block my-auto"
                                                />
                                            </div>
                                            <div
                                                className={cn(
                                                    'relative text-left text-base md:text-sm',
                                                    'text-neutral mr-auto leading-8 md:leading-6',
                                                )}
                                            >
                                                {t('profile.inventory')}
                                            </div>
                                        </div>
                                    </Link>
                                    <Link href={'/unpack'}>
                                        <div className={'flex cursor-pointer'}>
                                            <div
                                                className={cn(
                                                    'flex mr-3 h-5 w-5 my-auto text-secondary',
                                                )}
                                            >
                                                <img
                                                    src={'/gift.svg'}
                                                    alt={'unpack'}
                                                    className="block my-auto"
                                                />
                                            </div>
                                            <div
                                                className={cn(
                                                    'relative text-left text-base md:text-sm',
                                                    'text-neutral mr-auto leading-8 md:leading-6',
                                                )}
                                            >
                                                {t('asset.unpack')}/
                                                {t('asset.claim')}
                                            </div>
                                        </div>
                                    </Link>
                                    <div
                                        className={'flex cursor-pointer'}
                                        onClick={logout}
                                    >
                                        <div
                                            className={cn(
                                                'flex mr-3 h-5 w-5 my-auto',
                                                {
                                                    'text-secondary': true,
                                                },
                                            )}
                                        >
                                            <img
                                                src={'/log-out-outline.svg'}
                                                alt={'inventory'}
                                                className="block my-auto"
                                            />
                                        </div>
                                        <div
                                            className={cn(
                                                'relative text-left text-base md:text-sm',
                                                'text-neutral mr-auto leading-8 md:leading-6',
                                            )}
                                        >
                                            {t('profile.logout')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    )
}
