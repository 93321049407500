import qs from 'qs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from '../components/helpers/Api'
import {
    getAttributeNames,
    getDefaultAttrNames,
} from '../components/helpers/FormatLinks'

export const useGetter = (path, endpoint = 'api', init = {}) => {
    return (handler) => {
        const controller = new AbortController()
        get(path, endpoint, { ...init, signal: controller.signal }).then(
            handler,
        )
        return () => controller.abort()
    }
}

export const useGet = (path, endpoint) => {
    const [state, setState] = useState({
        data: undefined,
        loading: true,
        controller: undefined,
    })

    // need to use a ref here to prevent the fetch function to be re-build
    const stateRef = useRef(state)
    stateRef.current = state

    const request = useCallback(
        (force = false) => {
            // unpack the state here
            const state = stateRef.current

            if (!path)
                return setState(({ data }) => ({
                    data,
                    loading: false,
                    controller: undefined,
                }))
            // if there is a retry, abort the previous request when forced, else do nothing
            if (state.loading) {
                if (!force) return
                state.controller?.abort()
            }

            const controller = new AbortController()
            setState(({ data }) => ({ data, loading: true, controller }))

            get(path, endpoint, { signal: controller.signal }).then(
                (result) => {
                    setState({
                        data: result,
                        loading: false,
                        controller: undefined,
                    })
                },
            )
            return () => controller.abort()
        },
        [path],
    )

    useEffect(() => request(true), [request])

    return { data: state.data, loading: state.loading, fetch: request }
}

export const useFetch = (input, init) => {
    const [state, setState] = useState({
        data: undefined,
        loading: true,
        controller: undefined,
    })

    // need to use a ref here to prevent the fetch function to be re-build
    const stateRef = useRef(state)
    stateRef.current = state

    const request = useCallback(
        (force = false) => {
            // unpack the state here
            const state = stateRef.current
            if (!input)
                return setState(({ data }) => ({
                    data,
                    loading: false,
                    controller: undefined,
                }))
            // if there is a retry, abort the previous request when forced, else do nothing
            if (state.loading) {
                if (!force) return
                state.controller?.abort()
            }

            const controller = new AbortController()

            setState(({ data }) => ({ data, loading: true, controller }))

            fetch(input, { signal: controller.signal, ...init })
                .then(async (result) => {
                    setState({
                        data: await result.json(),
                        loading: false,
                        controller: undefined,
                    })
                })
                .catch((error) => {
                    console.error(error)
                    setState({
                        data: null,
                        loading: false,
                        controller: undefined,
                    })
                })
            return () => controller.abort()
        },
        [input],
    )

    useEffect(() => request(true), [request])

    return { data: state.data, loading: state.loading, fetch: request }
}

export const useAttributeNames = (
    author,
    schema,
    initialAttributeNames = getDefaultAttrNames(),
) => {
    const [attrNames, setAttrNames] = useState(initialAttributeNames)

    useEffect(() => {
        if (author) {
            const controller = new AbortController()
            getAttributeNames(author, schema, controller.signal).then(
                (result) => {
                    if (result && !('errors' in result)) setAttrNames(result)
                },
            )
            return () => controller.abort()
        }
    }, [author, schema])

    return [attrNames, setAttrNames]
}

export const useLanguage = (props = { lang: 'en' }, ns = 'common') => {
    const { t, i18n } = useTranslation(ns)

    const language = props['lang'] ? props['lang'] : 'en'

    if (typeof window === undefined && language) i18n.changeLanguage(language)

    return { t, language }
}

export const useWindow = () => (typeof window !== 'undefined' ? window : null)

/**
 * Returns the contents of the query string
 */
export const useQueryString = () => {
    const window = useWindow()

    let values = window
        ? qs.parse(
              window.location.search.substring(
                  1,
                  window.location.search.length,
              ),
          )
        : {}

    const getValue = (key, def = undefined, { expectArray = true } = {}) => {
        const value = values[key]
        if (!value) return def
        if (expectArray && !Array.isArray(value)) return [value]
        return Array.isArray(value) ? value[0] : value
    }

    return { values, getValue }
}
