import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function DropAuthProofPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const dropId = props['dropId']
    const dropAuthProofRulesJoin = props['dropAuthProofRulesJoin']
    const dropAuthProofRules = props['dropAuthProofRules']

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const actions = []

        const proof = []
        proof.push({ op: dropAuthProofRulesJoin.toUpperCase(), proof: [] })
        for (const rule of dropAuthProofRules) {
            const collectionSchemaTemplate = {}

            collectionSchemaTemplate['collection_name'] = rule.collection

            collectionSchemaTemplate['schema'] = rule.schema ? rule.schema : ''

            collectionSchemaTemplate['template_id'] = rule.templateId
                ? parseInt(rule.templateId)
                : 0

            proof[0].proof.push({
                num: parseInt(rule.minAmount),
                ...collectionSchemaTemplate,
            })
        }

        const data = {
            authorized_account: userName,
            drop_id: parseInt(dropId),
            proof: proof,
        }

        const action = {
            account: 'nfthivedrops',
            name: 'setproof',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: data,
        }

        actions.push(action)

        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result.transactionId
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Set Proof of Ownership Rules" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to set the following Proof of Ownership Rules?
            </div>
            <div className="PopupBody">
                <div className="PopupDetails">
                    <div className="flex justify-center mb-5">
                        Drop ID: {dropId}
                    </div>
                    <div className="flex flex-wrap justify-center mb-5">
                        {dropAuthProofRulesJoin === 'and'
                            ? `All of the following rules must apply:`
                            : `At least one of the following rules has to apply:`}
                    </div>
                    <div className="mb-2">User owns at least...</div>
                    <div className="mb-5">
                        {dropAuthProofRules.map((rule) => (
                            <div>
                                ... {rule.minAmount} asset of the{' '}
                                {rule.ruleLevel} {rule.collection} {rule.schema}{' '}
                                {rule.templateId}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={create}>
                        Set Rules
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default DropAuthProofPopup
