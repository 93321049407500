import { Wax } from '@eosdacio/ual-wax'
import { UALProvider, withUAL } from '@hivebp/ual-renderer'
import { ThemeProvider } from '@mui/material'
import cn from 'classnames'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import 'react-dropdown/style.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'regenerator-runtime'
import { Anchor } from 'ual-anchor'
import { Ledger } from 'ual-ledger'
import { Scatter } from 'ual-scatter'
import { Starteos } from 'ual-starteos'
import { Wombat } from 'ual-wombat'
import BackgroundImage from '../components/backgroundimage/BackgroundImage'
import ScrollUpIcon from '../components/common/util/ScrollUpIcon'
import Footer from '../components/footer'
import FullImageView from '../components/fullimageview/FullImageView'
import Navigation from '../components/navigation/Navigation'
import SideNavigation from '../components/navigation/SideNavigation'
import PopupWrapper from '../components/popups/PopupWrapper'
import PopupWrapperSearch from '../components/popups/PopupWrapperSearch'
import { mainTheme } from '../components/ui/themes'
import Waxplorer, { Context } from '../components/waxplorer'
import { setup } from '../logging'
import { Brigde as LoggingBridge } from '../logging/bridge'
import '../styles/App.css'
import '../styles/Collection.scss'
import '../styles/globals.css'
import '../styles/Language.css'
import '../styles/Notifications.css'
import '../styles/Popup.css'
import '../styles/Search.css'
import '../styles/Trade.css'

setup() // setup logging

const queryClient = new QueryClient()

const waxNet = {
    chainId:
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12'
            : '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    rpcEndpoints: [
        {
            protocol: 'https',
            host:
                process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                    ? 'api-test.hivebp.io'
                    : 'api3.hivebp.io',
            port: 443,
        },
    ],
}

const anchor = new Anchor([waxNet], {
    appName: 'NFTHive.io',
})

const wombat = new Wombat([waxNet], {
    appName: 'NFTHive.io',
})

const scatter = new Scatter([waxNet], {
    appName: 'NFTHive.io',
})

const ledger = new Ledger([waxNet], {
    appName: 'NFTHive.io',
})

const wax = new Wax([waxNet], {
    appName: 'NFTHive.io',
})

const starteos = new Starteos([waxNet], {
    appName: 'NFTHive.io',
})

const wallets =
    process.env.NEXT_PUBLIC_TESTNET === 'TRUE' ? [anchor] : [wax, anchor]

wallets.push(starteos)
wallets.push(ledger)

const MyApp = ({ Component, pageProps }) => {
    const AppContainer = (props) => {
        const [, setShowSideNavigation] = useState(true)

        const [state, dispatch] = useContext(Context)

        const [showScrollDown, setShowScrollDown] = useState(false)

        const showSideNavigation = state.side_navigation_visible

        const toggleSideNavigation = () => {
            dispatch({
                type: 'SIDE_NAVIGATION_VISIBLE',
                payload: !showSideNavigation,
            })
        }

        const router = useRouter()
        const location = router.asPath.substring(
            0,
            router.asPath.indexOf('?') > 0
                ? router.asPath.indexOf('?')
                : router.asPath.length,
        )
        const hasScrollDown = ['/bulk'].includes(location)

        const [showScrollUpIcon, setShowScrollUpIcon] = useState(false)

        const scrollUp = () => {
            if (process.browser) {
                const element = document.getElementById('ScrollArea')
                element.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
            }
        }

        const handleScroll = (e) => {
            let element = e.target

            setShowScrollUpIcon(element.scrollTop > element.clientHeight)

            if (
                element.scrollHeight -
                    element.scrollTop -
                    element.clientHeight <
                    600 &&
                showScrollDown
            ) {
                setShowScrollDown(false)
            } else if (
                element.scrollHeight -
                    element.scrollTop -
                    element.clientHeight >
                    600 &&
                !showScrollDown
            ) {
                setShowScrollDown(true)
            }
        }

        const toggleImage = () => {
            dispatch({
                type: 'FULL_IMAGE',
                payload: null,
            })
        }

        const scrollDown = () => {
            const elem = document.getElementById('ScrollArea')

            elem.scrollTo({
                left: 0,
                top: elem.scrollHeight - elem.clientHeight - 200,
                behavior: 'smooth',
            })
        }

        const displayImage = state.full_image

        useEffect(() => {}, [])

        return (
            <div className="bg-app h-screen">
                <PopupWrapperSearch {...props} />
                <PopupWrapper {...props} />
                <Navigation toggleSideNavigation={toggleSideNavigation} />
                <BackgroundImage />
                <div
                    id={'ScrollArea'}
                    className={cn(
                        'h-page-s flex flex-wrap md:h-page-md lg:h-page-lg xl:h-page-xl InsideScroll',
                        'overflow-y-auto',
                        {
                            'overflow-y-hidden md:overflow-y-auto scrolling-touch':
                                !showSideNavigation,
                        },
                    )}
                    onScroll={(e) => handleScroll(e)}
                >
                    <div
                        className={cn(
                            'hidden md:block relative h-screen-s md:h-screen-md lg:h-screen-lg transform',
                            'transition-width duration-200',
                            { 'w-0 md:w-3/20 2xl:w-1/10': showSideNavigation },
                            {
                                'w-17/20 md:w-0 overflow-hidden scrolling-touch':
                                    !showSideNavigation,
                            },
                        )}
                    />
                    <div
                        className={cn(
                            'fixed z-10 transform transition-width duration-200',
                            'h-screen',
                            { 'w-0 md:w-3/20 2xl:w-1/10': showSideNavigation },
                            {
                                'w-17/20 md:w-0 overflow-hidden scrolling-touch':
                                    !showSideNavigation,
                            },
                        )}
                    >
                        <SideNavigation
                            setShowSideNavigation={setShowSideNavigation}
                            showSideNavigation={showSideNavigation}
                        />
                    </div>
                    {hasScrollDown && showScrollDown && (
                        <div
                            onClick={scrollDown}
                            className={cn(
                                'absolute p-4 right-52 top-32',
                                'bg-blue z-100 cursor-pointer',
                                'rounded-2xl shadow',
                            )}
                        >
                            <div className={'flex justify-center'}>
                                <div className={''}>Finish</div>
                                <div className={cn('h-6 w-6 animate-bounce')}>
                                    <img
                                        className={'m-1'}
                                        src={'/arrowhead-down-outline.svg'}
                                        alt={''}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        className={cn(
                            'transform transition-width duration-200 overflow-x-hidden scrolling-touch',
                            {
                                'w-full md:w-17/20 2xl:w-9/10':
                                    showSideNavigation,
                            },
                            { 'w-full': !showSideNavigation },
                        )}
                    >
                        <Component {...props} />
                        <div className={'w-full'}>
                            <Footer />
                        </div>
                    </div>
                    {showScrollUpIcon && <ScrollUpIcon onClick={scrollUp} />}
                </div>
                {displayImage ? (
                    <FullImageView
                        image={displayImage}
                        toggleFunc={toggleImage}
                    />
                ) : (
                    ''
                )}
            </div>
        )
    }

    const AppWithUAL = withUAL(AppContainer)

    return (
        <ThemeProvider theme={mainTheme}>
            <Waxplorer>
                <UALProvider
                    chains={[waxNet]}
                    authenticators={wallets}
                    appName={'NFTHive.io'}
                >
                    <LoggingBridge />
                    <CookieConsent>
                        This website uses cookies to enhance the user
                        experience.
                    </CookieConsent>
                    <QueryClientProvider client={queryClient}>
                        <AppWithUAL {...pageProps} />
                    </QueryClientProvider>
                </UALProvider>
            </Waxplorer>
        </ThemeProvider>
    )
}

export default MyApp
