import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function CreatePackPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const packName = props['packName']
    const packDescription = props['packDescription']
    const packUnpackAnimation = props['packUnpackAnimation']
    const templateId = props['packTemplateId']
    const releaseId = props['packReleaseId']
    const totalPacks = props['packTotalPacks']
    const slots = props['packSlots']
    const unlockTime = props['packUnlockTime']

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const createPackActions = [getBoostAction(activeUser)]

        const createPackData = {
            authorized_account: userName,
            collection_name: collectionName,
            release_id: releaseId,
            template_id: templateId,
            total: totalPacks,
            slots: slots,
            unlock_time: unlockTime,
            display_data: JSON.stringify({
                name: packName,
                description: packDescription,
                animation: {
                    drawing: {
                        type: 'video',
                        data: { video: packUnpackAnimation },
                        bg_color: '#000000',
                    },
                },
            }),
        }

        const createPackAction = {
            account: 'nfthivepacks',
            name: 'createpack',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: createPackData,
        }

        createPackActions.push(createPackAction)

        let createPackTransferError = null
        let createPackTransactionId = null

        try {
            const createPackResult = await activeUser.signTransaction(
                {
                    actions: createPackActions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            createPackTransactionId = createPackResult.transactionId
        } catch (e) {
            createPackTransferError = e.message
            setError(e.message)
        }

        if (!createPackTransferError) {
            closeCallBack()
            setIsLoading(false)
            callBack(
                !createPackTransferError,
                createPackTransferError ? [createPackTransferError] : null,
                {
                    packReleaseId: releaseId,
                    transactionId: createPackTransactionId,
                },
            )
        }

        if (createPackTransferError) {
            setIsLoading(false)
            callBack(false, [createPackTransferError], {
                packReleaseId: releaseId,
                transactionId: createPackTransactionId,
            })
        }
    }

    const cancel = () => {
        callBack(false, null, {
            packReleaseId: releaseId,
        })
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Create Pack" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to create a new pack for release #{releaseId} of the{' '}
                {collectionName} collection?
            </div>
            <div className="PopupBody">
                <div className="PopupDetails">
                    <div className="grid grid-cols-2">
                        <div>Collection Name:</div>
                        <div>{collectionName}</div>
                        <div>Template ID used for this Pack:</div>
                        <div>
                            <a href={`/template/${templateId}`} target="_blank">
                                {templateId}
                            </a>
                        </div>
                        <div>Name of the Pack:</div>
                        <div>{packName}</div>
                        <div>Pack Description:</div>
                        <div>
                            {packDescription
                                ? packDescription
                                : `<no description>`}
                        </div>
                        <div>Unlock Time (Unix Timestamp):</div>
                        <div>{unlockTime}</div>
                        <div>Total Packs:</div>
                        <div>{totalPacks}</div>
                        <div>Slots:</div>
                        <div>{slots}</div>
                    </div>
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={create}>
                        Create Pack
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default CreatePackPopup
