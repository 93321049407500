// Factory functions to handle the transport of log messages.

import { logMessage } from '../components/helpers/Api'
import { getCurrentData } from './bridge'
import { getGlobalObject } from './global'
import { isDebugBuild } from './is'

export const hiveServer = () => {
    return (error, event, type) => {
        const data = {
            ...getCurrentData(),
            url: event?.url,
            path: getGlobalObject().document?.location.href,
            type: event.exception.values[0].type,
            error: error?.message ?? event.exception.values[0].value,
        }

        if (isDebugBuild()) console.error(data)
        else logMessage(JSON.stringify(data))
    }
}
