import cn from 'classnames'
import React from 'react'
import ImageElement from '../imageelement/ImageElement'
import VideoElement from '../videoelement/VideoElement'

export const AssetPreviewDisplay = ({
    image,
    backImg,
    prevImg,
    video,
    className = cn(
        'max-h-48 md:max-h-32 xl:max-h-48 4xl:max-h-60 min-h-32',
        'max-w-48 md:max-w-24 lg:max-w-32 xl:max-w-48 4xl:max-w-60',
        'm-auto',
    ),
    previewDuration = 1,
}) => {
    return video ? (
        <VideoElement
            className={className}
            image={prevImg}
            backimg={backImg}
            video={video}
            autoPlay={true}
            timeout={previewDuration * 1000}
        />
    ) : (
        <ImageElement src={prevImg ? prevImg : image} className={className} />
    )
}

export default AssetPreviewDisplay
