const FN_MARKER = '__marked_function'

/**
 * Replace a method in an object with a wrapped version of itself.
 *
 * @param source An object that contains a method to be wrapped.
 * @param name The name of the method to be wrapped.
 * @param replacementFactory A higher-order function that takes the original version of the given method and returns a
 * wrapped version. Note: The function returned by `replacementFactory` needs to be a non-arrow function, in order to
 * preserve the correct value of `this`, and the original method must be called using `origMethod.call(this, <other
 * args>)` or `origMethod.apply(this, [<other args>])` (rather than being called directly), again to preserve `this`.
 */
export const fill = (source, name, replacementFactory) => {
    if (!(name in source)) return

    const original = source[name]
    const wrapped = replacementFactory(original)

    // Make sure it's a function first, as we need to attach an empty prototype for `defineProperties` to work
    // otherwise it'll throw "TypeError: Object.defineProperties called on non-object"
    if (typeof wrapped === 'function')
        try {
            markFunctionWrapped(wrapped, original)
        } catch (_Oo) {}

    source[name] = wrapped
}

export const markFunctionWrapped = (wrapped, original) => {
    const proto = original.prototype || {}
    wrapped.prototype = original.prototype = proto
    addNonEnumerableProperty(wrapped, FN_MARKER, original)
}

export const addNonEnumerableProperty = (obj, name, value) =>
    Object.defineProperty(obj, name, {
        enumerable: false,
        value: value,
        writable: true,
        configurable: true,
    })

export const getOriginalFunction = (func) => func[FN_MARKER]
