import cn from 'classnames'
import moment from 'moment'
import Link from 'next/link'
import React from 'react'
import config from '../../config.json'
import { get } from './Api'

export const getAssetLink = (
    assetId,
    name,
    number,
    variant,
    offer,
    orderId,
) => {
    return (
        <Link
            href={
                (offer ? '/listing/' : '/asset/') +
                assetId +
                (orderId ? `?listing_id=${orderId}` : '')
            }
        >
            <div className="cursor-pointer">
                {name ? (variant && number ? `${name}` : name) : assetId}
            </div>
        </Link>
    )
}

export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const parseAtomicImmutableData = (atomicData) => {
    const idata = atomicData.immutable_data
    const template_idata = atomicData.template
        ? atomicData.template.immutable_data
        : null

    return Object.assign({}, idata, template_idata)
}

export const parseAtomicMutuableData = (atomicData) => {
    return atomicData.mutable_data &&
        Object.keys(atomicData.mutable_data).length > 0
        ? atomicData.mutable_data
        : null
}

export const setQueryStringWithoutPageReload = (qsValue) => {
    const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        qsValue
    const newPath = window.location.pathname + '?' + qsValue

    const oldState = window.history.state
    oldState.path = newPath
    oldState.url = newurl

    window.history.replaceState(oldState, '', newPath)
}

export const setQueryStringWithoutPageReloadPush = (qsValue) => {
    const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        (qsValue && qsValue.length > 0 ? '?' : '') +
        qsValue
    const newPath =
        window.location.pathname +
        (qsValue && qsValue.length > 0 ? '?' : '') +
        qsValue

    const oldState = window.history.state

    oldState.path = newPath
    oldState.url = newurl
    oldState.as = newPath

    window.history.pushState(oldState, '', newPath)
}

export const setQueryStringWithoutPageReloadPushPath = (qsValue, path) => {
    const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        '/' +
        path +
        '?' +
        qsValue
    const newPath = window.location.host + '/' + path + '?' + qsValue

    const oldState = window.history.state

    oldState.path = newurl
    oldState.url = newurl
    oldState.as = newurl

    window.history.pushState(oldState, '', newPath)
}

export const getOwnerLink = (owner) => {
    return (
        <Link href={`/user/${owner}`}>
            <div className="cursor-pointer">{owner}</div>
        </Link>
    )
}

export const getAuthorLink = (author) => {
    return (
        <Link href={`/collection/${author}`}>
            <div className="cursor-pointer">{author}</div>
        </Link>
    )
}

export const getVariantLink = (author, schema, variant) => {
    return (
        <Link
            href={`/market?searchType=sales&order_by=offer_asc&collection=${author}&schema=${schema}&variant=${variant}`}
        >
            <div className="cursor-pointer">{variant}</div>
        </Link>
    )
}

export const getSearchName = (t, values, props) => {
    let search = t('search.sales')

    if (process.browser) {
        if (values['search_type'] && 'cheapest' === values['search_type'])
            search = 'Cheapest Sales per Asset'
        else if (
            values['search_type'] &&
            'cheapest_missing' === values['search_type']
        )
            search = t('search.cheapest_missing_assets')
        else if (
            values['search_type'] &&
            ['sales', 'below_average'].includes(values['search_type'])
        )
            search = t('search.sales')
        else if (values['search_type'] && values['search_type'] === 'staked')
            search = t(`search.staked_assets`)
        else if (values['search_type'])
            search = t(`search.${values['search_type']}`)
        if (values['tab'] === 'packs') search = t('overview.packs')
        else if (values['tab'] === 'auctions') search = t('search.auctions')
    } else {
        if (props.search_type && 'cheapest' === props.search_type)
            search = 'Cheapest Sales per Asset'
        else if (props.search_type && 'cheapest_missing' === props.search_type)
            search = t('search.cheapest_missing_assets')
        else if (
            props.search_type &&
            ['sales', 'below_average'].includes(props.search_type)
        )
            search = t('search.sales')
        else if (props.search_type && props.search_type === 'staked')
            search = t(`search.staked_assets`)
        else if (props.search_type) search = t(`search.${props.search_type}`)
        if (props.tab === 'packs') search = t('overview.packs')
        else if (props.tab === 'auctions') search = t('search.auctions')
    }

    return search
}

export const formatAuthor = (author) => {
    return author ? author.charAt(0).toUpperCase() + author.slice(1) : author
}

export const createCollectionOption = (name) => {
    if (!name) return name

    return (
        <div
            className={cn(
                'flex justify-between font-bold w-32',
                { 'text-xs': name.length >= 15 },
                { 'text-sm': name.length < 15 },
            )}
        >
            {name}
        </div>
    )
}

export const createCollectionImageOption = (name, image) => {
    return (
        <div className={cn('flex justify-between w-32', 'text-sm font-bold')}>
            <div className="w-8 h-8 m-auto">
                <img src={image} className="w-8 h-8" />
            </div>
            <div className="w-full pl-1 pr-3 overflow-x-hidden whitespace-nowrap">
                {name}
            </div>
        </div>
    )
}

export const createAuthors = (data, search = '') => {
    const authors = []

    ;(data['authors'] ? data['authors'] : data)
        .filter((author) => data['verified'][author])
        .forEach((element) => {
            if (!authors.find((a) => a.value === element))
                if (
                    !search ||
                    element.toLowerCase().includes(search.toLowerCase()) ||
                    (data['names'][element] &&
                        data['names'][element]
                            .toLowerCase()
                            .includes(search.toLowerCase()))
                )
                    authors.push({
                        value: element,
                        title: data['names'][element]
                            ? data['names'][element]
                            : element,
                        label: element,
                        image:
                            data['images'] && data['images'][element]
                                ? data['images'][element]
                                : '',
                    })
        })
    ;(data['authors'] ? data['authors'] : data)
        .filter((author) => !data['verified'][author])
        .forEach((element) => {
            if (!authors.find((a) => a.value === element))
                if (
                    !search ||
                    element.toLowerCase().includes(search.toLowerCase()) ||
                    (data['names'][element] &&
                        data['names'][element]
                            .toLowerCase()
                            .includes(search.toLowerCase()))
                )
                    authors.push({
                        value: element,
                        title: data['names'][element]
                            ? data['names'][element]
                            : element,
                        label: element,
                        image:
                            data['images'] && data['images'][element]
                                ? data['images'][element]
                                : '',
                    })
        })

    return authors
}

export const getImage = (author) => {
    if (author && author !== '*')
        return 'https://ipfs.hivebp.io/hive/author/' + author

    return 'https://nfthive.io/nfthive-logo.png'
}

export const getImageData = (images, imageFormats, assetData, format) => {
    if (assetData) {
        if (Object.keys(assetData).includes('pfp')) {
            images.push(
                assetData['pfp'].includes('http')
                    ? assetData['pfp']
                    : `${config.ipfs}${assetData['pfp']}`,
            )
            imageFormats.push('image')
        }

        if (Object.keys(assetData).includes('img2')) {
            images.push(
                assetData['img2'].includes('http')
                    ? assetData['img2']
                    : `${config.ipfs}${assetData['img2']}`,
            )
            imageFormats.push('image')
        }

        if (Object.keys(assetData).includes('img3')) {
            images.push(
                assetData['img3'].includes('http')
                    ? assetData['img3']
                    : `${config.ipfs}${assetData['img3']}`,
            )
            imageFormats.push('image')
        }

        if (Object.keys(assetData).includes('img4')) {
            images.push(
                assetData['img4'].includes('http')
                    ? assetData['img4']
                    : `${config.ipfs}${assetData['img4']}`,
            )
            imageFormats.push('image')
        }

        if (Object.keys(assetData).includes('Image 2')) {
            images.push(
                assetData['Image 2'].includes('http')
                    ? assetData['Image 2']
                    : `${config.ipfs}${assetData['Image 2']}`,
            )
            imageFormats.push('image')
        }

        if (Object.keys(assetData).includes('Image 3')) {
            images.push(
                assetData['Image 3'].includes('http')
                    ? assetData['Image 3']
                    : `${config.ipfs}${assetData['Image 3']}`,
            )
            imageFormats.push('image')
        }

        if (Object.keys(assetData).includes('Video')) {
            images.push(
                assetData['Video'].includes('http')
                    ? assetData['Video']
                    : `${config.ipfs}${assetData['Video']}`,
            )
            imageFormats.push('video')
        }

        if (Object.keys(assetData).includes('Video 1')) {
            images.push(
                assetData['Video 1'].includes('http')
                    ? assetData['Video 1']
                    : `${config.ipfs}${assetData['Video 1']}`,
            )
            imageFormats.push('video')
        }

        if (Object.keys(assetData).includes('Video2')) {
            images.push(
                assetData['Video2'].includes('http')
                    ? assetData['Video2']
                    : `${config.ipfs}${assetData['Video2']}`,
            )
            imageFormats.push('video')
        }

        if (Object.keys(assetData).includes('hi_res_boxed')) {
            images.push(
                assetData['unboxed_img'].includes('http')
                    ? assetData['unboxed_img']
                    : `${config.ipfs}${assetData['unboxed_img']}`,
            )
            imageFormats.push('image')
        }

        if (Object.keys(assetData).includes('hi_res_boxed')) {
            images.push(
                assetData['hi_res_boxed'].includes('http')
                    ? assetData['hi_res_boxed']
                    : `${config.ipfs}${assetData['hi_res_boxed']}`,
            )
            imageFormats.push('video')
        }

        if (Object.keys(assetData).includes('hi_res_unboxed')) {
            images.push(
                assetData['hi_res_unboxed'].includes('http')
                    ? assetData['hi_res_unboxed']
                    : `${config.ipfs}${assetData['hi_res_unboxed']}`,
            )
            imageFormats.push('video')
        }

        if (Object.keys(assetData).includes('transcript_esp')) {
            images.push(
                assetData['transcript_esp'].includes('http')
                    ? assetData['transcript_esp']
                    : `${config.ipfs}${assetData['transcript_esp']}`,
            )
            imageFormats.push('image')
        }

        if (Object.keys(assetData).includes('transcript_eng')) {
            images.push(
                assetData['transcript_eng'].includes('http')
                    ? assetData['transcript_eng']
                    : `${config.ipfs}${assetData['transcript_eng']}`,
            )
            imageFormats.push('image')
        }
    }

    if (format && assetData) {
        const formatDict = {}
        for (let a of format) {
            formatDict[a['name']] = a['type']
        }
        Object.keys(assetData).map((key) => {
            if (formatDict[key] && formatDict[key] === 'image') {
                const image = assetData[key].includes('http')
                    ? assetData[key]
                    : `${config.ipfs}${assetData[key]}`
                if (!images.includes(image)) {
                    images.push(image)
                    imageFormats.push('image')
                }
            }
        })
    }
}

export const createSchemas = (categories, includeDefault = true) => {
    const categoriesOptions = []
    const addedCategories = []

    if (!categories || !categories.length) return null

    if (includeDefault) categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element['schema']) {
            if (!addedCategories.includes(element['schema'])) {
                categoriesOptions.push({
                    value: element['schema'],
                    label: element['schema'],
                })
                addedCategories.push(element['schema'])
            }
        }
    })

    return categoriesOptions
}

export const createCategories = (t) => {
    return [
        {
            value: 1,
            label: t('collection_category.1'),
        },
        {
            value: 2,
            label: t('collection_category.2'),
        },
        {
            value: 3,
            label: t('collection_category.3'),
        },
        {
            value: 4,
            label: t('collection_category.4'),
        },
        {
            value: 5,
            label: t('collection_category.5'),
        },
        {
            value: 6,
            label: t('collection_category.6'),
        },
    ]
}

export const createRarities = (categories, includeDefault = true) => {
    const categoriesOptions = []
    const addedRarities = []

    if (!categories || !categories.length) return null

    if (includeDefault) categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element['rarity']) {
            if (!addedRarities.includes(element['rarity'])) {
                categoriesOptions.push({
                    value: element['rarity'],
                    label: element['rarity'],
                })
                addedRarities.push(element['rarity'])
            }
        }
    })

    return categoriesOptions
}

export const createVariants = (categories, variant = 'Variant') => {
    const categoriesOptions = []
    const addedVariants = []

    if (!categories || !categories.length) return null

    categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element['variant']) {
            if (!addedVariants.includes(element['variant'])) {
                categoriesOptions.push({
                    value: element['variant'],
                    label: element['variant'],
                })
                addedVariants.push(element['variant'])
            }
        }
    })

    return categoriesOptions
}

export const createColors = (categories, color = 'Color') => {
    const categoriesOptions = []
    const addedColors = []

    if (!categories || !categories.length) return null

    categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element['color']) {
            if (!addedColors.includes(element['color'])) {
                categoriesOptions.push({
                    value: element['color'],
                    label: element['color'],
                })
                addedColors.push(element['color'])
            }
        }
    })

    return categoriesOptions
}

export const createAttributes = (categories, key, labelName) => {
    const categoriesOptions = []
    const addedBorder = []

    if (!categories || !categories.length) return null

    categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element[key] === 0 || element[key]) {
            if (!addedBorder.includes(element[key])) {
                categoriesOptions.push({
                    value: '' + element[key],
                    label: '' + element[key],
                })
                addedBorder.push(element[key])
            }
        }
    })

    return categoriesOptions.sort(
        (a, b) => parseInt(a.value) - parseInt(b.value),
    )
}

export const createBorders = (categories, border = 'Border') => {
    const categoriesOptions = []
    const addedBorder = []

    if (!categories || !categories.length) return null

    categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element['border'] === 0 || element['border']) {
            if (!addedBorder.includes(element['border'])) {
                categoriesOptions.push({
                    value: '' + element['border'],
                    label: '' + element['border'],
                })
                addedBorder.push(element['border'])
            }
        }
    })

    return categoriesOptions.sort(
        (a, b) => parseInt(a.value) - parseInt(b.value),
    )
}

export const createTypes = (categories, type = 'Type') => {
    const categoriesOptions = []
    const addedBorder = []

    if (!categories || !categories.length) return null

    categoriesOptions.push({ value: '', label: 'All' })

    categories.forEach((element) => {
        if (element['type']) {
            if (!addedBorder.includes(element['type'])) {
                categoriesOptions.push({
                    value: element['type'],
                    label: element['type'],
                })
                addedBorder.push(element['type'])
            }
        }
    })

    return categoriesOptions.sort(
        (a, b) => parseInt(a.value) - parseInt(b.value),
    )
}

export const createNames = (categories, name = 'Name') => {
    const categoriesOptions = []
    const addedBorder = []

    if (!categories || !categories.length) return null

    categoriesOptions.push({ value: '', label: 'All' })

    categories
        .sort((a, b) => ('' + a.name).localeCompare(b.name))
        .forEach((element) => {
            if (element['name']) {
                if (!addedBorder.includes(element['name'])) {
                    categoriesOptions.push({
                        value: element['name'],
                        label: element['name'],
                    })
                    addedBorder.push(element['name'])
                }
            }
        })

    return categoriesOptions.sort(
        (a, b) => parseInt(a.value) - parseInt(b.value),
    )
}

export const getAttributeNames = async (author, schema, signal) =>
    get(`attribute-names/${author}${schema ? `/${schema}` : ''}`, undefined, {
        signal,
    })

export const getDefaultAttrNames = () => {
    return {
        variant: 'Variant',
        rarity: 'Rarity',
        number: 'Number',
        type: 'Type',
        color: 'Color',
        border: 'Border',
        attr7: '',
        attr8: '',
        attr9: '',
        attr10: '',
    }
}

export const getVariantName = (t, author, schema) => {
    if (author === 'stf.capcom' || author === 'robotech') return 'Power Score'

    if (author === 'girlpinupart') return t('search.artist')

    if (author === 'bitcoinorign') return 'Moment'

    if (author === 'alien.worlds') {
        if (schema === 'land.worlds' || schema === 'Alien Worlds Land')
            return 'Planet'
        else if (!schema) return 'Shine / Planet'
        else return 'Shine'
    }

    if (author === 'armiesxpower') {
        return 'Mission'
    }

    if (author === 'upliftworld') {
        return 'World'
    }

    if (author === 'kogsofficial') {
        return 'Series'
    }

    if (author === 'darkcountryh') {
        if (schema === 'heroes') {
            return t('search.bagspace')
        }
        if (schema === 'cards') {
            return t('search.type')
        }

        return `${t('search.type')}/${t('search.bagspace')}`
    }

    if (author === 'cardinalland') {
        return 'Continent'
    }

    if (author === 'greenrabbit') {
        return 'Class'
    }

    return t('asset.variant')
}

export const getHash = (s) => {
    return s.split('').reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0)
        return a & a
    }, 0)
}

export const openSearchPopup = (searchType, asset, dispatch, callback) => {
    dispatch({
        type: 'SET_SEARCH_ID',
        payload: getHash(moment.now().toString()),
    })
    dispatch({ type: 'SET_SEARCH_ASSET', payload: asset })
    dispatch({ type: 'SET_SEARCH_TYPE', payload: searchType })
    dispatch({ type: 'SET_CALLBACK', payload: callback ? callback : () => {} })
    dispatch({ type: 'SET_ACTION_SEARCH', payload: 'search' })
}

export const formatNumber = (value) => {
    const number = parseInt(value)
    if (Math.abs(number) >= 1000000000)
        return `${parseInt(`${Math.round(number / 10000000.0)}`) / 10.0}B`
    else if (Math.abs(number) >= 1000000)
        return `${parseInt(`${Math.round(number / 100000.0)}`) / 10.0}M`
    else if (Math.abs(number) >= 10000)
        return `${parseInt(`${Math.round(number / 100.0)}`) / 10.0}K`
    else return Math.round(value * 100) / 100
}

export const formatRAM = (value) => {
    const number = parseInt(value)
    if (Math.abs(number) >= 1024 * 1024 * 1024)
        return `${
            parseInt(
                `${Math.round((number / (1024.0 * 1024.0 * 1024.0)) * 10)}`,
            ) / 10.0
        }GB`
    else if (Math.abs(number) >= 1024 * 1024)
        return `${
            parseInt(`${Math.round((number / (1024.0 * 1024.0)) * 10)}`) / 10.0
        }MB`
    else if (Math.abs(number) >= 1024)
        return `${parseInt(`${Math.round((number / 1024.0) * 10)}`) / 10.0}KB`
    else return `${Math.round(number * 100) / 100} Byte`
}

export const formatRateNumber = (value) => {
    const number = parseInt(value)
    if (Math.abs(number) >= 1000000000)
        return `${Math.round(
            parseInt(`${Math.round(number / 10000000.0)}`) / 10.0,
        )}B`
    else if (Math.abs(number) >= 1000000)
        return `${Math.round(
            parseInt(`${Math.round(number / 100000.0)}`) / 10.0,
        )}M`
    else if (Math.abs(number) >= 10000)
        return `${Math.round(
            parseInt(`${Math.round(number / 100.0)}`) / 10.0,
        )}K`
    else if (Math.abs(number) >= 100)
        return parseInt(`${Math.round(value * 100) / 100}`)
    else return Math.round(value * 100) / 100
}

export const formatUSD = (usd) => {
    return `$${usd.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })}`
}

export const formatWAX = (wax) => {
    return `${wax.toLocaleString(undefined, {
        maximumFractionDigits: 8,
        minimumFractionDigits: 0,
    })} WAX`
}

export const formatUSDM = (value) => {
    const number = parseInt(value)
    if (Math.abs(number) >= 1000000000)
        return `$${parseInt(`${Math.round(number / 10000000.0)}`) / 10.0}B`
    else if (Math.abs(number) >= 1000000)
        return `$${parseInt(`${Math.round(number / 100000.0)}`) / 10.0}M`
    else if (Math.abs(number) >= 10000)
        return `$${parseInt(`${Math.round(number / 100.0)}`) / 10.0}K`
    else
        return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        })}`
}

export const formatDate = (timestamp) => {
    const utc = moment.utc(timestamp).toDate()
    return moment(utc).local().format('YYYY-MM-DD HH:mm:ss')
}

export const formatNumberS = (value) => {
    return Math.round(value * 10000) / 10000.0
}

export const formatNumber1K = (value) => {
    const number = parseInt(value)
    if (Math.abs(number) >= 1000000000)
        return `${parseInt(`${Math.round(number / 10000000.0)}`) / 10.0}B`
    else if (Math.abs(number) >= 1000000)
        return `${parseInt(`${Math.round(number / 100000.0)}`) / 10.0}M`
    else if (Math.abs(number) >= 1000)
        return `${parseInt(`${Math.round(number / 100.0)}`) / 10.0}K`
    else if (Math.abs(number) >= 10) return Math.round(value)
    else return value
}

export const formatPercentage = (value) => {
    const number = Math.round(parseFloat(value) * 10000.0) / 100.0
    return `${formatNumber1K(number)}%`
}

export const formatPercentageFull = (value) => {
    const number = Math.round(parseFloat(value) * 10000.0) / 100.0
    return `${formatNumber(number)}%`
}

export const formatTransaction = (tx) => {
    return tx.substring(0, 20) + '…'
}

export const formatGrowth = (value, usd = 0, includeWAX = false) => {
    const number = parseInt(value)
    if (number > 0)
        return (
            <div className="flex items-center">
                <svg
                    class="fill-primary"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M9 7v6H7V7H4l4-4 4 4z" fill-rule="evenodd"></path>
                </svg>
                <div>
                    {includeWAX
                        ? `${formatNumber(value)} WAX`
                        : formatNumber(value)}
                    {usd ? (
                        <span className="text-xs">/${formatNumber(usd)}</span>
                    ) : null}
                </div>
            </div>
        )
    if (number < 1000000)
        return (
            <div className="flex items-center">
                <svg
                    className="fill-neutral"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M9 9h3l-4 4-4-4h3V3h2z" fill-rule="evenodd"></path>
                </svg>
                <div>
                    {includeWAX
                        ? `${formatNumber(value)} WAX`
                        : formatNumber(value)}
                    {usd ? (
                        <span className="text-xs">/${formatNumber(usd)}</span>
                    ) : null}
                </div>
            </div>
        )
    else return '-'
}

export const formatMarket = (market) => {
    if (market === 'simplemarket') {
        return 'Simplemarket'
    } else if (market === 'waxinventory') {
        return 'WAX Inventory'
    } else if (market === 'morevgocom11') {
        return 'More VGO'
    } else if (
        market === 'market.myth' ||
        market === 'gpk.myth' ||
        market === 'ws.myth' ||
        market === 'myth'
    ) {
        return 'Myth.Market'
    } else if (market === 'alcornftswap' || market === 'alcordexmain') {
        return 'alcor.exchange'
    } else if (market === 'market.place') {
        return 'Collectables.io'
    } else if (market === 'gpk.market') {
        return 'GPK.Auction'
    } else if (market === 'wax.stash') {
        return 'waxstash.com'
    } else if (market === 'nft.cow') {
        return 'nftcow.com'
    } else if (market === 'atomicmarket') {
        return 'Atomichub.io'
    } else if (market === 'waxplorercom' || market === 'nft.hive') {
        return 'NFTHive.io'
    } else if (market === 'cryptoslamio') {
        return 'Cryptoslam.io'
    } else if (market === 'waxarena3dk1') {
        return 'waxarena.net'
    } else if (market === 'primarydrops') {
        return 'Primary Sales'
    } else if (market === 'neftyblocksd') {
        return 'NeftyBlocks Drops'
    } else if (market === 'wdny.io') {
        return 'wdny.io'
    } else if (market === 'atomicdropsx') {
        return 'Atomichub.io Drops'
    } else if (market === 'market.nefty') {
        return 'NeftyBlocks Market'
    }

    return market
}

export const getWaxStashAuthor = (author) => {
    if (author === 'dopestickers') {
        return 'boxmaze'
    } else if (author === 'cryptotwerpz') {
        return 'twerpz'
    }

    return 'gpk'
}

export const getPriceLink = (
    asset,
    t = null,
    newOwner = null,
    activeUser = null,
) => {
    const {
        price,
        totalRevenue,
        offer,
        assetId,
        owner,
        receiver,
        name,
        orderId,
        author,
        sender,
        market,
        isAuction,
        bidder,
        buyer,
        seller,
    } = asset

    const aClass = cn('flex justify-between w-full no-underline text-neutral')

    if (price)
        return (
            <div className="w-full no-underline text-neutral text-xs">
                <div>
                    {t('asset.sold_to_buyer_on_market', {
                        buyer: buyer ? buyer : receiver ? receiver : owner,
                        market: formatMarket(market),
                    })}
                </div>
            </div>
        )
    if (totalRevenue)
        return (
            <div className="w-full no-underline text-neutral">
                <div>
                    Revenue: <b>{Math.round(totalRevenue * 100) / 100}</b> WAX
                </div>
            </div>
        )
    if (offer) {
        let link = ''
        const marketElement =
            isAuction && bidder ? (
                <div className="text-sm my-auto">
                    {t('search.highest_bidder')}:
                </div>
            ) : (
                <div className="text-sm my-auto">
                    {seller !== activeUser || (!market && !owner)
                        ? t('search.seller')
                        : t('search.market')}
                </div>
            )

        const displayName = bidder
            ? bidder
            : seller !== activeUser || (!market && !owner)
            ? seller
            : formatMarket(market ? market : owner)

        if (owner === 'simplemarket') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                        'https://wax.simplemarket.io/products/asset/' + assetId
                    }
                >
                    {marketElement}
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (market === 'waxarena3dk1') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={'https://waxarena.net/card/' + assetId}
                >
                    {marketElement}
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (owner === 'waxplorercom') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={'https://nfthive.io/asset/' + assetId}
                >
                    {marketElement}
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (owner === 'waxinventory') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={'https://waxinventory.com/app/nft/' + assetId}
                >
                    {marketElement}
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (owner === 'morevgocom11') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={'https://morevgo.com/market/?search=' + name}
                >
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (owner === 'market.myth') {
            if (author === 'gpk.topps') {
                link = (
                    <a
                        className={aClass}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://gpk.market/asset/${assetId}?referral=waxplorerref`}
                    >
                        {marketElement}
                        <div className="text-xs my-auto">
                            <div className="relative -top-px">»</div>
                            {displayName}
                        </div>
                    </a>
                )
            } else if (author === 'shatner') {
                link = (
                    <a
                        className={aClass}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://shatner.market/asset/${assetId}?referral=waxplorerref`}
                    >
                        {marketElement}
                        <div className="text-xs my-auto">
                            <div className="relative -top-px">»</div>
                            {displayName}
                        </div>
                    </a>
                )
            }
        } else if (owner === 'alcornftswap') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://wax.alcor.exchange/nft-market/order/${orderId}`}
                >
                    {marketElement}
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (owner === 'market.place') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://collectables.io/listing/${orderId}?ref=waxplorerref`}
                >
                    {marketElement}
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (owner === 'gpk.market') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://gpk.auction/auction/${assetId}`}
                >
                    {marketElement}
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (owner === 'wax.stash') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://waxstash.com/${getWaxStashAuthor(
                        author,
                    )}?assetid=${assetId}&refer=waxplorerref`}
                >
                    {marketElement}
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (owner === 'nft.cow') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://nftcow.com/#/`}
                >
                    <div>Moo</div>
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (owner === 'atomicmarket') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://nfthive.io/sale/${assetId}`}
                >
                    {marketElement}
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (owner === 'alcordexmain') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://wax.alcor.exchange/markets/${name}-packs.topps`}
                >
                    {marketElement}
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (owner === 'gpk.myth') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://gpk.market/packs/${name}?referral=waxplorerref`}
                >
                    {marketElement}
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        } else if (owner === 'ws.myth') {
            link = (
                <a
                    className={aClass}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://shatner.market/packs/${name}?referral=waxplorerref`}
                >
                    {marketElement}
                    <div className="text-xs my-auto">
                        <div className="relative -top-px">»</div>
                        {displayName}
                    </div>
                </a>
            )
        }
        return link
    } else {
        return (
            <a
                target="_blank"
                className={aClass}
                href={`/profile?account=${owner}&collection=${author}&tab=inventory`}
                rel="noreferrer"
            >
                <div>{t('search.owner')}:</div>
                <div>
                    <div className="relative -top-px">»</div>
                    <div>{newOwner ? newOwner : owner}</div>
                </div>
            </a>
        )
    }
}

export default getPriceLink
