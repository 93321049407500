import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import { get } from '../helpers/Api'
import { formatWAX } from '../helpers/FormatLinks'
import { getBoostAction, getWaxBuyOfferBalance } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function BuyOfferPopup(props) {
    const asset = props['asset']
    const { t } = useTranslation('common')

    const { author, name, image, templateId } = asset

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']

    const callBack = props['callBack']

    const userName = activeUser ? activeUser['accountName'] : null
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [offer, setOffer] = useState(null)
    const [showInput, setShowInput] = useState(true)
    const [userBalance, setUserBalance] = useState(0)
    const [combinedActiveOfferValue, setCombinedActiveOfferValue] = useState(0)
    const [isLoadingUserOffers, setIsLoadingUserOffers] = useState(true)

    const parseUserOffers = (res) => {
        let combinedValue = 0
        if (res.length > 0) {
            for (const offerElement of res) {
                combinedValue += offerElement.offer
            }
        }
        setCombinedActiveOfferValue(combinedValue)
        setIsLoadingUserOffers(false)
    }

    useEffect(async () => {
        setUserBalance(await getWaxBuyOfferBalance(userName))
        get(`active-buyoffers?buyer=${userName}`).then(parseUserOffers)
    }, [userName])

    const create = async (depositType = 'min') => {
        if (!offer || !offer['price']) return
        const { mdata } = asset

        const data = mdata
            ? JSON.parse(mdata)
            : {
                  video:
                      asset['image'] && asset['image'].includes('video:')
                          ? asset['image'].replace('video:', '')
                          : '',
                  img:
                      asset['image'] && !asset['image'].includes('video:')
                          ? asset['image'].replace(
                                'https://ipfs.nfthive.io/ipfs/',
                                '',
                            )
                          : '',
              }

        const video = data['video']
        const image = data['img']

        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        if (depositType === 'min') {
            if (offer['missingMin'] > 0) {
                actions.push({
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser['requestPermission'],
                        },
                    ],
                    data: {
                        from: userName,
                        to: 'waxbuyoffers',
                        quantity: `${parseFloat(offer['missingMin']).toFixed(
                            8,
                        )} WAX`,
                        memo: 'deposit',
                    },
                })
            }
        } else if (depositType === 'max') {
            actions.push({
                account: 'eosio.token',
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: {
                    from: userName,
                    to: 'waxbuyoffers',
                    quantity: `${parseFloat(offer['missingMax']).toFixed(
                        8,
                    )} WAX`,
                    memo: 'deposit',
                },
            })
        } else {
            return
        }
        actions.push({
            account: 'waxbuyoffers',
            name: 'createoffer',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: {
                buyer: userName,
                template_id: templateId,
                collection_name: author,
                asset_name: name,
                image: video ? '' : image,
                video: video,
                offer: `${parseFloat(offer['price']).toFixed(8)} WAX`,
                maker: 'nft.hive',
            },
        })

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ created: true, error: null })
        } catch (e) {
            callBack(false, e)
            console.log(e)
            setError(e.message)
        } finally {
            setIsLoading(false)
            closeCallBack()
        }
    }

    const createWithMinDeposit = async () => {
        create('min')
    }

    const createWithMaxDeposit = async () => {
        create('max')
    }

    const cancel = () => {
        callBack({ created: false, error: null })
        closeCallBack()
    }

    const changePrice = (e) => {
        const inputString = e.target.value
        const inputInt = parseInt(inputString, 10)
        if (/^\d*\.?\d*$/.test(inputString)) {
            let missingMinAmount = 0
            let missingMaxAmount = 0
            if (inputInt && userBalance) {
                missingMinAmount = inputInt - userBalance
                missingMinAmount = missingMinAmount < 0 ? 0 : missingMinAmount
                missingMaxAmount =
                    inputInt + combinedActiveOfferValue - userBalance
                missingMaxAmount = missingMaxAmount < 0 ? 0 : missingMaxAmount
            } else if (inputInt) {
                missingMinAmount = inputInt
                missingMaxAmount = inputInt + combinedActiveOfferValue
            }

            setOffer({
                price: inputString,
                missingMin: missingMinAmount,
                missingMax: missingMaxAmount,
            })
        }
    }

    const buttonClass = cn(
        'py-1 my-1 mx-1 w-52 border-2 h-auto rounded-tr-md rounded-bl-md outline-0',
    )

    const buttonEnabled = cn('text-black bg-primary border-primary')

    const buttonDisabled = cn('text-black bg-neutral border-neutral')

    return (
        <Popup title={name} cancel={cancel} image={image}>
            {isLoadingUserOffers ? (
                <LoadingIndicator />
            ) : (
                <>
                    <div className={cn('text-center text-base m-4')}>
                        Do you want to create a Buy Offer for ANY asset that
                        matches <b className={'text-primary'}>{name}</b> by{' '}
                        <b className={'text-primary'}>{author}</b> with the
                        Template ID{' '}
                        <b className={'text-primary'}>{templateId}</b>?<br />
                        <div
                            className={cn(
                                'text-sm text-neutral w-full md:w-2/3 m-auto text-left bg-bluet p-3 rounded',
                            )}
                        >
                            Deposit the Amount of WAX you are willing to pay and
                            anyone can sell a matching Asset with the same
                            Template ID and Name to you immediately.
                            <br />
                            You can cancel your offer and withdraw your funds at
                            any time.
                            <br />
                            You can create multiple offers with an existing
                            balance, but when your contract balance falls below
                            any of your offers they're canceled automatically.
                        </div>
                    </div>
                    <div className={cn('w-1/2 justify-center mx-auto')}>
                        <div className={'flex justify-between'}>
                            <div>Contract Balance:</div>
                            <div>
                                {userBalance ? formatWAX(userBalance) : 0}
                            </div>
                        </div>
                        {combinedActiveOfferValue > 0 ? (
                            <div className={'flex justify-between'}>
                                <div>
                                    Combined Value of your other active offers:
                                </div>
                                <div>{formatWAX(combinedActiveOfferValue)}</div>
                            </div>
                        ) : (
                            ''
                        )}
                        {offer &&
                        offer['missingMin'] &&
                        offer['missingMin'] > 0 ? (
                            <div className={'flex justify-between'}>
                                <div>Minimum Deposit:</div>
                                <div>{formatWAX(offer['missingMin'])}</div>
                            </div>
                        ) : (
                            ''
                        )}
                        {offer &&
                        offer['missingMax'] &&
                        offer['missingMax'] > 0 &&
                        offer['missingMin'] !== offer['missingMax'] ? (
                            <div className={'flex justify-between'}>
                                <div>
                                    Deposit to cover this offer and all other
                                    active offers:
                                </div>
                                <div>{formatWAX(offer['missingMax'])}</div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    {error && <ErrorMessage error={error} />}
                    <div className={cn('w-full')}>
                        <div
                            className={cn('w-1/2 justify-center mx-auto pb-5')}
                        >
                            <div className="flex justify-between">
                                <div className={cn('DropdownLabel my-auto')}>
                                    Enter Offer Amount:
                                </div>
                                <input
                                    className={cn(
                                        showInput
                                            ? 'SellInput Memo'
                                            : 'SellInput Hidden',
                                    )}
                                    type="text"
                                    onChange={changePrice}
                                    value={offer ? offer['price'] : ''}
                                />
                            </div>
                        </div>
                        <div className={'flex justify-evenly'}>
                            <MainButton
                                className={cn(buttonClass)}
                                onClick={cancel}
                            >
                                {t('asset.cancel')}
                            </MainButton>
                            <MainButton
                                disabled={
                                    (!offer || !offer['price']) && 'disabled'
                                }
                                className={cn(
                                    buttonClass,
                                    !offer || !offer['price']
                                        ? buttonDisabled
                                        : buttonEnabled,
                                )}
                                onClick={createWithMinDeposit}
                            >
                                {offer &&
                                offer['missingMin'] &&
                                offer['missingMin'] > 0
                                    ? 'Create Offer (Add Min Deposit)'
                                    : 'Create Offer'}
                            </MainButton>
                            {offer &&
                            offer['price'] &&
                            offer['missingMax'] !== 0 &&
                            offer['missingMin'] !== offer['missingMax'] ? (
                                <MainButton
                                    className={cn(buttonClass, buttonEnabled)}
                                    onClick={createWithMaxDeposit}
                                >
                                    Create Offer (Add Max Deposit)
                                </MainButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </>
            )}

            {isLoading && (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            )}
        </Popup>
    )
}

export default BuyOfferPopup
