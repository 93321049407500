import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUAL } from '../../hooks/ual'
import {
    SideNavigationHeader,
    SideNavigationHeaderWrapper,
} from './SideNavigationHeader'
import { SideNavigationItem } from './SideNavigationItem'
import { SideNavigationSubmenu } from './SideNavigationSubmenu'

export const SubMenu = ({
    active,
    icon,
    menuItems,
    onToggle,
    onLogin,
    loginRequired = false,
    open,
    title,
}) => {
    const { t } = useTranslation('common')
    const ual = useUAL()
    const loggedIn = !!ual?.activeUser
    const itemCount = !loginRequired || loggedIn ? menuItems.length : 1.25

    return (
        <SideNavigationHeaderWrapper active={active}>
            <SideNavigationHeader
                active={active}
                icon={icon}
                onClick={onToggle}
                open={open}
                title={title}
            />
            {loginRequired && !loggedIn ? (
                <SideNavigationSubmenu itemCount={itemCount} open={open}>
                    <div
                        className={cn('mt-2 mb-4 pl-8 cursor:pointer')}
                        onClick={onLogin}
                    >
                        {t('navigation.login')}
                    </div>
                </SideNavigationSubmenu>
            ) : (
                <SideNavigationSubmenu itemCount={itemCount} open={open}>
                    {menuItems.map(({ href, title, onClick }, i) => (
                        <SideNavigationItem
                            key={i}
                            onClick={onClick}
                            href={href}
                            title={t(title)}
                        />
                    ))}
                </SideNavigationSubmenu>
            )}
        </SideNavigationHeaderWrapper>
    )
}

export default SubMenu
