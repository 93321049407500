import { Tooltip } from '@mui/material'
import cn from 'classnames'
import React from 'react'

export const Button = ({
    children,
    className,
    disabled,
    type,
    disabledReason,
    ...props
}) => (
    <button
        className={cn(
            'focus:outline-none focus-visible:ring-1 focus-visible:ring-inset',
            'focus-visible:ring-primary',
            'transition-opacity duration-200',
            'opacity-75 hover:opacity-100',
            'cursor-pointer',
            { 'cursor-not-allowed': disabled },
            className,
        )}
        type={type || 'button'}
        disabled={disabled}
        {...props}
    >
        {disabled && disabledReason ? (
            <Tooltip title={disabled ? disabledReason : ''}>
                <div className={'m-auto'}>{children}</div>
            </Tooltip>
        ) : (
            <div className={'m-auto'}>{children}</div>
        )}
    </button>
)

export default Button
