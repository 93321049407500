import { useRouter } from 'next/router'
import React, { useContext } from 'react'
import { Context } from '../waxplorer'

const pagesWithBackgroundImage = [
    '/asset/[assetId]',
    '/listing/[assetId]',
    '/sale/[assetId]',
    '/auction/[assetId]',
    '/template/[templateId]',
]

const BackgroundImage = () => {
    const [state] = useContext(Context)
    const router = useRouter()

    const image = state.background_image
    const location = router.route

    if (!image) return null

    if (!pagesWithBackgroundImage.includes(location)) return null

    return (
        <div
            className="BackgroundImage"
            style={{ backgroundImage: `url(${image})` }}
        ></div>
    )
}

export default BackgroundImage
