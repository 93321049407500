import cn from 'classnames'
import React from 'react'
import { Img } from 'react-image'
import LazyLoad from '../helpers/LazyLoad'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'

const PreLoader = () => (
    <div className={cn('my-auto')}>
        <LoadingIndicator />
    </div>
)

export const ImageElement = ({ className, src, ...props }) => {
    return (
        <div className="flex h-full content-center m-auto">
            {src ? (
                <LazyLoad>
                    <Img
                        className={cn('m-auto h-auto ', className)}
                        loader={<PreLoader />}
                        src={src}
                        key={src}
                        {...props}
                    />
                </LazyLoad>
            ) : null}
        </div>
    )
}

export default ImageElement
