import React, { useCallback, useEffect, useRef } from 'react'
import {
    FacebookIcon,
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WeiboIcon,
    WeiboShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share'

const useOutsideAlerter = (ref, callback) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                event.preventDefault()
                event.stopPropagation()
                callback()
            }
        }
        // Bind the event listener
        document.addEventListener('click', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside)
        }
    }, [ref])
}

const OutsideAlerter = ({ callback, children }) => {
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, callback)
    return <div ref={wrapperRef}>{children}</div>
}

const SharePopup = ({ callBack: callback, link }) => {
    const close = useCallback(() => callback(false), [callback])

    return (
        <OutsideAlerter callback={() => close()}>
            <div className="SharePopup">
                <div className="ShareButton">
                    <img
                        src="/share-outline.svg"
                        alt="share"
                        onClick={() => close()}
                    />
                </div>
                <TelegramShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <TelegramIcon size={36} />
                </TelegramShareButton>
                <TwitterShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <TwitterIcon size={36} />
                </TwitterShareButton>
                <FacebookShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <FacebookIcon size={36} />
                </FacebookShareButton>
                <LineShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <LineIcon size={36} />
                </LineShareButton>
                <RedditShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <RedditIcon size={36} />
                </RedditShareButton>
                <WhatsappShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <WhatsappIcon size={36} />
                </WhatsappShareButton>
                <WeiboShareButton
                    url={link}
                    quote={
                        'NFTHive.io - NFT Market on the WAX Blockchain - Simpleassets & Atomicassets - All Markets'
                    }
                    hashtag="#nfts"
                >
                    <WeiboIcon size={36} />
                </WeiboShareButton>
            </div>
        </OutsideAlerter>
    )
}

export default SharePopup
