import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AssetPreviewDisplay from '../assetpreviewdisplay/AssetPreviewDisplay'
import { Card } from '../card/Card'
import Link from '../common/util/input/Link'
import get from '../helpers/Api'
import CollectionHeader from './CollectionHeader'
import MoreOptions from './MoreOptions'

function TemplatePreview(props) {
    const template = props['template']

    const index = props['index']
    const { t } = useTranslation('common')
    const ual = props['ual'] ? props['ual'] : { activeUser: '' }
    const activeUser = ual['activeUser']
    const [showMenu, setShowMenu] = useState(false)
    const [favored, setFavored] = useState(template['isFavorited'])

    const [frontVisible, setFrontVisible] = useState(true)

    const [priceInfo, setPriceInfo] = useState(null)

    const { templateId, author, idata, verified } = template

    let { authorImg, authorThumbnail, thumbnail, previmg, backimg, image } =
        template

    const assetData = idata ? JSON.parse(idata) : null

    let video = ''
    if (assetData && Object.keys(assetData).includes('video')) {
        video = assetData['video'].includes('http')
            ? assetData['video']
            : `https://ipfs.hivebp.io/video?hash=${assetData['video']}`
    }

    let name = template['name']

    if (!name) {
        name = assetData['name']
    }

    if (!image) {
        image = `https://ipfs.hivebp.io/thumbnail?hash=${
            assetData['img'] ? assetData['img'] : assetData['image']
        }`
    }

    const favor = () => {
        setFavored(true)
        var encodedString = window.btoa(activeUser['accountName'])

        get(`favor-template/${templateId}?key=${encodedString}`)
    }

    const unfavor = () => {
        setFavored(false)
        var encodedString = window.btoa(activeUser['accountName'])
        get(`unfavor-template/${templateId}?key=${encodedString}`)
    }

    if (!authorImg && verified) {
        authorImg =
            'https://ipfs.hivebp.io/thumbnail?hash=QmVVE5aLtq4EtMe4Khev5CSic3AKqX2RuNTAB79thvpqQW'
    } else if (!verified && authorImg) {
        authorImg = null
    }

    const getPriceInfo = async () => {
        if (!priceInfo) {
            //const pInfo = await get('price-template-info/' + templateId);
            //setPriceInfo(pInfo);
        }
    }

    const toggleShowMenu = () => {
        setShowMenu(!showMenu)
    }

    const loadBack = () => {
        setFrontVisible(false)
        getPriceInfo()
    }

    return (
        <Card id={'TemplatePreview_' + index}>
            <MoreOptions
                setShowMenu={setShowMenu}
                asset={template}
                ual={props['ual']}
            />
            <div
                onClick={toggleShowMenu}
                className={cn(
                    'absolute text-sm h-11 text-white right-2 top-2 z-10',
                )}
            >
                <img
                    className={cn(
                        'w-4 h-4',
                        {
                            'transition transform duration-250 rotate-90':
                                showMenu,
                        },
                        {
                            'transition transform duration-250 rotate-0':
                                !showMenu,
                        },
                    )}
                    src="/more.svg"
                    alt=""
                />
            </div>
            <div
                onClick={favored ? unfavor : favor}
                className={cn(
                    'absolute right-10 md:right-6 lg:right-10 top-m1 z-10',
                )}
            >
                <img
                    className={cn('h-5 md:h-4 lg:h-5')}
                    src={favored ? '/bookmark.svg' : '/bookmark-outline.svg'}
                    alt={t('search.favorites')}
                />
            </div>
            <div className={'bg-page relative'}>
                <div
                    className={cn(
                        'flex relative m-auto',
                        'w-56 md:w-40 xl:w-56',
                        'h-56 md:h-40 xl:h-56',
                    )}
                >
                    <Link href={'/template/' + templateId}>
                        <div className={cn('m-auto cursor-pointer')}>
                            <AssetPreviewDisplay
                                image={image}
                                index={index}
                                prevImg={thumbnail ? thumbnail : previmg}
                                video={video}
                                backImg={backimg}
                            />
                        </div>
                    </Link>
                </div>
            </div>
            <CollectionHeader
                author={author}
                authorImg={authorImg}
                authorThumbnail={authorThumbnail}
                verified={verified}
            />
            <div
                className={cn(
                    'h-8 md:h-4 lg:h-6 xl:h-10',
                    'mx-auto w-full flex justify-evenly',
                    'text-white cursor-pointer font-bold',
                    {
                        'text-sm md:text-2xs lg:text-2xs xl:text-xs leading-3':
                            name && name.length >= 52,
                    },
                    {
                        'text-sm md:text-2xs lg:text-2xs xl:text-xs':
                            name && name.length >= 30 && name.length < 50,
                    },
                    {
                        'text-base md:text-1xs lg:text-xs xl:text-base':
                            name && name.length < 20,
                    },
                )}
            >
                {name ? name : templateId}
            </div>
        </Card>
    )
}

export default TemplatePreview
