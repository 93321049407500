import SendIcon from '@mui/icons-material/Send'
import { Button, TextField } from '@mui/material'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getUser } from '../helpers/Api'
import { Context } from '../waxplorer'

export const TransferInput = () => {
    const [receiver, setReceiver] = useState(null)
    const [memo, setMemo] = useState(null)
    const [state, dispatch] = useContext(Context)
    const [isValidUser, setIsValidUser] = useState(undefined)

    const changeReceiver = (e) => {
        const val = e.target.value.trim().toLowerCase()
        setIsValidUser(undefined)
        setReceiver(val)
    }

    const changeMemo = (e) => {
        const val = e.target.value
        setMemo(val)
    }

    const handleTransferred = (transferred) => {
        const errors = transferred['errors']
        const transferItems = state.selectedAssets
        const transferredItems = {}
        const newSelected = []

        if (transferred['transferred']) {
            transferItems.map((item) => {
                if (
                    !errors ||
                    (!Object.keys(errors).includes(
                        item['assetId'].toString(),
                    ) &&
                        !Object.keys(errors).includes(
                            item['aAssetId'].toString(),
                        ))
                ) {
                    const localId =
                        item['standard'] === 'atomicassets'
                            ? item['aAssetId']
                            : item['assetId']
                    transferredItems[localId] = item
                    transferredItems[localId]['newOwner'] = receiver
                } else {
                    newSelected.push(item)
                }
            })
            dispatch({
                type: 'SET_TRANSFERRED_ASSETS',
                payload: transferredItems,
            })
            dispatch({
                type: 'SET_SELECTED_ASSETS',
                payload: newSelected,
            })
        }

        dispatch({
            type: 'SET_ERROR_ASSETS',
            payload: errors,
        })
    }

    const { t } = useTranslation('common')

    const transfer = async () => {
        const user = await getUser(receiver)
        setIsValidUser(user !== null)

        if (user !== null) {
            if (state.selectedAssets.length > 0) {
                const transferItems = {}
                for (let asset of state.selectedAssets) {
                    const localId =
                        asset['standard'] === 'atomicassets'
                            ? asset['aAssetId']
                            : asset['assetId']
                    transferItems[localId] = asset
                }
                dispatch({
                    type: 'SET_ASSETS',
                    payload: transferItems,
                })
                dispatch({ type: 'SET_RECEIVER', payload: receiver })
                dispatch({ type: 'SET_MEMO', payload: memo })
                dispatch({ type: 'SET_ACTION', payload: 'bulk_transfer' })
                dispatch({
                    type: 'SET_CALLBACK',
                    payload: (transferred) => handleTransferred(transferred),
                })
            }
        }
    }

    return (
        <div className={'flex flex-wrap w-full h-full mx-auto p-3'}>
            <div className={'w-full mb-4 font-bold text-lg'}>
                {t('search.transfer_x_items', {
                    x: state.selectedAssets.length,
                })}
            </div>
            <div
                className={
                    'w-full flex flex-col lg:flex-row lg:flex-wrap justify-center lg:justify-evenly'
                }
            >
                <div className="my-auto xl:w-48">
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label={t('asset.receiver')}
                        variant="outlined"
                        value={receiver}
                        onChange={changeReceiver}
                        spellCheck={false}
                        error={!receiver || isValidUser === false}
                        helperText={
                            receiver && isValidUser === false
                                ? 'This user does not exist'
                                : ' '
                        }
                    />
                </div>
                <div className="my-auto xl:w-96">
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label={t('asset.memo')}
                        variant="outlined"
                        value={memo}
                        onChange={changeMemo}
                        spellCheck={false}
                        helperText=" "
                    />
                </div>
                <div className="xl:mt-2">
                    <Button
                        fullWidth
                        disabled={!receiver || isValidUser === false}
                        variant="outlined"
                        size="large"
                        onClick={transfer}
                        endIcon={<SendIcon />}
                    >
                        {t('asset.send')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
