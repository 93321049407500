import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function UnstakeCpuNetPopup(props) {
    const cpu = props['cpu']
    const net = props['net']
    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const callBack = props['callBack']
    const userName = activeUser ? activeUser['accountName'] : null
    const [isLoading, setIsLoading] = useState(false)
    const [unstakeNet, setUnstakeNet] = useState(0)
    const [unstakeCpu, setUnstakeCpu] = useState(0)
    const closeCallBack = props['closeCallBack']
    const [error, setError] = useState()

    const { t } = useTranslation('common')

    const unstake = async () => {
        if ((!unstakeNet && !unstakeCpu) || unstakeNet < 0 || unstakeCpu < 0) {
            setError('Must at least unstake a positive amount of CPU or NET')
            return false
        }
        const quantity_net = unstakeNet ? unstakeNet : 0
        const quantity_cpu = unstakeCpu ? unstakeCpu : 0
        setIsLoading(true)
        try {
            const result = await activeUser.signTransaction(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'eosio',
                            name: 'undelegatebw',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser['requestPermission'],
                                },
                            ],
                            data: {
                                from: userName,
                                receiver: userName,
                                unstake_net_quantity: `${quantity_net.toFixed(
                                    8,
                                )} WAX`,
                                unstake_cpu_quantity: `${quantity_cpu.toFixed(
                                    8,
                                )} WAX`,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ wasStaked: true })
            closeCallBack()
        } catch (e) {
            console.log(e)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const changeNet = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) {
            if (val === '') {
                setUnstakeNet(0)
            } else {
                setUnstakeNet(Math.min(parseFloat(val), net))
            }
        }
    }

    const changeCpu = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) {
            if (val === '') {
                setUnstakeCpu(0)
            } else {
                setUnstakeCpu(Math.min(parseFloat(val), cpu))
            }
        }
    }

    const dismissError = () => {
        setError(null)
    }

    const cancel = () => {
        callBack({ wasStaked: false })
        closeCallBack()
    }

    return (
        <Popup title={'Unstake CPU / NET'} cancel={cancel}>
            <div className="mx-auto text-xl font-bold mb-4">
                Unstaking {unstakeCpu} WAX from CPU and {unstakeNet} WAX from
                NET
            </div>
            <div className={cn('flex')}>
                <input
                    className={cn(
                        'border-b-2 border-primary bg-transparent color-primary',
                    )}
                    type="text"
                    placeholder={'CPU'}
                    onChange={changeCpu}
                    value={unstakeCpu ? unstakeCpu : ''}
                />
                <div
                    className={'m-auto hover:underline cursor-pointer'}
                    onClick={() => setUnstakeCpu(cpu)}
                >
                    Available: {cpu ? cpu : 0}
                </div>
                <input
                    className={cn(
                        'border-b-2 border-primary bg-transparent color-primary',
                    )}
                    type="text"
                    placeholder={'NET'}
                    onChange={changeNet}
                    value={unstakeNet ? unstakeNet : ''}
                />
                <div
                    className={'m-auto hover:underline cursor-pointer'}
                    onClick={() => setUnstakeNet(net)}
                >
                    Available: {net ? net : 0}
                </div>
                <MainButton
                    className={'mt-0'}
                    onClick={unstake}
                    disabled={!unstakeNet && !unstakeCpu}
                >
                    Unstake
                </MainButton>
                <MainButton
                    className={'mt-0 border-neutral text-neutral'}
                    onClick={cancel}
                >
                    Cancel
                </MainButton>
            </div>

            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage error={error} />
                </div>
            ) : (
                ''
            )}
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default UnstakeCpuNetPopup
