import config from '../../config.json'
import { post } from './Api'

export const getBoostAction = (activeUser) => {
    const userName = activeUser.accountName

    return {
        account:
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? 'nfthiveboost'
                : 'nft.hive',
        name: 'boost',
        authorization: [
            {
                actor: userName,
                permission: activeUser.requestPermission,
            },
        ],
        data: {
            booster: userName,
        },
    }
}

function gtag_report_conversion(url) {
    var callback = function () {
        if (typeof url !== 'undefined') {
            // @ts-ignore
            window.location = url
        }
    }
    // @ts-ignore
    window.gtag('event', 'conversion', {
        send_to: 'AW-954346584/4DBICPaTrZADENjYiMcD',
        event_callback: callback,
    })
    return false
}

function gtag_report_conversion_purchase(value, url) {
    var callback = function () {
        if (typeof url !== 'undefined') {
            // @ts-ignore
            window.location = url
        }
    }
    // @ts-ignore
    window.gtag('event', 'conversion', {
        send_to: 'AW-954346584/V-6yCMKL2pkDENjYiMcD',
        value: value,
        currency: 'USD',
        transaction_id: '',
        event_callback: callback,
    })
    return false
}

export const logPurchase = (value) => {
    gtag_report_conversion_purchase(value)
}

export const performLogin = async (ual) => {
    gtag_report_conversion()
    ual.showModal()
}

export const swapTransaction = async (activeUser, standard, data) => {
    const userName = activeUser['accountName']

    await activeUser.signTransaction(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account:
                        standard === 'atomicassets' ? standard : 'simpleassets',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser['requestPermission'],
                        },
                    ],
                    data: data,
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const stakeAtomicAssets = async (activeUser, assetIds) => {
    const userName = activeUser.accountName

    await activeUser.signTransaction(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'atomicassets',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission,
                        },
                    ],
                    data: {
                        from: userName,
                        memo: 'stake',
                        asset_ids: assetIds,
                        to: 's.rplanet',
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const stakeSimpleAssets = async (activeUser, assetIds) => {
    const userName = activeUser.accountName

    return await activeUser.signTransaction(
        {
            actions: [
                getBoostAction(activeUser),
                {
                    account: 'simpleassets',
                    name: 'transfer',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser.requestPermission,
                        },
                    ],
                    data: {
                        from: userName,
                        to: 's.rplanet',
                        assetids: assetIds,
                        memo: 'stake',
                    },
                },
            ],
        },
        {
            expireSeconds: 300,
            blocksBehind: 0,
        },
    )
}

export const getWaxBuyOfferBalance = async (user) => {
    const body = {
        code: 'waxbuyoffers',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: user,
        upper_bound: user,
        reverse: 'true',
        scope: 'waxbuyoffers',
        show_payer: 'false',
        table: 'balances',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows[0] &&
        res.data.rows[0]['amount']
    ) {
        const row = res.data.rows[0]
        return row['amount'] ? parseFloat(row['amount'].replace(' WAX', '')) : 0
    } else {
        return 0
    }
}

export const getBrawler = async (user, brawler) => {
    const body = {
        code: 'bcbrawlers',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: brawler,
        upper_bound: brawler,
        reverse: 'true',
        scope: user,
        show_payer: 'false',
        table: 'brawlers',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows[0] &&
        res.data.rows[0]['available']
    ) {
        const row = res.data.rows[0]
        return {
            available: row['available'] ? row['available'] : 0,
            health: row['health'] ? row['health'] : 0,
            slotId: row['slot_id'] ? row['slot_id'] : 0,
        }
    } else {
        return 0
    }
}
