export const isDebugBuild = () => process.env.NODE_ENV !== 'production'

// should return true if the error in question was thrown by logging system
export const isOwnError = (error) => false // error && error.__sentry_own_request__

const objectToString = Object.prototype.toString
export const isBuiltin = (val, type) =>
    objectToString.call(val) === `[object ${type}]`

export const isErrorEvent = (val) => isBuiltin(val, 'ErrorEvent')

export const isError = (val) => {
    switch (objectToString.call(val)) {
        case '[object Error]':
        case '[object Exception]':
        case '[object DOMException]':
            return true
        default:
            return isInstanceOf(val, Error)
    }
}

export const isPlainObject = (val) => isBuiltin(val, 'Object')

export const isEvent = (val) =>
    typeof Event !== 'undefined' && isInstanceOf(val, Event)

export const isPrimitive = (val) =>
    val === null || (typeof val !== 'object' && typeof val !== 'function')

export const isInstanceOf = (val, base) => {
    try {
        return val instanceof base
    } catch (_e) {
        return false
    }
}

export const isFailedRequest = (response) =>
    response?.status && (response.status > 299 || response.status < 200)

export const isAbortError = (error) =>
    isBuiltin(error, 'DOMException') &&
    error.code === 20 &&
    error.name === 'AbortError'
