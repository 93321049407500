import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import MainButton from '../common/util/input/MainButton'
import { formatWAX } from '../helpers/FormatLinks'
import { getBoostAction, getWaxBuyOfferBalance } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function TopUpPopup(props) {
    const asset = props['asset']
    const { t } = useTranslation('common')

    const { author, name, image, templateId } = asset

    const video = image.includes('video:')
        ? config.ipfs + image.replace('video:', '')
        : ''

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']

    const callBack = props['callBack']

    const userName = activeUser ? activeUser['accountName'] : null
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [offer, setOffer] = useState((asset.offer - asset.balance).toString())
    const [showInput, setShowInput] = useState(true)
    const [userBalance, setUserBalance] = useState(0)

    useEffect(async () => {
        setUserBalance(await getWaxBuyOfferBalance(userName))
    }, [userName])

    const topup = async () => {
        if (!offer || offer === '0') return

        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        actions.push({
            account: 'eosio.token',
            name: 'transfer',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: {
                from: userName,
                to: 'waxbuyoffers',
                quantity: `${parseFloat(offer).toFixed(8)} WAX`,
                memo: 'deposit',
            },
        })

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ toppedUp: true, canceled: false, error: null })
        } catch (e) {
            callBack({ toppedUp: false, canceled: false, error: e.message })
            console.log(e)
            setError(e.message)
        } finally {
            closeCallBack()
            setIsLoading(false)
        }
    }

    const cancelOffer = async () => {
        setIsLoading(true)

        const { orderId } = asset

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'waxbuyoffers',
                            name: 'eraseoffer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser['requestPermission'],
                                },
                            ],
                            data: {
                                offer_id: orderId,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ toppedUp: false, canceled: true, error: null })
        } catch (e) {
            callBack({ toppedUp: false, canceled: false, error: e.message })
            console.log(e)
            setError(e.message)
        } finally {
            closeCallBack()
            setIsLoading(false)
        }
    }

    const cancel = () => {
        callBack({ toppedUp: false, canceled: false, error: null })
        closeCallBack()
    }

    const changePrice = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) {
            setOffer(val)
        }
    }

    return (
        <Popup title={name} cancel={cancel} image={image}>
            <div className={cn('text-center text-base m-4')}>
                Your Balance is not sufficient for this Buy Offer:{' '}
                <b className={'text-primary'}>{name}</b> by{' '}
                <b className={'text-primary'}>{author}</b> with the Template ID{' '}
                <b className={'text-primary'}>{templateId}</b>.<br />
            </div>
            <div className={cn('w-1/2 justify-center mx-auto')}>
                <div className={'grid grid-cols-2'}>
                    <div className={'text-left'}>Contract Balance:</div>
                    <div className={'text-left'}>
                        {userBalance ? formatWAX(userBalance) : 0}
                    </div>
                    <div className={'text-left'}>Offer:</div>
                    <div className={'text-left'}>{formatWAX(asset.offer)}</div>
                    <div className={'text-left'}>Top Up at least:</div>
                    <div className={'text-left'}>
                        {formatWAX(asset.offer - asset.balance)}
                    </div>
                </div>
                {offer && offer['missing'] && offer['missing'] > 0 ? (
                    <div className={'flex justify-between'}>
                        <div>Deposit:</div>
                        <div>{formatWAX(offer['missing'])}</div>
                    </div>
                ) : (
                    ''
                )}
            </div>
            {error ? <ErrorMessage error={error} /> : ''}
            <div
                className={cn('flex h-12 m-auto justify-end relative mt-auto')}
            >
                <div className="relative h-12 flex">
                    <div className="DropdownLabel my-auto">
                        Enter Top Up Amount:
                    </div>
                    <input
                        className={
                            showInput ? 'SellInput Memo' : 'SellInput Hidden'
                        }
                        type="text"
                        onChange={changePrice}
                        value={offer ? offer : ''}
                    />
                </div>
                <div className={'my-auto'}>
                    <MainButton
                        className="border border-neutral text-neutral ml-4"
                        onClick={cancel}
                    >
                        {t('asset.cancel')}
                    </MainButton>
                    <MainButton
                        disabled={
                            !offer ||
                            parseFloat(offer) < asset.offer - asset.balance
                                ? 'disabled'
                                : ''
                        }
                        onClick={topup}
                        className={'ml-4'}
                    >
                        Top Up
                    </MainButton>
                </div>
            </div>
            <div className={cn('text-center text-base m-4')}>
                Alternatively, cancel the Offer:
                <MainButton
                    className={cn('border border-card3 ml-4 text-card3')}
                    onClick={cancelOffer}
                >
                    Cancel Offer
                </MainButton>
            </div>

            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default TopUpPopup
