import cn from 'classnames'
import Link from '../common/util/input/Link'
import { performLogin } from '../helpers/WaxApi'
import Popup from './Popup'

const BuyWaxPopup = (props) => {
    const callBack = props['callBack']
    const closeCallBack = props['closeCallBack']
    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']

    const cancel = () => {
        callBack({ created: false, error: null })
        closeCallBack()
    }

    const desc = cn('text-lg font-bold')
    const exchangeTitle = cn('text-center align-top text-xl text-primary')
    const exchangeDetails = cn('text-center align-top')

    const marketplaceOption = (title, link, description) => {
        return (
            <div
                className={cn('pb-3 hover:text-primary-shine hover:font-bold')}
            >
                <Link href={link} external={true}>
                    <div className={exchangeTitle}>{title}</div>
                    <div className={exchangeDetails}>{description}</div>
                </Link>
            </div>
        )
    }

    return (
        <Popup
            cancel={cancel}
            image={
                'https://ipfs.hivebp.io/ipfs/QmRXzwkdZwApC3MYEEiyxzRU8MsymmvdTzPBZFx8rihG3N'
            }
        >
            {!activeUser ? (
                <div className={cn('flex justify-center')}>
                    <div className={cn('w-10/12')}>
                        <div className={desc}>
                            Before you can trade on the WAX Blockchain you will
                            need a wallet. <br />
                            For beginners we recommend the official WAX Cloud
                            Wallet.
                            <br />
                            Setting one up is quick, easy and free:
                        </div>
                        <Link
                            className={cn(
                                desc,
                                'text-primary cursor-pointer underline hover:font-bold hover:underline pb-5',
                            )}
                            href={'https://all-access.wax.io/'}
                            external={true}
                        >
                            all-access.wax.io
                        </Link>
                        <div className={desc}>Already have a wallet?</div>
                        <div
                            onClick={() => performLogin(ual)}
                            className={cn(
                                desc,
                                'text-primary cursor-pointer underline hover:font-bold hover:underline pb-5',
                            )}
                        >
                            Login
                        </div>
                    </div>
                </div>
            ) : (
                <div className={cn('pb-5')}>
                    <div className={cn(desc, 'pb-10')}>
                        In order to buy NFTs, you will need WAXP
                        <br />
                        You can buy WAXP at the following exchanges:
                    </div>
                    <div className={cn('grid grid-cols-2')}>
                        {marketplaceOption(
                            'MoonPay',
                            'https://www.moonpay.com/buy/wax',
                            'Not available in these US States: RI, HI, NY',
                        )}
                        {marketplaceOption(
                            'Simplex',
                            'https://buy.chainbits.com/',
                            'Not available in these US States: AK, HI, LA, NV, NM, WA, VT, NY',
                        )}
                        {marketplaceOption(
                            'Binance',
                            'https://www.binance.com/',
                            'Not available in the US.',
                        )}
                        {marketplaceOption(
                            'Crypto.com',
                            'https://crypto.com/exchange/trade/spot/WAXP_USDT/',
                            'Available worldwide.',
                        )}
                        {marketplaceOption(
                            'Bittrex',
                            'https://global.bittrex.com/Market/Index?MarketName=USD-WAXP/',
                            'Available worldwide.',
                        )}
                        {marketplaceOption(
                            'KuCoin',
                            'https://trade.kucoin.com/trade/WAX-BTC/',
                            'Available worldwide.',
                        )}
                        {marketplaceOption(
                            'Upbit',
                            'https://upbit.com/exchange?code=CRIX.UPBIT.BTC-WAXP',
                            'Korea Only.',
                        )}
                        {marketplaceOption(
                            'Huobi Global',
                            'https://www.huobi.com/en-us/exchange/waxp_btc/',
                            'Not available in the US.',
                        )}
                        {marketplaceOption(
                            'Bithumb',
                            'https://www.bithumb.com/trade/order/WAXP_KRW',
                            'Korea Only.',
                        )}
                        {marketplaceOption(
                            'ChangeNOW',
                            'https://changenow.io/de/currencies/wax',
                            'Available worldwide.',
                        )}
                    </div>
                </div>
            )}
        </Popup>
    )
}

export default BuyWaxPopup
