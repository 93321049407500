import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function WithdrawBuyOffersPopup(props) {
    const balance = props['balance']
    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const callBack = props['callBack']
    const userName = activeUser ? activeUser['accountName'] : null
    const [isLoading, setIsLoading] = useState(false)
    const [withdrawAmount, setWithdrawAmount] = useState(0)
    const closeCallBack = props['closeCallBack']
    const [error, setError] = useState()

    const { t } = useTranslation('common')

    const withdraw = async () => {
        if (!withdrawAmount || withdrawAmount < 0) {
            setError('Must at least withdraw a positive amount of WAX')
            return false
        }
        setIsLoading(true)
        try {
            const result = await activeUser.signTransaction(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'waxbuyoffers',
                            name: 'withdraw',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser['requestPermission'],
                                },
                            ],
                            data: {
                                account: userName,
                                amount: `${withdrawAmount.toFixed(8)} WAX`,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ wasWithdrawn: true })
            closeCallBack()
        } catch (e) {
            console.log(e)
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const changeWithdrawAmount = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) {
            if (val === '') {
                setWithdrawAmount(0)
            } else {
                const newAmount = Math.min(parseFloat(val), balance)

                setWithdrawAmount(newAmount)
            }
        }
    }

    const dismissError = () => {
        setError(null)
    }

    const cancel = () => {
        callBack({ wasWithdrawn: false })
        closeCallBack()
    }

    return (
        <Popup title={'Withdraw from the Buy Offer Contract'} cancel={cancel}>
            <div className={'flex h-5 my-2 justify-center text-lg'}>
                Withdraw {withdrawAmount} WAX from waxbuyoffers.
            </div>
            <div className={'flex h-5 m-auto justify-center'}>
                <div className={'w-5 h-5'}>
                    <img src={'/Warning_icn.svg'} />
                </div>
                <div className={'mx-4'}>
                    Withdrawing balance might cancel your active offers.
                </div>
            </div>
            <div className={cn('flex mt-4')}>
                <div
                    className={'m-auto hover:underline cursor-pointer'}
                    onClick={() => setWithdrawAmount(balance)}
                >
                    Available: {balance ? balance : 0} WAX
                </div>
                <input
                    className={cn(
                        'border-b-2 border-primary bg-transparent color-primary',
                    )}
                    type="text"
                    placeholder={'WAX'}
                    onChange={changeWithdrawAmount}
                    value={withdrawAmount ? withdrawAmount : ''}
                />
                <MainButton
                    className={cn('mt-0')}
                    onClick={withdraw}
                    disabled={!withdrawAmount}
                >
                    Withdraw
                </MainButton>
                <MainButton
                    className={'mt-0 border-neutral text-neutral'}
                    onClick={cancel}
                >
                    Cancel
                </MainButton>
            </div>

            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage error={error} />
                </div>
            ) : (
                ''
            )}
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default WithdrawBuyOffersPopup
