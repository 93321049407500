import Link from 'next/link'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { format, stringToDate } from '../../utils/date'
import { formatNumber, getAssetLink } from '../helpers/FormatLinks'

const useNotificationInfo = (type) => {
    const { t } = useTranslation('common')
    if (type === 'buy')
        return {
            notificationText: t('navigation.your_asset_was_bought'),
            info: t('navigation.price'),
        }
    if (type === 'bid')
        return {
            notificationText: t('navigation.you_received_a_new_bid'),
            info: t('asset.bid_noun'),
        }
    if (type === 'offer')
        return {
            notificationText: t('navigation.you_received_a_new_trade_offer'),
            info: t('asset.offer_id'),
        }

    return {
        notificationText: '',
        info: '',
    }
}

export const Notification = ({
    notification: {
        type,
        assetId,
        timestamp,
        price,
        image,
        prevImg,
        read,
        offerId,
        listingId,
    },
}) => {
    const { t } = useTranslation('common')

    const date = stringToDate(timestamp)
    const dateStr = date ? format(date) : ''

    const { info, notificationText } = useNotificationInfo(type)

    const [displayImg, setDisplayImg] = useState(prevImg ? prevImg : image)

    const replaceImage = () => {
        if (displayImg !== image) {
            setDisplayImg(image)
        }
    }

    return (
        <div className="Notification">
            <div className="NotificationMessage">{notificationText}</div>
            <div className="NotificationAsset">
                <div className="NotificationImage">
                    {displayImg.includes('video:') ? (
                        <video
                            width="60"
                            height="60"
                            loop
                            controls
                            autoPlay={true}
                            muted={true}
                            poster={image ? image : ''}
                        >
                            <source
                                src={`${config.ipfs}${displayImg.replace(
                                    'video:',
                                    '',
                                )}`}
                            />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <img src={displayImg} onError={replaceImage} />
                    )}
                </div>
                <table>
                    <tbody>
                        {type === 'offer' ? (
                            <tr>
                                <td>{t('trade.offer_id')}:</td>
                                <td>
                                    <Link href={'/trade?tab=received-offers'}>
                                        <div className="cursor-pointer">
                                            {offerId}
                                        </div>
                                    </Link>
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td>{t('navigation.asset_id')}:</td>
                                <td>
                                    {getAssetLink(
                                        assetId,
                                        null,
                                        null,
                                        null,
                                        listingId ? price : null,
                                        listingId,
                                    )}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td>{t('navigation.date')}:</td>
                            <td>{dateStr}</td>
                        </tr>
                        {type === 'offer' ? null : (
                            <tr>
                                <td>{info}:</td>
                                <td>{formatNumber(price)} WAX</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="NotificationInfo">
                    {read ? null : t('general.new')}
                </div>
            </div>
        </div>
    )
}

export default Notification
