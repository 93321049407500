import { useEffect } from 'react'

let listenerCallbacks = new WeakMap()

let observer

let observerInit = {
    rootMargin: '200px 0px 100px 0px',
    threshold: 0,
}

const handleIntersections = (entries) => {
    entries.forEach((entry) => {
        if (listenerCallbacks.has(entry.target)) {
            let cb = listenerCallbacks.get(entry.target)
            if (cb && (entry.isIntersecting || entry.intersectionRatio > 0)) {
                // @ts-ignore - observer is available, as it just called this function
                observer.unobserve(entry.target)
                listenerCallbacks.delete(entry.target)
                cb()
            }
        }
    })
}

export const updateIntersectionObserverInit = (newInit) => {
    observerInit = newInit
    // here should be a method to replace the old observer
}

const getIntersectionObserver = () => {
    if (observer === undefined)
        observer = new IntersectionObserver(handleIntersections, observerInit)
    return observer
}

export const useIntersection = (elem, callback) => {
    useEffect(() => {
        const target = elem.current
        if (!target) return

        const observer = getIntersectionObserver()
        listenerCallbacks.set(target, callback)
        observer.observe(target)

        return () => {
            listenerCallbacks.delete(target)
            observer.unobserve(target)
        }
    }, [callback, elem])
}
