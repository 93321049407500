import { Tooltip } from '@mui/material'
import cn from 'classnames'
import React, { useContext } from 'react'
import { format } from '../../utils/date'
import Link from '../common/util/input/Link'
import {
    formatNumber,
    formatPercentage,
    openSearchPopup,
} from '../helpers/FormatLinks'
import { Context } from '../waxplorer'

export const MarketInfo = ({
    asset,
    priceInfo,
    assets,
    bundle,
    currentAsset,
}) => {
    const {
        average,
        usd_average,
        last_sold,
        usd_last_sold,
        lowest,
        usd_lowest,
        revenue,
        usd_revenue,
        num_sales,
        craftingCost,
        buyer,
        seller,
        market,
        price,
        usd_price,
        date,
        sender,
        receiver,
        transactionId,
    } = asset

    const dateStr = format(date, null)

    const avgWAX =
        priceInfo && priceInfo['average'] ? priceInfo['average'] : average
    const avgUSD =
        priceInfo && priceInfo['average_usd']
            ? priceInfo['average_usd']
            : usd_average
    const lastSold =
        priceInfo && priceInfo['last_sold'] ? priceInfo['last_sold'] : last_sold
    const lastSoldUSD =
        priceInfo && priceInfo['last_sold_usd']
            ? priceInfo['last_sold_usd']
            : usd_last_sold
    const lowestWAX = priceInfo ? priceInfo['lowest'] : lowest
    const lowestUSD = priceInfo ? priceInfo['lowest_usd'] : usd_lowest
    const revenueWAX = priceInfo ? priceInfo['revenue'] : revenue
    const revenueUSD = priceInfo ? priceInfo['revenue_usd'] : usd_revenue
    const numSales = priceInfo ? priceInfo['num_sales'] : num_sales

    let diff = 0
    if (asset && asset['usd_offer'] && avgUSD)
        diff = asset['usd_offer'] / avgUSD - 1

    const classFirst = cn(
        'text-left text-primary font-bold md:font-semibold w-1/3 text-xs lg:text-1xs xl:text-xs 4xl:text-sm',
        {
            'leading-2 lg:leading-3 xl:leading-4 h3': !craftingCost,
        },
        {
            'leading-2 lg:leading-2 xl:leading-3 h2': craftingCost,
        },
    )
    const classLast = cn(
        'text-right text-neutral xl:h-auto w-2/3 text-xs lg:text-1xs xl:text-xs 4xl:text-sm',
        {
            'leading-2 lg:leading-3 xl:leading-4 h3': !craftingCost,
        },
        {
            'leading-2 lg:leading-2 xl:leading-3 h2': craftingCost,
        },
    )
    const classLink = classLast + ' cursor-pointer'
    const [state, dispatch] = useContext(Context)
    return buyer && seller && price ? (
        <div className={'flex flex-wrap mx-5 my-2 h-24'}>
            {buyer && <div className={classFirst}>Buyer:</div>}
            {buyer && (
                <div className={classLast}>
                    <Link href={'/user/' + buyer}>{buyer}</Link>
                </div>
            )}
            {seller && <div className={classFirst}>Seller:</div>}
            {seller && (
                <div className={classLink}>
                    <Link href={'/user/' + seller}>{seller}</Link>
                </div>
            )}
            {market && <div className={classFirst}>Market:</div>}
            {market && <div className={classLast}>{market}</div>}
            {price && <div className={classFirst}>Price:</div>}
            {price && (
                <div className={classLink}>
                    {formatNumber(price)} WAX / ${formatNumber(usd_price)}
                </div>
            )}
            {dateStr && <div className={classFirst}>Date:</div>}
            {dateStr && <div className={classLink}>{dateStr}</div>}
        </div>
    ) : receiver && sender ? (
        <div className={'flex flex-wrap mx-5 my-2 h-24'}>
            {sender && <div className={classFirst}>Sender:</div>}
            {sender && (
                <div className={classLast}>
                    <Link href={'/user/' + sender}>{sender}</Link>
                </div>
            )}
            {receiver && <div className={classFirst}>Receiver:</div>}
            {receiver && (
                <div className={classLink}>
                    <Link href={'/user/' + receiver}>{receiver}</Link>
                </div>
            )}
            {dateStr && <div className={classFirst}>Date:</div>}
            {dateStr && <div className={classLink}>{dateStr}</div>}
            {transactionId && <div className={classFirst}>Tx:</div>}
            {transactionId && (
                <div className={classLink}>
                    <Link
                        href={
                            'https://wax.bloks.io/transaction/' + transactionId
                        }
                        external={true}
                    >
                        {transactionId.substr(0, 20) + '...'}
                    </Link>
                </div>
            )}
        </div>
    ) : (
        <div className={'flex flex-wrap mx-5 my-2 h-24'}>
            {avgWAX && <div className={classFirst}>Median:</div>}
            {avgWAX && (
                <div
                    className={cn(classLast, 'flex justify-end h-fit-content')}
                >
                    {`${formatNumber(avgWAX)} WAX / $${formatNumber(avgUSD)}`}
                    {diff ? (
                        <Tooltip
                            title={
                                'waxbuyoffers' !== market
                                    ? diff > 0
                                        ? `Price is ${formatPercentage(
                                              diff,
                                          )} above Median`
                                        : `Price is ${formatPercentage(
                                              diff,
                                          )} below Median`
                                    : diff > 0
                                    ? `Offer is ${formatPercentage(
                                          diff,
                                      )} above Median`
                                    : `Offer is ${formatPercentage(
                                          diff,
                                      )} below Median`
                            }
                        >
                            <div
                                className={cn(
                                    {
                                        'text-negative':
                                            'waxbuyoffers' !== market
                                                ? diff > 0
                                                : diff < 0,
                                    },
                                    {
                                        'text-positive':
                                            'waxbuyoffers' !== market
                                                ? diff < 0
                                                : diff > 0,
                                    },
                                )}
                            >
                                &nbsp;
                                {diff
                                    ? ` (${
                                          diff > 0 ? '+' : ''
                                      }${formatPercentage(diff)})`
                                    : ''}
                            </div>
                        </Tooltip>
                    ) : (
                        ''
                    )}
                </div>
            )}

            {lastSold && <div className={classFirst}>Last Sold:</div>}

            {lastSold && (
                <div
                    className={classLink}
                    onClick={() =>
                        openSearchPopup(
                            'trades',
                            bundle ? assets[currentAsset] : asset,
                            dispatch,
                        )
                    }
                >
                    {formatNumber(lastSold)} WAX / ${formatNumber(lastSoldUSD)}
                </div>
            )}

            {!lowestWAX && revenue && <div className={classFirst}>Volume:</div>}
            {!lowestWAX && revenue && (
                <div className={classLast}>
                    {formatNumber(revenueWAX)} WAX / ${formatNumber(revenueUSD)}
                </div>
            )}

            {numSales ? <div className={classFirst}>Sales:</div> : ''}

            {numSales ? <div className={classLast}>{numSales}</div> : ''}

            {lowestWAX ? <div className={classFirst}>Floor:</div> : ''}

            {lowestWAX ? (
                <div
                    className={classLink}
                    onClick={() =>
                        openSearchPopup(
                            'sales',
                            bundle ? assets[currentAsset] : asset,
                            dispatch,
                        )
                    }
                >
                    {formatNumber(lowestWAX)} WAX / ${formatNumber(lowestUSD)}
                </div>
            ) : (
                ''
            )}

            {craftingCost && <div className={classFirst}>Crafting Cost:</div>}

            {craftingCost && (
                <div
                    className={classLink}
                    onClick={() =>
                        openSearchPopup(
                            'sales',
                            bundle ? assets[currentAsset] : asset,
                            dispatch,
                        )
                    }
                >
                    ~{formatNumber(craftingCost)} WAX
                </div>
            )}
        </div>
    )
}
