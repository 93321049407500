import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function CreatePoolPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const releaseId = props['packReleaseId']

    const [poolName, setPoolName] = useState('')

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const data = {
            authorized_account: userName,
            collection_name: collectionName,
            release_id: releaseId,
            pool_name: poolName,
        }

        const action = {
            account: 'nfthivepacks',
            name: 'createpool',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: data,
        }

        actions.push(action)

        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result.transactionId
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(!transferError, transferError ? [transferError] : null, {
            poolReleaseId: releaseId,
        })
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Create Pool" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to create a Pool in Release {releaseId} (
                {collectionName})?
            </div>
            <div className="PopupBody">
                <div className="flex justify-center">
                    <TextField
                        onChange={(e) =>
                            setPoolName(
                                e.target.value.toLowerCase().replace(' ', ''),
                            )
                        }
                        value={poolName}
                        label="Pool Name"
                        variant="outlined"
                        size="large"
                        error={poolName.length === 0}
                        required
                        helperText={
                            poolName.length === 0
                                ? 'Pool Name may not be empty'
                                : 'All lowercase, no spaces'
                        }
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                create()
                            }
                        }}
                    ></TextField>
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button
                        variant="outlined"
                        onClick={create}
                        disabled={poolName.length === 0}
                    >
                        Create Pool
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default CreatePoolPopup
