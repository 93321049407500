import { memo, useEffect } from 'react'
import { useUAL } from '../hooks/ual'

export const currentData = {}
export const Brigde = memo(() => {
    const ual = useUAL()

    useEffect(() => {
        currentData.accountName = ual?.activeUser?.accountName
    }, [ual?.activeUser?.accountName])
    return null
})

export const getCurrentData = () => currentData
