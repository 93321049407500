import { Tooltip } from '@mui/material'
import cn from 'classnames'
import React from 'react'
import Link from '../common/util/input/Link'

export const CollectionHeader = ({
    author,
    authorImg,
    authorThumbnail = authorImg,
    verified = false,
    blacklisted = false,
    displayName = author,
    href = '/collection/' + author,
}) => {
    const image =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE' ? authorImg : authorThumbnail
    return (
        <Link href={href} inactive={blacklisted}>
            <Tooltip
                title={
                    verified && !blacklisted
                        ? 'This collection is verified'
                        : blacklisted
                        ? 'This collection is blacklisted and potentially a Scam'
                        : ''
                }
            >
                <div
                    className={cn(
                        'h-6 md:h-4 xl:h-6 4xl:h-8 -top-3 md:-top-2 2xl:-top-3 4xl:-top-4',
                        'relative flex justify-center text-white cursor-pointer ',
                    )}
                >
                    <div
                        className={cn(
                            'flex w-auto border rounded-xl 4xl:rounded-2xl bg-page',
                            { 'border-primaryt': verified && !blacklisted },
                            { 'border-neutralt': !verified && !blacklisted },
                            { 'border-negative': blacklisted },
                        )}
                    >
                        {image && (
                            <div
                                className={cn(
                                    'flex w-5 mt-a-pix ml-a-pix',
                                    'md:w-3 xl:w-5 4xl:w-7 h-5 md:h-3 xl:h-5 4xl:h-7',
                                    'rounded-xl 4xl:rounded-3xl overflow-hidden',
                                )}
                            >
                                <img
                                    className={cn('m-auto')}
                                    src={image}
                                    alt=""
                                />
                            </div>
                        )}
                        <div
                            className={cn(
                                'text-center mx-2 my-auto bottom-2 h-full',
                            )}
                        >
                            <p
                                className={cn(
                                    'mb-auto h-full',
                                    {
                                        'leading-5 text-xs md:leading-3 md:text-2xs lg:text-1xs lg:leading-3 xl:leading-5 xl:text-xs 4xl:text-sm 4xl:leading-8':
                                            displayName &&
                                            displayName.length > 15,
                                    },
                                    {
                                        'leading-5 text-sm md:leading-3 md:text-1xs lg:text-xs lg:leading-3 xl:leading-5 xl:text-sm 4xl:text-base 4xl:leading-8':
                                            displayName &&
                                            displayName.length <= 15,
                                    },
                                )}
                            >
                                {displayName && displayName.length > 20
                                    ? displayName.substring(0, 20) + '...'
                                    : displayName}
                            </p>
                        </div>
                        {verified && !blacklisted && (
                            <div
                                className={cn(
                                    'w-5 md:w-3 xl:w-5 h-4 md:h-3 xl:h-4 4xl:h-5 4xl:w-6 my-auto',
                                )}
                            >
                                <img src={'/verified.svg'} alt="" />
                            </div>
                        )}
                        {blacklisted && (
                            <div
                                className={cn(
                                    'w-5 md:w-3 xl:w-5 h-4 md:h-3 xl:h-4 4xl:h-5 4xl:w-6 my-auto',
                                )}
                            >
                                <img src={'/blacklisted.svg'} alt="" />
                            </div>
                        )}
                    </div>
                </div>
            </Tooltip>
        </Link>
    )
}

export default CollectionHeader
