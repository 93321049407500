import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckIndicator from '../checkindicator/CheckIndicator'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function BulkStakePopup(props) {
    const assets = props['assets']

    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']

    const callBack = props['callBack']

    const userName = activeUser ? activeUser['accountName'] : null
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [errors, setErrors] = useState({})
    const [transferred, setTransferred] = useState([])
    const [transactions, setTransactions] = useState({})

    const addError = (assetId, e) => {
        errors[assetId] = e
        setErrors(errors)
    }

    const addTransaction = (assetId, tx) => {
        transactions[assetId] = tx
        setTransactions(transactions)
    }

    const transfer = async () => {
        setIsLoading(true)

        let transferError = null

        const simpleAssetIds = []
        const atomicAssetIds = []
        const actions = [getBoostAction(activeUser)]

        Object.keys(assets).map((assetId) => {
            const asset = assets[assetId]

            if (asset.standard === 'atomicassets')
                atomicAssetIds.push(asset.aAssetId)
            else if (asset.standard === 'simpleassets')
                simpleAssetIds.push(asset.assetId)
        })

        if (simpleAssetIds.length > 0) {
            actions.push({
                account: 'simpleassets',
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: {
                    from: userName,
                    to: 's.rplanet',
                    assetids: simpleAssetIds,
                    memo: 'stake',
                },
            })
        }

        if (atomicAssetIds.length > 0) {
            actions.push({
                account: 'atomicassets',
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: {
                    from: userName,
                    memo: 'stake',
                    asset_ids: atomicAssetIds,
                    to: 's.rplanet',
                },
            })
        }

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            Object.keys(assets)
                .sort((a, b) => parseInt(a.value) - parseInt(b.value))
                .map((assetId) =>
                    addTransaction(assetId, result['transactionId']),
                )
        } catch (e) {
            Object.keys(assets).map((asset) => addError(asset, e.message))
            console.log(e)
            setError(e.message)
            transferError = e
        } finally {
            if (!error && !transferError) {
                Object.keys(assets)
                    .sort((a, b) => parseInt(a.value) - parseInt(b.value))
                    .map((assetId) => transferred.push(assets[assetId]))
                setTransferred(true)
            }
        }
        setIsLoading(false)
        callBack({
            transferred: true,
            errors: errors,
            transactionIds: transactions,
        })
        closeCallBack()
    }

    let transferInfo = t('asset.stake')

    const transferField = (
        <MainButton className="PopupSellButton" onClick={transfer}>
            {transferInfo}
        </MainButton>
    )

    const cancel = () => {
        callBack({
            transferred: true,
            errors: errors,
            transactionIds: transactions,
        })
        closeCallBack()
    }

    return (
        <Popup title={t('asset.stake')} cancel={cancel}>
            <div className="PopupTitle">{}</div>
            {Object.keys(transferred).length > 0 ? <CheckIndicator /> : ''}
            {Object.keys(transferred).length === 0 ? (
                <div className="mx-auto text-xl font-bold mb-4">
                    {t('popups.are_you_sure_you_want_to_stake_assets', {
                        number: Object.keys(assets).length,
                    })}
                </div>
            ) : (
                ''
            )}
            {error ? <ErrorMessage error={error} /> : ''}
            {Object.keys(transferred).length === 0 ? (
                <div className="Buttons">
                    <MainButton className="PopupCancelButton" onClick={cancel}>
                        {t('asset.cancel')}
                    </MainButton>
                    {transferField}
                </div>
            ) : (
                ''
            )}
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default BulkStakePopup
