import cn from 'classnames'
import React from 'react'
import { Button } from './Button'

export const PopupButton = ({ className, ...props }) => (
    <Button
        className={cn(
            'w-24 md:w-20 h-8 md:h-6 lg:w-20 xl:w-32 xl:h-8 4xl:w-40 4xl:h-9 leading-6 bg-transparent',
            'text-sm md:text-1xs xl:text-sm 4xl:text-base leading-6 md:leading-5 xl:leading-6 4xl:leading-7',
            'font-bold border border-solid border-primary outline-none cursor-pointer',
            'hover:font-extrabold',
            { 'text-primary': !className || !className.includes('text-') },
            className,
        )}
        {...props}
    />
)

export default PopupButton
