import cn from 'classnames'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '../waxplorer'
import { CollectionHeaderPopups } from './CollectionHeaderPopups'
import { PopupImage } from './PopupImage'

export const Popup = ({
    children,
    cancel,
    image,
    title,
    asset,
    className = 'h-auto',
}) => {
    const { t } = useTranslation('common')

    const [, dispatch] = useContext(Context)

    const toggleImage = (img) => {
        dispatch({ type: 'FULL_IMAGE', payload: img })
    }

    return (
        <div
            className={cn(
                'relative z-40 w-11/12 md:w-4/5 justify-center lg:w-1/2 shadow-xl',
                'backdrop-blur-xl text-xs md:text-sm text-neutral overflow-y-auto',
                'bg-paperd rounded-sm md:rounded-2xl m-auto InsideScroll max-h-screen',
                className,
            )}
        >
            <div className="bg-page py-4 2xl:py-8">
                <div className="text-lg 2xl:text-2xl text-center m-2">
                    {title}
                </div>
                <img
                    className="absolute top-4 md:top-8 right-4 md:right-8 cursor-pointer z-50 h-4 2xl:h-6"
                    onClick={cancel}
                    src="/close_btn.svg"
                    alt={t('popups.wrapper.close-alt')}
                />
                {image && (
                    <PopupImage image={image} toggleImage={toggleImage} />
                )}
            </div>
            {asset && <CollectionHeaderPopups asset={asset} />}
            <div className="p-4 md:p-8">{children}</div>
        </div>
    )
}

export default Popup
