import cn from 'classnames'
import React from 'react'

export const LoadingIndicator = ({ text, margin = 'm-3' }) => {
    return text ? (
        <div className={cn('flex justify-center w-full m-auto')}>
            <div className={cn('flex w-16 justify-center my-auto', margin)}>
                <img src="/Loader.svg" className="RefreshIcon Loading" />
            </div>
            <div className={'text-base font-bold leading-10'}>{text}</div>
        </div>
    ) : (
        <div className={cn('flex justify-center w-full m-auto')}>
            <div className={cn('flex justify-center', margin)}>
                <img src="/Loader.svg" className="RefreshIcon Loading" />
            </div>
        </div>
    )
}

export default LoadingIndicator
