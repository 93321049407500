import cn from 'classnames'
import { gsap } from 'gsap'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../common/util/input/MainButton'
import ResultCard from './ResultCard'

function ResultList(props) {
    const results = props['results']['assets']
        ? props['results']['assets']
        : props['results']['templates']

    const close = props['close']

    const resultType = props['results']['assets'] ? 'assets' : 'templates'

    const el = useRef()
    const q = gsap.utils.selector(el)

    const { t } = useTranslation('common')

    const [cardCount, setCardCount] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(1)
    const [showDone, setShowDone] = useState(false)

    const angles = []
    for (let i = 0; i < 50; ++i) {
        angles[i] = Math.floor(Math.random() * 20) - 10
    }

    useEffect(() => {
        if (results && results.length) {
            gsap.to(q('.result-card'), {
                y: '-500%',
                duration: 0,
            })

            gsap.to(q('.result-card'), {
                y: '-50%',
                duration: 0.4,
                stagger: 1.2,
                yoyo: true,
                delay: 1,
            })

            setCardCount(results.length)
        }
    }, [results && results.length])

    useEffect(() => {
        if (cardCount > 0) {
            setTimeout(() => {
                setShowDone(true)
            }, cardCount * 1200 + 1000)
        }
    }, [cardCount])

    const onEnter = ({ currentTarget }) => {
        gsap.to(currentTarget, { scale: 1.03 })
    }

    const onLeave = ({ currentTarget }) => {
        gsap.to(currentTarget, { scale: 1 })
    }

    const onCardClick = ({ currentTarget }) => {
        setCurrentIndex(currentIndex + 1)

        // reset animation on last card
        if (currentIndex === cardCount) {
            gsap.to(currentTarget, {
                y: '-300%',
                duration: 0.66,
            })
            gsap.to(q('.result-card'), {
                y: '-50%',
                duration: 0.3,
                stagger: 0.6,
                yoyo: true,
                delay: 0.5,
            })

            setCurrentIndex(1)
        } else {
            gsap.to(currentTarget, {
                y: '-300%',
                duration: 0.66,
            })
        }
    }

    return (
        <div
            className={cn(
                'relative justify-center flex w-screen h-screen my-auto relative overflow-hidden',
            )}
        >
            <div
                className={cn(
                    'absolute h-screen w-72 md:w-144 top-1/2 left-1/2',
                    'transform',
                    '-translate-y-1/2 inset-y-1/2',
                    '-translate-x-1/2 inset-x-1/2',
                )}
            >
                {
                    <div className={cn('relative w-full h-screen')} ref={el}>
                        {angles &&
                            results &&
                            results.map((asset, index) => (
                                <div
                                    className={cn(
                                        'absolute top-1/2 left-0 w-full',
                                        'transform result-card cursor-pointer',
                                        {
                                            '-rotate-1':
                                                angles[
                                                    index % angles.length
                                                ] === -1,
                                        },
                                        {
                                            '-rotate-2':
                                                angles[
                                                    index % angles.length
                                                ] === -2,
                                        },
                                        {
                                            '-rotate-3':
                                                angles[
                                                    index % angles.length
                                                ] === -3,
                                        },
                                        {
                                            '-rotate-4':
                                                angles[
                                                    index % angles.length
                                                ] === -4,
                                        },
                                        {
                                            '-rotate-5':
                                                angles[
                                                    index % angles.length
                                                ] === -5,
                                        },
                                        {
                                            '-rotate-6':
                                                angles[
                                                    index % angles.length
                                                ] === -6,
                                        },
                                        {
                                            '-rotate-7':
                                                angles[
                                                    index % angles.length
                                                ] === -7,
                                        },
                                        {
                                            '-rotate-8':
                                                angles[
                                                    index % angles.length
                                                ] === -8,
                                        },
                                        {
                                            '-rotate-9':
                                                angles[
                                                    index % angles.length
                                                ] === -9,
                                        },
                                        {
                                            'rotate-1':
                                                angles[
                                                    index % angles.length
                                                ] === 1,
                                        },
                                        {
                                            'rotate-2':
                                                angles[
                                                    index % angles.length
                                                ] === 2,
                                        },
                                        {
                                            'rotate-3':
                                                angles[
                                                    index % angles.length
                                                ] === 3,
                                        },
                                        {
                                            'rotate-4':
                                                angles[
                                                    index % angles.length
                                                ] === 4,
                                        },
                                        {
                                            'rotate-5':
                                                angles[
                                                    index % angles.length
                                                ] === 5,
                                        },
                                        {
                                            'rotate-6':
                                                angles[
                                                    index % angles.length
                                                ] === 6,
                                        },
                                        {
                                            'rotate-7':
                                                angles[
                                                    index % angles.length
                                                ] === 7,
                                        },
                                        {
                                            'rotate-8':
                                                angles[
                                                    index % angles.length
                                                ] === 8,
                                        },
                                        {
                                            'rotate-9':
                                                angles[
                                                    index % angles.length
                                                ] === 9,
                                        },
                                    )}
                                    onMouseEnter={onEnter}
                                    onMouseLeave={onLeave}
                                    onClick={(evt) => {
                                        onCardClick(evt)
                                    }}
                                >
                                    <ResultCard
                                        {...props}
                                        index={index}
                                        asset={asset}
                                        key={index}
                                        resultType={resultType}
                                    />
                                </div>
                            ))}
                    </div>
                }
            </div>

            {showDone && (
                <div className={'absolute top-auto bottom-10'}>
                    <MainButton onClick={close} className={'mx-auto'}>
                        {t('asset.close')}
                    </MainButton>
                </div>
            )}
        </div>
    )
}

export default ResultList
