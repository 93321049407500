import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function EditCollectionPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const editDescription = props['editDescription']
    const editUniqueName = props['editUniqueName']
    const chainUniqueName = props['chainUniqueName']
    const editDisplayName = props['editDisplayName']
    const editUrl = props['editUrl']
    const editMarketFee = props['editMarketFee']
    const editImage = props['editImage']
    const newCollection = props['newCollection']

    const [error, setError] = useState(null)

    const edit = async () => {
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]

        const data = []

        data.push({
            key: 'description',
            value: [
                'string',
                editDescription
                    ? editDescription.replace('edit:Empty', '')
                    : '',
            ],
        })

        data.push({
            key: 'name',
            value: [
                'string',
                editDisplayName
                    ? editDisplayName.replace('edit:Empty', '')
                    : '',
            ],
        })

        data.push({
            key: 'img',
            value: [
                'string',
                editImage ? editImage.replace('edit:Empty', '') : '',
            ],
        })

        data.push({
            key: 'url',
            value: [
                'string',
                editUrl ? editUrl.replace('edit:Empty', '') : editUrl,
            ],
        })

        if (newCollection) {
            if (data.length > 0)
                actions.push({
                    account: 'atomicassets',
                    name: 'createcol',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser['requestPermission'],
                        },
                    ],
                    data: {
                        author: userName,
                        collection_name: editUniqueName,
                        allow_notify: true,
                        authorized_accounts: [userName],
                        notify_accounts: [],
                        market_fee: (parseFloat(editMarketFee) / 100.0).toFixed(
                            2,
                        ),
                        data: data,
                    },
                })
        } else {
            if (data.length > 0)
                actions.push({
                    account: 'atomicassets',
                    name: 'setcoldata',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser['requestPermission'],
                        },
                    ],
                    data: {
                        collection_name: chainUniqueName,
                        data: data,
                    },
                })

            if (editMarketFee)
                actions.push({
                    account: 'atomicassets',
                    name: 'setmarketfee',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser['requestPermission'],
                        },
                    ],
                    data: {
                        collection_name: chainUniqueName,
                        market_fee: (parseFloat(editMarketFee) / 100.0).toFixed(
                            2,
                        ),
                    },
                })
        }

        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result.transactionId
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            newCollection,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack({ transferred: false, errors: null, transactionIds: null })
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup
            title={t('collection.edit_collection')}
            cancel={cancel}
            image={
                editImage.startsWith(config.ipfs)
                    ? editImage
                    : `${config.ipfs}${editImage}`
            }
        >
            <div className="mx-auto text-xl font-bold mb-4">
                {t('popups.do_you_want_to_save_these_changes')}
            </div>
            <div className="PopupBody">
                <div className="PopupDetails">
                    <table>
                        <tbody>
                            {editUniqueName ? (
                                <tr>
                                    <td>
                                        <b>{t('collection.name')}:</b>
                                    </td>
                                    <td>
                                        {editUniqueName.replace(
                                            'edit:Empty',
                                            '',
                                        )}
                                    </td>
                                </tr>
                            ) : (
                                ''
                            )}
                            {editDisplayName ? (
                                <tr>
                                    <td>
                                        <b>{t('collection.display_name')}:</b>
                                    </td>
                                    <td>
                                        {editDisplayName.replace(
                                            'edit:Empty',
                                            '',
                                        )}
                                    </td>
                                </tr>
                            ) : (
                                ''
                            )}
                            {editDescription ? (
                                <tr>
                                    <td>
                                        <b>{t('collection.description')}:</b>
                                    </td>
                                    <td>
                                        {editDescription.replace(
                                            'edit:Empty',
                                            '',
                                        )}
                                    </td>
                                </tr>
                            ) : (
                                ''
                            )}
                            {editUrl ? (
                                <tr>
                                    <td>
                                        <b>{t('collection.url')}:</b>
                                    </td>
                                    <td>{editUrl.replace('edit:Empty', '')}</td>
                                </tr>
                            ) : (
                                ''
                            )}
                            {editMarketFee ? (
                                <tr>
                                    <td>
                                        <b>{t('collection.market_fee')}:</b>
                                    </td>
                                    <td>{editMarketFee}</td>
                                </tr>
                            ) : (
                                ''
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="Buttons">
                <MainButton className="PopupCancelButton" onClick={cancel}>
                    {t('asset.cancel')}
                </MainButton>
                <MainButton className="PopupEditButton" onClick={edit}>
                    {t('collection.save_collection')}
                </MainButton>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default EditCollectionPopup
