import cn from 'classnames'
import React from 'react'
import { Button } from './Button'

export const MainButton = ({ className, ...props }) => (
    <Button
        className={cn(
            'leading-6 bg-transparent rounded',
            'text-sm md:text-1xs xl:text-sm 4xl:text-base',
            'font-bold border border-solid border-primary outline-none cursor-pointer',
            'hover:font-extrabold',
            {
                'w-24 md:w-16 xl:w-24 4xl:w-32':
                    !className || !className.includes('w-'),
            },
            {
                'leading-6 md:leading-5 xl:leading-6 4xl:leading-7':
                    !className || !className.includes('leading-'),
            },
            {
                'h-8 md:h-6 xl:h-8 4xl:h-9':
                    !className || !className.includes(' h-'),
            },
            { 'text-primary': !className || !className.includes('text-') },
            className,
        )}
        {...props}
    />
)

export default MainButton
