import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function CreateDropPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const templateIds = props['dropTemplateIds']

    const dropName = props['dropName']
    const waxRam = props['waxRam']
    const dropDescription = props['dropDescription']
    const startDateTime = props['dropStartDateTime']
    const endDateTime = props['dropEndDateTime']
    const price = props['dropPrice']
    const currency = props['dropCurrency']
    const payoutRecipients = props['dropPayoutRecipient']
    const maxClaimableDrops = props['dropMaxClaimableDrops']
    const accountLimit = props['dropAccountLimit']
    const accountLimitCooldown = props['dropAccountLimitCooldown']
    const useAuthProof = props['dropUseAuthProof']

    const usePremint = props['dropUsePremint']

    const [error, setError] = useState(null)

    const settlement_symbol =
        price === 0
            ? '0,NULL'
            : currency.split(',')[0] + ',' + currency.split(',')[1]
    const price_symbol = currency.split(',')[2]
    const decimals = price_symbol === 'USD' ? 2 : currency.split(',')[0]
    const listing_price =
        price === 0
            ? '0 NULL'
            : `${Number(price).toFixed(decimals)} ${price_symbol}`

    const create = async () => {
        setIsLoading(true)

        const templateIdsToMint = []

        templateIds.map((templateId) =>
            templateIdsToMint.push({
                template_id: parseInt(templateId),
                tokens_to_back: [],
                // Hack to differentiate between preminted and on demand drops
                pool_id: usePremint ? 1 : 0,
            }),
        )

        const createDropActions = [getBoostAction(activeUser)]

        const createDropData = {
            authorized_account: userName,
            collection_name: collectionName,
            assets_to_mint: templateIdsToMint,
            listing_price: listing_price,
            settlement_symbol: settlement_symbol,
            price_recipients: payoutRecipients,
            auth_required: useAuthProof,
            is_hidden: false,
            max_claimable: maxClaimableDrops.toString(),
            account_limit: accountLimit.toString(),
            account_limit_cooldown: parseInt(accountLimitCooldown),
            start_time: startDateTime,
            end_time: endDateTime,
            display_data: JSON.stringify({
                name: dropName,
                description: dropDescription,
            }),
        }

        if (waxRam && parseFloat(waxRam) > 0) {
            createDropActions.push({
                account: 'eosio.token',
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: {
                    from: userName,
                    to: 'nfthivedrops',
                    quantity: `${parseFloat(waxRam).toFixed(8)} WAX`,
                    memo: 'deposit_collection_ram:' + collectionName,
                },
            })
        }

        const createDropAction = {
            account: 'nfthivedrops',
            name: 'createdrop',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: createDropData,
        }

        createDropActions.push(createDropAction)
        let createDropTransferError = null
        let createDropTransactionId = null

        try {
            const createDropResult = await activeUser.signTransaction(
                {
                    actions: createDropActions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            createDropTransactionId = createDropResult.transactionId
        } catch (e) {
            createDropTransferError = e.message
            setError(e.message)
        }

        if (!createDropTransferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !createDropTransferError,
            createDropTransferError ? [createDropTransferError] : null,
            createDropTransactionId,
        )

        return
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Create Drop" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to create a drop with these attributes?
            </div>
            <div className="PopupBody">
                <div className="PopupDetails">
                    <div className="grid grid-cols-2">
                        <div>Collection Name:</div>
                        <div>{collectionName}</div>
                        <div>Template IDs:</div>
                        <div>
                            {templateIds.map((id) => (
                                <a href={`/template/${id}`}>{id} </a>
                            ))}
                        </div>
                        <div>Name of the Drop:</div>
                        <div>{dropName.toString()}</div>
                        <div>Drop Description:</div>
                        <div>
                            {dropDescription
                                ? dropDescription
                                : `<no description>`}
                        </div>
                        <div>Start Date / Time (Unix Timestamp):</div>
                        <div>{startDateTime}</div>
                        <div>End Date / Time (Unix Timestamp):</div>
                        <div>{endDateTime.toString()}</div>
                        <div>Price per Drop Claim:</div>
                        <div>{listing_price}</div>
                        <div>Payout Recipients:</div>
                        {payoutRecipients.map((recipient) => (
                            <>
                                <div>
                                    <a
                                        href={`/user/${recipient.account}`}
                                        target="_blank"
                                    >
                                        {recipient.account} - {recipient.share}{' '}
                                        parts
                                    </a>
                                </div>
                                <div />
                            </>
                        ))}
                        <div></div>

                        <div>Max Claimable Drops:</div>
                        <div>
                            {maxClaimableDrops === 0
                                ? 'Infinite'
                                : maxClaimableDrops}
                        </div>
                        <div>Account Limit:</div>
                        <div>
                            {accountLimit === 0 ? 'Infinite' : accountLimit}
                        </div>
                        <div>Account Limit Cooldown:</div>
                        <div>
                            {accountLimitCooldown === 0
                                ? 'No Cooldown'
                                : accountLimitCooldown}
                        </div>
                        <div>Authorization Required?</div>
                        <div>
                            {useAuthProof
                                ? 'Yes (Set up in the Drop Overview)'
                                : 'No'}
                        </div>
                    </div>
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={create}>
                        Create Drop
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default CreateDropPopup
