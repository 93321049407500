export const checkIpfs = (ipfs) => {
    const isIPFS = require('is-ipfs')

    if (ipfs && ipfs.indexOf('/') === 46) {
        const hash = ipfs.substr(0, 46)

        return isIPFS.multihash(hash)
    } else {
        return isIPFS.multihash(ipfs)
    }
}
