import styled from '@emotion/styled'
import cn from 'classnames'
import React from 'react'

const SideNavigationSubmenuWrapper = styled.div`
    --count: ${(props) => props.itemCount};
    height: ${(props) =>
        props.open
            ? 'calc(var(--count) * var(--side-navigation-item-height))'
            : '0'};
`

export const SideNavigationSubmenu = ({ children, style, open, itemCount }) => (
    <SideNavigationSubmenuWrapper
        className={cn(
            'relative w-full pl-12 md:pl-9 xl:pl-12 ml-1 overflow-hidden mr-auto',
            'text-sm md:text-xs xl:text-sm text-neutral cursor:pointer transition-height duration-500',
            'flex flex-col justify-evenly items-start',
        )}
        style={style}
        aria-expanded={open}
        open={open}
        itemCount={itemCount}
    >
        {children}
    </SideNavigationSubmenuWrapper>
)

export default SideNavigationSubmenu
