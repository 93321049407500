import cn from 'classnames'
import Link from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from '../card/Card'
import MainButton from '../common/util/input/MainButton'

const NewBuyOfferAsset = ({ linkTarget = '/newoffer' }) => {
    const { t } = useTranslation('common')

    return (
        <Card>
            <Link href={linkTarget}>
                <div className={cn('flex flex-col h-full cursor-pointer')}>
                    <img
                        className={cn('h-1/2 w-full m-auto p-5')}
                        src={'/plus-square-outline.svg'}
                        alt={t('trade.new_buyoffer')}
                    />
                    <div className="h-1/2 w-full flex justify-center">
                        <MainButton
                            className={cn(
                                'text-primary rounded border border-primary outline-none text-sm font-normal',
                                'bg-transparent hover:font-bold my-auto mx-auto',
                            )}
                        >
                            {t('trade.new_buyoffer')}
                        </MainButton>
                    </div>
                </div>
            </Link>
        </Card>
    )
}

export default NewBuyOfferAsset
