import { Checkbox, ClickAwayListener, Popper, Tooltip } from '@mui/material'
import cn from 'classnames'
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useGet } from '../../hooks'
import { useUAL } from '../../hooks/ual'
import { get, post_admin } from '../helpers/Api'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import Transitions from '../ui/extended/Transitions'
import { Context } from '../waxplorer'
import { Notification } from './Notification'

const NOTIFICATION_INTERVAL = 60 * 1000 // every minute

export const useNotificationCount = (userName, hasNotifications) => {
    const { data: newNotifications, fetch } = useGet(
        userName ? `num-new-notifications/${userName}` : undefined,
    )

    useEffect(() => {
        if (hasNotifications) {
            const intervalId = setInterval(fetch, NOTIFICATION_INTERVAL)
            return () => clearInterval(intervalId)
        }
    }, [userName, hasNotifications])

    return hasNotifications && newNotifications?.num ? newNotifications.num : 0
}

export const Notifications = () => {
    const ual = useUAL()
    const userName = ual?.activeUser?.accountName
        ? ual.activeUser.accountName
        : null
    const { t } = useTranslation('common')

    const [showNotifications, setShowNotifications] = useState(false)

    const [state, dispatch] = useContext(Context)

    const hasNotifications = state.hasNotifications === userName

    const numNewNotifications = useNotificationCount(userName, hasNotifications)

    const [notificationUrl, setNotificationUrl] = useState()
    const { data: notifications, loading } = useGet(notificationUrl)

    const parseHasNotifications = (res) => {
        const data = res['data'] ? res['data'] : res
        if (data['hasNotifications'])
            dispatch({ type: 'SET_HAS_NOTIFICATIONS', payload: userName })
        else dispatch({ type: 'SET_HAS_NOTIFICATIONS', payload: '' })
    }

    useEffect(() => {
        if (userName && !hasNotifications) {
            get('has-notifications/' + userName).then(parseHasNotifications)
        }
    }, [userName, hasNotifications])

    const openNotifications = () => {
        if (userName) {
            setShowNotifications(true)
            setNotificationUrl(`notifications/${userName}`)
        }
    }

    const handleChangeNotifications = (e) => {
        post_admin(
            `toggle-notifications`,
            { userName: userName, enable: e.target.checked },
            userName,
            'RandomBS',
        ).then(parseHasNotifications)
    }

    const closeNotifications = useCallback(() => {
        if (!showNotifications) return
        setShowNotifications(false)
        setNotificationUrl(undefined)
    }, [showNotifications])

    const anchorRef = useRef(null)

    return (
        <>
            <div
                className={cn(
                    'relative flex cursor-pointer mx-auto px-0 md:px-4 py-2',
                )}
            >
                <div
                    className={cn(
                        'relative my-auto w-18 h-8 rounded bg-paperl py-1',
                        'hover:bg-primaryt2 transition-color duration-200 flex',
                    )}
                    ref={anchorRef}
                >
                    <Tooltip
                        title={
                            hasNotifications
                                ? t('navigation.disable_notifications')
                                : t('navigation.enable_notifications')
                        }
                    >
                        <Checkbox
                            color="neutral"
                            checked={hasNotifications}
                            onChange={handleChangeNotifications}
                        />
                    </Tooltip>
                    <div onClick={openNotifications}>
                        <img
                            className={cn(
                                'w-6 h-6 m-auto',
                                {
                                    'opacity-60':
                                        !showNotifications &&
                                        !numNewNotifications,
                                },
                                {
                                    'opacity-100':
                                        showNotifications ||
                                        numNewNotifications,
                                },
                            )}
                            src={
                                numNewNotifications
                                    ? '/notification.svg'
                                    : '/notification-none.svg'
                            }
                            alt="Notifications"
                            title={t('navigation.notifications')}
                        />
                        {numNewNotifications ? (
                            <div
                                className={cn(
                                    'absolute top-4 -right-2 flex justify-center h-5 w-5 bg-paper backdrop-filter',
                                    'backdrop-blur-xl border border-primary rounded-xl overflow-hidden m-auto px-0.5',
                                    'pt-0.25 pb-0.75',
                                    {
                                        'text-1xs leading-5':
                                            numNewNotifications >= 100,
                                    },
                                    {
                                        'text-xs leading-5':
                                            numNewNotifications >= 10 &&
                                            numNewNotifications < 100,
                                    },
                                    {
                                        'text-sm leading-4':
                                            numNewNotifications < 10,
                                    },
                                )}
                            >
                                {numNewNotifications}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

            <Popper
                placement="bottom"
                open={showNotifications}
                role={undefined}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 0],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={closeNotifications}>
                        <Transitions
                            in={showNotifications}
                            {...TransitionProps}
                        >
                            <div
                                className={cn(
                                    'absolute -left-36 md:left-auto top-1 md:-right-6 w-80 max-h-100',
                                    'overflow-y-auto z-index-100 bg-dark shadow',
                                )}
                            >
                                {loading ? (
                                    <LoadingIndicator />
                                ) : notifications &&
                                  notifications.length > 0 ? (
                                    notifications.map((notification, i) => (
                                        <Notification
                                            key={i}
                                            notification={notification}
                                        />
                                    ))
                                ) : (
                                    <div
                                        className={
                                            'w-full flex justify-center my-4'
                                        }
                                    >
                                        {t('navigation.no_notifications')}
                                    </div>
                                )}
                            </div>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    )
}

export default Notifications
