import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PopupButton from '../common/util/input/PopupButton'
import get from '../helpers/Api'
import { formatNumber } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function EditPopup(props) {
    const asset = props['asset']

    let {
        schema,
        number,
        variant,
        rarity,
        author,
        assetId,
        image,
        name,
        offer,
        bundle,
        assets,
    } = asset

    const assetIds = []

    if (bundle) {
        const a = assets[0]
        assetId = a.assetId
        assets.forEach((asset) => assetIds.push(asset.assetId))
        schema = a.schema
        variant = a.variant
        rarity = a.rarity
        author = a.author
        number = a.number
        name = `${a.name} ${
            assets.length > 1 ? `(Bundle of ${assets.length})` : '(In a Bundle)'
        }`
        image = a.image
    } else {
        if (assetId) assetIds.push(assetId)
    }

    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']

    const callBack = props['callBack']

    const userName = activeUser ? activeUser['accountName'] : null
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const closeCallBack = props['closeCallBack']
    const [price, setPrice] = useState(offer)
    const [priceInfo, setPriceInfo] = useState(null)

    const loadPriceInfo = async (assetId) => {
        return await get('price-info/' + assetId)
    }

    const getOrderId = async (assetId, market, bundle) => {
        const orderId = await get(
            'get-order-id/' +
                (market ? market + '/' : '') +
                assetId +
                '?bundle=' +
                bundle,
        )
        return orderId ? orderId['orderId'] : null
    }

    useEffect(() => {
        loadPriceInfo(assetId).then((res) => setPriceInfo(res))
    }, [assetId])

    const cancelSale = async () => {
        setIsLoading(true)

        let { market, orderId, owner } = asset

        let newOrderId = null

        if (assetId || (assetIds.length > 0 && assetIds[0]))
            newOrderId = await getOrderId(
                assetId ? assetId : assetIds[0],
                market ? market : owner,
                assets && assets.length > 1,
            )

        if (newOrderId) orderId = newOrderId

        const actions = [getBoostAction(activeUser)]

        if (market === 'atomicmarket') {
            actions.push({
                account: market,
                name: 'cancelsale',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: {
                    sale_id: orderId,
                },
            })
        }

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
        } catch (e) {
            console.log(e)
            callBack({ edited: false, canceled: false, error: e.message })
        }

        setIsLoading(false)
        callBack({
            edited: false,
            canceled: true,
            error: null,
        })
        closeCallBack()
    }

    const editSale = async () => {
        setIsLoading(true)

        let { orderId, market, owner } = asset
        const actions = [getBoostAction(activeUser)]

        let newOrderId = null

        if (assetId || (assetIds.length > 0 && assetIds[0]))
            newOrderId = await getOrderId(
                assetId ? assetId : assetIds[0],
                market ? market : owner,
                assets && assets.length > 1,
            )

        if (newOrderId) orderId = newOrderId

        actions.push({
            account: 'atomicmarket',
            name: 'cancelsale',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: {
                sale_id: orderId,
            },
        })
        actions.push({
            account: 'atomicmarket',
            name: 'announcesale',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: {
                seller: userName,
                maker_marketplace:
                    process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                        ? 'nfthiveboost'
                        : 'nft.hive',
                settlement_symbol: '8,WAX',
                asset_ids: assetIds,
                listing_price: parseFloat(price).toFixed(8) + ' WAX',
            },
        })
        actions.push({
            account: 'atomicassets',
            name: 'createoffer',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: {
                sender: userName,
                recipient: 'atomicmarket',
                sender_asset_ids: assetIds,
                recipient_asset_ids: [],
                memo: 'sale',
            },
        })

        let error = null

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
        } catch (e) {
            console.log(e)
            error = e.message
            callBack({ edited: false, canceled: false, error: e.message })
        }

        setIsLoading(false)
        if (!error)
            callBack({
                edited: price,
                canceled: false,
                error: null,
            })
        closeCallBack()
    }

    const changePrice = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) setPrice(val)
    }

    const cancel = () => {
        callBack({
            edited: false,
            wasCanceled: false,
            error: null,
        })
        closeCallBack()
    }

    return (
        <Popup title={'Edit - ' + name} cancel={cancel} image={image}>
            <div className="PopupBody">
                <div className="PopupDetails">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <b>{t('asset.schema')}:</b>
                                </td>
                                <td>{schema}</td>
                            </tr>
                            {number && variant ? (
                                <tr>
                                    <td>
                                        <b>{t('asset.card')}:</b>
                                    </td>
                                    <td>
                                        {number}
                                        {variant}
                                    </td>
                                </tr>
                            ) : (
                                ''
                            )}
                            {rarity ? (
                                <tr>
                                    <td>
                                        <b>{t('asset.rarity')}:</b>
                                    </td>
                                    <td>{rarity}</td>
                                </tr>
                            ) : (
                                ''
                            )}
                            <tr>
                                <td>
                                    <b>{t('asset.author')}:</b>
                                </td>
                                <td>{author}</td>
                            </tr>
                            {bundle ? (
                                ''
                            ) : (
                                <tr>
                                    <td>
                                        <b>
                                            {t('asset.lowest_available_price')}:
                                        </b>
                                    </td>
                                    <td>
                                        {!priceInfo
                                            ? 'Loading...'
                                            : priceInfo['lowest']
                                            ? `${formatNumber(
                                                  priceInfo['lowest'],
                                              )} WAX`
                                            : ''}
                                        {!priceInfo ? (
                                            ''
                                        ) : (
                                            <span className="usd">
                                                (
                                                {!priceInfo
                                                    ? `$${formatNumber(
                                                          priceInfo[
                                                              'lowest_usd'
                                                          ],
                                                      )}`
                                                    : '-'}
                                                )
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            )}
                            {bundle ? (
                                ''
                            ) : (
                                <tr>
                                    <td>
                                        <b>{t('asset.average_bought')}:</b>
                                    </td>
                                    <td>
                                        {!priceInfo
                                            ? 'Loading...'
                                            : priceInfo['average']
                                            ? `${
                                                  Math.round(
                                                      priceInfo['average'] *
                                                          100,
                                                  ) / 100
                                              } WAX`
                                            : ''}
                                        {!priceInfo ? (
                                            ''
                                        ) : priceInfo['average_usd'] ? (
                                            <span className="usd">
                                                /$
                                                {formatNumber(
                                                    priceInfo['average_usd'],
                                                )}
                                            </span>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                </tr>
                            )}
                            {
                                <tr>
                                    <td>
                                        <b>
                                            {t('asset.lowest_available_price')}:
                                        </b>
                                    </td>
                                    <td>
                                        {!priceInfo
                                            ? 'Loading...'
                                            : priceInfo['lowest']
                                            ? `${formatNumber(
                                                  priceInfo['lowest'],
                                              )} WAX`
                                            : ''}
                                        {!priceInfo ? (
                                            ''
                                        ) : (
                                            <span className="usd">
                                                (
                                                {priceInfo['lowest_usd']
                                                    ? `$${formatNumber(
                                                          priceInfo[
                                                              'lowest_usd'
                                                          ],
                                                      )}`
                                                    : '-'}
                                                )
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            }
                            {
                                <tr>
                                    <td>
                                        <b>{t('asset.last_sold')}:</b>
                                    </td>
                                    <td>
                                        {!priceInfo
                                            ? 'Loading...'
                                            : priceInfo['last_sold']
                                            ? `${
                                                  Math.round(
                                                      priceInfo['last_sold'] *
                                                          100,
                                                  ) / 100
                                              } WAX`
                                            : ''}
                                        {!priceInfo ? (
                                            ''
                                        ) : priceInfo['last_sold_usd'] ? (
                                            <span className="usd">
                                                /$
                                                {formatNumber(
                                                    priceInfo['last_sold_usd'],
                                                )}
                                            </span>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {error ? <ErrorMessage error={error} /> : ''}

            <div className="grid grid-cols-2 lg:grid-cols-4">
                <div className={'flex m-auto'}>
                    <div className={'leading-7 w-18'}>New Price:</div>
                    <input
                        className={cn(
                            'outline-none ml-2 border-primary border-b w-16 bg-transparent',
                            'bg-wax bg-no-repeat bg-opacity-50 bg-25% bg-right bg-no-repeat',
                        )}
                        type="text"
                        placeholder={t('asset.price')}
                        onChange={changePrice}
                        value={price ? price : ''}
                    />
                </div>
                <PopupButton
                    className={cn(
                        'text-primary rounded border border-primary outline-none text-sm align-middle font-normal',
                        'bg-transparent cursor-pointer hover:font-bold m-auto',
                    )}
                    disabled={!price && 'disabled'}
                    onClick={editSale}
                >
                    {'Set Price'}
                </PopupButton>
                <PopupButton
                    className={cn(
                        'text-negative rounded border border-negative outline-none text-sm align-middle font-normal',
                        'bg-transparent cursor-pointer hover:font-bold m-auto',
                    )}
                    onClick={cancelSale}
                >
                    {'Cancel Listing'}
                </PopupButton>
                <PopupButton
                    className={cn(
                        'text-white rounded border border-white outline-none text-sm align-middle font-normal',
                        'bg-transparent cursor-pointer hover:font-bold m-auto',
                    )}
                    onClick={cancel}
                >
                    {'Back'}
                </PopupButton>
            </div>
            {isLoading ? (
                <div className="Overlay">
                    <LoadingIndicator text={'Loading Transactions'} />
                </div>
            ) : (
                ''
            )}
        </Popup>
    )
}

export default EditPopup
