import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function UpdateDropAssetsPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const templateIds = props['dropTemplateIds']

    const dropId = props['dropId']
    const createPool = props['createPool']

    const premintedAssetIds = props['dropPremintedAssetIds']
    const assetsToTransfer = props['dropAssetsToTransfer']

    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const premintActions = [getBoostAction(activeUser)]

        // Create the pool if necessary
        if (createPool) {
            const createPoolData = {
                authorized_account: userName,
                drop_id: dropId,
            }

            const createPoolAction = {
                account: 'nfthivedrops',
                name: 'createpool',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: createPoolData,
            }

            premintActions.push(createPoolAction)
        }

        // Transfer user assets if necessary
        if (assetsToTransfer && assetsToTransfer.length > 0) {
            const transferAssetsData = {
                authorized_account: userName,
                asset_ids: assetsToTransfer,
                from: userName,
                to: 'nfthivedrops',
                memo: 'deposit',
            }

            const transferAssetsAction = {
                account: 'atomicassets',
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: transferAssetsData,
            }

            premintActions.push(transferAssetsAction)
        }

        // Add asset ids
        const addAssetsData = {
            authorized_account: userName,
            drop_id: dropId,
            asset_ids: premintedAssetIds,
        }

        const addAssetsAction = {
            account: 'nfthivedrops',
            name: 'addassets',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: addAssetsData,
        }

        premintActions.push(addAssetsAction)

        // Set pool to ready
        const setPoolReadyData = {
            authorized_account: userName,
            drop_id: dropId,
        }

        const setPoolReadyAction = {
            account: 'nfthivedrops',
            name: 'setready',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: setPoolReadyData,
        }

        premintActions.push(setPoolReadyAction)

        let premintError = null
        let premintTransactionId = null

        try {
            const premintResult = await activeUser.signTransaction(
                {
                    actions: premintActions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            premintTransactionId = premintResult.transactionId
        } catch (e) {
            premintError = e.message
            setError(e.message)
        }

        if (premintError) {
            setIsLoading(false)
            callBack(false, [premintError], premintTransactionId)
        }

        // Finally done
        if (!premintError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !premintError,
            premintError ? [premintError] : null,
            premintTransactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Create Drop" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to update the assets for this drop?
            </div>
            <div className="PopupBody">
                <div className="PopupDetails">
                    <div className="grid grid-cols-2">
                        <div>Collection Name:</div>
                        <div>{collectionName}</div>
                        <div>Template IDs:</div>
                        <div>
                            {templateIds.map((id) => (
                                <a href={`/template/${id}`}>{id} </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={create}>
                        Update Assets
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default UpdateDropAssetsPopup
