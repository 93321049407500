import DeleteIcon from '@mui/icons-material/Delete'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Collapse,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import cn from 'classnames'
import ReactMde from 'react-mde'
import config from '../../config.json'
import Link from '../common/util/input/Link'
import { post } from '../helpers/Api'
import { checkIpfs } from '../helpers/Validators'
import ImageElement from '../imageelement/ImageElement'
import MarkDown from '../markdown/MarkDown'
import { floatRegEx } from './EditorComponents'

export const dropDetailsEditor = (
    dropName,
    setDropName,
    editorOpen,
    setEditorOpen,
    dropDescription,
    setDropDescription,
    isActuallyAboutPacks = false,
) => {
    return (
        <div
            className={cn(
                'border rounded-2xl p-5 my-10',
                dropName.length === 0 ? 'border-red-600' : '',
            )}
        >
            <div className="text-2xl mb-8">
                {!isActuallyAboutPacks ? 'Drop' : 'Pack'} Details
            </div>
            <div className="mb-10">
                <TextField
                    label={`${!isActuallyAboutPacks ? 'Drop' : 'Pack'} Name`}
                    value={dropName}
                    required={true}
                    error={dropName.length === 0}
                    onChange={(e) => setDropName(e.target.value)}
                    helperText={`The name of this ${
                        !isActuallyAboutPacks ? 'Drop' : 'Pack'
                    } (e.g. 'Legendary Item Collection')`}
                />
            </div>
            <div className="text-xl mb-5">
                {!isActuallyAboutPacks ? 'Drop' : 'Pack'} Description (optional,
                recommended)
            </div>
            <div
                className={cn(
                    'text-left',
                    editorOpen || dropDescription ? 'mb-5' : 'mb-10',
                )}
            >
                <Button
                    size="large"
                    variant="outlined"
                    color={
                        !editorOpen && !dropDescription ? 'warning' : 'primary'
                    }
                    onClick={() => setEditorOpen(!editorOpen)}
                >
                    {editorOpen
                        ? `Close`
                        : dropDescription
                        ? `Edit`
                        : `Add Description`}
                </Button>
            </div>

            <Collapse in={editorOpen}>
                <div className="text-black mb-5 border-8 border-graphbg rounded-md">
                    <ReactMde
                        value={dropDescription}
                        onChange={setDropDescription}
                        disablePreview={true}
                    />
                </div>
            </Collapse>

            <Collapse in={dropDescription}>
                <div>
                    <div className="mb-2">Preview:</div>
                    <div className="border rounded-md p-5 mb-5">
                        <MarkDown text={dropDescription} />
                    </div>
                </div>
            </Collapse>

            <Collapse in={!editorOpen && !dropDescription}>
                <div className="mb-5">
                    <Alert severity="warning">
                        We strongly recommend adding a Description for your{' '}
                        {!isActuallyAboutPacks ? 'Drop' : 'Pack'}!
                    </Alert>
                </div>
            </Collapse>

            <Collapse in={dropName.length === 0}>
                <div className="mb-5">
                    <Alert severity="error">
                        {!isActuallyAboutPacks ? 'Drop' : 'Pack'} name is
                        missing
                    </Alert>
                </div>
            </Collapse>
        </div>
    )
}

const circularProgressWithLabel = (value) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={value} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    )
}

const getRecipientSplitPercentage = (recipientSplits, index) => {
    const total = recipientSplits.reduce((acc, curr) => acc + curr, 0)
    if (total === 0) return 0
    const split = recipientSplits[index]
    const splitPercentage = (split / total) * 100
    return splitPercentage
}

const getRecipientSplitTotal = (recipientSplits, index, dropPrice) => {
    const total = recipientSplits.reduce((acc, curr) => acc + curr, 0)
    if (total === 0) return 0
    const split = recipientSplits[index]
    const splitTotal = (split / total) * dropPrice
    return splitTotal
}

export const isDropPriceInvalid = (
    dropPrice,
    dropPriceCurrency,
    payoutRecipients,
) => {
    if (!floatRegEx.test(dropPrice)) return 'Drop price must be a number'
    if (!dropPriceCurrency) return 'Drop price must have a currency'
    if (payoutRecipients.length === 0)
        return 'Drop must have at least one recipient'
    for (const recipient of payoutRecipients) {
        if (recipient.trim().length === 0)
            return 'Payout Recipient cannot be empty'
    }
    const tempSet = new Set(payoutRecipients)
    if (tempSet.size !== payoutRecipients.length)
        return 'Recipients must be unique'
}

export const dropPriceEditor = (
    dropPrice,
    setDropPrice,
    dropPriceCurrency,
    setDropPriceCurrency,
    payoutRecipients,
    setPayoutRecipients,
    currencies,
    recipientSplits,
    setRecipientSplits,
) => {
    return (
        <div
            className={cn(
                'border rounded-2xl p-5 my-10',
                isDropPriceInvalid(
                    dropPrice,
                    dropPriceCurrency,
                    payoutRecipients,
                )
                    ? 'border-red-600'
                    : '',
            )}
        >
            <div className="text-2xl mb-8">Price and Payment details</div>
            <div className="flex my-5">
                <div className="mr-5">
                    <TextField
                        label="Price per drop"
                        value={dropPrice}
                        required={true}
                        error={!floatRegEx.test(dropPrice)}
                        onChange={(e) => setDropPrice(e.target.value)}
                        helperText="The amount the user pays per drop (0 = Drop is free to Claim)"
                    />
                </div>
                <div className="w-32">
                    <FormControl fullWidth={true}>
                        <InputLabel>Currency</InputLabel>
                        <Select
                            value={dropPriceCurrency}
                            label="Currency"
                            onChange={(e) =>
                                setDropPriceCurrency(e.target.value)
                            }
                        >
                            {currencies.map((currency) => (
                                <MenuItem
                                    value={
                                        currency.symbol + ',' + currency.label
                                    }
                                >
                                    {currency.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div>
                <div className="mb-2">
                    The recipient{payoutRecipients.length > 1 ? 's' : ''} of the
                    payment for each drop
                </div>
                {payoutRecipients.map((recipient, i) => (
                    <div className="mb-5 flex">
                        <TextField
                            size="small"
                            label="Payout to"
                            value={recipient}
                            required={true}
                            error={
                                !recipient ||
                                recipient.length === 0 ||
                                payoutRecipients.length === 0
                            }
                            onChange={(e) => {
                                const tempPayoutRecipients = [
                                    ...payoutRecipients,
                                ]
                                tempPayoutRecipients[i] = e.target.value
                                setPayoutRecipients(tempPayoutRecipients)
                            }}
                        />

                        <Collapse in={payoutRecipients.length > 1}>
                            <div className="flex">
                                <div className="mt-2 mx-2">receives</div>
                                <div className="w-16">
                                    <TextField
                                        size="small"
                                        value={recipientSplits[i]}
                                        required={true}
                                        error={
                                            !floatRegEx.test(recipientSplits[i])
                                        }
                                        onChange={(e) => {
                                            const tempRecipientSplits = [
                                                ...recipientSplits,
                                            ]
                                            tempRecipientSplits[i] = Number(
                                                e.target.value,
                                            )
                                            setRecipientSplits(
                                                tempRecipientSplits,
                                            )
                                        }}
                                    />
                                </div>
                                <div className="mt-2 mx-2">parts:</div>
                                <div className="my-auto mx-2">
                                    {circularProgressWithLabel(
                                        getRecipientSplitPercentage(
                                            recipientSplits,
                                            i,
                                        ),
                                    )}
                                </div>
                                <div className="mt-2 mx-2">
                                    {`${getRecipientSplitTotal(
                                        recipientSplits,
                                        i,
                                        dropPrice,
                                    )}
                                                ${
                                                    dropPriceCurrency.split(
                                                        ',',
                                                    )[2]
                                                }`}
                                </div>
                                <div className="my-auto mx-2">
                                    <Button
                                        color="error"
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => {
                                            const tempPayoutRecipients = [
                                                ...payoutRecipients,
                                            ]
                                            tempPayoutRecipients.splice(i, 1)
                                            setPayoutRecipients(
                                                tempPayoutRecipients,
                                            )
                                            const tempRecipientSplits = [
                                                ...recipientSplits,
                                            ]
                                            tempRecipientSplits.splice(i, 1)
                                            setRecipientSplits(
                                                tempRecipientSplits,
                                            )
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                ))}
                <div>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            const tempPayoutRecipients = [...payoutRecipients]
                            tempPayoutRecipients.push('')
                            setPayoutRecipients(tempPayoutRecipients)
                            const tempRecipientSplits = [...recipientSplits]
                            tempRecipientSplits.push(1)
                            setRecipientSplits(tempRecipientSplits)
                        }}
                        disabled={isDropPriceInvalid(
                            dropPrice,
                            dropPriceCurrency,
                            payoutRecipients,
                        )}
                    >
                        Add Recipient
                    </Button>
                </div>

                <Collapse
                    in={isDropPriceInvalid(
                        dropPrice,
                        dropPriceCurrency,
                        payoutRecipients,
                    )}
                >
                    <div className="my-5">
                        <Alert severity="error">
                            {isDropPriceInvalid(
                                dropPrice,
                                dropPriceCurrency,
                                payoutRecipients,
                            )}
                        </Alert>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

export const dropStartDateValid = (dropStartDateEnabled, dropStartDate) => {
    if (!dropStartDateEnabled) return true
    return dropStartDate > new Date()
}

export const dropEndDateValid = (
    dropEndDateEnabled,
    dropStartDateEnabled,
    dropStartDate,
    dropEndDate,
) => {
    if (!dropEndDateEnabled) return true
    if (dropStartDateEnabled) return dropEndDate > dropStartDate
    else return dropEndDate > new Date()
}

export const dropStartEndDateEditor = (
    dropStartDate,
    setDropStartDate,
    dropStartDateEnabled,
    setDropStartDateEnabled,
    dropEndDate,
    setDropEndDate,
    dropEndDateEnabled,
    setDropEndDateEnabled,
    use24hrFormat,
    setUse24hrFormat,
) => {
    return (
        <div
            className={cn(
                'border rounded-2xl p-5 my-10',
                (dropStartDateEnabled &&
                    !dropStartDateValid(dropStartDateEnabled, dropStartDate)) ||
                    (dropEndDateEnabled &&
                        !dropEndDateValid(
                            dropEndDateEnabled,
                            dropStartDateEnabled,
                            dropStartDate,
                            dropEndDate,
                        ))
                    ? 'border-red-600'
                    : '',
            )}
        >
            <div className="text-2xl mb-8">Start and End Date / Time</div>
            <div className="flex my-5">
                <div className="mr-5">
                    <div className="mb-2">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={dropStartDateEnabled}
                                        onChange={(e) =>
                                            setDropStartDateEnabled(
                                                e.target.checked,
                                            )
                                        }
                                    />
                                }
                                label="Set Start Date and Time"
                            />
                        </FormGroup>
                    </div>

                    <Collapse in={dropStartDateEnabled}>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Start Date and Time (Your local timezone)"
                                    value={dropStartDate}
                                    onChange={(e) =>
                                        setDropStartDate(
                                            new Date(e.setSeconds(0)),
                                        )
                                    }
                                    ampm={!use24hrFormat}
                                    minDateTime={new Date()}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                    </Collapse>
                </div>
                <div className="mr-10">
                    <div className="mb-2">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={dropEndDateEnabled}
                                        onChange={(e) =>
                                            setDropEndDateEnabled(
                                                e.target.checked,
                                            )
                                        }
                                    />
                                }
                                label="Set End Date and Time"
                            />
                        </FormGroup>
                    </div>

                    <Collapse in={dropEndDateEnabled}>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="End Date and Time (Your local timezone)"
                                    value={dropEndDate}
                                    onChange={(e) =>
                                        setDropEndDate(
                                            new Date(e.setSeconds(0)),
                                        )
                                    }
                                    ampm={!use24hrFormat}
                                    // End time must be at least one hour after start time
                                    minDateTime={new Date()}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                    </Collapse>
                </div>
            </div>

            <Collapse in={dropStartDateEnabled || dropEndDateEnabled}>
                <div className="my-5">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={use24hrFormat}
                                    onChange={() => {
                                        setUse24hrFormat(!use24hrFormat)
                                    }}
                                />
                            }
                            label="Use 24-hour format"
                        />
                    </FormGroup>
                </div>
            </Collapse>

            <Collapse
                in={
                    dropStartDateEnabled &&
                    !dropStartDateValid(dropStartDateEnabled, dropStartDate)
                }
            >
                <div className="mb-5">
                    <Alert severity="error">
                        The start date must be in the future
                    </Alert>
                </div>
            </Collapse>

            <Collapse
                in={
                    dropEndDateEnabled &&
                    !dropEndDateValid(
                        dropEndDateEnabled,
                        dropStartDateEnabled,
                        dropStartDate,
                        dropEndDate,
                    )
                }
            >
                <div className="mb-5">
                    <Alert severity="error">
                        The end date must be{' '}
                        {dropStartDateEnabled
                            ? 'after the start date'
                            : 'in the future'}
                    </Alert>
                </div>
            </Collapse>
        </div>
    )
}

export const getCurrencies = async () => {
    const body = {
        code: 'nfthivedrops',
        index_position: 1,
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: '',
        upper_bound: '',
        reverse: 'true',
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'newconfig',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    if (res && res.status === 200 && res.data && res.data.rows) {
        const tokens = [
            { label: 'USD', symbol: '8,WAX', contract: 'eosio.token' },
        ]
        for (const token of res.data.rows[0]['supported_tokens']) {
            const symbol = token['token_symbol']
            const currency = symbol.split(',')[1]
            const contract = token['token_contract']
            if (currency !== 'NULL')
                tokens.push({
                    label: currency === 'GOLD' ? 'BRPG.GOLD' : currency,
                    symbol: symbol,
                    contract: contract,
                })
        }

        return tokens
    }

    return []
}

export const fetchTemplateAssets = async (
    collectionName,
    schemaName,
    templateId,
    userName,
    userTemplateAssets,
    setUserTemplateAssets,
    dropContractTemplateAssets,
    setDropContractTemplateAssets,
) => {
    if (templateId in userTemplateAssets) {
        return
    }

    const fetchUserAssetsUrl =
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
        `/atomicassets/v1/assets?collection_name=${collectionName}&schema_name=${schemaName}&template_id=${templateId}` +
        `&burned=false&owner=${userName}&is_transferable=true&limit=1000`

    const userAssetsResponse = await fetch(fetchUserAssetsUrl)
    const userAssetsJson = await userAssetsResponse.json()
    const userAssetsData = userAssetsJson.data

    let contractAssetsData = null

    if (
        dropContractTemplateAssets !== null &&
        setDropContractTemplateAssets !== null
    ) {
        const fetchContractAssetsUrl =
            (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.atomictest
                : config.atomic) +
            `/atomicassets/v1/assets?collection_name=${collectionName}&schema_name=${schemaName}&template_id=${templateId}` +
            `&burned=false&owner=nfthivedrops&is_transferable=true&limit=1000`

        const contractAssetsResponse = await fetch(fetchContractAssetsUrl)
        const contractAssetsJson = await contractAssetsResponse.json()
        contractAssetsData = contractAssetsJson.data
    }

    if (
        (!userAssetsData || userAssetsData.length === 0) &&
        (!contractAssetsData || contractAssetsData.length === 0)
    ) {
        return
    }

    const userAssets = []
    for (const dataItem of userAssetsData) {
        userAssets.push({
            id: dataItem.asset_id,
            mint: dataItem.template_mint,
        })
    }

    const tempUserTemplateAssets = {
        ...userTemplateAssets,
        [templateId]: userAssets.reverse(),
    }
    setUserTemplateAssets(tempUserTemplateAssets)

    if (contractAssetsData !== null) {
        const contractAssets = []
        for (const dataItem of contractAssetsData) {
            contractAssets.push({
                id: dataItem.asset_id,
                mint: dataItem.template_mint,
            })
        }

        const tempDropContractTemplateAssets = {
            ...dropContractTemplateAssets,
            [templateId]: contractAssets.reverse(),
        }
        setDropContractTemplateAssets(tempDropContractTemplateAssets)
    }
}

export const fetchCollectionSchemas = async (
    userName,
    collectionName,
    setIsUserAuthor,
    setIsUserAuthorized,
    setIsSpecialAccountAuthorized,
    specialAccount,
    setCollectionData,
    setSchemas,
    setOpenSchemas,
    setIsLoading,
    schemaFilter,
) => {
    if (!userName) {
        return
    }

    const response = await fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/schemas?collection_name=${collectionName}`,
    )
    const json = await response.json()
    const data = json.data
    if (!data || data.length === 0) {
        setIsCollectionSchemasLoading(false)
        return
    }

    // 'collection' will be the same for each schema in the Array, so pick the first one
    const collectionInfo = data[0].collection

    setIsUserAuthor(collectionInfo.author === userName)

    setIsUserAuthorized(collectionInfo.authorized_accounts.includes(userName))

    setIsSpecialAccountAuthorized(
        collectionInfo.authorized_accounts.includes(specialAccount),
    )

    setCollectionData({
        uniqueName: collectionInfo.collection_name,
        displayName: collectionInfo.name,
        image: collectionInfo.img,
    })

    const tempSchemas = []
    const tempOpenSchemas = {}

    for (const dataItem of data) {
        if (schemaFilter) {
            if (schemaFilter.includes(dataItem.schema_name)) {
                tempSchemas.push(dataItem.schema_name)
                tempOpenSchemas = {
                    ...tempOpenSchemas,
                    [dataItem.schema_name]: false,
                }
            }
        } else {
            tempSchemas.push(dataItem.schema_name)
            tempOpenSchemas = {
                ...tempOpenSchemas,
                [dataItem.schema_name]: false,
            }
        }
    }

    setSchemas(tempSchemas.sort())
    setOpenSchemas(tempOpenSchemas)

    setIsLoading(false)
}

export const fetchSchemaTemplates = async (
    collectionName,
    schemaName,
    schemaTemplates,
    setSchemaTemplates,
    templateFilter,
) => {
    if (schemaName in schemaTemplates) {
        return
    }

    const response = await fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/templates?collection_name=${collectionName}&schema_name=${schemaName}&limit=1000`,
    )
    const json = await response.json()
    const data = json.data
    if (!data || data.length === 0) {
        return
    }

    const templates = []
    for (const dataItem of data) {
        if (templateFilter) {
            if (templateFilter.includes(parseInt(dataItem.template_id))) {
                templates.push({
                    id: dataItem.template_id,
                    name: dataItem.name,
                    image: dataItem.immutable_data.img
                        ? dataItem.immutable_data.img
                        : null,
                    rarity: dataItem.immutable_data.rarity
                        ? dataItem.immutable_data.rarity
                        : null,
                    issuedSupply: parseInt(dataItem.issued_supply),
                    maxSupply: parseInt(dataItem.max_supply),
                    schema: dataItem.schema.schema_name,
                })
            }
        } else {
            templates.push({
                id: dataItem.template_id,
                name: dataItem.name,
                image: dataItem.immutable_data.img
                    ? dataItem.immutable_data.img
                    : null,
                rarity: dataItem.immutable_data.rarity
                    ? dataItem.immutable_data.rarity
                    : null,
                issuedSupply: parseInt(dataItem.issued_supply),
                maxSupply: parseInt(dataItem.max_supply),
                schema: dataItem.schema.schema_name,
            })
        }
    }

    const tempSchemaTemplates = {
        ...schemaTemplates,
        [schemaName]: templates.reverse(),
    }

    setSchemaTemplates(tempSchemaTemplates)
}

const SchemaCollapseHeader = ({
    collectionName,
    schemaName,
    openSchemas,
    setOpenSchemas,
    schemaTemplates,
    setSchemaTemplates,
    templateFilter,
}) => {
    const isOpen = openSchemas[schemaName]
    return (
        <div
            className="flex cursor-pointer mb-5"
            onClick={() => {
                fetchSchemaTemplates(
                    collectionName,
                    schemaName,
                    schemaTemplates,
                    setSchemaTemplates,
                    templateFilter,
                )
                setOpenSchemas({
                    ...openSchemas,
                    [schemaName]: !openSchemas[schemaName],
                })
            }}
        >
            <div
                className={cn(
                    'ml-1',
                    {
                        'transition transform duration-250 rotate-180': isOpen,
                    },
                    {
                        'transition transform duration-250 rotate-0': !isOpen,
                    },
                )}
            >
                <img className="h-full w-full" src="/collapse.svg" />
            </div>
            <div className="mx-5 font-bold text-xl">{schemaName}</div>
        </div>
    )
}

const assetIdSelection = (
    collectionName,
    checkedTemplateIds,
    template,
    checkedAssetIds,
    templateAssets,
    setCheckedAssetIds,
    maxClaimable,
    isActuallyAboutPacks,
    t,
) => {
    return (
        <TableRow>
            <TableCell
                style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                }}
                colSpan={7}
            >
                <Collapse in={checkedTemplateIds.has(template.id)}>
                    <div className="ml-10 mb-15">
                        <div className="my-5 text-lg">
                            Asset IDs of schema '{template.schema}' -{' '}
                            {template.id in checkedAssetIds &&
                            checkedAssetIds[template.id].size
                                ? checkedAssetIds[template.id].size
                                : 0}{' '}
                            assets selected
                        </div>
                        <div className="flex flex-wrap mb-5">
                            {templateAssets &&
                            templateAssets[template.id] &&
                            templateAssets[template.id].length > 0 ? (
                                templateAssets[template.id].map((asset) => (
                                    <div className="m-1">
                                        <Chip
                                            label={
                                                parseInt(asset.mint) !== 0
                                                    ? '#' + asset.mint
                                                    : 'id: ' + asset.id
                                            }
                                            color={
                                                checkedAssetIds[template.id] &&
                                                checkedAssetIds[
                                                    template.id
                                                ].has(asset.id)
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                            onClick={() => {
                                                if (
                                                    template.id in
                                                        checkedAssetIds &&
                                                    checkedAssetIds[
                                                        template.id
                                                    ].has(asset.id)
                                                ) {
                                                    const tempSet = new Set(
                                                        checkedAssetIds[
                                                            template.id
                                                        ],
                                                    )
                                                    tempSet.delete(asset.id)
                                                    setCheckedAssetIds({
                                                        ...checkedAssetIds,
                                                        [template.id]: tempSet,
                                                    })
                                                } else if (
                                                    template.id in
                                                        checkedAssetIds &&
                                                    !checkedAssetIds[
                                                        template.id
                                                    ].has(asset.id)
                                                ) {
                                                    const tempSet = new Set(
                                                        checkedAssetIds[
                                                            template.id
                                                        ],
                                                    )
                                                    tempSet.add(asset.id)
                                                    setCheckedAssetIds({
                                                        ...checkedAssetIds,
                                                        [template.id]: tempSet,
                                                    })
                                                } else if (
                                                    !(
                                                        template.id in
                                                        checkedAssetIds
                                                    )
                                                ) {
                                                    setCheckedAssetIds({
                                                        ...checkedAssetIds,
                                                        [template.id]: new Set([
                                                            asset.id,
                                                        ]),
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div>{t('editor.no_assets_available')}</div>
                            )}
                            <div className={'m-1'}>
                                <Link
                                    href={`/editor/${collectionName}?tab=nfts&schema=${template.schema}&template=${template.id}`}
                                >
                                    <Chip
                                        label="Mint more Assets to Contract..."
                                        clickable
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="mb-5 flex flex-wrap">
                            <div className="mx-2 my-auto">
                                <Button
                                    variant="outlined"
                                    disabled={
                                        !templateAssets ||
                                        !templateAssets[template.id]
                                    }
                                    onClick={() => {
                                        const tempAssetIds = []
                                        templateAssets[template.id].map(
                                            (asset) =>
                                                tempAssetIds.push(asset.id),
                                        )
                                        setCheckedAssetIds({
                                            ...checkedAssetIds,
                                            [template.id]: new Set(
                                                tempAssetIds,
                                            ),
                                        })
                                    }}
                                >
                                    Select All
                                </Button>
                            </div>
                            <div className="mx-2 my-auto">
                                <Button
                                    variant="outlined"
                                    disabled={
                                        !templateAssets ||
                                        !templateAssets[template.id]
                                    }
                                    onClick={() => {
                                        setCheckedAssetIds({
                                            ...checkedAssetIds,
                                            [template.id]: new Set([]),
                                        })
                                    }}
                                >
                                    Deselect All
                                </Button>
                            </div>
                            {!isActuallyAboutPacks && (
                                <div className="mx-2 my-auto">
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            const tempAssetIds = []
                                            for (
                                                let i = 0;
                                                i <
                                                Math.min(
                                                    parseInt(maxClaimable),
                                                    templateAssets[template.id]
                                                        .length,
                                                );
                                                i++
                                            ) {
                                                tempAssetIds.push(
                                                    templateAssets[template.id][
                                                        i
                                                    ].id,
                                                )
                                            }
                                            setCheckedAssetIds({
                                                ...checkedAssetIds,
                                                [template.id]: new Set(
                                                    tempAssetIds,
                                                ),
                                            })
                                        }}
                                        disabled={
                                            !templateAssets ||
                                            !templateAssets[template.id] ||
                                            parseInt(maxClaimable) === 0 ||
                                            (parseInt(maxClaimable) > 0 &&
                                                templateAssets[template.id] &&
                                                parseInt(maxClaimable) >
                                                    templateAssets[template.id]
                                                        .length)
                                        }
                                    >
                                        Select Max Claimable #
                                    </Button>
                                </div>
                            )}
                            <div className="mx-2 my-auto">
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label="Select Up to..."
                                    type="number"
                                    onChange={(e) => {
                                        const tempAssetIds = []
                                        for (
                                            let i = 0;
                                            i <
                                            Math.min(
                                                e.target.value,
                                                templateAssets &&
                                                    template.id in
                                                        Object.keys(
                                                            templateAssets,
                                                        )
                                                    ? templateAssets[
                                                          template.id
                                                      ].length
                                                    : 0,
                                            );
                                            i++
                                        ) {
                                            tempAssetIds.push(
                                                templateAssets[template.id][i]
                                                    .id,
                                            )
                                        }
                                        setCheckedAssetIds({
                                            ...checkedAssetIds,
                                            [template.id]: new Set(
                                                tempAssetIds,
                                            ),
                                        })
                                    }}
                                />
                            </div>
                            <div>
                                <Tooltip title="Randomize selection (while keeping the same amount)">
                                    <IconButton>
                                        <ShuffleIcon
                                            onClick={() => {
                                                const selectedAmount =
                                                    checkedAssetIds[template.id]
                                                        .size
                                                const tempAssetIds = [
                                                    ...templateAssets[
                                                        template.id
                                                    ],
                                                ]
                                                // shuffle
                                                tempAssetIds.sort(
                                                    () => 0.5 - Math.random(),
                                                )
                                                const newAssetIds = new Set([])
                                                for (
                                                    let i = 0;
                                                    i < selectedAmount;
                                                    i++
                                                ) {
                                                    newAssetIds.add(
                                                        tempAssetIds[i].id,
                                                    )
                                                }
                                                setCheckedAssetIds({
                                                    ...checkedAssetIds,
                                                    [template.id]: newAssetIds,
                                                })
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </TableCell>
        </TableRow>
    )
}

export const isTemplateAssetSelectionInvalid = (
    isAssetLevel,
    checkedAssetIds,
    checkedTemplateIds,
    maxClaimable,
    isActuallyAboutPacks,
) => {
    if (isActuallyAboutPacks) {
        return false
    }

    if (isAssetLevel) {
        let sizeComparison
        for (const [, assets] of Object.entries(checkedAssetIds)) {
            if (!sizeComparison) {
                sizeComparison = assets.size
            } else if (sizeComparison !== assets.size) {
                return 'All templates must have the same number of selected assets'
            }
        }

        if (!sizeComparison) {
            return 'No assets selected'
        }

        if (sizeComparison !== parseInt(maxClaimable)) {
            return (
                `You have selected ${sizeComparison} assets, which must be equal ` +
                `to your max claimable drops (currently set to ${maxClaimable})`
            )
        }
    } else {
        if (checkedTemplateIds.size === 0) {
            return 'No templates selected'
        }
    }
}

export const dropSelectTemplatesEditor = (
    collectionName,
    isAssetLevel,
    schemas,
    openSchemas,
    setOpenSchemas,
    schemaTemplates,
    setSchemaTemplates,
    checkedTemplateIds,
    handleTemplateChecked,
    templateAssets,
    checkedAssetIds,
    setCheckedAssetIds,
    maxClaimable,
    t,
    isActuallyAboutPacks = false,
    ignoreRemainingCount = false,
    templateFilter = null,
) => {
    return (
        <div
            className={cn(
                'my-10 p-5 border rounded-xl',
                isTemplateAssetSelectionInvalid(
                    isAssetLevel,
                    checkedAssetIds,
                    checkedTemplateIds,
                    maxClaimable,
                    isActuallyAboutPacks,
                )
                    ? 'border-red-600'
                    : '',
            )}
        >
            <div className="text-2xl mb-8">
                Select the Templates to be included in Your{' '}
                {isActuallyAboutPacks ? 'Pool' : 'Drop'}
            </div>
            {isAssetLevel && maxClaimable > 0 && (
                <div className="mb-5">
                    Please select {maxClaimable} assets per template
                </div>
            )}
            {schemas.map((schema) => (
                <>
                    <SchemaCollapseHeader
                        collectionName={collectionName}
                        schemaName={schema}
                        openSchemas={openSchemas}
                        setOpenSchemas={setOpenSchemas}
                        schemaTemplates={schemaTemplates}
                        setSchemaTemplates={setSchemaTemplates}
                        templateFilter={templateFilter}
                    />
                    <div className="mb-10">
                        <Collapse in={openSchemas[schema]}>
                            {!schemaTemplates[schema] ? (
                                <div>
                                    No Templates present in schema {schema}
                                </div>
                            ) : (
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Rarity</TableCell>
                                                <TableCell align="center">
                                                    Image
                                                </TableCell>
                                                <TableCell>
                                                    Issued / Total Available
                                                </TableCell>
                                                <TableCell>Remaining</TableCell>
                                                <TableCell>
                                                    Include in{' '}
                                                    {isActuallyAboutPacks
                                                        ? 'Pool'
                                                        : 'Drop'}
                                                    ?
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {schemaTemplates[schema] &&
                                                schemaTemplates[schema].map(
                                                    (template) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell>
                                                                    {
                                                                        template.id
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        template.name
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {template.rarity
                                                                        ? template.rarity
                                                                        : 'n/a'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <ImageElement
                                                                        src={
                                                                            config.ipfsthumbnailhash +
                                                                            template.image
                                                                        }
                                                                        className="max-h-24 max-w-min"
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        template.issuedSupply
                                                                    }{' '}
                                                                    /{' '}
                                                                    {template.maxSupply ===
                                                                    0
                                                                        ? 'Infinite'
                                                                        : template.maxSupply}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {template.maxSupply ===
                                                                    0
                                                                        ? 'Infinite'
                                                                        : template.maxSupply -
                                                                          template.issuedSupply}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Checkbox
                                                                        disabled={
                                                                            !ignoreRemainingCount &&
                                                                            !isAssetLevel &&
                                                                            template.maxSupply >
                                                                                0 &&
                                                                            template.issuedSupply >=
                                                                                template.maxSupply
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) => {
                                                                            handleTemplateChecked(
                                                                                e,
                                                                                template.schema,
                                                                                template.id,
                                                                            )
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                            {isAssetLevel &&
                                                                assetIdSelection(
                                                                    collectionName,
                                                                    checkedTemplateIds,
                                                                    template,
                                                                    checkedAssetIds,
                                                                    templateAssets,
                                                                    setCheckedAssetIds,
                                                                    maxClaimable,
                                                                    isActuallyAboutPacks,
                                                                    t,
                                                                )}
                                                        </>
                                                    ),
                                                )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Collapse>
                    </div>
                </>
            ))}

            <Collapse
                in={isTemplateAssetSelectionInvalid(
                    isAssetLevel,
                    checkedAssetIds,
                    checkedTemplateIds,
                    maxClaimable,
                    isActuallyAboutPacks,
                )}
            >
                <div className="mb-5">
                    <Alert severity="error">
                        {isTemplateAssetSelectionInvalid(
                            isAssetLevel,
                            checkedAssetIds,
                            checkedTemplateIds,
                            maxClaimable,
                            isActuallyAboutPacks,
                        )}
                    </Alert>
                </div>
            </Collapse>
        </div>
    )
}

export const isUnpackAnimationInvalid = (
    useUnpackAnimation,
    unpackAnimation,
) => {
    if (!useUnpackAnimation) return
    if (!unpackAnimation || !checkIpfs(unpackAnimation)) {
        return 'Unpack animation must be a valid IPFS multihash'
    }
}

export const editUnpackAnimation = (
    useUnpackAnimation,
    setUseUnpackAnimation,
    unpackAnimation,
    setUnpackAnimation,
) => {
    return (
        <div
            className={cn(
                'my-10 border rounded-2xl p-5',
                isUnpackAnimationInvalid(useUnpackAnimation, unpackAnimation) &&
                    'border-red-600',
            )}
        >
            <div className="text-2xl mb-8">Unpack Animation</div>
            <div className="my-5">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={useUnpackAnimation}
                                onChange={() => {
                                    setUseUnpackAnimation(!useUnpackAnimation)
                                }}
                            />
                        }
                        label="Use Unpack Animation"
                    />
                </FormGroup>
            </div>
            <Collapse in={useUnpackAnimation}>
                <div className="w-full md:w-96 mb-5">
                    <TextField
                        fullWidth
                        label="Unpack Animation IPFS Hash"
                        value={unpackAnimation}
                        onChange={(e) =>
                            setUnpackAnimation(e.target.value.trim())
                        }
                        error={isUnpackAnimationInvalid(
                            useUnpackAnimation,
                            unpackAnimation,
                        )}
                    />
                </div>
                <div className="w-full md:w-96 mb-5">
                    <Button
                        fullWidth
                        size="large"
                        href={config.ipfs + unpackAnimation}
                        target="_blank"
                        variant="outlined"
                        disabled={isUnpackAnimationInvalid(
                            useUnpackAnimation,
                            unpackAnimation,
                        )}
                    >
                        Preview Animation in new Tab
                    </Button>
                </div>
                <Collapse
                    in={isUnpackAnimationInvalid(
                        useUnpackAnimation,
                        unpackAnimation,
                    )}
                >
                    <div className="mb-5">
                        <Alert severity="error">
                            {isUnpackAnimationInvalid(
                                useUnpackAnimation,
                                unpackAnimation,
                            )}
                        </Alert>
                    </div>
                </Collapse>
            </Collapse>
        </div>
    )
}
