import cn from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import get, { getAtomicAsset } from '../helpers/Api'
import { getAttributeNames, getDefaultAttrNames } from '../helpers/FormatLinks'
import { MarketInfoDetails } from '../marketinfo/MarketInfoDetails'
import { CollectionHeaderPopups } from '../popups/CollectionHeaderPopups'
import { PopupImage } from '../popups/PopupImage'
import { Context } from '../waxplorer'
import PreviewDetailsTable from './PreviewDetailsTable'

export const AssetPreviewInfo = ({
    cancel,
    asset,
    newOwner,
    currentAsset,
    bundleView,
    setPriceInfo,
    priceInfo,
}) => {
    const [attrNames, setAttrNames] = useState(getDefaultAttrNames())
    const [atomicData, setAtomicData] = useState(null)

    const [, dispatch] = useContext(Context)

    const { t } = useTranslation('common')

    let {
        assetId,
        templateId,
        assets,
        author,
        schema,
        name,
        image,
        bundle,
        standard,
        symbol,
    } = asset

    const getPriceInfo = async () => {
        if (!priceInfo && (symbol || assetId || templateId)) {
            const pInfo = await get(
                symbol
                    ? 'price-info-fts/' + symbol
                    : assetId
                    ? 'price-info/' + assetId
                    : 'price-info-template/' + templateId,
            )

            setPriceInfo(pInfo)
        }
    }

    const getAtomicData = () => {
        const controller = new AbortController()
        if (standard === 'atomicassets')
            getAtomicAsset(assetId)
                .then((res) => {
                    res && res.success && setAtomicData(res.data)
                })
                .catch((e) => {
                    console.error(e)
                })
        return () => controller.abort()
    }

    useEffect(() => {
        if (!priceInfo || !atomicData) {
            getAtomicData()
            getPriceInfo()
            const controller = new AbortController()
            getAttributeNames(author, schema, controller.signal).then(
                (result) => {
                    if (result && !('errors' in result)) setAttrNames(result)
                },
            )
        }
    }, [assetId])

    const toggleImage = (img) => {
        dispatch({ type: 'FULL_IMAGE', payload: image })
    }

    return (
        <div
            className={cn(
                'absolute flex w-11/12 md:w-4/5 lg:w-2/3 max-h-screen',
            )}
        >
            <img
                className="absolute top-4 right-4 cursor-pointer z-50 h-4 2xl:h-6"
                onClick={cancel}
                src="/close_btn.svg"
                alt={t('popups.wrapper.close-alt')}
            />
            <div
                className={cn(
                    'w-full flex flex-col md:flex-row z-40 justify-center shadow-xl',
                    'backdrop-blur-xl text-xs md:text-sm ',
                    'bg-paperd rounded-sm md:rounded-2xl ',
                )}
            >
                <div className="bg-page md:w-1/3 grid place-content-center md:h-full">
                    <div className="">
                        <div className="text-lg 2xl:text-2xl text-center my-3 md:my-10">
                            {name}
                        </div>
                        {image && (
                            <div className="md:my-5 cursor-pointer">
                                <PopupImage
                                    image={image}
                                    toggleImage={toggleImage}
                                />
                            </div>
                        )}
                        {asset && (
                            <div className="mt-7 md:my-10">
                                <CollectionHeaderPopups asset={asset} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="md:w-2/3 overflow-y-auto InsideScroll">
                    <div className="mt-16 mx-5 ">
                        <PreviewDetailsTable
                            asset={asset}
                            newOwner={newOwner}
                            bundleView={bundleView}
                            currentAsset={currentAsset}
                            attrNames={attrNames}
                            atomicData={atomicData}
                            className="none"
                        />
                        <MarketInfoDetails
                            asset={asset}
                            priceInfo={priceInfo}
                            currentAsset={currentAsset}
                            assets={assets}
                            bundle={bundle}
                            className="none"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
