import { Alert, Button, TextField } from '@mui/material'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import get from '../helpers/Api'
import { formatWAX } from '../helpers/FormatLinks'
import { getBoostAction, getWaxBuyOfferBalance } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

const BuyOfferBalanceManagementPopup = (props) => {
    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const callBack = props['callBack']
    const closeCallBack = props['closeCallBack']
    const userName = activeUser ? activeUser['accountName'] : null
    const { t } = useTranslation('common')
    const [userBalance, setUserBalance] = useState(0)
    const [userOffers, setUserOffers] = useState(null)
    const [isLoadingBuyOfferBalance, setIsLoadingBuyOfferBalance] =
        useState(true)
    const [isLoadingUserOffers, setIsLoadingUserOffers] = useState(true)
    const [isLoadingTransaction, setIsLoadingTransaction] = useState(false)
    const [isUpdateRequired, setIsUpdateRequired] = useState(false)
    const [error, setError] = useState()
    const [customAmount, setCustomAmount] = useState('')

    const dismissError = () => {
        setError(null)
    }

    const withdrawFromContract = async (withdrawAmount) => {
        if (!withdrawAmount || withdrawAmount < 0) {
            setError('Must at least withdraw a positive amount of WAX')
            return false
        }
        setIsLoadingTransaction(true)
        try {
            const result = await activeUser.signTransaction(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'waxbuyoffers',
                            name: 'withdraw',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser['requestPermission'],
                                },
                            ],
                            data: {
                                account: userName,
                                amount: `${withdrawAmount.toFixed(8)} WAX`,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
        } catch (e) {
            console.log(e)
            setError(e.message)
        } finally {
            setIsLoadingTransaction(false)
            setIsUpdateRequired(true)
        }
    }

    const depositIntoContract = async (amount) => {
        setIsLoadingTransaction(true)
        const actions = [getBoostAction(activeUser)]
        actions.push({
            account: 'eosio.token',
            name: 'transfer',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: {
                from: userName,
                to: 'waxbuyoffers',
                quantity: `${parseFloat(amount).toFixed(8)} WAX`,
                memo: 'deposit',
            },
        })
        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
        } catch (e) {
            console.log(e)
            setError(e.message)
        } finally {
            setIsLoadingTransaction(false)
            setIsUpdateRequired(true)
        }
    }

    const withdrawAll = async () => {
        withdrawFromContract(userBalance)
    }

    const adjustToMax = async () => {
        if (userBalance > getMaxOffer()) {
            withdrawFromContract(userBalance - getMaxOffer())
        }
    }

    const adjustToCombined = async () => {
        if (userBalance > getTotalOfferValue()) {
            withdrawFromContract(userBalance - getTotalOfferValue())
        } else if (userBalance < getTotalOfferValue()) {
            depositIntoContract(getTotalOfferValue() - userBalance)
        }
    }

    const adjustToCustom = async () => {
        if (customAmount > userBalance) {
            depositIntoContract(customAmount - userBalance)
        } else if (customAmount < userBalance) {
            withdrawFromContract(userBalance - customAmount)
        }
        setCustomAmount('')
    }

    const parseUserOffers = (res) => {
        setUserOffers(res)
        setIsLoadingUserOffers(false)
    }

    const parseBuyOfferBalance = (res) => {
        setUserBalance(res)
        setIsLoadingBuyOfferBalance(false)
    }

    const getMaxOffer = () => {
        let max = 0
        for (let offer of userOffers) {
            if (parseFloat(offer.offer) > max) {
                max = parseFloat(offer.offer)
            }
        }
        return max
    }

    const getTotalOfferValue = () => {
        let total = 0
        for (let offer of userOffers) {
            total += parseFloat(offer.offer)
        }
        return total
    }

    const changeCustomAmount = (e) => {
        const val = e.target.value
        if (/^\d*\.?\d*$/.test(val)) {
            if (val === '') {
                setCustomAmount('')
            } else {
                let newAmount = val
                if (val < getMaxOffer()) newAmount = getMaxOffer()
                if (val > getTotalOfferValue()) newAmount = getTotalOfferValue()
                setCustomAmount(newAmount)
            }
        }
    }

    const cancel = () => {
        callBack({ created: false, error: null })
        closeCallBack()
    }

    useEffect(() => {
        if (userName) {
            setIsLoadingUserOffers(true)
            setIsLoadingBuyOfferBalance(true)
            getWaxBuyOfferBalance(userName).then(parseBuyOfferBalance)
            get(`active-buyoffers?buyer=${userName}`).then(parseUserOffers)
            setIsUpdateRequired(false)
        }
    }, [isUpdateRequired])

    const buttonClass = cn(
        'border-2 rounded-tr-md rounded-bl-md outline-0 my-2 p-2',
    )

    const buttonEnabled = cn(
        'text-black bg-primary border-black hover:border-white',
    )

    const buttonDisabled = cn('text-secondaryt bg-neutral border-black')

    const buttonDanger = cn(
        'text-neutral bg-red-600 border-red-800 hover:border-white',
    )

    const explanationTextClass = cn('p-1')

    return (
        <Popup cancel={cancel}>
            {isLoadingBuyOfferBalance ||
            isLoadingUserOffers ||
            isUpdateRequired ? (
                <div>
                    <LoadingIndicator />
                </div>
            ) : userBalance > 0 ? (
                <div
                    className={cn('px-5 md:px-0 flex flex-col justify-center')}
                >
                    <div
                        className={cn(
                            'flex flex-row justify-center text-left pb-5',
                        )}
                    >
                        <div className={cn('flex flex-col px-5')}>
                            <div>
                                {t(
                                    'popups.buyoffer_balance.stats_contract_balance',
                                )}
                            </div>
                            <div>
                                {t(
                                    'popups.buyoffer_balance.stats_active_offers',
                                )}
                            </div>
                            <div>
                                {t(
                                    'popups.buyoffer_balance.stats_highest_offer',
                                )}
                            </div>
                            <div>
                                {t(
                                    'popups.buyoffer_balance.stats_combined_offer_value',
                                )}
                            </div>
                        </div>
                        <div className={cn('flex flex-col px-5')}>
                            <div>{formatWAX(userBalance)}</div>
                            <div>{userOffers.length}</div>
                            <div>{formatWAX(getMaxOffer())}</div>
                            <div>{formatWAX(getTotalOfferValue())}</div>
                        </div>
                    </div>
                    <div
                        className={cn(
                            'px-5 md:px-0 flex flex-col justify-center',
                        )}
                    >
                        <div
                            className={cn(
                                'flex flex-row justify-center text-left',
                            )}
                        >
                            <div className={cn('flex flex-col max-w-1/2 px-5')}>
                                <div className={explanationTextClass}>
                                    {userBalance < getTotalOfferValue()
                                        ? t(
                                              'popups.buyoffer_balance.explanation_balance_below_combined',
                                              {
                                                  amount: formatWAX(
                                                      Math.abs(
                                                          userBalance -
                                                              getTotalOfferValue(),
                                                      ),
                                                  ),
                                              },
                                          )
                                        : userBalance > getTotalOfferValue()
                                        ? t(
                                              'popups.buyoffer_balance.explanation_balance_above_combined',
                                              {
                                                  amount: formatWAX(
                                                      Math.abs(
                                                          userBalance -
                                                              getTotalOfferValue(),
                                                      ),
                                                  ),
                                              },
                                          )
                                        : t(
                                              'popups.buyoffer_balance.explanation_balance_equals_combined',
                                          )}
                                </div>
                                <div className={explanationTextClass}>
                                    {userBalance === getMaxOffer()
                                        ? t(
                                              'popups.buyoffer_balance.explanation_balance_equals_highest',
                                          )
                                        : t(
                                              'popups.buyoffer_balance.explanation_balance_above_highest',
                                              {
                                                  amount: formatWAX(
                                                      Math.abs(
                                                          userBalance -
                                                              getMaxOffer(),
                                                      ),
                                                  ),
                                              },
                                          )}
                                </div>
                                <div className={explanationTextClass}>
                                    {t(
                                        'popups.buyoffer_balance.explanation_withdraw_all',
                                    )}
                                </div>
                            </div>
                            <div className={cn('flex flex-col px-5')}>
                                <div className="my-2">
                                    <Button
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        onClick={adjustToCombined}
                                        disabled={
                                            userBalance === getTotalOfferValue()
                                        }
                                    >
                                        {t(
                                            'popups.buyoffer_balance.adjust_to_all_button_text',
                                        )}
                                    </Button>
                                </div>
                                <div className="my-2">
                                    <Button
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        onClick={adjustToMax}
                                        disabled={userBalance === getMaxOffer()}
                                    >
                                        {t(
                                            'popups.buyoffer_balance.adjust_to_highest_button_text',
                                        )}
                                    </Button>
                                </div>
                                <div className="my-2">
                                    <Button
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={withdrawAll}
                                    >
                                        {t(
                                            'popups.buyoffer_balance.withdraw_all_button_text',
                                        )}
                                    </Button>
                                </div>
                                <div className="mt-5">
                                    <TextField
                                        fullWidth
                                        disabled={
                                            getTotalOfferValue() ==
                                            getMaxOffer()
                                        }
                                        type="number"
                                        size="small"
                                        label="custom balance"
                                        helperText={
                                            getTotalOfferValue() !==
                                            getMaxOffer()
                                                ? `${formatWAX(
                                                      getMaxOffer(),
                                                  )} - ${formatWAX(
                                                      getTotalOfferValue(),
                                                  )}`
                                                : 'n/a'
                                        }
                                        value={customAmount}
                                        onChange={(e) => changeCustomAmount(e)}
                                    />
                                </div>
                                <div className="my-2">
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        onClick={adjustToCustom}
                                        disabled={
                                            getTotalOfferValue() ==
                                            getMaxOffer()
                                        }
                                    >
                                        {customAmount !== ''
                                            ? t(
                                                  'popups.buyoffer_balance.withdraw_custom_amount_button_text',
                                                  {
                                                      amount: formatWAX(
                                                          customAmount,
                                                      ),
                                                  },
                                              )
                                            : t(
                                                  'popups.buyoffer_balance.withdraw_custom_amount_default_button_text',
                                              )}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoadingTransaction && (
                        <PopupLoadingIndicator
                            text={t('popups.loading_transaction')}
                        />
                    )}
                    {error && (
                        <ErrorMessage error={error} onClick={dismissError} />
                    )}
                </div>
            ) : (
                <Alert severity="info">
                    {t('popups.buyoffer_balance.zero_balance')}
                </Alert>
            )}
        </Popup>
    )
}

export default BuyOfferBalanceManagementPopup
