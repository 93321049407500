import { useCallback } from 'react'
import { useUAL } from './ual'

export const reduceTransactionActions = async (actions) =>
    Promise.all(await actions)

export const useTransaction = (
    actions,
    callback,
    options = {
        expireSeconds: 300,
        blocksBehind: 0,
    },
) => {
    const { activeUser } = useUAL()

    return useCallback(async () => {
        try {
            // store the transaction as a Promise
            const tx = activeUser.signTransaction(
                { actions: await reduceTransactionActions(actions) },
                options,
            )

            // the transaction is triggered
            callback(undefined, tx)

            await tx
        } catch (error) {
            callback(error)
        }
    }, [actions, callback])
}

export const useCreateBoostAction = () => {
    const { activeUser } = useUAL()
    return useCallback(
        async () => ({
            account: 'nft.hive',
            name: 'boost',
            authorization: [
                {
                    actor: await activeUser.accountName,
                    permission: activeUser.requestPermission,
                },
            ],
            data: {
                booster: activeUser.accountName,
            },
        }),
        [activeUser?.accountName],
    )
}

export const useCreateBurnAction = () => {
    const { activeUser } = useUAL()
    return useCallback(
        async ({ assetId, standard }) => {
            return standard === 'simpleassets'
                ? {
                      account: 'simpleassets',
                      name: 'burn',
                      authorization: [
                          {
                              actor: await activeUser.accountName,
                              permission: activeUser.requestPermission,
                          },
                      ],
                      data: {
                          owner: activeUser.accountName,
                          memo: '',
                          assetids: [assetId],
                      },
                  }
                : {
                      account: 'atomicassets',
                      name: 'burnasset',
                      authorization: [
                          {
                              actor: await activeUser.accountName,
                              permission: activeUser.requestPermission,
                          },
                      ],
                      data: {
                          asset_owner: activeUser.accountName,
                          asset_id: assetId,
                      },
                  }
        },
        [activeUser?.accountName],
    )
}
