import { isEvent } from './is'
import { chrome, gecko, opera10, opera11, winjs } from './stack-parsers'

const STACKTRACE_LIMIT = 50
const defaultFunctionName = '<anonymous>'
/**
 * Safely extract function name from itself
 */
export const getFunctionName = (fn) => {
    try {
        if (!fn || typeof fn !== 'function') return defaultFunctionName
        return fn.name || defaultFunctionName
    } catch (e) {
        return defaultFunctionName
    }
}

const reactMinifiedRegexp = /Minified React error #\d+;/i
const getPopSize = (ex) => {
    if (ex) {
        if (typeof ex.framesToPop === 'number') return ex.framesToPop
        if (reactMinifiedRegexp.test(ex.message)) return 1
    }
    return 0
}

export function parseStackFrames(ex) {
    const stacktrace = ex.stacktrace || ex.stack || ''
    const popSize = getPopSize(ex)

    try {
        // The order of the parsers in important
        return createStackParser(
            opera10,
            opera11,
            chrome,
            winjs,
            gecko,
        )(stacktrace, popSize)
    } catch (e) {
        // no-empty
    }

    return []
}

const createStackParser =
    (...parsers) =>
    (stack, skipFirst = 0) => {
        const frames = []

        for (const line of stack.split('\n').slice(skipFirst)) {
            for (const parser of parsers) {
                const frame = parser(line)

                if (frame) {
                    frames.push(frame)
                    break
                }
            }
        }

        return stripFramesAndReverse(frames)
    }

export const stripFramesAndReverse = (stack) => {
    if (!stack.length) {
        return []
    }

    let localStack = stack

    // const firstFrameFunction = localStack[0].function || '';
    // const lastFrameFunction = localStack[localStack.length - 1].function || '';

    // // If stack starts with one of our API calls, remove it (starts, meaning it's the top of the stack - aka last call)
    // if (firstFrameFunction.indexOf('captureMessage') !== -1 || firstFrameFunction.indexOf('captureException') !== -1) {
    //   localStack = localStack.slice(1);
    // }

    // // If stack ends with one of our internal API calls, remove it (ends, meaning it's the bottom of the stack - aka top-most call)
    // if (lastFrameFunction.indexOf('sentryWrapped') !== -1) {
    //   localStack = localStack.slice(0, -1);
    // }

    // The frame where the crash happened, should be the last entry in the array
    return localStack
        .slice(0, STACKTRACE_LIMIT)
        .map((frame) => ({
            ...frame,
            filename: frame.filename || localStack[0].filename,
            function: frame.function || '?',
        }))
        .reverse()
}

export const eventFromString = (input, syntheticException, options = {}) => {
    const event = {
        message: input,
    }

    if (options.attachStacktrace && syntheticException) {
        const frames = parseStackFrames(syntheticException)
        if (frames.length) {
            event.stacktrace = { frames }
        }
    }

    return event
}

export const eventFromError = (ex) => ({
    exception: {
        values: [exceptionFromError(ex)],
    },
})

export function eventFromPlainObject(exception, syntheticException, rejection) {
    const event = {
        exception: {
            values: [
                {
                    type: isEvent(exception)
                        ? exception.constructor.name
                        : rejection
                        ? 'UnhandledRejection'
                        : 'Error',
                    value: `Non-Error ${
                        rejection ? 'promise rejection' : 'exception'
                    } captured`,
                },
            ],
        },
        // extra: {
        //     __serialized__: normalizeToSize(exception),
        // },
    }

    if (syntheticException) {
        const frames = parseStackFrames(syntheticException)
        if (frames.length) {
            event.stacktrace = { frames }
        }
    }

    return event
}

export function exceptionFromError(ex) {
    // Get the frames first since Opera can lose the stack if we touch anything else first
    const frames = parseStackFrames(ex)

    const exception = {
        type: ex && ex.name,
        value: extractMessage(ex),
    }

    if (frames && frames.length) exception.stacktrace = { frames }

    if (exception.type === undefined && exception.value === '')
        exception.value = 'Unrecoverable error caught'

    return exception
}

const extractMessage = (ex) => {
    const message = ex && ex.message
    if (!message) return 'No error message'

    if (message.error && typeof message.error.message === 'string')
        return message.error.message

    return message
}
