import cn from 'classnames'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUAL } from '../../hooks/ual'
import { performLogin } from '../helpers/WaxApi'
import { Context } from '../waxplorer'
import {
    SideNavigationHeader,
    SideNavigationHeaderWrapper,
} from './SideNavigationHeader'
import SubMenu from './SubMenu'

export const SideNavigation = (props) => {
    const { t } = useTranslation('common')
    const ual = useUAL()
    const userName = ual?.activeUser?.accountName
        ? ual.activeUser.accountName
        : null
    const logout = ual?.logout

    const { showSideNavigation } = props

    const router = useRouter()
    const location = router.asPath.substring(
        0,
        router.asPath.indexOf('?') > 0
            ? router.asPath.indexOf('?')
            : router.asPath.length,
    )
    const isHome = location === '/'
    const isMarket = location.includes('/market')
    const isTrade = location === '/trade'
    const isAccount = [`/user/${userName}`, '/account'].includes(location)

    const isCollections = location && location.includes('/collections')
    const isAnalytics = location === '/stats'
    const isEditor = location === '/editor'
    const isCPU = location === '/cpu'
    const isAdmin = location === '/admin'
    const isBulk = ['/bulkoverview', '/bulk'].includes(location)

    const [activeSubNavigation, activateSubNavigation] = useState(undefined)

    let touchstartX = 0
    let touchendX = 0

    const touchStart = (e) => {
        touchstartX = e.changedTouches[0].screenX
    }

    const touchMove = (e) => {
        touchendX = e.changedTouches[0].screenX
        if (touchstartX - touchendX > 100) {
            mobileHide()
        }
    }

    const mobileHide = () => {
        dispatch({
            type: 'SIDE_NAVIGATION_VISIBLE',
            payload: true,
        })
    }

    const toggleSubNav = useCallback((target) => {
        activateSubNavigation((current) =>
            current === target ? undefined : target,
        )
    }, [])

    const [, dispatch] = useContext(Context)

    const showBuyWaxPopup = async () => {
        dispatch({
            type: 'SET_ACTION',
            payload: 'buy_wax',
        })
        dispatch({
            type: 'SET_CALLBACK',
            payload: () => {},
        })
    }

    return (
        <div
            className={cn(
                'h-full absolute w-full bg-app md:bg-transparent pr-0',
            )}
            aria-expanded={showSideNavigation}
            onTouchStart={touchStart}
            onTouchMove={touchMove}
        >
            <div className="absolute w-full h-page-s md:h-page-md lg:h-page-lg xl:h-page-xl flex flex-col overflow-x-hidden InsideScroll pr-1 overflow-y-scroll">
                <SideNavigationHeaderWrapper active={isHome}>
                    <SideNavigationHeader
                        active={isHome}
                        hasSubmenu={false}
                        href={`/`}
                        icon={'/home.svg'}
                        title={t('navigation.home')}
                    />
                </SideNavigationHeaderWrapper>

                <SubMenu
                    active={isAccount}
                    icon={'/person-outline.svg'}
                    onToggle={() => toggleSubNav('account')}
                    onLogin={() => performLogin(ual)}
                    open={isAccount || activeSubNavigation === 'account'}
                    title={t('navigation.account')}
                    loginRequired
                    menuItems={
                        userName
                            ? [
                                  {
                                      href: `/user/${userName}`,
                                      title: t('navigation.account'),
                                      onClick: mobileHide,
                                  },
                                  {
                                      href: `/user/${userName}?tab=inventory`,
                                      title: t('profile.inventory'),
                                      onClick: mobileHide,
                                  },
                                  {
                                      href: `/unpack`,
                                      title: t('asset.unpack'),
                                      onClick: mobileHide,
                                  },
                                  {
                                      href: '',
                                      onClick: logout,
                                      title: 'Logout',
                                  },
                              ]
                            : []
                    }
                />

                <SubMenu
                    active={isCollections}
                    icon={'/book-open-outline.svg'}
                    onToggle={() => toggleSubNav('collections')}
                    onLogin={() => performLogin(ual)}
                    open={
                        isCollections || activeSubNavigation === 'collections'
                    }
                    title={t('landing.collections')}
                    loginRequired={false}
                    menuItems={[
                        {
                            href: `/collections`,
                            title: 'landing.overview',
                            onClick: mobileHide,
                        },
                        {
                            href: `/collections/p2e`,
                            title: 'landing.p2e',
                            onClick: mobileHide,
                        },
                        {
                            href: `/collections/collectibles`,
                            title: 'landing.collectibles',
                            onClick: mobileHide,
                        },
                        {
                            href: `/collections/art`,
                            title: 'landing.art',
                            onClick: mobileHide,
                        },
                        {
                            href: `/collections/games`,
                            title: 'landing.games',
                            onClick: mobileHide,
                        },
                        {
                            href: `/collections/pfp`,
                            title: 'landing.pfp',
                            onClick: mobileHide,
                        },
                        {
                            href: `/collections/vip`,
                            title: 'landing.vip',
                            onClick: mobileHide,
                        },
                        {
                            href: `/collections/other`,
                            title: 'landing.other',
                            onClick: mobileHide,
                        },
                    ]}
                />

                <SubMenu
                    active={isMarket}
                    icon={'/shopping-cart-outline.svg'}
                    onToggle={() => toggleSubNav('market')}
                    open={isMarket || activeSubNavigation === 'market'}
                    title={t('search.market')}
                    menuItems={[
                        {
                            href: `/market`,
                            title: t('search.sales'),
                            onClick: mobileHide,
                        },
                        {
                            href: `/market?tab=buyoffers`,
                            title: 'Global Buy Offers',
                            onClick: mobileHide,
                        },
                        {
                            href: `/market?tab=auctions`,
                            title: t('search.auctions'),
                            onClick: mobileHide,
                        },
                        {
                            href: `/market?tab=drops`,
                            title: t('navigation.drops'),
                            onClick: mobileHide,
                        },
                    ]}
                />

                <SubMenu
                    active={isBulk}
                    icon={'/bulk.svg'}
                    onToggle={() => toggleSubNav('bulk')}
                    onLogin={() => performLogin(ual)}
                    open={isBulk || activeSubNavigation === 'bulk'}
                    title={t('navigation.bulk')}
                    menuItems={[
                        {
                            href: `/bulkoverview?search_type=bulk_sell&owner=${userName}`,
                            title: 'landing.overview',
                            onClick: mobileHide,
                        },
                        {
                            href: `/bulk?search_type=bulk_sell&owner=${userName}`,
                            title: 'Bulk Sell NFTs',
                            onClick: mobileHide,
                        },
                        {
                            href: `/bulk?search_type=bulk_edit&owner=${userName}&order_by=offer_asc`,
                            title: 'Bulk Edit Listings',
                            onClick: mobileHide,
                        },
                        {
                            href: `/bulk?search_type=bulk_transfer&owner=${userName}`,
                            title: 'Bulk Transfer NFTs',
                            onClick: mobileHide,
                        },
                        {
                            href: `/bulk?search_type=bulk_stake&owner=${userName}`,
                            title: 'Bulk Stake RPlanet',
                            onClick: mobileHide,
                        },
                    ]}
                />

                <SubMenu
                    active={isTrade}
                    icon={'/trade.svg'}
                    onToggle={() => toggleSubNav('trade')}
                    onLogin={() => performLogin(ual)}
                    open={isTrade || activeSubNavigation === 'trade'}
                    title={t('trade.trade')}
                    loginRequired
                    menuItems={[
                        {
                            href: '/trade?tab=new-trade',
                            title: 'trade.new_trade',
                            onClick: mobileHide,
                        },

                        {
                            href: '/trade?tab=received-offers',
                            title: 'trade.received_offers',
                            onClick: mobileHide,
                        },

                        {
                            href: '/trade?tab=sent-offers',
                            title: 'trade.sent_offers',
                            onClick: mobileHide,
                        },
                    ]}
                />
                {process.env.NEXT_PUBLIC_TESTNET !== 'TRUE' && (
                    <SideNavigationHeaderWrapper active={isAnalytics}>
                        <SideNavigationHeader
                            active={isAnalytics}
                            open={
                                isAnalytics ||
                                activeSubNavigation === 'analytics'
                            }
                            title={t('navigation.analytics')}
                            icon={'/analytics.svg'}
                            href={`/stats`}
                            hasSubmenu={false}
                            onClick={mobileHide}
                        />
                    </SideNavigationHeaderWrapper>
                )}
                <SideNavigationHeaderWrapper>
                    <SideNavigationHeader
                        active={false}
                        open={false}
                        title={t('footer.buy_wax')}
                        icon={'/WAX_W_White.svg'}
                        hasSubmenu={false}
                        onClick={showBuyWaxPopup}
                    />
                </SideNavigationHeaderWrapper>

                <SideNavigationHeaderWrapper active={isEditor}>
                    <SideNavigationHeader
                        active={isEditor}
                        open={isEditor || activeSubNavigation === 'editor'}
                        title={'Editor'}
                        icon={'/edit_white.svg'}
                        href={`/editor`}
                        hasSubmenu={false}
                    />
                </SideNavigationHeaderWrapper>

                {process.env.NEXT_PUBLIC_TESTNET !== 'TRUE' && (
                    <SideNavigationHeaderWrapper active={isCPU}>
                        <SideNavigationHeader
                            active={isCPU}
                            open={isCPU || activeSubNavigation === 'cpu'}
                            title={t('navigation.rent_cpu')}
                            icon={'/cpu.svg'}
                            href={`/cpu`}
                            hasSubmenu={false}
                            onClick={mobileHide}
                        />
                    </SideNavigationHeaderWrapper>
                )}
                {['frcqu.wam', 't1.5c.wam', 'tgz5k.wam', 'scfay.wam'].includes(
                    userName,
                ) && (
                    <SideNavigationHeaderWrapper active={isAdmin}>
                        <SideNavigationHeader
                            active={isAdmin}
                            open={isAdmin || activeSubNavigation === 'admin'}
                            title={t('navigation.admin')}
                            icon={'/admin.svg'}
                            href={`/admin`}
                            hasSubmenu={false}
                            onClick={mobileHide}
                        />
                    </SideNavigationHeaderWrapper>
                )}
            </div>
        </div>
    )
}

export default SideNavigation
