import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import config from '../../config.json'
import MainButton from '../common/util/input/MainButton'
import { getBoostAction } from '../helpers/WaxApi'
import Popup from './Popup'

function UserPicturePopup(props) {
    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const callBack = props['callBack']
    const userName = activeUser ? activeUser['accountName'] : null
    const asset = props['asset']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']
    const [error, setError] = useState()
    const [changeTimer, setChangeTimer] = useState()

    const [edited, setEdited] = useState(asset)

    const [ipfs, setIpfs] = useState(asset)

    useEffect(() => {}, [ipfs])

    const savePicture = async () => {
        setIsLoading(true)
        try {
            const result = await activeUser.signTransaction(
                {
                    actions: [
                        getBoostAction(activeUser),
                        {
                            account: 'wax.gg',
                            name: 'updatephoto',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser['requestPermission'],
                                },
                            ],
                            data: {
                                account: userName,
                                photo_hash: edited,
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            callBack({ new: edited })
            closeCallBack()
        } catch (e) {
            console.log(e)
            callBack({ new: null, error: e.message() })
            setError(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const changeIPFS = (e) => {
        const val = e.target.value
        setEdited(val)
        if (changeTimer) {
            clearInterval(changeTimer)
        }

        setChangeTimer(
            setTimeout(async () => {
                setIpfs(val)
            }, 1000),
        )
    }

    const cancel = () => {
        callBack({ new: null })
        closeCallBack()
    }

    return (
        <Popup
            title={'Update WAX Profile Picture'}
            cancel={cancel}
            image={
                ipfs
                    ? 'https://gateway.pinata.cloud/ipfs/' + ipfs + '?preview=1'
                    : config.ipfs + asset
            }
        >
            <div className={cn('grid grid-cols-3')}>
                <input
                    className={cn(
                        'border-b-2 border-primary bg-transparent text-xs color-primary mr-4',
                    )}
                    type="text"
                    placeholder={'IPFS Hash'}
                    onChange={changeIPFS}
                    value={edited && edited !== asset ? edited : ''}
                />
                <MainButton
                    className={'mt-0 border-primary text-primary ml-auto'}
                    onClick={savePicture}
                >
                    Save
                </MainButton>
                <MainButton
                    className={'mt-0 border-neutral text-neutral ml-auto'}
                    onClick={cancel}
                >
                    Cancel
                </MainButton>
            </div>
        </Popup>
    )
}

export default UserPicturePopup
