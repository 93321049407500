import { useRouter } from 'next/router'
import React, { useCallback, useState } from 'react'
import { SearchBar } from '../searchbar/SearchBar'

export const NavigationSearch = () => {
    const [tempSearch, setTempSearch] = useState('')
    const router = useRouter()

    const search = (e) => {
        e.preventDefault()
        router.push(`/search?term=${tempSearch}`)
    }

    const changeSearch = useCallback((e) => {
        const val = e.target.value
        setTempSearch(val)
    }, [])

    return (
        <SearchBar
            onSubmit={search}
            target={'/search'}
            value={tempSearch}
            placeHolder={'Search'}
            onChange={changeSearch}
            onClickSearch={search}
        />
    )
}

export default NavigationSearch
