import cn from 'classnames'
//import PrivateKey from 'eosjs'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import Link from '../common/util/input/Link'
import MainButton from '../common/util/input/MainButton'
import {
    getAtomicAssets,
    getDelphiMedian,
    getDropKeys,
    getProofOwn,
    getWhiteList,
    post,
} from '../helpers/Api'
import getCookie, { setCookie } from '../helpers/cookies'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import CountrySelect from '../ui/countryselect/CountrySelect'
import Popup from './Popup'

function BuyDropPopup(props) {
    const drop = props['drop']

    const { templatesToMint } = drop
    const { idata } = templatesToMint[0]

    const [country, setCountry] = useState(getCookie('country'))

    const data = JSON.parse(idata)

    const image = data['img']
        ? config.ipfs + data['img']
        : data['video']
        ? 'video:' + data['video']
        : null

    const amount = props['amount']

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const callBack = props['callBack']
    const closeCallBack = props['closeCallBack']
    const userName = activeUser ? activeUser['accountName'] : null
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingValidation, setIsLoadingValidation] = useState(false)
    const [quantity, setQuantity] = useState(null)
    const [delphiMedian, setDelphiMedian] = useState(0)
    const [requiredAssets, setRequiredAssets] = useState(null)
    const [whitelist, setWhitelist] = useState(null)
    const [publicKey, setPublicKey] = useState(null)
    const [supportedTokens, setSupportedTokens] = useState(null)
    const [missingProof, setMissingProof] = useState(null)

    let values = []
    if (process.browser)
        values = qs.parse(
            window.location.search.substring(1, window.location.search.length),
        )

    const key = values['key']

    const { name, listingPrice } = drop

    const parseUSDListingPrice = (median, amount, usd) => {
        if (median) {
            setQuantity(
                ((amount * usd) / (median / 10000.0)).toFixed(8) + ' WAX',
            )
            setDelphiMedian(median)
        }
    }

    const validateAssets = (assets, amount) => {
        const assetIds = []
        for (let i = 0; i < amount && i < assets.data.length; ++i) {
            assetIds.push(assets.data[i].asset_id)
        }
        return assetIds
    }

    const validateSchema = async (
        collection_name,
        schema_name,
        amount,
        userName,
    ) => {
        const assets = await getAtomicAssets({
            collections: [collection_name],
            schema: schema_name,
            limit: amount + 1,
            user: userName,
        })

        return validateAssets(assets, amount)
    }

    const validateCollection = async (collection_name, amount, userName) => {
        const assets = await getAtomicAssets({
            collections: [collection_name],
            limit: amount + 1,
            user: userName,
        })

        return validateAssets(assets, amount)
    }

    const validateByTemplate = async (
        collection_name,
        template_id,
        amount,
        userName,
    ) => {
        const assets = await getAtomicAssets({
            collections: [collection_name],
            template_id: template_id,
            limit: amount + 1,
            user: userName,
        })

        return validateAssets(assets, amount)
    }

    const getValidation = async (userName) => {
        if (!userName) return
        setIsLoadingValidation(true)
        const wl = await getWhiteList(drop, userName)

        if (wl) {
            setWhitelist(wl)

            setIsLoadingValidation(false)
            return
        }

        if (key) {
            const pk = await getDropKeys(drop.dropId)
            if (pk) {
                setPublicKey(pk)

                setIsLoadingValidation(false)
                return
            }
        }

        const proofOwn = await getProofOwn(drop.dropId)

        let logical_operator = 0
        if (proofOwn && proofOwn.proof && proofOwn.proof[0].op)
            logical_operator = proofOwn.proof[0].op

        const assetIds = []
        const missing = []

        if (proofOwn && proofOwn.proof && proofOwn.proof[0].proof) {
            let found = false
            let foundCounter = 0
            for (let i = 0; i < proofOwn.proof[0].proof.length; ++i) {
                const proof = proofOwn.proof[0].proof[i]
                if (proof) {
                    const num = proof.num
                    const collection_name = proof.collection_name
                    const schema = proof.schema
                    const template_id = proof.template_id
                    let assets = []

                    if (collection_name && !schema && !template_id) {
                        assets = await validateCollection(
                            collection_name,
                            num,
                            data['comparison_operator'],
                            userName,
                        )
                    } else if (collection_name && schema && !template_id) {
                        assets = await validateSchema(
                            collection_name,
                            schema,
                            num,
                            userName,
                        )
                    } else if (collection_name && schema && template_id) {
                        assets = await validateByTemplate(
                            collection_name,
                            template_id,
                            num,
                            userName,
                        )
                    }

                    if (assets.length >= num) {
                        found = true
                        ++foundCounter
                        assets.map((assetId) => assetIds.push(assetId))
                    } else {
                        missing.push({
                            schema: schema,
                            collection_name: collection_name,
                            template_id: template_id,
                            amount: num - assets.length,
                        })
                    }
                }
            }

            if (
                (logical_operator === 'AND' &&
                    foundCounter === proofOwn.proof[0].proof.length) ||
                (logical_operator === 'OR' && foundCounter > 0)
            ) {
                setRequiredAssets(assetIds)
            } else {
                setMissingProof(missing)
            }
        }

        setIsLoadingValidation(false)
    }

    const getSupportedTokens = async () => {
        const body = {
            code: 'nfthivedrops',
            index_position: 1,
            json: 'true',
            key_type: 'i64',
            limit: 1,
            lower_bound: '',
            upper_bound: '',
            reverse: 'true',
            scope: 'nfthivedrops',
            show_payer: 'false',
            table: 'newconfig',
            table_key: '',
        }

        const url =
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.testapi + '/v1/chain/get_table_rows'
                : config.api + '/v1/chain/get_table_rows'
        const res = await post(url, body)

        if (res && res.status === 200 && res.data && res.data.rows) {
            const tokens = []
            for (const token of res.data.rows[0]['supported_tokens']) {
                const symbol = token['token_symbol']
                const decimals = symbol.split(',')[0]
                const currency = symbol.split(',')[1]
                const contract = token['token_contract']
                tokens.push({
                    symbol: symbol,
                    decimals: decimals,
                    currency: currency,
                    contract: contract,
                })
            }
            if (!listingPrice.includes(' USD') && !free) {
                const tokenCurrency = drop.listingPrice.split(' ')[1]
                let tokenContract = 'eosio.token'
                let symbol = '8,WAX'
                for (const token of tokens) {
                    if (token['currency'] === tokenCurrency) {
                        tokenContract = token['contract']
                        symbol = token['symbol']
                    }
                }

                const quantity = parseFloat(listingPrice.split(' ')[0]) * amount
                const precision = parseInt(symbol.split(',')[0])

                const formattedListingPrice = `${quantity.toFixed(
                    precision,
                )} ${tokenCurrency}`

                setQuantity(formattedListingPrice)
            } else if (listingPrice.includes(' USD') && !free) {
                getDelphiMedian().then((res) =>
                    parseUSDListingPrice(
                        res,
                        amount,
                        parseFloat(listingPrice.replace(' USD', '')),
                    ),
                )
            }
            setSupportedTokens(tokens)
        }
    }

    useEffect(() => {
        getSupportedTokens()
        if (drop.authRequired && userName) {
            getValidation(userName)
        }
    }, [userName])

    const free = listingPrice === '0 NULL' || listingPrice === '0.00000000 WAX'

    const purchase = async () => {
        closeCallBack()
        setIsLoading(true)

        const actions = [getBoostAction(activeUser)]
        const tokenCurrency = drop.listingPrice.split(' ')[1]

        let tokenContract = 'eosio.token'
        let symbol = '8,WAX'
        for (const token of supportedTokens) {
            if (token['currency'] === tokenCurrency) {
                tokenContract = token['contract']
                symbol = token['symbol']
            }
        }

        if (!free) {
            actions.push({
                account: tokenContract,
                name: 'transfer',
                authorization: [
                    {
                        actor: userName,
                        permission: activeUser['requestPermission'],
                    },
                ],
                data: {
                    from: userName,
                    to: 'nfthivedrops',
                    quantity: quantity,
                    memo: 'deposit',
                },
            })
        }

        const data = {
            referrer: 'nft.hive',
            drop_id: drop.dropId,
            country: country,
            intended_delphi_median: delphiMedian ? delphiMedian : 0,
            amount: amount,
            claimer: userName,
            currency: symbol,
        }

        if (requiredAssets) {
            data['asset_ids'] = requiredAssets
        }

        if (publicKey) {
            //const signatureNonce = Math.floor(Math.random() * 2 ** 32)
            //const dropPrivateKey = PrivateKey.fromString(key)
            //const claimSignature = dropPrivateKey
            //    .sign(
            //        `${drop.dropId}-${userName}-${signatureNonce}`,
            //        true,
            //        'utf8',
            //    )
            //    .toString()
            //data['authkey_key'] = dropPrivateKey.getPublicKey().toString()
            //data['signature_nonce'] = signatureNonce
            //data['claim_signature'] = claimSignature
        }

        actions.push({
            account: 'nfthivedrops',
            name: drop.authRequired
                ? whitelist
                    ? 'claimdropwl'
                    : publicKey
                    ? 'claimdropkey'
                    : 'claimwproof'
                : 'claimdrop',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: data,
        })

        try {
            await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            callBack({ bought: true })
        } catch (e) {
            callBack({ bought: false, error: e.message })
        } finally {
            setIsLoading(false)
        }
    }

    const cancel = () => {
        callBack(false)
        closeCallBack()
    }

    const fulfilled = requiredAssets || key || whitelist

    const onSelectCountry = (e) => {
        if (e) {
            setCookie('country', e.value)
            setCountry(e.value)
        } else {
            setCountry(null)
        }
    }

    const { t } = useTranslation('common')

    return (
        <Popup title={''} image={image} cancel={cancel}>
            <div className="text-3xl mt-4 lg:mt-0 text-center">{name}</div>
            <div className="text-lg text-center my-4">
                {free
                    ? `Do you want to claim this Drop for free?`
                    : `Do you want to buy ${
                          amount > 1 ? amount + ' of' : ''
                      } this Drop for ${drop.listingPrice}${
                          amount > 1 ? ' each' : ''
                      }?`}
            </div>
            {drop.authRequired ? (
                <div className={'w-full m-auto text-center mb-5'}>
                    <div className={'m-2'}>Authorization Required!</div>
                    {isLoadingValidation ? (
                        <LoadingIndicator />
                    ) : (
                        <div
                            className={cn(
                                'flex justify-evenly',
                                { 'text-green-400': fulfilled },
                                { 'text-red-400': !fulfilled },
                            )}
                        >
                            {fulfilled ? 'Fulfilled' : 'Not Fulfilled'}
                        </div>
                    )}
                    {missingProof && (
                        <div className={'w-1/2 mx-auto mt-1'}>
                            {t('drops.missing')}:
                            {missingProof &&
                                missingProof.map((proof) => (
                                    <div className={'grid grid-cols-3'}>
                                        {!proof.template_id &&
                                            !proof.schema &&
                                            proof.collection_name && (
                                                <div>{proof.amount}x</div>
                                            )}
                                        {!proof.template_id &&
                                            !proof.schema &&
                                            proof.collection_name && (
                                                <div>
                                                    {t('drops.collection')}:
                                                </div>
                                            )}
                                        {!proof.template_id &&
                                            !proof.schema &&
                                            proof.collection_name && (
                                                <Link
                                                    href={`/market?collection=${proof.collection_name}`}
                                                    onClick={closeCallBack}
                                                >
                                                    <div
                                                        className={
                                                            'text-primary'
                                                        }
                                                    >
                                                        {proof.collection_name}
                                                    </div>
                                                </Link>
                                            )}
                                        {!proof.template_id && proof.schema && (
                                            <div>{proof.amount}x</div>
                                        )}
                                        {!proof.template_id && proof.schema && (
                                            <div>{t('drops.schema')}:</div>
                                        )}
                                        {!proof.template_id && proof.schema && (
                                            <Link
                                                href={`/market?collection=${proof.collection_name}&schema=${proof.schema}`}
                                                onClick={closeCallBack}
                                            >
                                                <div className={'text-primary'}>
                                                    {proof.schema}
                                                </div>
                                            </Link>
                                        )}
                                        {proof.template_id && (
                                            <div>{proof.amount}x</div>
                                        )}
                                        {proof.template_id && (
                                            <div>{t('drops.template')}:</div>
                                        )}
                                        {proof.template_id && (
                                            <Link
                                                href={`/market?collection=${proof.collection_name}&schema=${proof.schema}&template_id=${proof.template_id}`}
                                                onClick={closeCallBack}
                                            >
                                                <div className={'text-primary'}>
                                                    {proof.template_id}
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            ) : (
                ''
            )}
            <div
                className={cn(
                    'relative m-auto h-20 lg:h-8',
                    'flex justify-evenly flex-wrap lg:justify-end',
                )}
            >
                <CountrySelect onSelect={onSelectCountry} country={country} />
                <MainButton
                    onClick={cancel}
                    className="mx-2 text-neutral bg-paper border-neutral w-40 h-10"
                >
                    Cancel
                </MainButton>
                <MainButton
                    className={'mx-2 w-40 h-10'}
                    onClick={purchase}
                    disabledReason={
                        !country
                            ? 'Please select your country'
                            : drop.authRequired && !fulfilled
                            ? 'You are not authorized to buy this Drop'
                            : ''
                    }
                    disabled={
                        !country ||
                        isLoadingValidation ||
                        (drop.authRequired && !fulfilled)
                    }
                >
                    {free ? 'Claim' : 'Purchase'}
                </MainButton>
            </div>
            {isLoading ? (
                <div className="absolute t-0 w-full h-full backdrop-filter backdrop-blur-md">
                    <LoadingIndicator text="Loading Transaction" />
                </div>
            ) : (
                ''
            )}
        </Popup>
    )
}

export default BuyDropPopup
