import cn from 'classnames'
import Link from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUAL } from '../../hooks/ual'
import { performLogin } from '../helpers/WaxApi'
import { MultiAssetControlPanel } from '../multiassetcontrolpanel/MultiAssetControlPanel'
import Notifications from '../notifications/Notifications'
import NavigationSearch from '../searchitems/NavigationSearch'
import { UserChip } from '../userchip/UserChip'

const Navigation = (props) => {
    const { t } = useTranslation('common')
    const ual = useUAL()
    const userName = ual?.activeUser?.accountName
        ? ual.activeUser.accountName
        : null

    const toggleSideNavigation = props.toggleSideNavigation

    return (
        <div
            className={cn(
                'relative h-36 md:h-12 lg:h-18 xl:h-24 mx-auto z-50 flex justify-between flex-nowrap',
            )}
        >
            <div className={cn('w-full flex flex-wrap justify-left flex-cols')}>
                <div
                    className={cn(
                        'relative h-24 md:h-12 lg:h-18 xl:h-24 flex w-full md:w-3/20 2xl:w-1/10',
                    )}
                >
                    <div className={'w-9/10 my-auto pl-4 md:pl-0'}>
                        <Link href={'/'}>
                            <img
                                src="/nfthive-logo.svg"
                                alt="NFTHive on WAX"
                                className={cn(
                                    'relative h-16 md:h-12 lg:h-18 xl:h-24 my-auto cursor-pointer',
                                )}
                            />
                        </Link>
                    </div>
                    <div className={cn('flex h-full my-auto')}>
                        <div
                            onClick={toggleSideNavigation}
                            id={'SideMenuButton'}
                            className={cn(
                                'my-auto w-8 h-8 rounded bg-paperl py-1 ml-4 mr-4 md:mr-0',
                                'hover:bg-primaryt2 transition-color duration-200',
                            )}
                        >
                            <img
                                className={cn('m-auto cursor-pointer')}
                                src="/menu.svg"
                                alt={t('navigation.menu')}
                                title={t('navigation.menu')}
                                id={'SideMenuIcon'}
                            />
                        </div>
                    </div>
                </div>
                <div className={'my-auto pl-0 md:pl-2 md:pl-4 w-2/5 md:w-1/4'}>
                    <NavigationSearch />
                </div>
                <div
                    className={cn(
                        'flex flex-cols justify-evenly md:justify-end w-3/5 md:w-3/5',
                    )}
                >
                    <div className={'mr-auto ml-auto md:mr-0 my-auto'}>
                        {userName && <Notifications />}
                    </div>
                    {userName && <UserChip userName={userName} />}
                    {!userName && (
                        <div
                            className={cn('my-auto mx-5 cursor-pointer', {
                                hidden: userName,
                                visible: !userName,
                            })}
                            onClick={() => performLogin(ual)}
                        >
                            <div className={cn('relative m-auto')}>
                                <img
                                    className={cn('w-6 h-6 m-auto')}
                                    src="/person-outline.svg"
                                    alt="Login"
                                    title={t('navigation.login')}
                                />
                            </div>
                            <div className={cn('relative w-full text-primary')}>
                                {t('navigation.login')}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <MultiAssetControlPanel />
        </div>
    )
}

export default Navigation
