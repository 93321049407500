import { addTransport } from './global'
import {
    installGlobalFetchHandler,
    installGlobalOnErrorHandler,
    installGlobalOnUnhandledRejectionHandler,
} from './handlers'
import { hiveServer } from './transports'

const logConfig = {
    useOnError: false,
    useOnRejection: false,
    useOnFetchFail: true,
}

export const setupTransports = () => {
    console.debug('Setting transports')
    console.debug('Adding hive server transport')
    addTransport(hiveServer())
    console.debug('Finished transports setup')
}

export const setupHandlers = () => {
    console.debug('Installing Debug Handlers')
    if (logConfig.useOnError) {
        console.debug('Installing global.onError handler')
        installGlobalOnErrorHandler()
    }
    if (logConfig.useOnRejection) {
        console.debug('Installing global.onUnhandledRejection handler')
        installGlobalOnUnhandledRejectionHandler()
    }
    if (logConfig.useOnFetchFail) {
        console.debug('Installing global.fetch handler')
        // added a few basic whitelist items to be ignored
        installGlobalFetchHandler((url, data) => {
            // filter these items
            for (let item of ['/__nextjs', '/_next', '/log-messages'])
                if (url.includes(item)) return false

            // filter is active for push transaction
            if (url.includes('/chain/push_transaction')) return true

            // default is disabled
            return false
        })
    }
    console.debug('Finished installing Debug Handlers')
}

export const setup = () => Promise.all([setupTransports(), setupHandlers()])
