import {
    Alert,
    Button,
    FormControlLabel,
    FormGroup,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { unsignedIntegerRegEx } from '../editor/EditorComponents'
import { isCollectionWhitelistedForPacks } from '../helpers/Api'
import { sleep } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import ImageElement from '../imageelement/ImageElement'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function MintNftPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionUniqueName = props['collectionUniqueName']
    const editSchemaName = props['editSchemaName']
    const editTemplateAttributes = props['editTemplateAttributes']
    const templateId = props['templateId']
    const [editNewAssetOwner, setEditNewAssetOwner] = useState(userName)
    const [nftsToMint, setNftsToMint] = useState(1)

    const [currentDisplayAction, setCurrentDisplayAction] = useState(0)

    const [transactionIds, setTransactionIds] = useState([])
    const addTransactionId = (transactionId) => {
        const tempTransactionIds = transactionIds
        tempTransactionIds.push(transactionId)
        setTransactionIds(tempTransactionIds)
    }

    const [toBeUsedInPacks, setToBeUsedInPacks] = useState(false)
    const [toBeUsedInDrops, setToBeUsedInDrops] = useState(false)

    const [errors, setErrors] = useState([])
    const addError = (error) => {
        const tempErrors = errors
        tempErrors.push(error)
        setErrors(tempErrors)
    }

    const [error, setError] = useState(null)

    const POPUP_STRING_CUTOFF = 50
    const MAX_ACTIONS_PER_TRANSACTION = 50

    const mint = async () => {
        setIsLoading(true)

        let currentAction = 0

        let transferError = null
        let mintsDone = 0
        while (mintsDone < nftsToMint) {
            const mintsThisTransaction = Math.min(
                MAX_ACTIONS_PER_TRANSACTION,
                nftsToMint - mintsDone,
            )
            const actions = [getBoostAction(activeUser)]

            for (let i = 0; i < mintsThisTransaction; i++) {
                const immutableData = editTemplateAttributes.immutableData

                const data = {
                    authorized_minter: userName,
                    collection_name: collectionUniqueName,
                    schema_name: editSchemaName,
                    template_id: parseInt(templateId),
                    new_asset_owner: toBeUsedInPacks
                        ? 'nfthivepacks'
                        : toBeUsedInDrops
                        ? 'nfthivedrops'
                        : editNewAssetOwner,
                    immutable_data: immutableData,
                    // TODO
                    mutable_data: [],
                    tokens_to_back: [],
                }

                const action = {
                    account: 'atomicassets',
                    name: 'mintasset',
                    authorization: [
                        {
                            actor: userName,
                            permission: activeUser['requestPermission'],
                        },
                    ],
                    data: data,
                }

                actions.push(action)
            }

            try {
                const result = await activeUser.signTransaction(
                    {
                        actions: actions,
                    },
                    {
                        expireSeconds: 300,
                        blocksBehind: 0,
                    },
                )
                addTransactionId(result.transactionId)
            } catch (e) {
                transferError = e.message
                addError(transferError)
                setError(transferError)
            } finally {
                mintsDone = mintsDone + mintsThisTransaction
                ++currentAction
                setCurrentDisplayAction(currentAction)
                if (nftsToMint - mintsDone > 0) {
                    sleep(10000)
                }
            }
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)

        callBack(
            !transferError,
            errors,
            transactionIds,
            editNewAssetOwner,
            nftsToMint,
        )
    }

    const cancel = () => {
        callBack(false, null, null, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
        setErrors([])
    }

    const [isWhiteListStatusLoading, setIsWhiteListStatusLoading] =
        useState(false)
    const [isWhiteListed, setIsWhiteListed] = useState(undefined)
    useEffect(async () => {
        setIsWhiteListStatusLoading(true)
        const whiteListStatus = await isCollectionWhitelistedForPacks(
            collectionUniqueName,
        )
        setIsWhiteListed(whiteListStatus)
        setIsWhiteListStatusLoading(false)
    }, [collectionUniqueName])

    return (
        <Popup title="Mint NFT" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to mint an NFT with the following attributes?
            </div>
            <div className="my-10">
                {isWhiteListStatusLoading ? (
                    <LoadingIndicator />
                ) : (
                    <div>
                        {isWhiteListed && (
                            <div className="mt-5 p-5 border rounded-md">
                                <div>
                                    <Alert severity="info">
                                        This collection is whitelisted to be
                                        used in NFTHive Packs. Check this box if
                                        you want to mint this NFT into a pack
                                        instead. You will be able to add this
                                        NFT to a pack later in the pack editor.
                                    </Alert>
                                </div>
                                <div className="mt-5 flex justify-center">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={toBeUsedInPacks}
                                                    onChange={(e) => {
                                                        setToBeUsedInPacks(
                                                            e.target.checked,
                                                        )
                                                        setToBeUsedInDrops(
                                                            false,
                                                        )
                                                    }}
                                                />
                                            }
                                            label="Use in a pack, mint to nfthivepacks"
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        )}
                        <div className="mt-5 p-5 border rounded-md">
                            <div>
                                <Alert severity="info">
                                    This collection can also be used in NFTHive
                                    Drops. Check this box if you want to mint
                                    this NFT into a drop instead. You will be
                                    able to add this NFT to a drop later in the
                                    drop editor.
                                </Alert>
                            </div>
                            <div className="mt-5 flex justify-center">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={toBeUsedInDrops}
                                                onChange={(e) => {
                                                    setToBeUsedInPacks(false)
                                                    setToBeUsedInDrops(
                                                        e.target.checked,
                                                    )
                                                }}
                                            />
                                        }
                                        label="Use in a drop, mint to nfthivedrops"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-10 justify-center">
                            <div className="mx-2">
                                <TextField
                                    value={
                                        toBeUsedInPacks
                                            ? 'nfthivepacks'
                                            : toBeUsedInDrops
                                            ? 'nfthivedrops'
                                            : editNewAssetOwner
                                    }
                                    label="Receiver of this NFT"
                                    onChange={(e) =>
                                        setEditNewAssetOwner(
                                            e.target.value
                                                .toLowerCase()
                                                .trim(' ', ''),
                                        )
                                    }
                                    disabled={
                                        toBeUsedInPacks || toBeUsedInDrops
                                    }
                                    error={editNewAssetOwner.length === 0}
                                    helperText=" "
                                />
                            </div>
                            <div className="mx-2">
                                <TextField
                                    value={nftsToMint}
                                    type="number"
                                    label="Amount of NFTs to mint"
                                    onChange={(e) =>
                                        setNftsToMint(e.target.value)
                                    }
                                    error={
                                        !unsignedIntegerRegEx.test(
                                            nftsToMint,
                                        ) || nftsToMint === '0'
                                    }
                                    helperText={
                                        !unsignedIntegerRegEx.test(
                                            nftsToMint,
                                        ) || nftsToMint === '0'
                                            ? 'Must be a positive integer'
                                            : ' '
                                    }
                                />
                            </div>
                        </div>
                        <div className="text-lg">Attributes</div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Value</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {editTemplateAttributes.immutableData.map(
                                    (attribute) => (
                                        <TableRow>
                                            <TableCell>
                                                {attribute.key}
                                            </TableCell>
                                            <TableCell>
                                                {[
                                                    'img',
                                                    'image',
                                                    'backimg',
                                                    'backimage',
                                                    'video',
                                                ].includes(attribute.key) ? (
                                                    <Tooltip
                                                        title={
                                                            <ImageElement
                                                                src={`${
                                                                    config.ipfs
                                                                }${attribute.value[1].replace(
                                                                    config.ipfs,
                                                                    '',
                                                                )}`}
                                                            />
                                                        }
                                                        followCursor
                                                        placement="top"
                                                    >
                                                        <div className="text-primary">
                                                            <a
                                                                href={`${
                                                                    config.ipfs
                                                                }${attribute.value[1].replace(
                                                                    config.ipfs,
                                                                    '',
                                                                )}`}
                                                                target="_blank"
                                                            >
                                                                {
                                                                    attribute
                                                                        .value[1]
                                                                }
                                                            </a>
                                                        </div>
                                                    </Tooltip>
                                                ) : attribute.value[0] ===
                                                  'string' ? (
                                                    attribute.value[1].length >
                                                    POPUP_STRING_CUTOFF ? (
                                                        attribute.value[1].substring(
                                                            0,
                                                            POPUP_STRING_CUTOFF,
                                                        ) + '...'
                                                    ) : (
                                                        attribute.value[1]
                                                    )
                                                ) : (
                                                    attribute.value[1]
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ),
                                )}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button
                        variant="outlined"
                        onClick={mint}
                        disabled={
                            editNewAssetOwner.length === 0 ||
                            !unsignedIntegerRegEx.test(nftsToMint) ||
                            nftsToMint === '0'
                        }
                    >
                        Mint NFT
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator
                    text={`Loading Transactions - Adding wait time to avoid Rate Limiting - Tx ${currentDisplayAction} / ${Math.ceil(
                        nftsToMint / MAX_ACTIONS_PER_TRANSACTION,
                    )}`}
                />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default MintNftPopup
