import cn from 'classnames'
import React from 'react'

export const ErrorNote = ({
    onClick,
    error,
    className = 'top-0 rounded-xl',
}) => {
    return (
        <div
            className={cn(
                'absolute bg-paper backdrop-filter backdrop-blur-3xl',
                'w-full h-20 max-w-160',
                'flex justify-center text-primary overflow-y-auto InsideScroll',
                'text-xs mx-auto p-2 leading-5',
                'border border-solid border-negative',
                'z-30',
                className,
            )}
            onClick={onClick}
        >
            <div className="m-auto">
                <img className="w-5 h-5" src="/Warning_icn.svg" alt="!" />
            </div>
            <div className="pl-4 m-auto text-center">{error}</div>
        </div>
    )
}

export default ErrorNote
