import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Collapse, Table, TableBody, TableCell, TableRow } from '@mui/material'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    formatNumberS,
    getDefaultAttrNames,
    getOwnerLink,
    parseAtomicImmutableData,
    parseAtomicMutuableData,
} from '../helpers/FormatLinks'

export const PreviewDetailsTable = ({
    asset,
    newOwner,
    currentAsset,
    bundleView,
    atomicData,
    attrNames = getDefaultAttrNames(),
    visible = true,
    className,
}) => {
    const { t } = useTranslation('common')

    const initialOpenItems = () => {
        const openItems = new Map()
        openItems.set(t('asset.details'), true)
        openItems.set(t('asset.immutable_data'), true)
        return openItems
    }

    const [toggleItem, setToggleItem] = useState(initialOpenItems())

    const toggleItemByName = (name) => {
        const newMap = new Map(toggleItem)
        newMap.set(name, !toggleItem.get(name))
        setToggleItem(newMap)
    }

    const collapsibleListItem = (name, children) => {
        return (
            <>
                <div
                    onClick={() => {
                        toggleItemByName(name)
                    }}
                    className="flex my-2 cursor-pointer"
                >
                    <div className="mr-2 my-auto">
                        {toggleItem.get(name) ? (
                            <ArrowDropUpIcon />
                        ) : (
                            <ArrowDropDownIcon />
                        )}
                    </div>
                    <div className="my-auto text-primary text-lg">{name}</div>
                </div>
                <Collapse in={toggleItem.get(name)}>
                    <div className="mb-5">{children}</div>
                </Collapse>
            </>
        )
    }

    const { assets, bundle, offer } = asset
    let {
        assetId,
        aAssetId,
        schema,
        symbol,
        contract,
        number,
        author,
        standard,
        total,
        burned,
        owner,
        seller,
        rarity_score,
        aether_value,
        templateId,
    } = asset

    if (standard === 'atomicassets') assetId = aAssetId

    useEffect(() => {}, [author, schema, visible])

    const assetMutableData = atomicData && parseAtomicMutuableData(atomicData)
    const assetImmutableData =
        atomicData && parseAtomicImmutableData(atomicData)

    if (bundle && !bundleView) {
        const a = assets[currentAsset]
        assetId = a.assetId
        schema = a.schema
        templateId = a.templateId
        rarity_score = a.rarity_score
    }

    const previewData = [
        ['ID', assetId],
        ['Template ID', templateId],
        [attrNames['number'], number],
        [
            t('asset.owner'),
            getOwnerLink(newOwner ? newOwner : offer ? seller : owner),
        ],
        [t('profile.total'), total],
        [t('asset.burned'), burned],
        [t('asset.schema'), schema],
        ['Symbol', symbol],
        ['Contract', contract],
        [
            t('ranking.rarity_score'),
            formatNumberS(rarity_score),
            !(rarity_score >= 0),
        ],
        ['Aether', formatNumberS(aether_value / 10000.0) + '/h', !aether_value],
    ]

    return (
        <div
            className={cn(
                className
                    ? className
                    : 'relative w-full mt-6 max-h-100 overflow-y-auto InsideScroll',
                {
                    hidden: !visible,
                },
            )}
        >
            {collapsibleListItem(
                t('asset.details'),
                <Table size="small">
                    <TableBody>
                        {previewData.map(
                            ([term, detail, ignore = false], i) =>
                                ignore === false &&
                                detail !== undefined &&
                                detail !== null && (
                                    <TableRow>
                                        <TableCell>{term}</TableCell>
                                        <TableCell>{detail}</TableCell>
                                    </TableRow>
                                ),
                        )}
                    </TableBody>
                </Table>,
            )}

            {assetImmutableData &&
                collapsibleListItem(
                    t('asset.immutable_data'),
                    <Table size="small">
                        <TableBody>
                            {Object.keys(assetImmutableData).map((key, i) => (
                                <TableRow>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>
                                        {assetImmutableData[key]}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>,
                )}
            {assetMutableData &&
                collapsibleListItem(
                    t('asset.mutable_data'),
                    <Table>
                        <TableBody>
                            {Object.keys(assetMutableData).map((key, i) => (
                                <TableRow>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>
                                        {assetMutableData[key]}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>,
                )}
        </div>
    )
}

export default PreviewDetailsTable
