import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import ErrorNote from '../common/util/ErrorNote'
import MainButton from '../common/util/input/MainButton'
import { get, post } from '../helpers/Api'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import ResultWindow from '../packs/ResultWindow'
import Popup from './Popup'

function UnpackPopup(props) {
    const asset = props['asset']

    const { name, assetId } = asset

    const image = asset['image']

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']

    const callBack = props['callBack']

    const { t } = useTranslation('common')

    const userName = activeUser ? activeUser['accountName'] : null
    const [isLoading, setIsLoading] = useState(true)
    const [loadingMessage, setLoadingMessage] = useState('Loading Transaction')
    const [error, setError] = useState()
    const claimOnly = props['claimOnly']
    const closeCallBack = props['closeCallBack']
    const [animation, setAnimation] = useState(null)
    const [showAnimation, setShowAnimation] = useState(true)
    const [showResults, setShowResults] = useState(false)
    const [pack, setPack] = useState(null)
    const [results, setResults] = useState(null)
    const [unpacked, setUnpacked] = useState(false)
    const [reloads, setReloads] = useState(0)

    const getPackInfo = async (asset) => {
        const body = {
            code: asset.contract,
            index_position: 1,
            json: 'true',
            key_type: 'i64',
            limit: 1,
            lower_bound: asset.packId,
            upper_bound: asset.packId,
            reverse: 'true',
            scope: asset.contract,
            show_payer: 'false',
            table: 'packs',
            table_key: '',
        }

        const url =
            process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                ? config.testapi + '/v1/chain/get_table_rows'
                : config.api + '/v1/chain/get_table_rows'
        const res = await post(url, body)

        if (res && res.status === 200 && res.data && res.data.rows) {
            setPack(res.data.rows[0])
        }

        setIsLoading(false)
    }

    useEffect(() => {}, [unpacked])

    useEffect(() => {
        setReloads(reloads + 1)
        getPackInfo(asset)
    }, [assetId])

    const claimPack = async (pack, asset, activeUser) => {
        const userName = activeUser['accountName']

        if (asset.contract === 'atomicpacksx') {
            const origin_roll_ids = []
            const result_templates = []

            const body = {
                code: asset.contract,
                index_position: 'primary',
                json: 'true',
                key_type: 'i64',
                limit: 1000,
                lower_bound: '',
                upper_bound: '',
                reverse: 'false',
                scope: asset.assetId,
                show_payer: 'false',
                table: 'unboxassets',
                table_key: '',
            }

            const url =
                process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                    ? config.testapi + '/v1/chain/get_table_rows'
                    : config.api + '/v1/chain/get_table_rows'
            const res = await post(url, body)

            if (res && res.status === 200 && res.data && res.data.rows) {
                res.data.rows.map((item) => {
                    origin_roll_ids.push(parseInt(item.origin_roll_id))
                    result_templates.push(parseInt(item.template_id))
                    return null
                })

                if (origin_roll_ids.length === 0) {
                    setError(
                        'Result not ready yet. Waiting for RNG queue. Try again in a few seconds.',
                    )
                    return
                }

                try {
                    await activeUser.signTransaction(
                        {
                            actions: [
                                {
                                    account: asset.contract,
                                    name: 'claimunboxed',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        origin_roll_ids: origin_roll_ids,
                                        pack_asset_id: asset.assetId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )

                    return { templates: result_templates }
                } catch (e) {
                    callBack({ unpacked: false, error: e.message })
                    console.log(e)
                    setError(e.message)

                    return null
                }
            }
        } else if (asset.contract === 'nfthivepacks') {
            const result_assets = []

            const body = {
                code: asset.contract,
                index_position: 'primary',
                json: 'true',
                key_type: 'i64',
                limit: 1,
                lower_bound: asset.assetId,
                upper_bound: asset.assetId,
                reverse: 'false',
                scope: asset.contract,
                show_payer: 'false',
                table: 'unpackassets',
                table_key: '',
            }

            const url =
                process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                    ? config.testapi + '/v1/chain/get_table_rows'
                    : config.api + '/v1/chain/get_table_rows'
            const res = await post(url, body)

            if (res && res.status === 200 && res.data && res.data.rows) {
                res.data.rows.map((item) => {
                    item['result_asset_ids'].map((assetId) =>
                        result_assets.push(parseInt(assetId)),
                    )
                    return null
                })

                try {
                    await activeUser.signTransaction(
                        {
                            actions: [
                                {
                                    account: asset.contract,
                                    name: 'claimunpack',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        pack_asset_id: asset.assetId,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )

                    return { assets: result_assets }
                } catch (e) {
                    callBack({ unpacked: false, error: e.message })
                    console.log(e)
                    setError(e.message)

                    return null
                }
            } else {
                setError(
                    'Result not ready yet. Waiting for RNG queue. Try again in a few seconds.',
                )
                return null
            }
        } else {
            const roll_indexes = []
            const result_assets = []

            const body = {
                code: asset.contract,
                index_position: 'primary',
                json: 'true',
                key_type: 'i64',
                limit: 1000,
                lower_bound: asset.assetId,
                upper_bound: asset.assetId,
                reverse: 'false',
                scope: asset.contract,
                show_payer: 'false',
                table: 'claimassets',
                table_key: '',
            }

            const url =
                process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
                    ? config.testapi + '/v1/chain/get_table_rows'
                    : config.api + '/v1/chain/get_table_rows'
            const res = await post(url, body)

            if (
                res &&
                res.status === 200 &&
                res.data &&
                res.data.rows &&
                res.data.rows.length > 0
            ) {
                res.data.rows[0].claims.map((item, index) => {
                    roll_indexes.push(index)
                    if (item && item['claim'])
                        result_assets.push(item['claim'][1]['asset_id'])
                })

                try {
                    await activeUser.signTransaction(
                        {
                            actions: [
                                {
                                    account: asset.contract,
                                    name: 'claim',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        claim_id: asset.assetId,
                                        roll_indexes: roll_indexes,
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                } catch (e) {
                    callBack({ unpacked: false, error: e.message })
                    console.log(e)
                    setError(e.message)

                    return null
                }

                return { assets: result_assets }
            } else {
                setError(
                    'Result not ready yet. Waiting for RNG queue. Try again in a few seconds.',
                )
                return null
            }
        }
    }

    const parseTemplateResults = (res) => {
        setResults({ templates: res })

        if (pack.display_data) {
            try {
                const displayData = JSON.parse(pack.display_data)

                if (
                    displayData &&
                    displayData.animation &&
                    displayData.animation.drawing
                ) {
                    const data = displayData.animation.drawing.data
                    const video = data && data.video ? data.video : null
                    const bgColor = displayData.animation.drawing.bg_color

                    setAnimation({ video: video, bgColor: bgColor })
                } else {
                    setAnimation(
                        displayData.animation
                            ? displayData.animation
                            : displayData,
                    )
                }
            } catch (e) {
                setAnimation('No Animation')
            }
        } else {
            setAnimation('No Animation')
        }
        setIsLoading(false)
    }

    const parseAssetResults = (res) => {
        setResults({ assets: res })

        if (pack && pack.display_data) {
            try {
                const displayData = JSON.parse(pack.display_data)

                if (
                    displayData &&
                    displayData.animation &&
                    displayData.animation.drawing
                ) {
                    const data = displayData.animation.drawing.data
                    const video = data && data.video ? data.video : null
                    const bgColor = displayData.animation.drawing.bg_color

                    setAnimation({ video: video, bgColor: bgColor })
                } else {
                    setAnimation('No Animation')
                }
            } catch (e) {
                setAnimation('No Animation')
                console.log(e)
            }
        }
        setIsLoading(false)
    }

    const loadResults = async (res) => {
        if (res && Object.keys(res).includes('templates')) {
            const templateIds = res['templates']

            get('templates?template_ids=' + templateIds.join(',')).then(
                parseTemplateResults,
            )
        } else if (res && Object.keys(res).includes('assets')) {
            const assetIds = res['assets']

            get('assets?asset_ids=' + assetIds.join(',')).then(
                parseAssetResults,
            )
        }
    }

    const getPackResult = () => {
        try {
            claimPack(pack, asset, activeUser).then((res) => loadResults(res))
        } catch (e) {
            callBack({ unboxed: false, error: e.message })
            console.log(e)
            setError(e.message)
        }
    }

    const handleUnpacked = () => {
        setUnpacked(true)
        setIsLoading(false)
    }

    const unbox = async () => {
        setLoadingMessage('Unpacking ...')
        setIsLoading(true)

        try {
            if (!pack) {
                throw 'Unable to Load Pack'
            }

            await activeUser.signTransaction(
                {
                    actions: [
                        {
                            account: 'atomicassets',
                            name: 'transfer',
                            authorization: [
                                {
                                    actor: userName,
                                    permission: activeUser['requestPermission'],
                                },
                            ],
                            data: {
                                from: userName,
                                memo:
                                    asset.contract === 'nfthivepacks'
                                        ? 'unpack'
                                        : 'unbox',
                                to: asset.contract,
                                asset_ids: [asset.assetId],
                            },
                        },
                    ],
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )
            setTimeout(handleUnpacked, 3000)
        } catch (e) {
            callBack({ unpacked: false, error: e.message })
            console.log(e)
            setError(e.message)
        }
    }

    const dismissError = () => {
        setError(null)
    }

    const cancel = () => {
        callBack({ unpacked: false })
        closeCallBack()
    }

    const acknowledge = () => {
        callBack({ unpacked: true })
        closeCallBack()
    }

    const stopAnimation = () => {
        setShowAnimation(false)
        setShowResults(true)
    }

    return animation ? (
        <ResultWindow
            stopAnimation={stopAnimation}
            showResults={showResults}
            showAnimation={showAnimation}
            results={results}
            animation={animation}
            acknowledge={acknowledge}
        />
    ) : (
        <Popup title={name} cancel={cancel} image={image}>
            <div className="relative text-lg text-left my-4">
                {error ? (
                    <ErrorNote error={error} onClick={dismissError} />
                ) : (
                    ''
                )}
                {unpacked || claimOnly
                    ? 'Claim your assets now!'
                    : `Are you sure you want to unpack ${name}?`}
            </div>
            {isLoading || !pack ? (
                <div className="mb-2">
                    <LoadingIndicator text={loadingMessage} />
                </div>
            ) : (
                <div
                    className={cn(
                        'relative m-auto mt-5 h-20 lg:h-8',
                        'flex justify-evenly flex-wrap lg:justify-end',
                    )}
                >
                    <MainButton className="PopupCancelButton" onClick={cancel}>
                        {t('asset.cancel')}
                    </MainButton>
                    {claimOnly || unpacked ? (
                        <MainButton
                            className="PopupSellButton"
                            onClick={getPackResult}
                        >
                            {t('asset.claim')}
                        </MainButton>
                    ) : (
                        <MainButton className="PopupSellButton" onClick={unbox}>
                            {t('asset.unpack')}
                        </MainButton>
                    )}
                </div>
            )}
        </Popup>
    )
}

export default UnpackPopup
