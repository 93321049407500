import cn from 'classnames'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const MarkDown = ({ text }) => {
    function LinkRenderer(props) {
        return (
            <a href={props.href} target="_blank" rel="noreferrer">
                {props.children}
            </a>
        )
    }

    return (
        <div
            className={cn(
                'prose prose-base prose-primary',
                'prose-a:text-bluel prose-a:no-underline',
                'prose-li:marker:text-neutral prose-li:marker:font-bold',
                'prose-strong:text-neutral prose-strong:font-bold prose-text:text-primary',
                'prose-headings:text-neutral',
                'prose-p:text-neutral',
            )}
        >
            <ReactMarkdown
                children={
                    text
                        ? text.replaceAll('<br>', '').replaceAll('</br>', '')
                        : ''
                }
                remarkPlugins={[remarkGfm]}
                components={{ a: LinkRenderer }}
            />
        </div>
    )
}

export default MarkDown
