import cn from 'classnames'
import React, { useEffect, useRef } from 'react'
import { auctionTime } from '../../utils/date'

export const AuctionTimeleft = ({ asset, position = 'justify-center' }) => {
    const { isAuction, endTime } = asset
    const element = useRef(null)

    useEffect(() => {
        if (!endTime) return
        const intervalId = setInterval(() => {
            if (element.current)
                element.current.innerText = auctionTime(endTime, Date.now())
        }, 1000)
        return () => clearInterval(intervalId)
    })
    return (
        <div
            className={cn(
                'w-full relative flex flex-wrap text-sm pt-4 my-auto text-white',
                position,
                {
                    hidden: !isAuction,
                },
            )}
        >
            Ends in:{' '}
            <div className={'ml-2'} ref={element}>
                {auctionTime(endTime, Date.now())}
            </div>
        </div>
    )
}
