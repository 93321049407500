import { Alert, Button, Collapse, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { unsignedIntegerRegEx } from '../editor/EditorComponents'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function EditPackTotalsPopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const packId = props['packId']

    const [totalPacks, setTotalPacks] = useState(props['packTotalPacks'])

    const [error, setError] = useState(null)

    const update = async () => {
        setIsLoading(true)

        const updatePackTotalsActions = [getBoostAction(activeUser)]

        const updatePackTotalsData = {
            authorized_account: userName,
            collection_name: collectionName,
            pack_id: packId,
            new_total: totalPacks,
        }

        const updatePackTotalsAction = {
            account: 'nfthivepacks',
            name: 'extendpack',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: updatePackTotalsData,
        }

        updatePackTotalsActions.push(updatePackTotalsAction)

        let updatePackTotalsError = null
        let updatePackTotalsTransactionId = null

        try {
            const updatePackTotalsResult = await activeUser.signTransaction(
                {
                    actions: updatePackTotalsActions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            updatePackTotalsTransactionId = updatePackTotalsResult.transactionId
        } catch (e) {
            updatePackTotalsError = e.message
            setError(e.message)
        }

        if (!updatePackTotalsError) {
            closeCallBack()
            setIsLoading(false)
            callBack(
                !updatePackTotalsError,
                updatePackTotalsError ? [updatePackTotalsError] : null,
                updatePackTotalsTransactionId,
            )
        }

        if (updatePackTotalsError) {
            setIsLoading(false)
            callBack(
                false,
                [updatePackTotalsError],
                updatePackTotalsTransactionId,
            )
        }
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    return (
        <Popup title="Update Total Pack Amount" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to update the total amount of pack #{packId} of the{' '}
                {collectionName} collection?
            </div>
            <div className="PopupBody">
                <div className="mt-10 mb-5">
                    <TextField
                        variant="outlined"
                        value={totalPacks}
                        type="number"
                        onChange={(e) => setTotalPacks(e.target.value)}
                        label="Total Packs"
                        error={
                            !totalPacks ||
                            totalPacks < 1 ||
                            !unsignedIntegerRegEx.test(totalPacks)
                        }
                    />
                </div>
                <Collapse
                    in={
                        !totalPacks ||
                        totalPacks < 1 ||
                        !unsignedIntegerRegEx.test(totalPacks)
                    }
                >
                    <div className="mb-5">
                        <Alert severity="error">
                            Value must be a whole, positive number!
                        </Alert>
                    </div>
                </Collapse>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button variant="outlined" onClick={update}>
                        Update Pack
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default EditPackTotalsPopup
