import axios from 'axios'
import config from '../../config.json'

const useProdConfig =
    process.env.NODE_ENV === 'production' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'staging'

export const pickEnvOr = (env, defaultValue) => {
    if (useProdConfig) return defaultValue
    if (typeof env === 'string' && env) return env
    return defaultValue
}

const endpoints = {
    v1:
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_V1_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_V1,
                  'https://stats.hivebp.io',
              ),
    statistics:
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_STATISTICS_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_STATISTICS,
                  'https://stats.hivebp.io',
              ),
    'set-api':
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_SET_API_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_SET_API,
                  'https://sets.nfthive.io',
              ),
    api:
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API,
                  'https://api.nfthive.io',
              ),
    'admin-api':
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API_TEST,
                  'https://test-api.nfthive.io',
              )
            : pickEnvOr(
                  process.env.NEXT_PUBLIC_HOST_API,
                  'https://api.nfthive.io',
              ),
}

export const get = (path, endpoint = 'api', init = {}) => {
    const server = endpoints[endpoint]
    const url = `${server}/${endpoint}/${path}`
    try {
        return fetch(url, {
            signal: init.signal ? init.signal : new AbortController().signal,
            headers: init.headers
                ? init.headers
                : { 'Content-Type': 'application/json' },
        })
            .then((res) => {
                if (res.status === 200) return res.json()
                else return { error: res.statusText }
            })
            .catch((ex) => console.error(ex))
    } catch (e) {
        console.log(`Failed to fetch ${url}`)
        console.error(e)
        return Promise.resolve(null)
    }
}

export const post_admin = (
    path,
    data,
    userName,
    password,
    endpoint = 'api',
) => {
    const token = Buffer.from(`${userName}:${password}`, 'utf8').toString(
        'base64',
    )
    const server = endpoints[endpoint]
    const url = `${server}/${endpoint}/${path}`
    return axios({
        method: 'post',
        url: url,
        data: data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getDelphiMedian = async () => {
    const body = {
        code: 'delphioracle',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: '',
        reverse: 'true',
        scope: 'waxpusd',
        show_payer: 'false',
        table: 'datapoints',
        table_key: '',
        upper_bound: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    if (res && res.status === 200 && res.data && res.data.rows) {
        const row = res.data.rows[0]

        if (row.median) return row.median
    }

    return null
}

export const getAtomicAsset = (assetId) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) + `/atomicmarket/v1/assets/${assetId}`,
    ).then((res) => res.json())
}

const getFilterParams = (filters) => {
    let filterStr = ''

    const {
        collections,
        page,
        user,
        schema,
        name,
        limit,
        orderDir,
        bidder,
        winner,
        sortBy,
        asset_id,
        rarity,
        seller,
        ids,
        artist,
        genre,
        template_ids,
        template_id,
    } = filters

    if (collections)
        filterStr += `&collection_whitelist=${collections.join(',')}`

    if (ids) filterStr += `&ids=${ids.join(',')}`

    if (template_ids)
        filterStr += `&template_whitelist=${template_ids.join(',')}`

    if (template_id) filterStr += `&template_id=${template_id}`

    if (page) filterStr += `&page=${page}`

    if (schema) filterStr += `&schema_name=${schema}`

    if (user) filterStr += `&owner=${user}`

    if (seller) filterStr += `&seller=${seller}`

    if (bidder) filterStr += `&bidder=${bidder}`

    if (winner) filterStr += `&participant=${winner}`

    if (name) filterStr += `&match=${escape(name)}`

    if (genre) filterStr += `&template_data.genre=${genre}`

    if (artist) filterStr += `&template_data.song_artist=${artist}`

    if (rarity) filterStr += `&template_data.rarity=${rarity}`

    if (limit) filterStr += `&limit=${limit}`

    if (orderDir) filterStr += `&order=${orderDir}`

    if (sortBy) filterStr += `&sort=${sortBy}`

    if (asset_id) filterStr += `&asset_id=${asset_id}`

    return filterStr
}

export const getAtomicAssets = (filters) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/assets?${getFilterParams(filters)}`,
    ).then((res) => res.json())
}

export const getAtomicAuction = (auctionId) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) + `/atomicmarket/v1/auctions/${auctionId}`,
    ).then((res) => res.json())
}

export const getAtomicTemplate = (templateId, collectionName) => {
    return fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/templates/${collectionName}/${templateId}`,
    ).then((res) => res.json())
}

export const get_ext = (url) => fetch(url).then((res) => res.json())

export const post = (url, data) =>
    axios({
        method: 'post',
        url: url,
        data: data,
    }).then((res) => res)

export const logMessage = (message) => {
    const server = endpoints['api']
    const encryptedMsg = window.btoa('nfthive' + message)
    const encryptedUrl = window.btoa('nfthive' + window.location.href)
    fetch(`${server}/api/log-message`, {
        method: 'POST',
        body: JSON.stringify({ message: encryptedMsg, url: encryptedUrl }),
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export const getCollectionThemeData = async (collection) => {
    const body = {
        json: true,
        code: 'neftyblocksa',
        scope: 'neftyblocksa',
        table: 'colthemedata',
        lower_bound: collection,
        upper_bound: collection,
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const getRules = async (ruleId) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: 'nfthivepacks',
        table: 'rules',
        lower_bound: ruleId,
        upper_bound: ruleId,
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const getPacksPool = async (poolId) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: 'nfthivepacks',
        table: 'pools',
        lower_bound: poolId,
        upper_bound: poolId,
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const isCollectionWhitelistedForPacks = async (collectionName) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: 'nfthivepacks',
        table: 'allconfig',
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return (
            res.data.rows[0].open_mode ||
            res.data.rows[0].col_wl.includes(collectionName)
        )
    }

    return null
}

export const getPack = async (packId) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: 'nfthivepacks',
        table: 'packs',
        lower_bound: packId,
        upper_bound: packId,
        table_key: '',
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const getAuthorizedCollections = async (userName) => {
    const response = await fetch(
        (process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.atomictest
            : config.atomic) +
            `/atomicassets/v1/collections?authorized_account=${userName}&limit=100`,
    )
    const json = await response.json()
    return json.data ? json.data : []
}

export const getReleases = async (collection) => {
    const body = {
        json: true,
        code: 'nfthivepacks',
        scope: collection,
        table: 'releases',
        table_key: '',
        index_position: 1,
        key_type: '',
        reverse: true,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    const releases = []
    if (res && res.status === 200 && res.data.rows.length > 0) {
        res.data.rows.map((row) => {
            releases.push(row)
        })
    }

    return releases
}

export const getUser = async (name) => {
    const body = {
        account_name: name,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_account'
            : config.api + '/v1/chain/get_account'

    let res = null
    try {
        res = await post(url, body)
    } catch (e) {
        return null
    }
    if (res && res.status === 200 && res.data) {
        return res.data
    }
    return null
}

export const getDropsAccountStats = async (user, dropID) => {
    const body = {
        json: true,
        code: 'nfthivedrops',
        scope: user,
        table: 'accounts',
        table_key: '',
        lower_bound: dropID,
        upper_bound: dropID,
        index_position: 1,
        key_type: '',
        limit: 1,
        reverse: false,
        show_payer: false,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data.rows.length > 0) {
        return res.data.rows[0]
    }

    return null
}

export const getDropKeys = async (dropId) => {
    return null

    const body = {
        code: 'neftyblocksd',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: '',
        upper_bound: '',
        reverse: 'true',
        scope: dropId,
        show_payer: 'false',
        table: 'authkeys',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    let result = null

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        result = res.data.rows[0]
    }

    return result
}

export const getWhiteList = async (drop) => {
    const body = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: drop.dropId,
        upper_bound: drop.dropId,
        reverse: 'true',
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'dropswl',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    let result = null

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        result = res.data.rows[0]
    }

    return result
}

export const getTransaction = async (trxId) => {
    const body = {
        id: trxId,
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.hyperiontest + '/v1/history/get_transaction'
            : config.hyperion + '/v1/history/get_transaction'

    const res = await post(url, body)

    if (res && res.status === 200 && res.data) {
        return res.data
    }

    return null
}

export const getProofOwn = async (dropId) => {
    const body = {
        code: 'nfthivedrops',
        index_position: 'primary',
        json: 'true',
        key_type: 'i64',
        limit: 1,
        lower_bound: dropId,
        upper_bound: dropId,
        reverse: 'true',
        scope: 'nfthivedrops',
        show_payer: 'false',
        table: 'dropproof',
        table_key: '',
    }

    const url =
        process.env.NEXT_PUBLIC_TESTNET === 'TRUE'
            ? config.testapi + '/v1/chain/get_table_rows'
            : config.api + '/v1/chain/get_table_rows'
    const res = await post(url, body)

    let result = null

    if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.rows &&
        res.data.rows.length > 0
    ) {
        result = res.data.rows[0]
    }

    return result
}

export default get
