import { Snackbar } from '@mui/material'
import cn from 'classnames'
import { useRouter } from 'next/router'
import qs from 'qs'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useUAL } from '../../hooks/ual'
import { Context } from '../waxplorer'
import { TransferInput } from './TransferInput'

export const MultiAssetControlPanel = () => {
    const [state, dispatch] = useContext(Context)

    const { t } = useTranslation('common')

    let values = []
    if (process.browser)
        values = qs.parse(
            window.location.search.substring(1, window.location.search.length),
        )

    const ual = useUAL()
    const userName = ual?.activeUser?.accountName
        ? ual.activeUser.accountName
        : null

    const router = useRouter()
    const location = router.asPath.substring(
        0,
        router.asPath.indexOf('?') > 0
            ? router.asPath.indexOf('?')
            : router.asPath.length,
    )

    const isMarket = location.includes('/market')
    const isAccount = [`/user/${userName}`, '/account'].includes(location)
    const isCollection = location && location.includes('/collection')

    const cancel = () => {
        dispatch({ type: 'SET_SELECTED_ASSETS', payload: [] })
    }

    return (
        <Snackbar
            open={
                state.selectedAssets &&
                state.selectedAssets.length > 0 &&
                (isMarket ||
                    (isAccount &&
                        values['tab'] &&
                        ['listings', 'inventory'].includes(values['tab'])) ||
                    (isCollection &&
                        values['tab'] &&
                        values['tab'] === 'open_market'))
            }
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <div
                className={cn(
                    'relative z-40 w-200 justify-center shadowed border border-primary',
                    'backdrop-blur-xl text-xs md:text-sm text-neutral overflow-y-auto',
                    'bg-page rounded-lg m-auto InsideScroll flex',
                )}
            >
                <img
                    className="absolute top-4 right-4 cursor-pointer z-50 h-4"
                    onClick={cancel}
                    src="/close_btn.svg"
                    alt={t('popups.wrapper.close-alt')}
                />
                <TransferInput />
            </div>
        </Snackbar>
    )
}
