import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-mde/lib/styles/css/react-mde-all.css'
import {
    dropPriceEditor,
    getCurrencies,
} from '../../components/editor/DropCreatorUtils'
import { floatRegEx } from '../editor/EditorComponents'
import { getBoostAction } from '../helpers/WaxApi'
import { PopupLoadingIndicator } from '../loadingindicator/PopupLoadingIndicator'
import ErrorMessage from './ErrorMessage'
import Popup from './Popup'

function UpdateDropPricePopup(props) {
    const { t } = useTranslation('common')

    const ual = props['ual'] ? props['ual'] : { activeUser: null }
    const activeUser = ual['activeUser']
    const userName = activeUser ? activeUser['accountName'] : null

    const callBack = props['callBack']
    const [isLoading, setIsLoading] = useState(false)
    const closeCallBack = props['closeCallBack']

    const collectionName = props['collectionName']
    const dropId = props['dropId']

    const [dropPrice, setDropPrice] = useState(props['dropPrice'])
    const [currencies, setCurrencies] = useState([])
    // TODO read these from somewhere
    const [dropPriceCurrency, setDropPriceCurrency] = useState('8,WAX,WAX')
    const [payoutRecipients, setPayoutRecipients] = useState([''])
    const [recipientSplits, setRecipientSplits] = useState([1])
    // TODO end
    const [error, setError] = useState(null)

    const create = async () => {
        setIsLoading(true)

        const settlement_symbol =
            dropPrice === '0'
                ? '0,NULL'
                : dropPriceCurrency.split(',')[0] +
                  ',' +
                  dropPriceCurrency.split(',')[1]

        const price_symbol = dropPriceCurrency.split(',')[2]
        const decimals =
            price_symbol === 'USD' ? 2 : dropPriceCurrency.split(',')[0]
        const listing_price =
            dropPrice === '0'
                ? '0 NULL'
                : `${Number(dropPrice).toFixed(decimals)} ${price_symbol}`

        const actions = [getBoostAction(activeUser)]

        const payoutRecipientWithSplits = []
        for (const i = 0; i < payoutRecipients.length; i++) {
            payoutRecipientWithSplits.push({
                account: payoutRecipients[i],
                share: recipientSplits[i],
            })
        }

        const dropPriceData = {
            authorized_account: userName,
            drop_id: dropId,
            listing_price: listing_price,
            settlement_symbol: settlement_symbol,
        }

        const dropPriceRecipientData = {
            authorized_account: userName,
            drop_id: dropId,
            price_recipients: payoutRecipientWithSplits,
        }

        const dropPriceAction = {
            account: 'nfthivedrops',
            name: 'setdropprice',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: dropPriceData,
        }

        const dropPriceRecipientAction = {
            account: 'nfthivedrops',
            name: 'setrecipient',
            authorization: [
                {
                    actor: userName,
                    permission: activeUser['requestPermission'],
                },
            ],
            data: dropPriceRecipientData,
        }

        actions.push(dropPriceAction)
        actions.push(dropPriceRecipientAction)

        let transferError = null
        let transactionId = null

        try {
            const result = await activeUser.signTransaction(
                {
                    actions: actions,
                },
                {
                    expireSeconds: 300,
                    blocksBehind: 0,
                },
            )

            transactionId = result.transactionId
        } catch (e) {
            transferError = e.message
            setError(e.message)
        }

        if (!transferError) {
            closeCallBack()
        }

        setIsLoading(false)
        callBack(
            !transferError,
            transferError ? [transferError] : null,
            transactionId,
        )
    }

    const cancel = () => {
        callBack(false, null, null)
        closeCallBack()
    }

    const dismissError = () => {
        setError(null)
    }

    useEffect(() => {
        getCurrencies().then((res) => setCurrencies(res))
    }, [dropId])

    return (
        <Popup title="Change Drop Price" cancel={cancel}>
            <div className="text-xl font-bold mb-4">
                Do you want to update the price of Drop {dropId} (
                {collectionName})?
            </div>
            <div className="PopupBody">
                <div>
                    {dropPriceEditor(
                        dropPrice,
                        setDropPrice,
                        dropPriceCurrency,
                        setDropPriceCurrency,
                        payoutRecipients,
                        setPayoutRecipients,
                        currencies,
                        recipientSplits,
                        setRecipientSplits,
                    )}
                </div>
            </div>
            {error ? (
                <div onClick={dismissError}>
                    <ErrorMessage layer={5} error={error} />
                </div>
            ) : (
                ''
            )}
            <div className="flex flex-row justify-end">
                <div className="mx-2">
                    <Button variant="outlined" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                <div className="mx-2">
                    <Button
                        variant="outlined"
                        onClick={create}
                        disabled={
                            !floatRegEx.test(dropPrice) || !dropPriceCurrency
                        }
                    >
                        Update Drop
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <PopupLoadingIndicator text={t('popups.loading_transaction')} />
            ) : (
                ''
            )}
        </Popup>
    )
}

export default UpdateDropPricePopup
