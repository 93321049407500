import cn from 'classnames'
import { useRouter } from 'next/router'
import React from 'react'
import Link from '../common/util/input/Link'

const SideNavigationAnchor = ({ children, active, onClick }) => {
    return (
        <div
            onClick={onClick}
            className={cn(
                'group relative w-full h-full flex items-center',
                'transform transition-color duration-200',
                {
                    'opacity-80 hover:opacity-100 hover:font-bold md:font-semibold':
                        !active,
                },
                { 'opacity-100 font-bold md:font-semibold': active },
            )}
        >
            {children}
        </div>
    )
}

const SideNavigationIndicator = ({ active }) => {
    return (
        <div
            className={cn(
                'top-0 relative h-full w-1 bg-primary border border-primary rounded-md -left-2',
                'transform transition-color duration-200',
                { 'opacity-0 group-hover:opacity-100': !active },
                { 'opacity-100': active },
            )}
        />
    )
}

export const SideNavigationItem = ({ onClick, href, title }) => {
    const router = useRouter()
    const path = router.asPath
    const route = router.route
    const query = router.query

    const active =
        query['subpage'] && href.includes(query['subpage'])
            ? path.includes(href)
            : path === href

    return (
        <Link href={href} className={'h-full w-full text-left'}>
            <SideNavigationAnchor
                active={active}
                onClick={onClick}
                type="button"
            >
                <SideNavigationIndicator active={active} />
                {title}
            </SideNavigationAnchor>
        </Link>
    )
}
