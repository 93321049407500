import cn from 'classnames'
import React from 'react'
import { Button } from './input/Button'

export const ScrollUpIcon = ({ className, ...props }) => (
    <Button
        className={cn('absolute right-4 bottom-10', 'lg:right-6', className)}
        {...props}
    >
        <img className="w-6 h-6" src="/up-arrow.svg" alt="up" />
    </Button>
)

export default ScrollUpIcon
