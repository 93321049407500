import cn from 'classnames'
import React, { useState } from 'react'
import { useUAL } from '../../hooks/ual'
import { formatRateNumber } from '../helpers/FormatLinks'
import { getBoostAction } from '../helpers/WaxApi'
import LoadingIndicator from '../loadingindicator'

export const AetherIcon = ({
    enabled,
    aetherValue,
    handleStake,
    staked,
    asset,
}) => {
    const [isLoading, setIsLoading] = useState(false)

    const { activeUser } = useUAL()

    const userName = activeUser ? activeUser['accountName'] : ''

    const stake = async () => {
        let { assetId, aAssetId, standard } = asset

        setIsLoading(true)

        let result
        try {
            switch (standard) {
                case 'simpleassets':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'simpleassets',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        to: 's.rplanet',
                                        assetids: [assetId],
                                        memo: 'stake',
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break

                case 'atomicassets':
                    result = await activeUser.signTransaction(
                        {
                            actions: [
                                getBoostAction(activeUser),
                                {
                                    account: 'atomicassets',
                                    name: 'transfer',
                                    authorization: [
                                        {
                                            actor: userName,
                                            permission:
                                                activeUser['requestPermission'],
                                        },
                                    ],
                                    data: {
                                        from: userName,
                                        memo: 'stake',
                                        asset_ids: [aAssetId],
                                        to: 's.rplanet',
                                    },
                                },
                            ],
                        },
                        {
                            expireSeconds: 300,
                            blocksBehind: 0,
                        },
                    )
                    break
            }
            handleStake(true)
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    return isLoading ? (
        <div className={cn('absolute w-10 h-11 -bottom-2 right-2')}>
            <LoadingIndicator />
        </div>
    ) : (
        <div
            className={cn('absolute w-10 h-11 -bottom-2 right-2', {
                'cursor-pointer': enabled,
            })}
            onClick={enabled && !staked ? stake : () => {}}
        >
            <img src="/asset_card/Wabe_winzig.svg" />
            <img
                className={cn('absolute w-6 h-6 top-1 left-2')}
                src={staked ? '/check.svg' : '/aether_icon.png'}
                alt="Aether per hour"
            />
            <div className={cn('absolute z-10 text-center w-10 top-6 text-xs')}>
                {formatRateNumber(aetherValue / 10000.0)}
            </div>
        </div>
    )
}
